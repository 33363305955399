import React, { useState, useEffect, useContext, createContext } from "react";
import { toast } from "react-toastify";

export default function BankList(props) {
  //console.log(props.bankItem);
  return (
    <>
      <table className="table table-striped table-sm ">
        <thead>
          <tr>
            <th className="text-center">
              {props.bankItem.bankAccountType.toUpperCase()} <br></br>
              <h4 className="font-weight-bold">
                {" "}
                S/ {Number(props.bankItem.bankAmount).toFixed(2)}
              </h4>
              Nº DE CUENTA : {props.bankItem.bankAccountNumber}
            </th>
          </tr>
        </thead>
      </table>

      <table className="table table-bordered table-striped table-sm bg-white">
        <thead>
          <tr>
            <th className="text-center">FECHA</th>
            <th className="text-center">OPERACIÓN</th>
            <th className="text-center" style={{ width: "30%" }}>
              DESCRIPCIÓN
            </th>
            <th className="text-center">MOVIMIENTOS</th>
            {/*<th className="text-center">SALIDA</th>*/}
            <th className="text-center">NRO OPERACION</th>
            <th className="text-center">CLIENTE</th>
            <th className="text-center">RESPONSABLE</th>
            <th className="text-center">EDITAR</th>
          </tr>
        </thead>
        <tbody>
          {props.operationsBanks.map((item, index) => {
            return (
              <tr key={index} value={item.cashFlowID}>
                <td className="text-uppercase text-center">
                  {item.transactionDate}
                </td>
                <td className="text-uppercase text-center">
                  {item.typeOperationDisplay}
                </td>
                <td className="text-uppercase text-center">
                  {item.description}
                </td>

                <td
                  className={
                    item.typeOperation == "D"
                      ? "text-right font-weight-bold"
                      : "text-right font-weight-bold text-danger"
                  }
                >
                  {item.typeOperation == "D"
                    ? "S/ " + item.total.toFixed(2)
                    : "S/ -" + item.total.toFixed(2)}

                  {/*item.typeOperation == "D"
                    ? "S/ " + item.total.toFixed(2)
            : " "*/}
                </td>
                {/*<td className="text-right font-weight-bold text-danger">
                  {item.typeOperation == "R"
                    ? "S/ -" + item.total.toFixed(2)
                    : " "}
          </td>*/}
                <td className="text-center">{item.bankOperationCode}</td>
                <td className="text-center">CLIENTE</td>
                <td className="text-center text-uppercase">{item.userName}</td>
                <td className="text-center">
                  <button
                    className="btn btn-link"
                    data-toggle="modal"
                    data-target="#modal-banks-operations"
                    onClick={(e) => {
                      props.setSelectedBankCashFlow(item);
                      //console.log("item", item);
                      
                    }}
                  >
                    <i className="text-success fas fa-edit"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
