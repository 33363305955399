import React, { useState, useEffect } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export function OrderDispatchReceived({detailsFromOrderDispatchReceived}) {
    const [filename, setFilename] = useState("ReporteVentas");
    return (
    
        <div
            className="modal fade"
            id="modal-order-dispatch-received"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Detalles del pedido</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                        <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="table-responsive">
                            <table className="table table-sm table-bordered"  id="table-to-xls">
                            <thead className="table-info small">
                                <tr>
                                    <td className="align-middle ">SKU</td>
                                    <td className="align-middle">PRODUCTO</td>
                                    <td className="align-middle">UND</td>
                                    <td className="align-middle">CANT.</td>
                                    <td className="align-middle">PRECIO</td>
                                    <td className="align-middle table-success">SUBTOTAL</td>
                                </tr>
                            </thead>
                            <tbody className="small">
                            {detailsFromOrderDispatchReceived.map((item, index) => {
                                return (
                                <tr key={index} value={item.operationDetailID}>
                                    <td className="align-middle">{item.productSku}</td>
                                    <td className="align-middle">{item.productName}</td>
                                    <td className="align-middle">{item.unitName}</td>
                                    <td className="align-middle text-center">{item.quantity}</td>
                                    <td className="align-middle text-right">S/{Number(item.price).toFixed(6)}</td>
                                    <td className="align-middle text-right">S/{Number(item.subtotal).toFixed(2)}</td>
                                 
                                </tr>
                                );
                            })}
                            </tbody>
                            <tfoot className="small">
                            <tr>
                                <td className="align-middle text-right" colSpan={3}>Total</td>
                                <td className="align-middle table-success text-center">{Number(detailsFromOrderDispatchReceived.reduce((pv, cv) => pv + cv.quantity, 0)).toFixed(0)}</td>
                                <td className="align-middle text-right table-success"></td>
                                <td className="align-middle text-right table-success">S/{Number(detailsFromOrderDispatchReceived.reduce((pv, cv) => pv + cv.subtotal, 0)).toFixed(2)}</td>
                            </tr>
                            </tfoot>
                            </table>
                        </div>

                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-outline-secondary mt-3 ml-1"
                            table="table-to-xls"
                            filename={filename}
                            sheet="report"
                            buttonText="Descargar como XLS"/>
                        

                    </div>

                    <div className="modal-footer justify-content-between">
                        <button
                            type="button"
                            className="btn btn-outline-light"
                            data-dismiss="modal"
                            id="close-modal"
                        >Cerrar
                        </button>
                    </div>

                </div>
            </div>
        </div>

  )
}
