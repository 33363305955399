import React, { useState, useEffect } from "react";
import { useHooksSubsidiary } from "../../../hooks";
import swal from "sweetalert2";

const initialState = {
  id: 0,
  name: "",
  serial: "",
  ruc: "",
  business: "",
  phone: "",
  address: "",
};

export function FragmentAddEditSubsidiary(props) {
  const { onRefetch, subsidiary } = props;
  const { addSubsidiary, updateSubsidiary } = useHooksSubsidiary();
  const [itemSubsidiary, setItemSubsidiary] = useState(initialState);
  useEffect(() => {
    if (subsidiary.id === undefined || subsidiary.id === 0) {
      setItemSubsidiary(initialState);
    } else {
      setItemSubsidiary(subsidiary);
    }
  }, [subsidiary]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    const newValues = {
      ...itemSubsidiary,
      [name]: value,
    };
    setItemSubsidiary(newValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.serial.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese un numero de serie!",
        //footer: e.target.document,
      });
      return false;
    }
    if (e.target.name.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese el nombre de la sede",
        //footer: e.target.document,
      });
      return false;
    }
    if (e.target.ruc.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese el numero de RUC",
        //footer: e.target.document,
      });
      return false;
    }
    if (e.target.business.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese la razon social de la sede",
        //footer: e.target.document,
      });
      return false;
    }
    try {
      if (subsidiary.id === undefined || subsidiary.id === 0) {
        await addSubsidiary(itemSubsidiary);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Sede registrada correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        await updateSubsidiary(itemSubsidiary);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Sede actualizada correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      document.getElementById("close-modal").click();
      onRefetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        id="form-person"
        className="embed-responsive p-1"
        onSubmit={handleSubmit}
        style={{ fontSize: 13 }}
      >
        <div className="card border-primary m-0">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label className="text-primary mb-1 mt-2">Serie</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="serial"
                      maxLength={4}
                      value={itemSubsidiary.serial}
                      onChange={handleChange}
                      placeholder="Serie Sede"
                    />
                  </div>
                  <div className="col-sm-9 col-md-9 col-lg-9">
                    <label className="text-primary mb-1 mt-2">Nombres</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="name"
                      maxLength={100}
                      value={itemSubsidiary.name}
                      onChange={handleChange}
                      placeholder="Nombre Sede"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label className="text-primary mb-1 mt-2">Ruc</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="ruc"
                      maxLength={11}
                      value={itemSubsidiary.ruc}
                      onChange={handleChange}
                      placeholder="Numero Ruc"
                    />
                  </div>
                  <div className="col-sm-9 col-md-9 col-lg-9">
                    <label className="text-primary mb-1 mt-2">
                      Razón Social
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="business"
                      maxLength={200}
                      value={itemSubsidiary.business}
                      onChange={handleChange}
                      placeholder="Razón Social"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label className="text-primary mb-1 mt-2">Telefono</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="phone"
                      maxLength={9}
                      value={itemSubsidiary.phone}
                      onChange={handleChange}
                      placeholder="Telefono"
                    />
                  </div>
                  <div className="col-sm-9 col-md-9 col-lg-9">
                    <label className="text-primary mb-1 mt-2">Dirección</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="address"
                      maxLength={200}
                      value={itemSubsidiary.address}
                      onChange={handleChange}
                      placeholder="Dirección"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 pt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-success w-50"
                    >
                      <i className="fas fa-check-circle" />{" "}
                      {subsidiary.id
                        ? "Actualizar Sucursal"
                        : "Registrar Sucursal"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
