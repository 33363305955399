import React from "react";
export function WidgetModal(props) {
  const { sizemodal, modaltittle, children } = props;

  return (
    <div
      id="modal-widget"
      className={"modal fade small"}
      role="dialog"
      aria-labelledby="ModalHelpTitle"
      aria-modal="true"
    >
      <div
        className={`modal-dialog modal-dialog-centered ${sizemodal}`}
        role="document"
      >
        <div className="modal-content">
          <div
            className="modal-header bg-primary"
            //style={{ backgroundColor: "rgb(233,156,5)" }}
          >
            {modaltittle && (
              <h6 className="modal-title text-white">{modaltittle}</h6>
            )}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer p-1 justify-content-center">
            <button
              id="close-modal"
              type="button"
              className="btn btn-sm btn-danger w-50"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
