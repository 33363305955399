import { useState } from "react";
import {
  getPpesApi,
  addPpeApi,
  updatePpeApi,
  deletePpeApi,
} from "../../apis/apis.hrm/apisPpe";
import { useHooksAuth } from ".";

export function useHooksPpe() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ppes, setPpes] = useState(null);
  const { auth } = useHooksAuth();
  const getPpes = async () => {
    try {
      setLoading(true);
      const response = await getPpesApi(auth.token);
      setLoading(false);
      setPpes(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const addPpe = async (data) => {
    try {
      setLoading(true);
      await addPpeApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const updatePpe = async (data) => {
    try {
      setLoading(true);
      await updatePpeApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const deletePpe = async (id) => {
    try {
      setLoading(true);
      await deletePpeApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    loading,
    error,
    ppes,
    getPpes,
    addPpe,
    updatePpe,
    deletePpe,
  };
}
