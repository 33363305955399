import React, { useState, useContext, useEffect } from "react";
import { fetchedStockInWarehouse } from "../../../apis/apis.dispatches/apisDispatch";


import { toast } from "react-toastify";


export function MerchandiseFilter({setOrders, vehicles, setVehicles, questionState, setQuestionState}) {
    

    const sendFilterDispatches = async (data) => {
      try {
        const response = await fetchedStockInWarehouse(data);
        setOrders(response)
        console.log(response)
        return response;
      } catch (error) {
        throw error;
      }
    };

    function handleChange(e) {
      setQuestionState({ ...questionState, [e.target.name]: e.target.value });
    }

    const handleQuery = (e) => {
      searchSuggestions();
    };
  
    function searchSuggestions() {
      sendFilterDispatches(questionState).then((res) => {
        let results = res.length;
        toast.info(
          results > 1
            ? "Se encontraron " + results.toString() + " resultados."
            : "Se encontró " + results.toString() + " resultado(s)."
        );
        setOrders(res);
      });
    }


  return (
    <>
      <div className="row">

        <div className="col-auto">
          <label className="">CUADRILLA:</label>
        </div>

        <div className="col-auto">
            <select
                className="form-control"
                name="warehouseID"
                value={questionState.warehouseID}
                onChange={handleChange}
            >
              <option value={0}>Elegir</option>
                {vehicles.map((item, index) => {
                  return (
                    <option key={index} value={item.vehicleID}>
                      {item.vehicleLicensePlate} | {item.gang.gangName}
                    </option>
                  );
                })}
                <option value={3}>Almacen Central</option>
            </select>
        </div>


        <div className="col-auto">
          <input
            type="date"
            className="form-control"
            name="otherDate"
            value={questionState.otherDate}
            onChange={handleChange}
          />
        </div>

        <div className="col-auto">
          <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
          <i className="fas fa-search"></i> Buscar
          </button>
        </div>


      </div>

    </>
  )
}
