import React, { useEffect, useRef, useContext, useState } from 'react';
import { ContextAuth } from "../../../contexts/ContextAuth";
import Chart from 'chart.js/auto';
import { SaleStatisticFilter } from "../fragments.dispatches/SaleStatisticFilter";

export function SaleStatisticPage() {

  const barChartRef = useRef(null);

  const { auth } = useContext(ContextAuth);
  


  const [salesWeekData, setSalesWeekData] = useState({
    monday:0,
    tuesday:0,
    wednesday:0,
    thursday:0,
    friday:0,
    saturday:0,
    sunday:0
  });

  useEffect(() => {
    //getSalesForChartPie();
    // console.log(auth.data)


    const barChartData = {
      labels: ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO'],
      datasets: [{
        label: 'PREVENTAS Y AUTOVENTAS',
        data: [salesWeekData.monday, salesWeekData.tuesday, salesWeekData.wednesday, salesWeekData.thursday, salesWeekData.friday, salesWeekData.saturday],
        backgroundColor: '#07bc0c'
      }]
    };

    const barChartOptions = {
      responsive: true,
      // maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    const barChart = new Chart(barChartRef.current, {
      type: 'bar',
      data: barChartData,
      options: barChartOptions
    });

    return () => {
      barChart.destroy();
    };
  }, [salesWeekData]);
  return (
    <>

<section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ventas por semana</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Distribuciones</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">

            
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    
                    
                    <div className='col-4'>
                    <SaleStatisticFilter setSalesWeekData={setSalesWeekData} />
                    </div>
                    
                    <div className='col-8'>
                      <canvas ref={barChartRef} />
                    </div>
                  </div>

                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
