import { LayoutsHome, LayoutsLogin } from "../layouts";
import {
  PageHome,
  PageLogin,
  PagePpe,
  PageSubsidiary,
  PageUser,
} from "../modules";
import { PageGang } from "../modules/modules.hrm/pages.hrm/PageGang";
const routesHRM = [
  {
    path: "/",
    layout: LayoutsHome,
    component: PageHome,
  },
  {
    path: "/login",
    layout: LayoutsLogin,
    component: PageLogin,
  },
  {
    path: "/employees",
    layout: LayoutsHome,
    component: PageUser,
  },
  {
    path: "/subsidiaries",
    layout: LayoutsHome,
    component: PageSubsidiary,
  },
  {
    path: "/gangs",
    layout: LayoutsHome,
    component: PageGang,
  },
  {
    path: "/epps",
    layout: LayoutsHome,
    component: PagePpe,
  },
];
export default routesHRM;
