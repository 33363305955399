import React, { useState, useEffect } from "react";

export function ProductList(props) {
  useEffect(() => {
    //console.log('products', props.products);
  }, []);
  return (
    <>
      <button
        className="btn btn-light border my-3"
        data-toggle="modal"
        data-target="#modal-product"
        onClick={() => props.setItemProduct({})}
      >
        Agregar Nuevo Producto
      </button>

      <p className="float-right">
        Total products list: {props.products.length}
      </p>
      <div className="table-responsive">
      <table className="table table-bordered" style={{fontSize:"medium"}}>
        <thead className="bg-light ">
          <tr>
           
            <th
              rowSpan={2}
              className="align-middle text-center text-uppercase font-weight-light"
              style={{ width: "5%" }}
            >
              #
            </th>
           
            
            <th
              rowSpan={2} colSpan={3}
              className="align-middle text-center text-uppercase font-weight-light"
              style={{ width: "30%" }}
            >
              descripcion
            </th>
            
            <th
              rowSpan={2}
              className="align-middle text-center text-uppercase font-weight-light"
              style={{ width: "5%" }}
            >
              imagen
            </th>


            <th
              colSpan={6}
              className="align-middle text-center text-uppercase font-weight-light"
              style={{ width: "65%" }}
            >
              tarifario
            </th>

          </tr>
          <tr>
            {/*<th colSpan={3} className="align-middle font-weight-light">
              <div className="row  ">
                <div className="col-6 align-self-center text-center text-uppercase">
                  almacen
                </div>
                <div className="col-6 align-self-center text-center text-uppercase">
                  stock actual
                </div>
              </div>
            </th>*/}

            <th colSpan={6}>
              <div className="row font-weight-light">
                <div className="col-2 text-center text-uppercase">unidad</div>
                <div className="col-2 text-center text-uppercase">COST X UND<br/>P. VEN TIENDA</div>
                <div className="col-2 text-center text-uppercase">COST CJ PEPSICO<br/>P. VEN PUBLICO</div>
                <div className="col-2 text-center text-uppercase">Costo Mayorista </div>
                <div className="col-2 text-center text-uppercase">Costo Grifo Primax </div>
                <div className="col-2 text-center text-uppercase">Costo para Sunat </div>

                <div className="col-2 text-center text-uppercase">%dscto</div>
                <div className="col-2 text-center text-uppercase">und min</div>
                <div className="col-2 text-center text-uppercase">edit.</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.products.map((item, index) => {
            let pathDefault = "https://firebasestorage.googleapis.com/v0/b/deliveryapp-e121f.appspot.com/o/guille%2Fimages%2F84e40303-c3c6-4eeb-bd3c-064afb593ba5?alt=media&token=3a87c275-6b64-40e0-801b-88b0719efa8e";
            return (
              <tr key={index} value={item.productID} className="smallville">
                <td className="align-middle">
                  <button
                    className={item.isGroup?"btn btn-danger border":"btn btn-success border"}
                    data-toggle="modal"
                    data-target="#modal-product"
                    onClick={() => {props.setItemProduct(item);}}
                  >
                    {Number(item.code).toFixed(2)}
                  </button>
                  
                </td>
                
                <td className="align-middle" colSpan={3}>
                  <span className="text-primary">SKU: </span>{item.sku}<br></br>
                  <span className="text-primary">NOM. COMPRA: </span>{item.purchaseName}<br></br>
                  <span className="text-primary">NOM. VENTA: </span>{item.saleName}<br></br>
                  <span className="text-primary">MARCA: </span>{item.productBrandName}<br></br>
                  <span className="text-primary">CLASIFICACION: </span>{item.classification=="B"?"BRONCE":item.classification=="P"?"PLATA":"ORO"}<br></br>
                  
                  ESTADO: {item.available?<span className="text-warning font-weight-bold">DISPONIBLE</span>:"NO DISPONIBLE"}<br></br>
                  ES CABEZA DE GRUPO: {item.isGroup?<span className="text-success font-weight-bold">SI</span>:"NO"}<br></br>
                  PERTENECE AL GRUPO: <span className="text-danger font-weight-bold">{item.parentProductName}</span><br/>

                </td>
                
                <td>
                  <img 
                    onError={(ev) => {
                      ev.target.src = pathDefault;
                      ev.target.onerror = null;
                    }} 
                    src={item.path ? item.path.replaceAll('.png', '_thumb_100.png') : pathDefault} 
                    width={50} 
                    alt="path" 
                  />
                </td>



                {/*<td colSpan={3} className="align-middle">
                  {item.warehouseDict.map((inventory, i) => {
                    return inventory.operationWarehouseName?(

                        <div key={i} className="row mb-1 border">
                        <div className="col-8 justify-content-center d-flex align-items-center text-center">
                          {inventory.operationWarehouseName}
                        </div>
                        <div className="col-4 bg-light">
                          {inventory.remainingQuantity}
                        </div>
                      </div>
                      
                    ):"";
                  })}
                </td>*/}

                <td colSpan={6} className="align-middle">
                  {item.productTariffList.map((tariff, t) => (
                    <div key={t} className="row">
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        {tariff.unitName}
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                      <span className={tariff.unitName=="CJ"?"text-secondary":tariff.unitName=="UND"?"text-secondary":"text-dark"}>
                        S/{Number(tariff.priceSale).toFixed(6)}
                      </span>
                        
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <span className={tariff.unitName=="CJ"?"text-secondary":tariff.unitName=="UND"?"text-secondary":"text-dark"}>
                          S/{Number(tariff.pricePurchase).toFixed(2)}
                        </span>
                      </div>

                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <span className={tariff.unitName=="CJ"?"text-secondary":tariff.unitName=="UND"?"text-secondary":"text-dark"}>
                        {tariff.unitName=="UND"?`S/${Number(tariff.priceSaleWholesale).toFixed(2)}`:"-"}
                        </span>
                      </div>

                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <span className={tariff.unitName=="CJ"?"text-secondary":tariff.unitName=="UND"?"text-secondary":"text-dark"}>
                          {tariff.unitName=="UND"?`S/${Number(tariff.priceSalePrimax).toFixed(2)}`:"-"}
                        </span>
                      </div>

                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <span className={tariff.unitName=="CJ"?"text-secondary":tariff.unitName=="UND"?"text-secondary":"text-dark"}>
                        {tariff.unitName=="UND"?`S/${Number(tariff.priceSaleSunat).toFixed(2)}`:"-"}
                        </span>
                      </div>

                      <div className="col-2 d-flex justify-content-center align-items-center">
                        {tariff.percentageDiscount}%
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        {tariff.quantityMinimum}
                      </div>
                      <div className="col-2 text-center">
                        <button
                          className="btn btn-light border"
                          data-toggle="modal"
                          data-target="#modal-product-tariff"
                          onClick={() => {
                            tariff.productSaleName = item.saleName;
                            props.setItemProductTariff(tariff);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-block btn-light border mt-1"
                        data-toggle="modal"
                        data-target="#modal-product-tariff"
                        onClick={() =>
                          props.setItemProductTariff({
                            productTariffID: "0",
                            unitID: "0",
                            unitName: "",
                            productSaleName: item.saleName,
                            productID: item.productID,
                            priceSale: "",
                            pricePurchase: "",
                            percentageDiscount: "",
                            quantityMinimum: "",
                            
                          })
                        }
                      >
                        <i className="fas fa-plus"></i> PRESENTACION
                      </button>
                    </div>
                  </div>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>
      </div>

    </>
  );
}
