import React, { useState, useEffect, useContext, createContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { toast } from "react-toastify";
import { deleteWarehouse } from "../../../apis/apis.logistics/apisProduct";


export function WarehouseList(props) {
  // const [warehouses, setWarehouses]  = useState([]);

  useEffect(() => {}, []); // <-- Have to pass in [] here!


  function removeWarehouseInGrid(item) {
    return props.warehouses.filter(
      (warehouse) => warehouse.warehouseID != item.warehouseID
    );
  } 

  const restRemoveWarehouse = async (registerData) => {
    try {
      const response = await deleteWarehouse(registerData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  function handleBtnRemove(item) {
    if (window.confirm("¿Estás seguro de que deseas anular esta operación?")) {
      if (item.productDict.length) {
        toast.error("El almacen aun contiene productos.");
      } else {

        restRemoveWarehouse(item)
        .then(async (r) => {
          if (r.status == 201 && r.success) {
            await props.setWarehouses(removeWarehouseInGrid(item));
            toast.success("almacen eliminado con exito!");
          } else {
            toast.error("Ocurrio algun error");
          }
        });

      }
    }
  }

  return (
    <>
      <button
        className="btn btn-light border my-3"
        data-toggle="modal"
        data-target="#modal-warehouse-register"
        onClick={(e) =>
          props.setSelectedWarehouse({
            warehouseID: 0,
            warehouseName: "",
            subsidiaryID: "",
            subsidiaryName: "",
            warehouseCategory: "",
            warehouseCategoryDisplay: "",
            productDict: [],
          })
        }
      >
        Agregar Nuevo almacen
      </button>
      <p className="float-right">
        Total warehouses list: {props.warehouses.length}
      </p>
      <table className="table table-bordered  font-weight-light">
        <thead>
          <tr>
            <th className="align-middle text-center text-uppercase">
              codigo
            </th>
            <th className="align-middle text-center text-uppercase">
              nombre
            </th>
            <th className="align-middle text-center text-uppercase">
              tipo
            </th>
            <th className="align-middle text-center text-uppercase">
              sede
            </th>
            <th className="align-middle text-center text-uppercase">
              acciones
            </th>
          </tr>
          
        </thead>
        <tbody>
          {props.warehouses.map((item, index) => {
            return (
              <tr key={index} value={item.warehouseID}>
                <th scope="row">{item.warehouseID}</th>
                <td>{item.warehouseName}</td>
                <td>{item.warehouseCategoryDisplay}</td>

                
                <td className="align-self-center text-center">
                  {item.subsidiaryName}
                </td>
                <td className="align-self-center text-center">
                  <button
                    className="btn btn-primary border font-weight-light"
                    data-toggle="modal"
                    data-target="#modal-warehouse-register"
                    onClick={(e) => props.setSelectedWarehouse(item)}
                  >
                    Editar <i className="fas fa-edit"></i>
                  </button>
                  
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
