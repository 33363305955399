import React from 'react'

export function UnitList(props) {
  

  return (
    <>
      <button
        className="btn btn-light border my-3"
        data-toggle="modal"
        data-target="#modal-unit"
        onClick={() => props.setSelectedUnit({})}
      >
        Agregar Nueva Unidad
      </button>
      <p className="float-right">Total units list: {props.units.length}</p>
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th
              className="align-middle text-center text-uppercase"
            >
              codigo
            </th>
            <th
              className="align-middle text-center text-uppercase"
            >
              nombre
            </th>
            <th
              className="align-middle text-center text-uppercase"
            >
              descripcion
            </th>
            <th
              className="align-middle text-center text-uppercase"
            >
              acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {props.units.map((item, index) => {
            return (
              <tr key={index} value={item.unitID}>
                <td>{item.unitID}</td>
                <td>{item.unitName}</td>
                <td>{item.unitDescription}</td>
                <td>
                  <button
                    className="btn btn-light border"
                    data-toggle="modal"
                    data-target="#modal-unit"
                    onClick={() => props.setSelectedUnit(item)}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
