import React from "react";
import { queryDocumentReniec } from "../../../apis/apis.logistics/apisSunat";
import { toast } from "react-toastify";
import { personDocumentTypes, igvOptions } from "../../../configs/Data";

export function NewOrderPurchaseOperationForm({order, setOrder, selectedOption, setSelectedOption}) {

      const getPersonData = async (
        typeDocument,
        numberDocument,
        isClient,
        isSupplier
      ) => {
        try {
          const response = await queryDocumentReniec({
            documentType: typeDocument,
            documentNumber: numberDocument,
            isClient: isClient,
            isSupplier: isSupplier,
          });
          return response;
        } catch (error) {
          throw error;
        }
      };
      const handleChangeOperation = (e) => {
        setOrder({ ...order, [e.target.name]: e.target.value });
      };
      const handleChangeOperationSupplierDocumentType = (e) => {
        setOrder({
          ...order,
          supplierDocumentType: e.target.value,
          operationDocumentTypeID: e.target.value == "06" ? "03" : "01",
        });
      };
      const handleChangeOperationSupplier = (e) => {
        setOrder({
          ...order,
          [e.target.name]: e.target.value,
        });
      };
      const handleKeyDownSupplier = async (e) => {
        if (e.keyCode === 13) {
          let res = await getPersonData(
            order.supplierDocumentType,
            order.supplierDocumentNumber,
            false,
            true
          );
    
          if (!res.error) {
            setOrder({
              ...order,
              supplierFirstSurname: res.firstSurname,
              supplierName: res.name,
              supplierSecondSurname: res.secondSurname,
              supplierFiscalAddress: res.fiscalAddress,
            });
            toast.info(res.message);
          } else {
            setOrder({
              ...order,
              supplierFirstSurname: "",
              supplierName: "",
              supplierSecondSurname: "",
              supplierFiscalAddress: "",
            });
            toast.error(res.message);
          }
        }
      };

      const handleChangePercentageIgv = (e) => {

        if (e.target.value.length == 3) return false; //limits to 2 digit entry

        let igv, base, total;
        let amountDiscount =
          Number(selectedOption.amount) - Number(selectedOption.discount);
        if (order.conditionIgv == "02") {
          base = amountDiscount;
          igv = (base * Number(e.target.value)) / 100;
          total = base + Number(igv);
        } else if (order.conditionIgv == "01") {
          base = amountDiscount / (1 + Number(e.target.value) / 100);
          igv = amountDiscount - base;
          total = amountDiscount;
        } else if (order.conditionIgv == "NA") {
          base = amountDiscount;
          igv = 0;
          // total = base + igv;
          total = 0;
        }
    
        setSelectedOption({
          ...selectedOption,
          amountDiscount: base.toFixed(4),
          igv: igv.toFixed(4),
          amountIgv: total.toFixed(4),
        });
    
        setOrder({
          ...order,
          percentageIgv: e.target.value,
          operationItems: order.operationItems.map((item) => {
            if (order.conditionIgv == "02") {
              base = Number(item.amount) - Number(item.discount);
              igv = (base * Number(e.target.value)) / 100;
              total = base + Number(igv);
            } else if (order.conditionIgv == "01") {
              base =
                (Number(item.amount) - Number(item.discount)) /
                (1 + Number(e.target.value) / 100);
              igv = Number(item.amount) - Number(item.discount) - base;
              total = Number(item.amount) - Number(item.discount);
            } else if (order.conditionIgv == "NA") {
              base = Number(item.amount) - Number(item.discount);
              igv = 0;
              // total = base + igv;
              total = 0;
            }
    
            item.amountDiscount = base.toFixed(4);
            item.igv = igv.toFixed(4);
            item.amountIgv = total.toFixed(4);
            return item;
          }),
        });
      };
    
      const handleChangeConditionIgv = (e) => {
        let amountDiscount, base, igv, total;
    
        amountDiscount =
          Number(selectedOption.amount) - Number(selectedOption.discount);
        if (e.target.value == "02") {
          base = amountDiscount;
          igv = (base * Number(order.percentageIgv)) / 100;
          total = base + Number(igv);
        } else if (e.target.value == "01") {
          base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
          igv = amountDiscount - base;
          total = amountDiscount;
        } else if (e.target.value == "NA") {
          base = amountDiscount;
          igv = 0;
          //total = base + igv;
          total = 0;
        }
    
        setSelectedOption({
          ...selectedOption,
          amountDiscount: base.toFixed(4),
          igv: igv.toFixed(4),
          amountIgv: total.toFixed(4),
        });
        setOrder({
          ...order,
          conditionIgv: e.target.value,
          operationItems: order.operationItems.map((item) => {


            /*console.log(order.operationStatus)
            if(order.operationStatus=="01" || order.operationStatus=="03"){
              item.amount =  item.quantityOrdered * item.price;
            }else{
              item.amount =  item.quantity * item.price;
            }

            item.discount = (item.amount * order.percentageIgv) / 100;
            // amountDiscount = item.amoun - item.discount;*/


            if (e.target.value == "02") {
              amountDiscount = Number(item.amount) - Number(item.discount);
              base = amountDiscount;
              igv = (base * Number(order.percentageIgv)) / 100;
              total = base + igv;
            } else if (e.target.value == "01") {
              amountDiscount = Number(item.amount) - Number(item.discount);
              base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
              igv = amountDiscount - base;
              total = amountDiscount;
            } else if (e.target.value == "NA") {
              amountDiscount = Number(item.amount) - Number(item.discount);
              base = amountDiscount;
              igv = 0;
              // total = base + igv;
              total = 0;
            }
    
            item.amountDiscount = base.toFixed(4);
            item.igv = igv.toFixed(4);
            item.amountIgv = total.toFixed(4);
            return item;
          }),
        });
      };
      
  /*useEffect(() => {
    getWarehouses(auth.data.subsidiary.subsidiary_id);
  }, []);*/
  return (
    <>
      <div className="card bg-light ">
        <div className="card-body" style={{ fontSize: "small" }}>
          <p>DATOS OPERACION</p>
          <hr />

          <div className="row mb-2">
            <div className="col-2">
              <label className="">FECHA:</label>
            </div>
            <div className="col-2">
              <input
                type="date"
                name="operationDate"
                className="form-control"
                value={order.operationDate}
                onChange={handleChangeOperation}
              />
            </div>

            <div className="col-2">
              <select
                className="form-control"
                name="igvOption"
                id="igvOption"
                value={order.conditionIgv}
                onChange={(e) => handleChangeConditionIgv(e)}
              >
                {igvOptions.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-2">
              <input
                type="number"
                className="form-control text-right"
                name="percentageIgv"
                id="percentageIgv"
                value={order.percentageIgv}
                onChange={handleChangePercentageIgv}
              />
            </div>

            <div className="col-2">
              <input
                type="text"
                maxLength={20}
                className="form-control text-left"
                name="operationDocumentNumber"
                id="operationDocumentNumber"
                placeholder="NRO FACTURA"
                autoComplete="off"
                value={order.operationDocumentNumber}
                onChange={(e) => {
                  setOrder({...order, operationDocumentNumber: e.target.value})
                }}
              />
            </div>
            <div className="col">
            <input
                type="text"
                maxLength={20}
                className="form-control text-left"
                name="code"
                id="code"
                placeholder="COD PEDIDO"
                autoComplete="off"
                value={order.code}
                onChange={(e) => {
                  setOrder({...order, code: e.target.value})
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body" style={{ fontSize: "small" }}>
          <p>DATOS PROVEEDOR</p>
          <hr />

          <div className="row mb-2">
            <div className="col-2">
              <label className="">TIP Y NRO DE DOC:</label>
            </div>
            <div className="col-2">
              <select
                className="form-control"
                name="supplierDocumentType"
                value={order.supplierDocumentType}
                onChange={handleChangeOperationSupplierDocumentType}
              >
                {personDocumentTypes.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-2">
              <input
                type="text"
                className="form-control"
                name="supplierDocumentNumber"
                placeholder="Ingrese Texto"
                value={order.supplierDocumentNumber}
                onChange={handleChangeOperationSupplier}
                onKeyDown={handleKeyDownSupplier}
                maxLength={11}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-2">
              <label className="">NOMBRES / RAZ SOCIAL:</label>
            </div>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                name="supplierName"
                placeholder="Ingrese Texto"
                value={order.supplierName}
                onChange={handleChangeOperationSupplier}
                maxLength={200}
              />
            </div>
          </div>
          {order.supplierDocumentType != "06" ? (
            <div className="row mb-2">
              <div className="col-2">
                <label className="">APE PATERNO:</label>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control"
                  name="supplierFirstSurname"
                  placeholder="Ingrese Texto"
                  value={order.supplierFirstSurname}
                  onChange={handleChangeOperationSupplier}
                  maxLength={200}
                  autoComplete="off"
                />
              </div>

              <div className="col-2">
                <label className="">APE MATERNO:</label>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control"
                  name="supplierSecondSurname"
                  placeholder="Ingrese Texto"
                  value={order.supplierSecondSurname}
                  onChange={handleChangeOperationSupplier}
                  maxLength={200}
                  autoComplete="off"
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-2">
                <label className="">DIRECCION:</label>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className="form-control"
                  name="supplierFiscalAddress"
                  placeholder="Ingrese Texto"
                  value={order.supplierFiscalAddress}
                  onChange={handleChangeOperationSupplier}
                  maxLength={500}
                  autoComplete="off"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
