import React, { useEffect, useContext, useState } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedSupplierbycash } from "../../../apis/apis.accounting/apisCash";
import { AccountSearch } from "../fragments.accounting/AccountPaySearch";
import { AccountPayList } from "../fragments.accounting/AccountPayList";
import { AccountPayForm } from "../fragments.accounting/AccountPayForm";
import {
  fetchedBanks,
  fetchedAllBanks,
} from "../../../apis/apis.accounting/apisCash";

export function AccountPayPage() {
  const [suppliersCash, setSupplierCash] = useState([]);
  const { auth } = useContext(ContextAuth);
  const [operationsAccountpay, setOperationsAccountPay] = useState([]);

  const [cashes, setCashes] = useState([]);
  const [banks, setBanks] = useState([]);  

  /*const [cashItem, setCashItem] = useState({
    cashAmount: "0",
    cashID: "0",
    cashName: "",
    cashStatus: "NA",
    lastDateStatus: "",
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    checkCloseCash: "",
    userID: auth.data.user_id,
  });

  const [bankItem, setBankItem] = useState({
    balance: "0",
    bankAccountNumber: "",
    bankAccountTypeDisplay: "0",
    bankAmount: "",
    bankCurrencyType: "0",
    bankID: "0",
    bankName: "",
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
  });*/

  // console.log("cashes", cashes);
  //console.log(auth)

  const [supplierItem, setSupplierItem] = useState({
    supplierID: "0",
    supplierName: "",
    supplierFiscalAddress: "0",
    supplierDocumentNumber: "",
    supplierAmount: "",
  });

  const [newPayAmortize, setNewPayAmortize] = useState({
    accountPayCashID: "0",
    accountPayTypePay: "0",
    accountPayAmountPay: "",
    accountPayDescription: "",
    accountPayDate: getCurrentDate("-"),
    bankCodOperation: "",
    cashID: "0",
    cashName: "",
    cashStatus: "NA",
    lastDateStatus: "",
    bankID: "0",
    bankName: ""
  });

  /*API DE CAJAS*/
  const getCashes = async () => {
    try {
      const response = await fetchedBanks();
      setCashes(response);
      return response;
    } catch (error) {
      throw error;
    }
  };
  /* ***** */

  /*API DE BANCOS*/
  const getBanks = async () => {
    try {
      const response = await fetchedAllBanks({
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
      });
      setBanks(response);
      //console.log(response)
      return response;
    } catch (error) {
      throw error;
    }
  };
  /* ***** */

  const getSupplierByCash = async () => {
    try {
      const response = await fetchedSupplierbycash({
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
      });
      setSupplierCash(response);
      //console.log(response)
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getSupplierByCash();
    getCashes();
    getBanks();
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>MODULO CUENTAS POR PAGAR (PROVEEDORES)</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Advanced Form</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <AccountSearch
                suppliersCash={suppliersCash}
                setSupplierCash={setSupplierCash}
                supplierItem={supplierItem}
                setSupplierItem={setSupplierItem}
                operationsAccountpay={operationsAccountpay}
                setOperationsAccountPay={setOperationsAccountPay}
                newPayAmortize={newPayAmortize}
                setNewPayAmortize={setNewPayAmortize}
              />
              <AccountPayList
                operationsAccountpay={operationsAccountpay}
                setOperationsAccountPay={setOperationsAccountPay}
                supplierItem={supplierItem}
                setSupplierItem={setSupplierItem}
              />
              <AccountPayForm
                newPayAmortize={newPayAmortize}
                setNewPayAmortize={setNewPayAmortize}
                cashes={cashes}
                setCashes={setCashes}
                //cashItem={cashItem}
                //setCashItem={setCashItem}
                banks={banks}
                setBanks={setBanks}
                //bankItem={bankItem}
                //setBankItem={setBankItem}
                supplierItem={supplierItem}
                setSupplierItem={setSupplierItem}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
