import React, { useState, useContext } from "react";
import { OrderTrayFilter } from "../fragments.dispatches/OrderTrayFilter";
import { OrderTrayList } from "../fragments.dispatches/OrderTrayList";
import { OrderDispatchReceived } from "../fragments.dispatches/OrderDispatchReceived";
import { ConfirmDispatchForm } from "../fragments.dispatches/ConfirmDispatchForm";

export function OrderTrayPage() {
  const [orders, setOrders] = useState([]);
  const [gangs, setGangs] = useState([]);
  const [users, setUsers] = useState([]);
  const [filename, setFilename] = useState("ReporteBandejaPedidos");
  const [detailsFromOrderDispatchReceived, setDetailsFromOrderDispatchReceived] = useState([]);
  const [dispatchToConfirm, setDispatchToConfirm] = useState({
    operationID:0,
    clientID:0,
    userID:0,
    warehouseID:0,
    observation: "Anulado por cancelación de la compra por parte del cliente",
    operationStatus:"",
    documentType:"",
    physicalDistribution:"",
    total:0,
    paymentMethods:{},
    details:[],
  });
  const [dailyRouteStatus, setDailyRouteStatus] = useState([ 
    { id: "04", name: "ENVIO PENDIENTE" }, { id: "05", name: "ENVIADO" }, 
    { id: "06", name: "ENTREGADO CONFORME" }, { id: "07", name: "ENVIO FALLIDO" }, { id: "08", name: "ENVIO ANULADO" },
  ]);
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Bandeja de pedidos de preventa </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Distribuciones</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
            <OrderTrayFilter setOrders={setOrders} gangs={gangs} setGangs={setGangs}  users={users} setUsers={setUsers}
            dailyRouteStatus={dailyRouteStatus} setDailyRouteStatus={setDailyRouteStatus} setFilename={setFilename}
            />
            <OrderTrayList setOrders={setOrders} orders={orders} filename={filename} 
              setDetailsFromOrderDispatchReceived={setDetailsFromOrderDispatchReceived}
              dispatchToConfirm={dispatchToConfirm}
              setDispatchToConfirm={setDispatchToConfirm}
             />
            <OrderDispatchReceived detailsFromOrderDispatchReceived={detailsFromOrderDispatchReceived} dispatchToConfirm={dispatchToConfirm} />
            <ConfirmDispatchForm dispatchToConfirm={dispatchToConfirm} setDispatchToConfirm={setDispatchToConfirm}/>
                
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

