import React, { useState, useEffect } from "react";
import { map } from "lodash";
import swal from "sweetalert2";
import { useHooksPpe, useHooksPpeUser } from "../../../hooks";
const initialState = {
  ppeUserID: 0,
  ppeID: 0,
  ppeUserQuantity: 0,
  ppeUserSize: "",
  userID: 0,
};
export function FragmentListPpe(props) {
  const { user } = props;
  const [itemPpeUser, setItemPpeUser] = useState(initialState);
  const { ppeusers, getPpeUsers, addPpeUser, deletePpeUser } =
    useHooksPpeUser();
  const { ppes, getPpes } = useHooksPpe();
  const [refetch, setRefetch] = useState(false);
  const onRefetch = () => setRefetch((prev) => !prev);
  useEffect(() => {
    getPpes();
    getPpeUsers(user.id);
    setItemPpeUser({ ...itemPpeUser, userID: user.id });
  }, [user, refetch]);

  const onDeletePpeUser = async (data) => {
    console.log(data);
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Esta seguro?",
        text: "Si acepta, No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Acepto!",
        cancelButtonText: "No, Cancelo!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deletePpeUser(data);
            onRefetch();
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "EPP eliminado con exito.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", "EPP a salvo :)", "error");
        }
      });
  };

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    const newValues = {
      ...itemPpeUser,
      [name]: value,
    };
    setItemPpeUser(newValues);
  };
  const handleSubmitEPP = async (e) => {
    e.preventDefault();
    if (e.target.ppeID.value === 0) {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Seleccione el EPP!",
        //footer: e.target.document,
      });
      return false;
    }
    if (e.target.ppeUserQuantity.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese la cantidad!",
        //footer: e.target.first_name,
      });
      return false;
    }
    if (e.target.ppeUserSize.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese la medida/talla del EPP!",
        //footer: e.target.last_name,
      });
      return false;
    }
    try {
      if (user.id !== undefined || user.id !== 0) {
        await addPpeUser(itemPpeUser);
        onRefetch();
        swal.fire({
          position: "center",
          icon: "success",
          title: "EPP asignado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Ups...",
          text: "Empleado no especificado!",
          //footer: e.target.document,
        });
        return false;
      }
    } catch (error) {
      console.log("error->", error);
      swal.fire({
        icon: "error",
        title: "Error en la operación",
        text: error,
        footer: '<a href="#!">Verfique la información</a>',
      });
    }
  };
  return (
    <>
      <div className="row p-2 mb-2">
        <form className="embed-responsive p-1" onSubmit={handleSubmitEPP}>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <label className="text-primary mb-1">Seleccione el EPP</label>
              <select
                className="form-control form-control-sm"
                name="ppeID"
                value={itemPpeUser.ppeID}
                onChange={(e) => handleChange(e)}
              >
                <option value={0}>Seleccione</option>
                {map(ppes, (item) => (
                  <option key={item.ppeID} value={item.ppeID}>
                    {item.ppeName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <label className="text-primary mb-1">Cantidad</label>
              <input
                type="number"
                className="form-control form-control-sm"
                name="ppeUserQuantity"
                value={itemPpeUser.ppeUserQuantity}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <label className="text-primary mb-1">Talla</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="ppeUserSize"
                maxLength={10}
                value={itemPpeUser.ppeUserSize}
                onChange={handleChange}
                placeholder="Medida"
                autoComplete="false"
              />
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <label className="text-primary mb-1">Empleado</label>
              <input
                type="text"
                className="form-control form-control-sm text-center"
                value={user.first_name}
                disabled
              />
              <input
                type="hidden"
                className="form-control form-control-sm text-center"
                name="userID"
                value={user.id}
              />
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <button
                type="submit"
                className="btn btn-sm btn-success mt-3 w-100"
              >
                <i className="fas fa-layer-group"></i> Agregar EPP
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped">
            <thead>
              <tr className="text-left">
                <th className="text-center" style={{ width: 10 }}>
                  Nº
                </th>
                <th className="text-center">Nombre EPP</th>
                <th className="text-center">Cantidad</th>
                <th className="text-center">Talla</th>
                <th className="text-center">Fecha</th>
                <th className="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              {map(ppeusers, (ppeuser, index) => (
                <tr className="text-center" key={index}>
                  <td className="align-middle">{ppeuser.ppeUserID}</td>
                  <td className="align-middle">{ppeuser.ppeName}</td>
                  <td className="align-middle">{ppeuser.ppeUserQuantity}</td>
                  <td className="align-middle">{ppeuser.ppeUserSize}</td>
                  <td className="align-middle">{ppeuser.ppeUserDate}</td>
                  <Actions
                    ppeuser={ppeuser}
                    onDeletePpeUser={onDeletePpeUser}
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
function Actions(props) {
  const { ppeuser, onDeletePpeUser } = props;
  return (
    <td className="align-middle text-center">
      <button
        type="button"
        className="btn btn-outline-danger btn-sm m-1"
        onClick={() => onDeletePpeUser(ppeuser.ppeUserID)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    </td>
  );
}
