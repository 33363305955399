import React, { useState, useEffect, useContext, createContext } from "react";
import { toast } from "react-toastify";
import {sendWarehouseData} from "../../../apis/apis.logistics/apisProduct";

const initialState = {
  warehouseID: 0,
  warehouseName: "",
  subsidiaryID: "",
  subsidiaryName: "",
  warehouseCategory: "",
  warehouseCategoryDisplay: "",
  productDict: [],
};
export function WarehouseRegisterForm(props) {
  const [item, setItem] = useState(initialState);

  const sendWarehouse = async (registerData) => {
    try {
      const response = await sendWarehouseData(registerData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const existsUpdateCategory = props.warehouses.find((warehouse) => {
    return (
      (warehouse.warehouseCategory == item.warehouseCategory &&
        warehouse.warehouseID != item.warehouseID &&
        item.warehouseCategory == "01") ||
      (warehouse.warehouseCategory == item.warehouseCategory &&
        warehouse.warehouseID != item.warehouseID &&
        item.warehouseCategory == "02") ||
      (warehouse.warehouseCategory == item.warehouseCategory &&
        warehouse.warehouseID != item.warehouseID &&
        item.warehouseCategory == "03")
    );
  });

  const existsCategory = props.warehouses.find((warehouse) => {
    return (
      (warehouse.warehouseCategory == item.warehouseCategory &&
        item.warehouseCategory != "01" &&
        item.warehouseCategory != "04") ||
      (warehouse.warehouseCategory == item.warehouseCategory &&
        item.warehouseCategory != "02" &&
        item.warehouseCategory != "04") ||
      (warehouse.warehouseCategory == item.warehouseCategory &&
        item.warehouseCategory != "03" &&
        item.warehouseCategory != "04")
    );
  });

  function handleChangeCategory(e) {
    var index = e.nativeEvent.target.selectedIndex;
    setItem((prevState) => ({
      ...prevState,
      warehouseCategory: e.target.value,
      warehouseCategoryDisplay: e.nativeEvent.target[index].text,
    }));
  }

  function handleChange(e) {
    setItem({ ...item, [e.target.name]: e.target.value });
  }

function updateWarehouse(data) {
  return props.warehouses.map((warehouse) => {
    if (warehouse.warehouseID === data.warehouseID) {
      var newWarehouseItem = Object.assign({}, warehouse);
      newWarehouseItem.warehouseName = data.warehouseName;
      newWarehouseItem.warehouseCategory = data.warehouseCategory;
      newWarehouseItem.warehouseCategoryDisplay = data.warehouseCategoryDisplay;
      return newWarehouseItem;
    } else {
      return warehouse;
    }
  });
}


  const handleSubmit = (e) => {
    e.preventDefault();
if (item.warehouseID == 0){
    if (existsCategory == undefined) {

      sendWarehouse(item)
        .then(async (r) => {
          let data = {
            warehouseID: r.warehouseID,
            warehouseName: item.warehouseName,
            subsidiaryID: item.subsidiaryID,
            subsidiaryName: item.subsidiaryName,
            warehouseCategory: item.warehouseCategory,
            warehouseCategoryDisplay: item.warehouseCategoryDisplay,
            productDict: item.productDict,
          };

          return data;
        })
        .then((data) => {
          if (data) {
            props.setWarehouses((prevPlanes) => {
              return [...prevPlanes, data];
            });
            props.setSelectedWarehouse(initialState);
            toast.success("almacen registrado con exito!");
          } else {
            toast.error("Ocurrio algun error");
          }
        });
      

      
    } else {
      toast.error(
        "ya existe una almacen con esa categoria. Solo se permite repetir si es vehiculo"
      );
    }

}else{
  if (existsUpdateCategory == undefined){

    sendWarehouse(item).then(async (r) => {
      let data = {
        warehouseID: r.warehouseID,
        warehouseName: item.warehouseName,
        subsidiaryID: item.subsidiaryID,
        subsidiaryName: item.subsidiaryName,
        warehouseCategory: item.warehouseCategory,
        warehouseCategoryDisplay: item.warehouseCategoryDisplay,
        productDict: item.productDict,
      };

      return data;
    }).then((data) => {
          if (data) {
            toast.success("almacen actualizado con exito!");
            props.setWarehouses(updateWarehouse(data));
          } else {
            toast.error("Ocurrio algun error");
          }
    });

    

  }else{
    toast.error(
      "ya existe una almacen con esa categoria. Solo se permite repetir si es vehiculo"
    );
  }
    
}

    console.log("item", item);

    document.getElementById("close-modal-register").click();

    //navigate("/");
  };

  useEffect(() => {
    if (Number(props.subsidiaryID) > 0)
      setItem({
        ...item,
        subsidiaryID: props.subsidiaryID,
        subsidiaryName: props.subsidiaryName,
      });

  }, []);

  useEffect(() => {
    if (props.selectedWarehouse.warehouseID > 0){
      setItem({
        ...item,
        warehouseID: props.selectedWarehouse.warehouseID,
        warehouseName: props.selectedWarehouse.warehouseName,
        warehouseCategory: props.selectedWarehouse.warehouseCategory,
        warehouseCategoryDisplay: props.selectedWarehouse.warehouseCategoryDisplay,
        productDict: props.selectedWarehouse.productDict,
      });
    }else{
      setItem({
        warehouseID: 0,
        warehouseName: "",
        subsidiaryID: props.subsidiaryID,
        subsidiaryName: props.subsidiaryName,
        warehouseCategory: "",
        warehouseCategoryDisplay: "",
        productDict: [],
      });
    }
      
  }, [props.selectedWarehouse]);

  return (
    <div
      className="modal fade"
      id="modal-warehouse-register"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Nuevo almacen</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <input type="hidden" value={item.warehouseID} />

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Nombre:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingrese ..."
                    name="warehouseName"
                    maxLength={100}
                    value={item.warehouseName}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label>Tipo:</label>
                  <select
                    className="form-control"
                    name="warehouseCategory"
                    onChange={handleChangeCategory}
                    value={item.warehouseCategory}
                  >
                    <option value="0">Elige</option>

                    {props.warehouseCategories.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-light"
              data-dismiss="modal"
              id="close-modal-register"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
