import React, { useEffect, useContext, useState } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { BankSearch } from "../fragments.accounting/BankSearch";
import { fetchedAllBanks } from "../../../apis/apis.accounting/apisCash";
import { getCurrentDate } from "../../../configs/helpers";
import BankList from "../fragments.accounting/BankList";
import { BankOperations } from "../fragments.accounting/BankOperations";
import { BankForm } from "../fragments.accounting/BankForm";

export function BankPage() {    
  const { auth } = useContext(ContextAuth);  
  const [ allBanks, setAllBanks] = useState([]);  
  const [ operationsBanks, setOperationsBanks ] = useState([]);  
  const [ selectedBankCashFlow, setSelectedBankCashFlow ] = useState({});
  
  const [newBank, setNewBank] = useState({
    bankID: "0",
    bankName: "",
    bankAmount: "",
    bankAccountType: "0",
    bankAccountTypeDisplay: "",
    bankTypeMoney: "0",
    bankAccountNumber: "",
    bankDate: getCurrentDate("-"),
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    userID: auth.data.user_id,
  });
  
  const [newOperation, setNewOperation] = useState({
    bankId: "",
    bankFlowID: "0",
    bankName: "",
    bankAmount: "",
    bankOperationDescription: "",
    bankNewTypeTransaction: "0",
    bankNewOperationDate: getCurrentDate("-"),
    bankOperationTotal: "",
    userID: auth.data.user_id,
  });
  
  const getAllBanks = async () => {    
    try {
      const response = await fetchedAllBanks(
        {
          subsidiaryID: auth.data.subsidiary.subsidiary_id
        }
      );      
      setAllBanks(response);
      //console.log(response)
      return response;
    } catch (error) {
      throw error;
    }
  };

  const [dates, setDates] = useState({
    startDate: getCurrentDate("-"),
    endDate: getCurrentDate("-"),
  });

  const [bankItem, setBankItem] = useState({
    bankID: "0",
    bankName: "",
    bankAmount: "0",
    bankAccountNumber: "",
    bankAccountType: "",
    bankDate: getCurrentDate("-"),
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    userID: auth.data.user_id,
  });

  useEffect(() => {
    getAllBanks();
  }, []);
  //console.log(operationsBanks);
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Control de dinero en Bancos</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Finanzas</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <BankSearch
                allBanks={allBanks}
                setAllBanks={setAllBanks}
                bankItem={bankItem}
                setBankItem={setBankItem}
                dates={dates}
                setDates={setDates}
                operationsBanks={operationsBanks}
                setOperationsBanks={setOperationsBanks}
                newOperation={newOperation}
                setNewOperation={setNewOperation}
              />
              <BankList
                operationsBanks={operationsBanks}
                setOperationsBanks={setOperationsBanks}
                bankItem={bankItem}
                setBankItem={setBankItem}
                newOperation={newOperation}
                setNewOperation={setNewOperation}
                selectedBankCashFlow={selectedBankCashFlow}
                setSelectedBankCashFlow={setSelectedBankCashFlow}
              />
              <BankOperations
                bankItem={bankItem}
                setBankItem={setBankItem}
                newOperation={newOperation}
                setNewOperation={setNewOperation}
                allBanks={allBanks}
                setAllBanks={setAllBanks}
                getAllBanks={getAllBanks}
                operationsBanks={operationsBanks}
                setOperationsBanks={setOperationsBanks}
                selectedBankCashFlow={selectedBankCashFlow}
                setSelectedBankCashFlow={setSelectedBankCashFlow}
              />
              <BankForm
                newBank={newBank}
                setNewBank={setNewBank}
                bankItem={bankItem}
                setBankItem={setBankItem}
                allBanks={allBanks}
                setAllBanks={setAllBanks}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
