import { BASE_API } from "../../configs/ConfigsConstants";

export async function queryDocumentReniec(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/document_consultation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}
