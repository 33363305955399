import React, { useState, useEffect, useContext, createContext } from "react";
import { toggleCash } from "../../../apis/apis.accounting/apisCash";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { toast } from "react-toastify";

export function CashList(props) {
  const { operations } = useContext(GlobalContext);
  const { totalsCash } = useContext(GlobalContext);
  const { opening } = useContext(GlobalContext);
  const { closing } = useContext(GlobalContext);
  const { auth } = useContext(ContextAuth);  
    
  //console.log("cashItem", props.cashItem);      
  function closeCash(params) {
    sendData({
      cashID: props.cashItem.cashID,
      cashDate: props.cashItem.cashDate,
      cashStatus: "C",
      userID: props.cashItem.userID,
    }).then((r) => {
      //console.log(r)
      if (r.data.error) {
        toast.error(r.data.message);
      } else {
        toast.success(r.data.message);
        document.getElementById("btn-search").click();
      }
    });
  }  
  


  const sendData = async (data) => {
    try {
      console.log(data);
      const response = await toggleCash(data);      
      return response;
      
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <table className="table table-sm table-bordered table-striped bg-white">
        <thead>
          <tr className="text-center">
            {/*<th style={{ width: 10 }}>ACCIÓN</th>*/}
            <th>OPERACIÓN</th>
            <th>FECHA</th>
            <th>DESCRIPCIÓN</th>
            <th style={{ width: 40 }}>TIPO DE DOCUMENTO</th>
            <th className="text-center">SUBTOTAL</th>
            <th className="text-center">IGV</th>
            <th className="text-center">TOTAL</th>
            <th className="text-center">RESPONSABLE</th>
            <th className="text-center">COMPROBANTE</th>
            <th className="text-center">CLIENTE</th>
            <th className="text-center">EDITAR</th>
          </tr>
        </thead>
        <tbody>
          {/*opening ? (
          <tr className="">
            <td>
              <button
                type="button"
                className="btn btn-danger"
                onClick={closeCash}
                id="btn-close-cash"
                disabled={
                  props.cashItem.checkCloseCash == true ? true : false
                }
              >
                Cerrar
              </button>
            </td>
            <td className="text-success font-weight-bold">APERTURA</td>
            <td colSpan="5"></td>
            <td className="text-right">S/ {opening}</td>
            <td colSpan="2"></td>
          </tr>
        ) : (
          ""
        )*/}

          {operations.map((item, index) => {
            return (
              <tr
                key={index}
                value={item.cashFlowID}
                className={
                  item.typeOperation === "E"
                    ? "bg-aqua-marine"
                    : "bg-aqua-orange"
                }
              >
                <td
                  className="text-uppercase text-center"
                  style={{ width: "100px" }}
                >
                  {item.typeOperationDisplay}
                </td>
                <td className="text-center" style={{ width: "100px" }}>
                  {item.transactionDate}
                </td>
                <td className="text-uppercase text-center">
                  {item.description}
                </td>
                <td className="text-uppercase text-center">
                  {item.documentTypeDisplay}
                </td>
                <td className="text-right">S/ {item.subtotal}</td>
                <td className="text-right">S/ {item.igv}</td>
                <td className="text-right">S/ {item.total}</td>
                <td className="text-center text-uppercase">{item.userName}</td>
                <td className="text-center text-uppercase">{item.operationDocumentNumber}</td>
                <td className="text-center text-uppercase">{item.operationClientName}</td>
                <td className="text-center" style={{ width: "100px" }}>
                  <button
                    className="btn btn-link"
                    data-toggle="modal"
                    data-target="#modal-operations"
                    onClick={(e) => {
                      props.setSelectedCashFlow(item);
                      //console.log("item", item);
                      //console.log("selectedCashFlow", props.selectedCashFlow);
                    }}
                    /*onClick={() => {
                    props.setCashFlow(item)
                  }}*/
                  >
                    <i className="text-success fas fa-edit"></i>
                  </button>

                  <i className="text-danger fas fa-ban"></i>
                </td>
              </tr>
            );
          })}
          {/*closing ? (
          <tr className="">
            <td></td>
            <td className="text-danger font-weight-bold">CIERRE</td>
            <td colSpan="5"></td>
            <td className="text-right">
              {closing == 0 ? "-" : "S /" + closing}
            </td>
            <td colSpan="2"></td>
          </tr>
        ) : (
          ""
        )*/}
        </tbody>
      </table>

      {operations.length !== 0 ? (
        <div className="text-center container pt-5">
          <table className="table table-bordered table-striped table-sm bg-aqua-blue text-blue">
            <thead>
              <tr>
                <th colSpan={2} className=" text-uppercase" style={{ width: "100px" }}>Resumen del Día</th>
              </tr>
            </thead>
            <tbody className="text-center align-middle text-uppercase">
              <tr>
                <td>Total Entradas</td>
                <td>
                  <h5 className="font-weight-bold">
                    S/ {Number(totalsCash.total_entries_sum).toFixed(2)}
                  </h5>
                </td>
              </tr>
              <tr>
                <td>Total Salidas</td>
                <td>
                  <h5 className="font-weight-bold">
                    S/ {Number(totalsCash.total_outputs_sum).toFixed(2)}
                  </h5>
                </td>
              </tr>
              <tr>
                <td>Balance del dia</td>
                <td>
                  <h5 className="font-weight-bold">
                    S/ {Number(totalsCash.balance_day).toFixed(2)}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );


}
