
//import firebase from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCcsLgiNVizdQWF8WRTkzxEycpoaxOLTaQ",
  authDomain: "deliveryapp-e121f.firebaseapp.com",
  databaseURL: "https://deliveryapp-e121f-default-rtdb.firebaseio.com",
  projectId: "deliveryapp-e121f",
  storageBucket: "deliveryapp-e121f.appspot.com",
  messagingSenderId: "1057170340379",
  appId: "1:1057170340379:web:695b4a3edf0297215616be",
  measurementId: "G-VYS4J0M1LE",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };