import React, { useEffect, useContext, useState } from "react";
import { sendNewBank } from "../../../apis/apis.accounting/apisCash";

export function BankForm(props) {

    const optionsTypeAccount = [
      { id: "CC", name: "Cuenta Corriente" },
      { id: "CA", name: "Cuenta de Ahorro" },
      { id: "CS", name: "Cuenta Sueldo" },
      { id: "CM", name: "Cuenta en dólares o monedas extranjeras" },      
    ];

    const optionsTypeCurrency = [
      { id: "S", name: "Soles" },
      { id: "D", name: "Dolares" },
    ];

    function handleChangeTypeAccount(e) {
      var index = e.nativeEvent.target.selectedIndex;
      let text = e.nativeEvent.target[index].text;  

      props.setNewBank({
        ...props.newBank,
        bankAccountType: e.target.value,
        bankAccountTypeDisplay: text,
      });      
    }

    function handleChange(e) {      
      //console.log(name, value) 
      //console.log("e", e.nativeEvent.target[index].text); 
      props.setNewBank({      
        ...props.newBank,
        [e.target.name]: e.target.value,
      });

      //console.log("handleChange", props.newBank);
    }

    const saveBank = async (data) => {
      try {        
        const response = await sendNewBank(data);
        return response;
      } catch (error) {
        throw error;
      }
    };

     const handleSubmit = (e) => {
       e.preventDefault();
       if (props.newBank.bankName != "" && props.newBank.bankAmount != "") {
         //console.log("props.newBank", props.newBank);
         saveBank(props.newBank).then(async (res) => {
           console.log('res', res);
           props.newBank.bankID = res.cashID;
           props.setBankItem({ ...props.bankItem, bankID: res.cashID });
           props.setAllBanks((prevPlanes) => {
             return [...prevPlanes, props.newBank];
           });
         });
       } else {
         alert("Complete los campos necesarioss");
       }
       document.getElementById("close-modal-cash").click();
     };
    
    useEffect(() => {}, [props.setNewBank]);


  return (
    <div
      className="modal fade"
      id="modal-new-bank"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Nueva Entidad Bancaria:</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Nombre de la Entidad:
              </label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control text-uppercase"
                  id="inputEmail3"
                  name="bankName"
                  //value={props.cashItem.cashName}
                  onChange={handleChange}
                />
              </div>

              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-right"
              >
                Tipo de Cuenta:
              </label>

              <div className="col-sm-3">
                <select
                  className="form-control select2 text-uppercase"
                  onChange={handleChangeTypeAccount}
                  name="bankAccountType"
                  //value={props.cashFlow.cashOperationTypeDocument}
                >
                  <option value="0">Seleccione...</option>
                  {optionsTypeAccount.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Numero de Cuenta:
              </label>
              <div className="col-auto">
                <input
                  type="text"
                  name="bankAccountNumber"
                  className="form-control"
                  //value={props.cashItem.cashDate}
                  onChange={handleChange}
                />
              </div>

              <label htmlFor="inputEmail3" className="col-auto col-form-label">
                Tipo de moneda:
              </label>
              <div className="col-sm-3">
                <select
                  className="form-control select2 text-uppercase"
                  onChange={handleChange}
                  name="bankTypeMoney"
                  //value={props.cashFlow.cashOperationTypeDocument}
                >
                  <option value="0">Seleccione...</option>
                  {optionsTypeCurrency.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Monto Inicial:
              </label>
              <div className="col-auto">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail3"
                  name="bankAmount"
                  //value={props.cashItem.cashAmount}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="close-modal-cash"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Crear Nueva Entidad
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
