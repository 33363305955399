import React, { useState, useEffect, useContext, createContext } from "react";
import Select from "react-select";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { queryDocumentReniec } from "../../../apis/apis.logistics/apisSunat";
import { fetchedGangs, fetchedMatrix, fetchedRoute, sendOperationData } from "../../../apis/apis.logistics/apisProduct";
import { fetchedStockInOneWarehouse } from "../../../apis/apis.buys/apisPurchase";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

import {
    customStyles,
    personDocumentTypes,
    operationActions,
    operationTypes,
    gangs,
    operationDocumentTypes,
    persons,
} from "../../../configs/Data";


const initialSelectedProductState = {
    productID: "",
    productName: "",
    productCode: "",
    grammage: "",
    sku: "",
    unitName: "",
    tariffs: [],
    price: "0",
    quantity: "0",
    typeTradeID: "1",
    subtotal: "0",
    stock: "0",
    productTariffID: "0",
};


export function SpecialSaleForm({ registerData, setRegisterData, clients, setClients, products, setProducts, clearRegisterDataState, searchSuggestions }) {

    const { auth } = useContext(ContextAuth);
    const [gangs, setGangs] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(initialSelectedProductState);


    /*const { setInventories, subsidiary, gangs, setGangs } =
      useContext(GlobalContext);*/

    const clearSelectedProductState = () => {
        setSelectedProduct({ ...selectedProduct,    
            productID: "",
            productName: "",
            productCode: "",
            grammage: "",
            sku: "",
            unitName: "",
            tariffs: [],
            price: "0",
            quantity: "0",
            subtotal: "0",
            stock: "0",
            productTariffID: "0", 
        });
    };

    const sendOperation = async () => {
        try {
            const response = await sendOperationData(registerData);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleChangeOperationClient = (selectOption) => {
        let foundClient = clients.find((c) => Number(c.value) === Number(selectOption.value))
        if (foundClient) {
            console.log("foundClient", foundClient.documentType)
        }
        console.log(selectOption)
        setRegisterData({
            ...registerData,
            clientID: selectOption.value,
            clientName: selectOption.label,
            operationDocumentTypeID: foundClient.documentType === "01" ? "02" : "03"
        });
    };


    const handleChangePriceAndQuantityBox = (e) => {
        let subtotal = document.getElementById("subtotal");
        if (e.target.name == "price") {
            subtotal = Number(selectedProduct.quantity) * Number(e.target.value);
            setSelectedProduct({
                ...selectedProduct,
                price: e.target.value,
                subtotal: subtotal.toFixed(2),
            });
        } else if (e.target.name == "quantity") {
            if (Number(e.target.value) <= selectedProduct.stock) {
                subtotal = Number(selectedProduct.price) * Number(e.target.value);
                setSelectedProduct({
                    ...selectedProduct,
                    quantity: e.target.value,
                    subtotal: subtotal.toFixed(2),
                });
            }

        }


    };

    function getSumValues() {
        return registerData.details
            .map((item) => item.subtotal)
            .reduce((prev, curr) => prev + curr, 0);
    }



    async function handleButtonRemove(item) {
        setRegisterData({
            ...registerData,
            details: registerData.details.filter(function (obj) {
                return (
                    obj.productID.toString().concat(obj.productTariffID.toString()) !==
                    item.productID.toString().concat(item.productTariffID.toString())
                );
            }),
            operationAmountBase:
                registerData.details.length > 1
                    ? Number(
                        Number(registerData.operationAmountBase) - item.subtotal
                    ).toFixed(2)
                    : "0",
            operationAmountTotal:
                registerData.details.length > 1
                    ? Number(
                        Number(registerData.operationAmountBase) - item.subtotal
                    ).toFixed(2)
                    : "0",
            operationAmountIgv: "0",
        });
    }

    const handleButtonAdd = () => {
        const found = registerData.details.find(function (detail, index) {
            if (detail.productTariffID == selectedProduct.productTariffID)
                return true;
        });

        if (!found) {
            if (Number(selectedProduct.productID) > 0) {
                if (
                    Number(selectedProduct.price) > 0 && Number(selectedProduct.quantity) > 0
                ) {
                    setRegisterData((prevState) => ({
                        ...prevState,
                        details: [...prevState.details, selectedProduct], // add new expense to expenses array
                        // operationAmountBase: Number(registerData.operationAmountBase) + Number(selectedProduct.subtotal), // reset this field
                        // operationAmountIgv: "0", // reset this field
                        // operationAmountTotal: Number(Number(registerData.operationAmountBase) + Number(selectedProduct.subtotal)).toFixed(2), // reset this field
                    }));
                    // document.getElementById("operationAmountIgv").value = "0";

                    clearSelectedProductState();

                } else {
                    toast.error("Verifique cantidad y precio");
                }
            } else {
                toast.info("Elija un producto");
            }
        } else {
            toast.info("Ya existe este item");
        }
    };

    useEffect(() => {
        calculateTotal();
    }, [registerData.details]);

    function calculateTotal() {
        const totalDiscountedSubtotal = registerData?.details?.reduce((total, detail) => {
            const quantityReturned = Number(detail.quantity);
            const price = Number(detail.price);
            const subtotal = quantityReturned * price;
            return total + subtotal;
        }, 0);

        let total = totalDiscountedSubtotal;
        let base = total / 1.18;
        let igv = total - base;

        setRegisterData((prevState) => ({
            ...prevState,
            operationAmountBase: Number(base).toFixed(2), // reset this field
            operationAmountIgv: Number(igv).toFixed(2), // reset this field
            operationAmountTotal: Number(total).toFixed(2), // reset this field
        }));
    }

    const handleClickBtnSave = async () => {
        if (registerData.details.length) {



            if (registerData.operationTypeID != "NA") {

                if (registerData.warehouseID != "0") {
                    if (registerData.clientID != "0") {
                        console.log("handleClickBtnSave", registerData);

                        await sendOperation().then((r) => {
                            if (r.status == 201) {
                                toast.success("Operacion registrada con exito!");
                                clearRegisterDataState();
                                searchSuggestions();
                            } else {
                                toast.error("Ocurrio algun error");
                            }
                        });


                        document.getElementById("btn-close-modal-register").click();

                    } else { toast.error("Verifique el cliente"); }
                } else { toast.error("Verifique el almacen"); }

            } else { toast.error("Verifique el tipo de operacion"); }

        } else { toast.error("Verifique los items a registrar"); }
    };

    const getRoute = async (data) => {
        try {
            const response = await fetchedRoute(data);

            console.log("response", response);

            response.forEach((client) => {
                setClients((prevState) => [
                    ...prevState,
                    {
                        value: client["personID"],
                        label: `${client["observation"]}; ${client["fullName"]}; ${client["documentTypeDisplay"]}: ${client["documentNumber"]}`,
                        routeDate: client["routeDate"],
                        documentType: client["documentType"],
                    },
                ]); // add new expense to expenses array
            });

            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleChangeOperation = (e) => {
        if (e.target.name === "typeTradeID") {
            const foundProduct = products.find((product) => (Number(product.productID) == Number(selectedProduct.productID)));
            console.log(foundProduct)
            let selectedPrice = 0;
            // let selectedProductTariffID = foundProduct["tariffs"][0]["productTariffID"];
            if (e.target.value === "1")
                selectedPrice = foundProduct["tariffs"][0]["priceSale"];
            else if (e.target.value === "2")
                selectedPrice = foundProduct["tariffs"][0]["priceSaleWholesale"];
            else if (e.target.value === "3")
                selectedPrice = foundProduct["tariffs"][0]["priceSalePrimax"];
            else if (e.target.value === "4")
                selectedPrice = foundProduct["tariffs"][0]["priceSaleSunat"];

            setSelectedProduct({
                ...selectedProduct,
                // tariffs: selectOption.tariff,
                typeTradeID: e.target.value,
                // productID: "0",
                // productTariffID: selectedProductTariffID,

                // productName: "",
                // grammage: selectOption.grammage,
                // sku: "",
                // stock: 0,
                price: Number(selectedPrice),
                // price: selectOption.tariff[0].price,
                subtotal: Number(Number(selectedPrice) * Number(selectedProduct.quantity)).toFixed(2),
                // productTariffID: selectOption.tariff[0].productTariffID,
                // unitName: selectOption.tariff[0].unitName,
            });
            // setRegisterData({ ...registerData, [e.target.name]: e.target.value });
        }
    };

    const handleChangeProductBox = async (selectOption) => {

        let selectedPrice = 0;
            // let selectedProductTariffID = foundProduct["tariffs"][0]["productTariffID"];
            if (selectedProduct.typeTradeID === "1")
                selectedPrice = selectOption["tariffs"][0]["priceSale"];
            else if (selectedProduct.typeTradeID === "2")
                selectedPrice = selectOption["tariffs"][0]["priceSaleWholesale"];
            else if (selectedProduct.typeTradeID === "3")
                selectedPrice = selectOption["tariffs"][0]["priceSalePrimax"];
            else if (selectedProduct.typeTradeID === "4")
                selectedPrice = selectOption["tariffs"][0]["priceSaleSunat"];

        setSelectedProduct({
            ...selectedProduct,
            // tariffs: selectOption.tariff,
            productID: selectOption.productID,
            productTariffID: selectOption.productTariffID,

            productCode: selectOption.productCode,
            productName: selectOption.productSaleName,
            // grammage: selectOption.grammage,
            sku: selectOption.sku,
            stock: selectOption.stock >= 0 ? selectOption.stock : 0,
            // price: selectOption.price >= 0 ? selectOption.price : 0,
            price: selectedPrice,
            // price: selectOption.tariff[0].price,
            // subtotal: Number(Number(selectOption.price) * Number(selectOption.quantity)).toFixed(2),
            // productTariffID: selectOption.tariff[0].productTariffID,
            // unitName: selectOption.tariff[0].unitName,
        });

        console.log(selectOption)

    };

    const getProducts = async (data) => {
        try {
            const response = await fetchedStockInOneWarehouse(data);
            response.forEach((product) => {
                setProducts((prevState) => [
                    ...prevState,
                    {
                        value: product["productID"],
                        label: product["productCode"] + " | " + product["productSaleName"],
                        stock: product["stockA"],
                        price: product["priceSale"],
                        tariffs: product["remainingQuantityInTariffs"],
                        productID: product["productID"],
                        productCode: product["productCode"],
                        productSaleName: product["productSaleName"],
                        productTariffID: product["productTariffID"],
                        sku: product["productSku"],
                    },
                ]); // add new expense to expenses array
            });

            return response;
        } catch (error) {
            throw error;
        }
    };

    const getWarehouses = async () => {
        try {
            const response = await fetchedGangs();
            setGangs(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    // const getCLientsAndProducts = async (gangID, operationDate) => {
    //     // const dateObject = new Date(operationDate);
    //     const found = gangs.find(function (gang, index) {
    //         if (gang.gangID == gangID) return true;
    //     });

    //     if (found) {
    //         setClients([]);
    //         setProducts([]);
    //         // let current = new Date();
    //         // let weekday = dateObject.getDay();
    //         // await getRoute({ gangID: found.gangID, visitDay: weekday });
    //         // await getProducts({ warehouseID: found.warehouseID });


    //         setRegisterData({
    //             ...registerData,
    //             warehouseID: found.warehouseID,
    //             gangID: found.gangID,
    //             warehouseName: found.warehouseName,
    //             operationDate: operationDate,
    //             clientID:"0",
    //             // warehouseCategory: found.warehouseCategory,
    //             userID: auth.data.user_id,
    //         });

    //     }
    // }

    const handleChangeOperationWarehouse = (e) => {
        // setProducts([]);
        const found = gangs.find(function (gang, index) {
            if (gang.gangID == e.target.value) return true;
        });
        if (found) {
            setRegisterData({
                ...registerData,
                // warehouseID: found.warehouseID,
                gangID: found.gangID,
                // warehouseName: found.warehouseName,
                // operationDate: operationDate,
                clientID: "0",
                // warehouseCategory: found.warehouseCategory,
                userID: auth.data.user_id,
            });
        }
        // getCLientsAndProducts(e.target.value, registerData.operationDate);
    };

    const handleChangeOperationDate = (e) => {
        // setProducts([]);
        // getCLientsAndProducts(registerData.gangID, e.target.value);
        setRegisterData({
            ...registerData,
            // warehouseID: found.warehouseID,
            // gangID: found.gangID,
            // warehouseName: found.warehouseName,
            operationDate: e.target.value,
            clientID: "0",
            // warehouseCategory: found.warehouseCategory,
            // userID: auth.data.user_id,
        });
    };

    useEffect(() => {
        if (Number(registerData.gangID) > 0 && registerData.operationDate != null && clients.length === 0) {
            const dateObject = new Date(registerData.operationDate);
            let weekday = dateObject.getDay();
            getRoute({ gangID: registerData.gangID, visitDay: weekday });
        }
    }, [clients]);

    useEffect(() => {
        setClients([]);
    }, [registerData.gangID, registerData.operationDate]);

    // useEffect(() => {
    //     if(Number(registerData.warehouseID)>0){
    //         getProducts({ warehouseID: registerData.warehouseID });
    //     }
    // }, [registerData.warehouseID]);

    useEffect(() => {


        if (auth.data.user_id != undefined) {
            getWarehouses();
            getProducts({ warehouseID: 3 });
            // console.log("auth", auth.data.user_id);
            setRegisterData({
                ...registerData,
                userID: auth.data.user_id,
            });
        }
        else {
            toast.error("Problemas con la obtencion del usuario")
        }



    }, []);
    return (
        <div className="modal fade" id="modal-register-sale-operation">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Registrar Venta</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="card bg-light ">
                            <div className="card-body">



                                <div className="row mb-3">
                                    <div className="col-2 text-right">
                                        <label className="">CUADRILLA:</label>
                                    </div>
                                    <div className="col-3">
                                        <select
                                            className="form-control"
                                            name="gangID"
                                            onChange={handleChangeOperationWarehouse}
                                            value={registerData.gangID}
                                        >
                                            <option value="0">Elegir</option>
                                            {gangs.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.gangID}>
                                                        {item.gangName} | {item.warehouseName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-1 text-right">
                                        <label className="">FECHA:</label>
                                    </div>
                                    <div className="col-2">
                                        <input
                                            type="date"
                                            name="operationDate"
                                            className="form-control"
                                            value={registerData.operationDate}
                                            onChange={handleChangeOperationDate}
                                        />
                                    </div>


                                    <div className="col-2 text-right">
                                        <label className="">TIP. DOC:</label>
                                    </div>
                                    <div className="col-2">
                                        <select
                                            className="form-control text-uppercase"
                                            name="operationDocumentTypeID"
                                            onChange={(e) => {
                                                setRegisterData({ ...registerData, operationDocumentTypeID: e.target.value });
                                            }}
                                            value={registerData.operationDocumentTypeID}
                                        >
                                            {operationDocumentTypes.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-2 text-right">
                                        <label className="">CLIENTE:</label>
                                    </div>
                                    <div className="col-10">
                                        {clients.length ? (
                                            <Select
                                                options={clients}
                                                styles={customStyles}
                                                onChange={(e) => handleChangeOperationClient(e)}
                                            //defaultValue={props.options[0]}
                                            />
                                        ) : (
                                            <select className="form-control" disabled></select>
                                        )}
                                    </div>


                                </div>
                            </div>

                        </div>

                        <table className="table table-bordered">
                            <thead>
                                <tr className="text-center">
                                    <td scope="col" style={{ width: "10%" }}>
                                        ID
                                    </td>
                                    <td scope="col" style={{ width: "30%" }}>
                                        PRODUCTO
                                    </td>
                                    <td scope="col" style={{ width: "10%" }}>
                                        STOCK
                                    </td>
                                    <td scope="col" style={{ width: "15%" }}>
                                        TIPO PRECIO
                                    </td>
                                    <td scope="col" style={{ width: "15%" }}>
                                        PRECIO
                                    </td>
                                    <td scope="col" style={{ width: "10%" }}>
                                        CANTIDAD
                                    </td>
                                    <td scope="col" style={{ width: "15%" }}>
                                        SUBTOTAL
                                    </td>
                                    <td scope="col" style={{ width: "10%" }}>
                                        ACCIONES
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {products.length ? (
                                            <Select
                                                options={products}
                                                styles={customStyles}
                                                onChange={(e) => handleChangeProductBox(e)}
                                            //defaultValue={props.options[0]}
                                            />
                                        ) : (
                                            <select className="form-control" disabled></select>
                                        )}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="stock"
                                            id="stock"
                                            value={selectedProduct.stock}
                                            readOnly
                                        />

                                    </td>
                                    <td>
                                        <select
                                            className="form-control"
                                            name="typeTradeID"
                                            onChange={handleChangeOperation}
                                            value={selectedProduct.typeTradeID}
                                        >
                                            <option value="1">P. V. (Tiendas)</option>
                                            <option value="2">P. V. Mayorista</option>
                                            <option value="3">P. V. Grifo Primax</option>
                                            <option value="4">P. V. para Sunat</option>

                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="price"
                                            placeholder="price"
                                            value={selectedProduct.price}
                                            onChange={(e) => handleChangePriceAndQuantityBox(e)}
                                            maxLength={6}
                                            autoComplete="off"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control text-center"
                                            name="quantity"
                                            placeholder="quantity"
                                            value={selectedProduct.quantity}
                                            onChange={(e) => handleChangePriceAndQuantityBox(e)}
                                            onFocus={(e) => e.target.select()}
                                            maxLength={6}
                                        />
                                    </td>
                                    <td>

                                        <input
                                            type="number"
                                            className="form-control text-right"
                                            name="subtotal"
                                            id="subtotal"
                                            placeholder=""
                                            value={selectedProduct.subtotal}
                                            onChange={(e) => handleChangePriceAndQuantityBox(e)}
                                            readOnly
                                            maxLength={6}
                                        />
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-success btn-block"
                                            onClick={handleButtonAdd}
                                        >
                                            Agregar
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {registerData.details.map((item, index) => {
                                    return (
                                        <tr key={index} value={item.productTariffID}>
                                            <td className="text-center align-middle">{item.productCode}</td>
                                            <td className="text-left align-middle">{item.productName}</td>
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                            <td className="text-center align-middle">{item.price}</td>
                                            <td className="text-center align-middle">{item.quantity}</td>
                                            <td className="text-right align-middle">
                                                S/ {item.subtotal}
                                            </td>

                                            <td>
                                                <button
                                                    className="btn btn-warning btn-block"
                                                    onClick={() => handleButtonRemove(item)}
                                                >
                                                    Quitar
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>

                                <tr>
                                    <td colSpan={6} className="text-right font-weight-bold">OP. GRAVADAS</td>
                                    <td className="text-right font-weight-bold text-nowrap"> S/ {registerData.operationAmountBase}</td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td colSpan={6} className="text-right font-weight-bold">IGV</td>
                                    <td className="text-right font-weight-bold text-nowrap"> S/ {registerData.operationAmountIgv}</td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td colSpan={6} className="text-right font-weight-bolder">TOTAL</td>
                                    <td className="text-right font-weight-bolder text-nowrap"> S/ {registerData.operationAmountTotal}</td>
                                    <td></td>
                                </tr>

                            </tfoot>
                        </table>




                    </div>
                    <div className="modal-footer  justify-content-end px-4">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            id="btn-close-modal-register"
                        >
                            Cerrar
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleClickBtnSave}
                        >
                            Registrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
