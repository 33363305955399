import React, { useState, useEffect, useContext, createContext } from "react";
import Select from "react-select";
import { customStyles, igvOptions } from "../../../configs/Data";
import { toast } from "react-toastify";
import {
  fetchedProductsWithTariffs,
  sendOrderPurchaseData,
} from "../../../apis/apis.logistics/apisProduct";



export function NewOrderPurchaseOperationDetailForm({order, setOrder, selectedOption, setSelectedOption, initialOptionState}) {
  const [options, setOptions] = useState([]);
  

  const getProductsWithTariffs = async () => {
    try {
      const response = await fetchedProductsWithTariffs();
      //console.log("response", response)

      response.forEach((mobile) => {
        setOptions((prevState) => [
          ...prevState,
          {
            value: mobile["productID"],
            label: mobile["productCode"] +
              " - " + mobile["productSku"] +
              " - " + mobile["purchaseName"] +
              " - " + mobile["productTariff"].length,
            purchaseName: mobile["purchaseName"],
            tariff: mobile["productTariff"],
            grammage: mobile["productGrammage"],
            sku: mobile["productSku"],
            stockMax: mobile["productStockMax"],
            stockMin: mobile["productStockMin"],
          },
        ]); // add new expense to expenses array
      });
    } catch (error) {
      throw error;
    }
  };

  function calculateIgv() {}

  const handleChangeProductBox = async (selectOption) => {
    let valueAmount = Number(selectOption.tariff[0].pricePurchase) * Number(selectedOption.quantityOrdered);
    let valueAmountDiscount = valueAmount * Number(selectOption.tariff[0].percentageDiscount).toFixed(2);
    let igv, base, total;
    let valueDiscount = (valueAmount * Number(selectOption.tariff[0].percentageDiscount)) / 100;
    if (order.conditionIgv == "02") {
      base = valueAmountDiscount;
      igv = (base * Number(order.percentageIgv)) / 100;
      total = base + Number(igv);
    } else if (order.conditionIgv == "01") {
      base = valueAmountDiscount / (1 + Number(order.percentageIgv) / 100);
      igv = valueAmountDiscount - base;
      total = valueAmountDiscount;
    } else if (order.conditionIgv == "NA") {
      base = valueAmountDiscount;
      igv = 0;
      total = base + igv;
    }

    setSelectedOption({
      ...selectedOption,
      tariffs: selectOption.tariff,
      productID: selectOption.value,
      productName: selectOption.purchaseName,
      grammage: selectOption.grammage,
      sku: selectOption.sku,
      productStockMax: selectOption.stockMax,
      productStockMin: selectOption.stockMin,
      pricePurchase: selectOption.tariff[0].pricePurchase,
      priceSale: selectOption.tariff[0].priceSale,
      unitaryPriceSale: selectOption.tariff[0].unitaryPriceSale,
      price: selectOption.tariff[0].pricePurchase > 0
          ? selectOption.tariff[0].pricePurchase
          : "",
      amount: valueAmount.toFixed(2),
      discount: valueDiscount.toFixed(2),
      productTariffID: selectOption.tariff[0].productTariffID,
      percentageDiscount: selectOption.tariff[0].percentageDiscount,
      unitName: selectOption.tariff[0].unitName,
      amountDiscount: base.toFixed(2),
      igv: igv.toFixed(2),
      amountIgv: total.toFixed(2),
    });
  };

  const handleChangeUnitBox = async (e) => {
    const found = await selectedOption.tariffs.find(function (post, index) {
        if (post.productTariffID == e.target.value) return true;
      });
      let valueAmount = Number(found.pricePurchase) * Number(selectedOption.quantityOrdered);
      let valueDiscount = (valueAmount * Number(found.percentageDiscount)) / 100;
      let valueAmountDiscount = valueAmount * Number(found.percentageDiscount).toFixed(2);
      let igv, base, total;
      if (order.conditionIgv == "02") {
        base = valueAmountDiscount;
        igv = (base * Number(order.percentageIgv)) / 100;
        total = base + Number(igv);
      } else if (order.conditionIgv == "01") {
        base = valueAmountDiscount / (1 + Number(order.percentageIgv) / 100);
        igv = valueAmountDiscount - base;
        total = valueAmountDiscount;
      } else if (order.conditionIgv == "NA") {
        base = valueAmountDiscount;
        igv = 0;
        total = base + igv;
      }
  
      setSelectedOption({
        ...selectedOption,
        pricePurchase: found.pricePurchase,
        priceSale: found.priceSale,
        unitaryPriceSale: found.unitaryPriceSale,
        price: found.pricePurchase > 0 ? found.pricePurchase : "",
        amount: valueAmount.toFixed(2),
        discount: valueDiscount.toFixed(2),
        productTariffID: found.productTariffID,
        percentageDiscount: found.percentageDiscount,
        unitName: found.unitName,
        amountDiscount: base.toFixed(2),
        igv: igv.toFixed(2),
        amountIgv: total.toFixed(2),
      });
  };

  const handleChangePriceAndQuantityBox = (e) => {
    let amount, discount, amountDiscount, igv, amountIgv, base, total;
    if (e.target.name == "price") {
      amount = Number(selectedOption.quantityOrdered) * Number(e.target.value);
      discount = (Number(amount) * Number(selectedOption.percentageDiscount)) / 100;
      amountDiscount = Number(amount) - Number(discount).toFixed(2);
      if (order.conditionIgv == "02") {
        base = amountDiscount;
        igv = (base * Number(order.percentageIgv)) / 100;
        total = base + Number(igv);
      } else if (order.conditionIgv == "01") {
        base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
        igv = amountDiscount - base;
        total = amountDiscount;
      } else if (order.conditionIgv == "NA") {
        base = amountDiscount;
        igv = 0;
        total = base + igv;
      }
      setSelectedOption({
        ...selectedOption,
        price: e.target.value,
        amount: amount.toFixed(2),
        discount: discount.toFixed(2),
        amountDiscount: base.toFixed(2),
        igv: igv.toFixed(2),
        amountIgv: total.toFixed(2),
      });
    } else if (e.target.name == "quantityOrdered") {
      amount = Number(selectedOption.price) * Number(e.target.value);
      discount = (Number(amount) * Number(selectedOption.percentageDiscount)) / 100;
      amountDiscount = Number(amount) - Number(discount).toFixed(2);

      if (order.conditionIgv == "02") {
        base = amountDiscount;
        igv = (base * Number(order.percentageIgv)) / 100;
        total = base + Number(igv);
      } else if (order.conditionIgv == "01") {
        base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
        igv = amountDiscount - base;
        total = amountDiscount;
      } else if (order.conditionIgv == "NA") {
        base = amountDiscount;
        igv = 0;
        total = base + igv;
      }

      setSelectedOption({
        ...selectedOption,
        quantityOrdered: e.target.value,
        amount: amount.toFixed(2),
        discount: discount.toFixed(2),
        amountDiscount: base.toFixed(2),
        igv: igv.toFixed(2),
        amountIgv: total.toFixed(2),
      });
    } else if (e.target.name == "percentageDiscount") {
      discount = (Number(selectedOption.amount) * Number(e.target.value)) / 100;
      amountDiscount = Number(selectedOption.amount) - Number(discount).toFixed(2);

      if (order.conditionIgv == "02") {
        base = amountDiscount;
        igv = (base * Number(order.percentageIgv)) / 100;
        total = base + Number(igv);
      } else if (order.conditionIgv == "01") {
        base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
        igv = amountDiscount - base;
        total = amountDiscount;
      } else if (order.conditionIgv == "NA") {
        base = amountDiscount;
        igv = 0;
        total = base + igv;
      }

      setSelectedOption({
        ...selectedOption,
        percentageDiscount: e.target.value,
        discount: discount.toFixed(2),
        amountDiscount: base.toFixed(2),
        igv: igv.toFixed(2),
        amountIgv: total.toFixed(2),
      });
    } else if (e.target.name == "unitaryPriceSale") {
      setSelectedOption({
        ...selectedOption,
        unitaryPriceSale: e.target.value,
      });
    } else if (e.target.name == "priceSale") {
      setSelectedOption({
        ...selectedOption,
        priceSale: e.target.value,
      });
    }
  };
  const handleButtonAdd = async (e) => {
    if (Number(selectedOption.productTariffID) > 0) {
        const found = await order.operationItems.find(function (
          detail,
          index
        ) {
          if (detail.productTariffID == selectedOption.productTariffID)
            return true;
        });
  
        if (!found) {
          if (Number(selectedOption.quantityOrdered) > 0) {
            if ((selectedOption.price).toString().length > 0 && Number(selectedOption.price) >= 0) {
              setOrder({
                ...order,
                operationItems: [...order.operationItems, selectedOption],
              });
              setSelectedOption(initialOptionState);
            } else {
              toast.info("verifique el nuevo costo");
            }
          } else {
            toast.info("verifique la cantidad");
          }
        } else {
          toast.info("Ya existe este item");
        }
      } else {
        toast.info("Elija un item");
      }
  };

  

  useEffect(() => {
    getProductsWithTariffs();
  }, []);
  return (
    <>
      <div className="card bg-light ">
        <div className="card-body">
          <div className="row mb-2" style={{ fontSize: "small" }}>
            <div className="col-2">PRODUCTO</div>
            <div className="col-5">
              {options.length ? (
                <Select
                  options={options}
                  styles={customStyles}
                  onChange={(e) => handleChangeProductBox(e)}
                  //defaultValue={props.options[0]}
                />
              ) : (
                "loading"
              )}
            </div>

            <div className="col-1">
              <select
                className="form-control text-info"
                name="productTariffID"
                id="productTariffID"
                disabled={selectedOption.tariffs.length ? false : true}
                value={selectedOption.productTariffID}
                onChange={(e) => handleChangeUnitBox(e)}
              >
                {selectedOption.tariffs.map((item, index) => {
                  return (
                    <option key={index} value={item.productTariffID}>
                      {item.unitName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-2">COSTO CAJA PEPSICO (ANTERIOR)</div>
            <div className="col-2">
              <div className="d-flex">
                <span className="p-2">S/</span>
                <input
                  type="number"
                  className="form-control text-info text-right"
                  name="pricePurchase"
                  placeholder="pricePurchase"
                  value={selectedOption.pricePurchase}
                  readOnly
                />
              </div>
            </div>

            
          </div>

          <div className="row mb-2" style={{ fontSize: "small" }}>
            <div className="col-2">%DSCTO</div>
            <div className="col-2">
              <input
                type="number"
                className="form-control text-center"
                id="percentageDiscount"
                name="percentageDiscount"
                placeholder="percentageDiscount"
                value={selectedOption.percentageDiscount}
                onFocus={(e) => e.target.select()}
                maxLength={6}
                autoComplete="off"
                onChange={(e) => handleChangePriceAndQuantityBox(e)}
              />
            </div>
            <div className="col"></div>
            <div className="col-1">CANTIDAD ORDENADA</div>
            <div className="col-1">
              <input
                type="number"
                className="form-control text-center"
                name="quantityOrdered"
                placeholder="cant"
                value={selectedOption.quantityOrdered}
                onFocus={(e) => e.target.select()}
                onChange={(e) => handleChangePriceAndQuantityBox(e)}
                maxLength={6}
                autoComplete="off"
              />
            </div>
            
            <div className="col-2">COSTO CAJA PEPSICO (NUEVO)</div>
            <div className="col-2">
              <div className="d-flex">
                <span className="p-2">S/</span>
                <input
                  type="number"
                  className="form-control text-right"
                  name="price"
                  placeholder="ingrese..."
                  value={selectedOption.price}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => handleChangePriceAndQuantityBox(e)}
                  maxLength={6}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          <div className="row mb-2" style={{ fontSize: "small" }}>
            

            <div className="col-2">
            FEC. VTO
            </div>
            <div className="col-2">
            <input
                        type="date"
                        name="expireDate"
                        className="form-control"
                        value={selectedOption.expireDate}
                        onChange={(e) => {

                          setSelectedOption({
                            ...selectedOption,
                            expireDate: e.target.value,
                          });
                           
                        }}
                    />
            </div>
            <div className="col-8 text-right">
            </div>
          </div>

          <div className="row mb-0" style={{ fontSize: "small" }}>
            

            <div className="col-6"></div>
            <div className="col-2"></div>
            <div className="col-4 text-right">
              <button className="btn btn-danger" onClick={handleButtonAdd}>
                <i className="fas fa-plus"></i> Agregar item
              </button>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}
