import { useState } from "react";
import {
  getLoginApi,
  getUserApi,
  addUserApi,
  updateUserApi,
  deleteUserApi,
} from "../../apis/apis.hrm/apisUsers";
import { useHooksAuth } from ".";

export function useHooksUser() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState(null);
  const { auth } = useHooksAuth();
  const getLogin = async (token) => {
    try {
      const response = await getLoginApi(token);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const getUser = async () => {
    try {
      setLoading(true);
      const response = await getUserApi(auth.token);
      setLoading(false);
      setUsers(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const addUser = async (data) => {
    try {
      setLoading(true);
      console.log("data", data);
      await addUserApi(data, auth.token);
      console.log("addUserApi", data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const updateUser = async (data) => {
    try {
      setLoading(true);
      await updateUserApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const deleteUser = async (id) => {
    try {
      setLoading(true);
      await deleteUserApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  return {
    getLogin,
    loading,
    error,
    users,
    getUser,
    addUser,
    updateUser,
    deleteUser,
  };
}
