import React, { useState, useEffect, useContext, createContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { InventoryForm } from "../fragments.logistics/InventoryForm";
import { InventoryList } from "../fragments.logistics/InventoryList";
import { InventoryRegisterOperation } from "../fragments.logistics/InventoryRegisterOperation";
import { InventoryRegisterSaleOperation } from "../fragments.logistics/InventoryRegisterSaleOperation";
import {
  fetchedProductsWithTariffs,
  fetchedWarehousesBySubsidiary,
} from "../../../apis/apis.logistics/apisProduct";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function InventoryPage() {

  const { inventories, setWarehouses, subsidiary, setSubsidiary } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState({
    productID: 0,
    productName: "",
    warehouseName: "",
    
  });
  
  const { auth } = useContext(ContextAuth);

  const getWarehousesBySubsidiary = async (id) => {
    try {
      const response = await fetchedWarehousesBySubsidiary({
        subsidiaryID: id,
      });
      setWarehouses(response);
      
    } catch (error) {
      throw error;
    }
  };

  const getProductsWithTariffs = async () => {
    try {
      const response = await fetchedProductsWithTariffs();

      response.forEach((mobile) => {
        setOptions((prevState) => [
          ...prevState,
          {
            value: mobile["productID"],
            label: mobile["purchaseName"],
            tariff: mobile["productTariff"],
            grammage: mobile["productGrammage"],
            sku: mobile["productSku"],
          },
        ]); // add new expense to expenses array
      });

    } catch (error) {
      throw error;
    }
  };





useEffect(() => {
  getWarehousesBySubsidiary(auth.data.subsidiary.subsidiary_id);

  // getComparativeFromProductsOfOneWarehouse();
  // console.log("auth", auth.data.subsidiary);
  /*inventories.forEach(function (arrayItem) {
    setOptions([
        ...options,
        {
          value: arrayItem.productID,
          label: arrayItem.productName,
        }
    ]);

//console.log("options", options);
  });*/
 
 
  setSubsidiary({
    ...subsidiary,
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    subsidiaryName: auth.data.subsidiary.name,
  });
  
}, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Modulo Logistico</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Logistica</a>
                </li>
                <li className="breadcrumb-item active">Inventario</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <div className="row">
                <div className="col-3">
                  <div className="card ">
                    <div className="card-body">
                      <InventoryForm
                        subsidiary={auth.data.subsidiary}
                        setSelectedInventory={setSelectedInventory}
                        selectedInventory={selectedInventory}
                      />
                    </div>
                  </div>
                </div>
                <div className="col table-responsive">
                  <InventoryList selectedInventory={selectedInventory} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <InventoryRegisterOperation options={options} />
      <InventoryRegisterSaleOperation options={options} />
    </>
  );
}
