import React, { useEffect, useContext, useState } from "react";
import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { sendNewBankOperation } from "../../../apis/apis.accounting/apisCash";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function BankOperations(props) {
  
  const { auth } = useContext(ContextAuth);  

   const optionsBanks = [
     { id: "D", name: "Deposito" },
     { id: "R", name: "Retiro" },
   ];
   
  function handleChange(e) {

    let value = e.target.value;
    let name = e.target.name;   
    
    props.setNewOperation({
      ...props.newOperation,
      [name]: value,      
    });  
  }
 
  const saveNewBankOperation = async (data) => {
    try {
      const response = await sendNewBankOperation(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      props.newOperation.bankOperationDescription != "" &&
      props.newOperation.bankOperationTotal != "" &&
      props.newOperation.bankNewTypeTransaction != "0"
    ) {

      if (props.newOperation.bankFlowID == 0) {
          saveNewBankOperation(props.newOperation).then(async (res) => {
          toast.success("LA OPERACIÓN SE REGISTRO CORRECTAMENTE");
          document.getElementById("btn-search").click();               
        });
      } else {        
        saveNewBankOperation(props.newOperation).then(async (res) => {
          document.getElementById("btn-search").click();
          toast.success("OPERACIÓN ACTUALIZADA CON EXITO!");
        });
      }
    } else {
      toast.warn("Complete los campos necesarioss");
      return false;
    }
    document.getElementById("close-modal-operations-bank").click();
  };

  //console.log(props.newOperation);
  //console.log(props.bankItem);
  //console.log('props.operationsBanks', props.operationsBanks);
  //console.log("props.selectedBankCashFlow", props.selectedBankCashFlow);
  useEffect(() => {
    const bankOperationFound = props.operationsBanks.find(
      (item3) => item3.cashFlowID == props.selectedBankCashFlow.cashFlowID
    );
    if (bankOperationFound) {
      props.setNewOperation({
        bankId: props.bankItem.bankID,
        bankFlowID: bankOperationFound.cashFlowID,
        bankName: props.bankItem.bankName,
        bankAmount: props.bankItem.bankAmount,
        bankOperationDescription: bankOperationFound.description,
        bankNewTypeTransaction: bankOperationFound.typeOperation,
        bankNewOperationDate: bankOperationFound.transactionDateFormat,
        bankOperationTotal: bankOperationFound.total,
        userID: auth.data.user_id,
      });
    } else {
      props.setNewOperation({
        ...props.newOperation,
        bankId: props.bankItem.bankID,
        bankFlowID: "0",
        bankName: props.bankItem.bankName,
        bankAmount: props.bankItem.bankAmount,
        bankOperationDescription: "",
        bankNewTypeTransaction: "0",
        bankNewOperationDate: getCurrentDate("-"),
        bankOperationTotal: "",
        userID: auth.data.user_id,
      });
    }
  }, [props.selectedBankCashFlow, props.bankItem]);
  
  return (
    <div
      className="modal fade"
      id="modal-banks-operations"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {/*props.selectedCashFlow.cashFlowID ? " Editando " : "Creando "*/}
              Movimiento Bancarios :
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Entidad Bancaria:</label>
                  <input
                    type="email"
                    className="form-control text-center"
                    id="exampleInputEmail1"
                    disabled
                    value={props.bankItem.bankName}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Numero de Cuenta:</label>
                  <input
                    type="email"
                    className="form-control text-center"
                    id="exampleInputEmail1"
                    disabled
                    value={props.bankItem.bankAccountNumber}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Saldo Actual:</label>
                  <input
                    type="email"
                    className="form-control text-right"
                    id="exampleInputEmail1"
                    disabled
                    value={"S/ " + Number(props.bankItem.bankAmount).toFixed(2)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-4 col-form-label text-right"
              >
                Descripcion de la Operación:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control text-uppercase"
                  id="inputEmail3"
                  value={props.newOperation.bankOperationDescription}
                  name="bankOperationDescription"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-4 col-form-label text-right"
              >
                Operacion Bancaria:
              </label>
              <div className="col-sm-4">
                <select
                  className="form-control select2 text-uppercase"
                  onChange={handleChange}
                  name="bankNewTypeTransaction"
                  value={props.newOperation.bankNewTypeTransaction}
                >
                  <option value="0">Seleccione...</option>
                  {optionsBanks.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <label
                htmlFor="inputEmail3"
                className="col-auto col-form-label text-right"
              >
                Fecha:
              </label>

              <div className="col-sm-3">
                <input
                  type="date"
                  name="bankNewOperationDate"
                  className="form-control"
                  value={props.newOperation.bankNewOperationDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-4 col-form-label text-right"
              >
                Total:
              </label>
              <div className="col-sm-2">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail31"
                  value={props.newOperation.bankOperationTotal}
                  name="bankOperationTotal"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="close-modal-operations-bank"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar Operación
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
