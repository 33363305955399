import React, { useEffect, useContext, useState } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";
import { AccountReceiveSearch } from "../fragments.accounting/AccountReceiveSearch";
import { fetchedDebtors } from "../../../apis/apis.accounting/apisCash";
import { AccountReceiveOperations } from "../fragments.accounting/AccountReceiveOperations";
import { AccountReceiveList } from "../fragments.accounting/AccountReceiveList";

export function AccountReceivePage() {
  const { auth } = useContext(ContextAuth);
  const [allDebtors, setallDebtors] = useState([]);
  const [operationsReceiveClient, setOperationsReceiveClient] = useState([]);
  const [allCashesAndBanks, setallCashesAndBanks] = useState([]);

  const [selectedCashFlowDebtor, setSelectedCashFlowDebtor] = useState({
    cashFlowID: "0",
    cashID: "0",
    cashName: "",
    total: "0",
    operationID: "0",
    differenceTotalAndTotalPayHC: "0",
  });

  const [dates, setDates] = useState({
    startDate: getCurrentDate("-"),
    endDate: getCurrentDate("-"),
  });

  const [debtorItem, setDebtorItem] = useState({
    debtorCashID: "0",
    debtorCashAmount: "0",
    debtorCashName: "",
    debtorCashStatus: "",
    debtorClientID: "0",
    debtorClientName: "",
    debtorClientVisitDay: "0",
  });

  const [newOperationPayReceive, setNewOperationPayReceive] = useState({
    cashId: "0",
    cashName: "",
    bankId: "0",
    bankName: "",
    bankAccountNumber: "",
    bankDescription: "",
    CashNewOperationDate: getCurrentDate("-"),
    cashTypeTransaction: "0",
    cashDescription: "",
    cashTotal: "",
    userID: auth.data.user_id,
    operationID: "0",
    cashClientID: "0"
  });

 
  /*API DE DEUDORES*/
  const getDebtors = async () => {
    try {
      const response = await fetchedDebtors();
      setallDebtors(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /* ***** */

  useEffect(() => {
    getDebtors();
    
  }, []);
  
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>MODULO CUENTAS POR COBRAR (CLIENTES)</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Advanced Form</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-body">
              <AccountReceiveSearch
                dates={dates}
                setDates={setDates}
                allDebtors={allDebtors}
                setallDebtors={setallDebtors}
                debtorItem={debtorItem}
                setDebtorItem={setDebtorItem}
                operationsReceiveClient={operationsReceiveClient}
                setOperationsReceiveClient={setOperationsReceiveClient}
              />
              <AccountReceiveList
                operationsReceiveClient={operationsReceiveClient}
                setOperationsReceiveClient={setOperationsReceiveClient}
                selectedCashFlowDebtor={selectedCashFlowDebtor}
                setSelectedCashFlowDebtor={setSelectedCashFlowDebtor}
                newOperationPayReceive={newOperationPayReceive}
                setNewOperationPayReceive={setNewOperationPayReceive}
              />
              <AccountReceiveOperations
                selectedCashFlowDebtor={selectedCashFlowDebtor}
                setSelectedCashFlowDebtor={setSelectedCashFlowDebtor}
                newOperationPayReceive={newOperationPayReceive}
                setNewOperationPayReceive={setNewOperationPayReceive}
                allCashesAndBanks={allCashesAndBanks}
                setallCashesAndBanks={setallCashesAndBanks}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
