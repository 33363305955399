import { map } from "lodash";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalProvider } from "../contexts/GlobalContext";
import routes from "./routes";

export function RoutesNavigation() {

    /*
    

    */



  return (
    <BrowserRouter>
      <GlobalProvider>
        <Routes>
          {map(routes, (route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <route.layout>
                  <route.component />
                </route.layout>
              }
            />
          ))}
        </Routes>
      </GlobalProvider>
    </BrowserRouter>
  );
}
