import React, { useState, useEffect, useContext } from "react";
import { getCurrentDate } from "../../../configs/helpers";
import { ReviewOrderPurchaseList } from "../fragments.buys/ReviewOrderPurchaseList";

import { useParams } from "react-router";
import { fetchedOrderPurchaseByID } from "../../../apis/apis.logistics/apisProduct";

const initialOrderState = {
    operationID: 0,
    operationDate: getCurrentDate("-"),
    operationDocumentNumber: "",
    operationCorrelative: 0,
    operationCode: "",
    percentageIgv: 18,
    conditionIgv: "02",
    operationNumberItems: 0,
    operationItems: [],
    operationStatus: "01",
    operationStatusDisplay: "PENDIENTE",
  
    operationActionID: "NA",
    operationTypeID: "08",
    operationDocumentTypeID: "03",
    operationAmountBase: "",
    operationAmountIgv: "",
    operationAmountTotal: "",
    supplierDocumentType: "06",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierSecondSurname: "",
    supplierDocumentNumber: "",
    supplierName: "",
    warehouseID: 0,
    warehouseName: "",
    warehouseCategory: "",
    userID: 0,
  };

export function ReviewOrderPurchasePage() {
    let { id } = useParams();
  const [order, setOrder] = useState(initialOrderState);
  const getOrderPurchase = async () => {
    try {
      const response = await fetchedOrderPurchaseByID({ operationID: id });
      setOrder(response);
      //return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getOrderPurchase();
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>ORDENES DE COMPRA: RECEPCION REALIZADA</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Orden de Compras</a>
                </li>
                <li className="breadcrumb-item active">{id}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
            <ReviewOrderPurchaseList order={order} setOrder={setOrder} initialOrderState={initialOrderState} />

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
