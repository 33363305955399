import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const dayNames = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"]
const showcases = ["NO CUENTA", "AEREO", "2 NIVELES", "5 NIVELES", "6X8 NIVELES", "BOLSA"]
const customerType = ["PEPSICO", "PROPIO"]

export function ClientPortfolioList({orders}) {
    return (
        <> 
            <table className="table table-sm table-bordered small" id="table-to-xls">
                <thead>
                <tr className='text-uppercase'>

                    <td className="align-middle bg-navy" >NOMBRE DEL VENDEDOR 3PD</td>
                    <td className="align-middle bg-navy" >CÓDIGO DE RUTA VENDEDOR 3PD</td>
                    <td className="align-middle bg-navy" >DÍA DE VISITA AL PDV<br/>(Lun - Dom)</td>
                    <td className="align-middle bg-navy" >CÓDIGO PDV</td>
                    <td className="align-middle bg-navy" >NOMBRE PDV</td>
                    <td className="align-middle bg-navy" >DIRECCIÓN PDV</td>
                    <td className="align-middle bg-navy" >TIPO CLIENTE</td>
                    <td className="align-middle bg-navy" >EXHIBIDOR</td>
                    <td className="align-middle bg-info" >Compra Mensual<br/>Mes 1 (S/)</td>
                    <td className="align-middle bg-info" >Compra Mensual<br/>Mes 2 (S/)</td>
                    <td className="align-middle bg-info" >Compra Mensual<br/>Mes 3 (S/)</td>
                    <td className="align-middle bg-info" >Compra Promedio Semanal<br/>Meses (DS S/)</td>
                    <td className="align-middle bg-primary" >CLASIFICACIÓN<br/>PDV</td>
                    <td className="align-middle bg-primary" >AAA</td>
                    <td className="align-middle bg-primary" >AA</td>
                    <td className="align-middle bg-primary" >A</td>
                    <td className="align-middle bg-primary" >B</td>
                    <td className="align-middle bg-primary" >C</td>
                    <td className="align-middle bg-primary" >D</td>
                    <td className="align-middle bg-primary" >E</td>
                    <td className="align-middle bg-orange" >Compra Mensual<br/>Mes 4 (S/)</td>
                    <td className="align-middle bg-orange" >Compra Prom Semanal<br/>Mes 4 (DS S/)</td>
                    <td className="align-middle bg-orange" >Diferencia S/</td>
                
                </tr>
                </thead>
                <tbody>
                {orders.map((o, index) => {
                    return (
                    <tr key={index} className='text-uppercase text' >
                        <td className="align-middle bg-warning text-nowrap">{o.sellerFirstName} {o.sellerLastName}</td>
                        <td className="align-middle bg-warning">{o.sellerGangName}</td>
                        <td className="align-middle bg-warning text-nowrap">{dayNames[o.clientVisitDay]}</td>
                        <td className="align-middle bg-warning">{o.clientObservation}</td>
                        <td className="align-middle bg-warning text-nowrap">{o.clientName} {o.clientFirstSurname} {o.clientSecondSurname}</td>
                        <td className="align-middle bg-warning text-nowrap">{o.clientAddress}</td> 
                        <td className="align-middle bg-warning text-nowrap">{customerType[o.clientCustomerType]}</td> 
                        <td className="align-middle bg-warning text-nowrap">{showcases[o.clientShowCases]}</td> 
                        <td className="align-middle bg-warning text-nowrap text-right">S/ {Number(o.month1).toFixed(2)}</td>            
                        <td className="align-middle bg-warning text-nowrap text-right">S/ {Number(o.month2).toFixed(2)}</td>            
                        <td className="align-middle bg-warning text-nowrap text-right">S/ {Number(o.month3).toFixed(2)}</td>            
                        <td className="align-middle text-nowrap text-right">S/ {Number(o.average).toFixed(2)}</td>
                        <td className="align-middle text-center">{o.purchaseVolume}</td> 
                        <td className="align-middle">{o.purchaseVolume==="3A"?1:0}</td> 
                        <td className="align-middle">{o.purchaseVolume==="2A"?1:0}</td> 
                        <td className="align-middle">{o.purchaseVolume==="A"?1:0}</td> 
                        <td className="align-middle">{o.purchaseVolume==="B"?1:0}</td> 
                        <td className="align-middle">{o.purchaseVolume==="C"?1:0}</td> 
                        <td className="align-middle">{o.purchaseVolume==="D"?1:0}</td> 
                        <td className="align-middle">{o.purchaseVolume==="E"?1:0}</td> 
                        <td className="align-middle bg-warning text-nowrap text-right">S/ {Number(o.month4).toFixed(2)}</td>            
                        <td className="align-middle bg-warning text-nowrap text-right">S/ {Number(o.average2).toFixed(2)}</td>            
                        <td className="align-middle bg-warning text-nowrap text-right">S/ {Number(o.differenceBetweenAverages).toFixed(2)}</td>            
                    </tr>
                    );
                })}
                </tbody>
                {/* <tfoot>
                <tr>
                    <td className="align-middle text-right" colSpan={4}>TOTAL</td>
                    <td className="align-middle text-right">S/ {Number(orders.reduce((previousValue, currentValue) => previousValue + currentValue.subtotal, 0)).toFixed(2)}</td>
                </tr>
                </tfoot> */}
            </table>
            <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-outline-secondary mt-3 ml-1"
                            table="table-to-xls"
                            filename="report_of_portfolios"
                            sheet="report"
                            buttonText="Descargar como XLS"/>
        </>
    )
}