import React from "react";
import { RoutesNavigation } from "./routes";
import { AuthProvider } from "./contexts";
import { ToastContainer, toast } from 'react-toastify';
import "./assets/css/App.css";
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <AuthProvider>
      <RoutesNavigation />
      <ToastContainer 
        position="top-right" 
        theme="colored"
        autoClose={5000} 
        hideProgressBar
        closeOnClick
      />
    </AuthProvider>
  );
}
