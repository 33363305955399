import React, { useState, useContext, useitem } from "react";
import { getCurrentDate } from "../../../configs/helpers";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function AccountReceiveList({
  operationsReceiveClient,
  setSelectedCashFlowDebtor,
  newOperationPayReceive,
  setNewOperationPayReceive,
}) {
  const newArrayDebt = [];
  const newArrayPay = [];
  //const { auth } = useContext(ContextAuth);
  console.log(operationsReceiveClient);

  const operationValues = Object.values(operationsReceiveClient);
  
  const tbodies = operationValues.map((item, index) => {

    newArrayDebt.push(item.differenceTotalAndTotalPayHC); 

    const payValues = Object.values(item.payHCDict);

    const buttonPay =
      index >= 0 ? (
        <td
          className="text-center align-middle"
          style={
            item.differenceTotalAndTotalPayHC === 0
              ? { backgroundColor: "#c0f8d3" }
              : null
          }
          rowSpan={payValues.length > 0 ? payValues.length + 1 : 1}
        >
          {item.differenceTotalAndTotalPayHC !== 0 ? (
            <button
              className="btn btn-success"
              data-toggle="modal"
              type="button"
              data-target="#modal-account-receive-operations"
              onClick={(e) => {
                setSelectedCashFlowDebtor({
                  cashFlowID: item.cashFlowID,
                  cashID: item.cashID,
                  cashName: item.cashName,
                  total: item.total,
                  operationID: item.operationID,
                  differenceTotalAndTotalPayHC:
                    item.differenceTotalAndTotalPayHC,
                });
                setNewOperationPayReceive({
                  cashId: "0",
                  cashName: "",
                  bankId: "0",
                  bankName: "",
                  bankAccountNumber: "",
                  bankDescription:
                    "PAGO DE LA VENTA Nro: " + item.documentNumber,
                  CashNewOperationDate: getCurrentDate("-"),
                  cashTypeTransaction: "0",
                  cashDescription:
                    "PAGO DE LA VENTA Nro: " + item.documentNumber,
                  cashTotal: item.differenceTotalAndTotalPayHC,
                  cashClientID: item.cashID, // cashID = ID de la caja del cliente en credito
                  //totalPayHC: item.totalPayHC
                  //userID: auth.data.user_id,
                });
              }}
            >
              PAGAR
            </button>
          ) : (
            <span>
              <h5>
                <strong>PAGADO</strong>
              </h5>
            </span>
          )}
        </td>
      ) : null;

    let payRows = payValues.map((itempay, i) => {
      
      newArrayPay.push(itempay.total);

      const transactionDate =
        i === 0 ? (
          <td className="text-center" rowSpan={payValues.length + 1}>
            {item.transactionDate}
          </td>
        ) : null;
      const documentNumber =
        i === 0 ? (
          <td className="text-center" rowSpan={payValues.length + 1}>
            {item.documentNumber}
          </td>
        ) : null;
      const documentTypeDisplay =
        i === 0 ? (
          <td className="text-center" rowSpan={payValues.length + 1}>
            {item.documentTypeDisplay}
          </td>
        ) : null;
      const description =
        i === 0 ? (
          <td className="text-center" rowSpan={payValues.length + 1}>
            {item.description}
          </td>
        ) : null;
      const usuario =
        i === 0 ? (
          <td
            className="text-center text-uppercase"
            rowSpan={payValues.length + 1}
          >
            {item.userName}
          </td>
        ) : null;
      const total =
        i === 0 ? (
          <td
            className="text-center font-weight-bold"
            rowSpan={payValues.length + 1}
          >
            S/ {Number(item.total).toFixed(2)}
          </td>
        ) : null;
      const total_debt =
        i === 0 ? (
          <td
            className="text-center font-weight-bold text-danger"
            rowSpan={payValues.length + 1}
          >
            S/ {Number(item.differenceTotalAndTotalPayHC).toFixed(2)}
          </td>
        ) : null;

      return (
        <tr key={i}>
          {transactionDate}
          {documentNumber}
          {documentTypeDisplay}
          {description}
          {usuario}
          {total}
          {total_debt}
          {i === 0 ? buttonPay : null}
          <td className="text-center" style={{ backgroundColor: "#c0f8d3" }}>
            {itempay.transactionDate}
          </td>
          <td className="text-center" style={{ backgroundColor: "#c0f8d3" }}>
            {itempay.typeOperation === "E" ? "EFECTIVO " : itempay.typeOperation === "D" ? "DEPOSITO" : itempay.typeOperation === "DY" ? "YAPE" : ""}
          </td>
          <td className="text-center" style={{ backgroundColor: "#c0f8d3" }}>
            {itempay.cashName}
          </td>
          <td
            className="text-center font-weight-bold"
            style={{ backgroundColor: "#c0f8d3" }}
          >
            S/ {Number(itempay.total).toFixed(2)}
          </td>
        </tr>
      );
    });

    if (payRows.length === 0) {
      payRows = (
        <tr key={index}>
          <td className="text-center">{item.transactionDate}</td>
          <td className="text-center">{item.documentNumber}</td>
          <td className="text-center">{item.documentTypeDisplay}</td>
          <td className="text-center">{item.description}</td>
          <td className="text-center text-uppercase">{item.userName}</td>
          <td className="text-center font-weight-bold">
            S/ {Number(item.total).toFixed(2)}
          </td>
          <td className="text-center font-weight-bold text-danger">
            S/ {Number(item.differenceTotalAndTotalPayHC).toFixed(2)}
          </td>
          {buttonPay}
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }

    return (
      <tbody key={index} className="table-bordered">
        {payRows}
      </tbody>
    );
  });

  const sumTotalDebt = newArrayDebt.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  const sumTotalPay = newArrayPay.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  
  //console.log("sumTotalPay", sumTotalPay);

  return (
    <>
      <table className="table table-bordered table-striped table-sm bg-white">
        <thead>
          <tr>
            <th className="text-center align-middle" style={{ width: "100px" }}>
              Fecha
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              Nro de Documento
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              Tipo de Documento
            </th>
            <th className="text-center align-middle">Descripción</th>
            <th className="text-center align-middle">Usuario</th>
            <th className="text-center" style={{ width: "100px" }}>
              Total de la Venta
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              Total deuda restante
            </th>
            <th className="text-center bg-success">Acción</th>
            <th className="text-center bg-primary">Fecha de Pago</th>
            <th className="text-center bg-primary">Tipo de Pago</th>
            <th className="text-center bg-primary">Entidad</th>
            <th className="text-center bg-primary">Monto Pagado</th>
          </tr>
        </thead>
        {tbodies}
      </table>

      <br></br>

      <table className="table table-bordered table-striped table-sm bg-aqua-marine text-blue">
        <thead>
          <tr>
            <th className="text-center align-middle text-uppercase">
              Total deuda restante
            </th>
            <th className="text-center align-middle">
              <h5 className="font-weight-bold">
                S/{" "}
                {
                  operationsReceiveClient
                    .reduce(
                      (previousValue, currentValue) =>
                        previousValue +
                        currentValue.differenceTotalAndTotalPayHC,
                      0
                    )
                    .toFixed(2)
                  //Number(sumTotalDebt).toFixed(2)
                }
              </h5>
            </th>
            <th className="text-center align-middle text-uppercase">
              Total pagado
            </th>
            <th className="text-center align-middle">
              <h5 className="font-weight-bold">
                S/ {Number(sumTotalPay).toFixed(2)}
              </h5>
            </th>
          </tr>
        </thead>
      </table>
    </>
  );
}
