import React, { useEffect, useState, useContext } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { WarehouseList } from "../fragments.logistics/WarehouseList";
import { WarehouseRegisterForm } from "../fragments.logistics/WarehouseRegisterForm";
import { WarehouseRemoveForm } from "../fragments.logistics/WarehouseRemoveForm";

import { fetchedWarehousesWithoutProducts } from "../../../apis/apis.logistics/apisProduct";
import { fetchedWarehouseCategories } from "../../../apis/apis.logistics/apisProduct";

export function WarehousePage() {
  const { auth } = useContext(ContextAuth);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [warehouseCategories, setWarehouseCategories] = useState([]);

  const getWarehousesAndProducts = async (id) => {
    try {
      const response = await fetchedWarehousesWithoutProducts({
        subsidiaryID: id,
      });
      setWarehouses(response);
    } catch (error) {
      throw error;
    }
  };
  const getWarehouseCategories = async () => {
    try {
      const response = await fetchedWarehouseCategories();
      setWarehouseCategories(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getWarehouseCategories();
    getWarehousesAndProducts(auth.data.subsidiary.subsidiary_id);
  }, []);
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Modulo Logistico</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Logistica</a>
                </li>
                <li className="breadcrumb-item active">Almacenes</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <WarehouseList
                warehouses={warehouses}
                setWarehouses={setWarehouses}
                selectedWarehouse={selectedWarehouse}
                setSelectedWarehouse={setSelectedWarehouse}
              />
              <WarehouseRegisterForm
                warehouseCategories={warehouseCategories}
                subsidiaryID={auth.data.subsidiary.subsidiary_id}
                subsidiaryName={auth.data.subsidiary.name}
                warehouses={warehouses}
                setWarehouses={setWarehouses}
                selectedWarehouse={selectedWarehouse}
                setSelectedWarehouse={setSelectedWarehouse}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
