import React, { useState, useContext, useEffect } from "react";
import { fetchedUsersByGang, getActualSalesOfWeekData } from "../../../apis/apis.dispatches/apisDispatch";

import { fetchedGangs } from "../../../apis/apis.logistics/apisProduct";

import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function SaleStatisticFilter({setSalesWeekData}) {

    const { auth } = useContext(ContextAuth);
    const [gangs, setGangs] = useState([]);
    const [users, setUsers] = useState([]);

    const [questionState, setQuestionState] = useState({
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
        warehouseID: 0,
        gang: {gangID: 0, gangName: ""},
        userID: 0,
        week: "2023-W37"
    });

    const getActualSalesOfWeek = async (o) => {
        try {
          const response = await getActualSalesOfWeekData(o);
          
          return response;
        } catch (error) {
          throw error;
        }
      };
      
    const getGangs = async () => {
        try {
          const response = await fetchedGangs();
          setGangs(response);
        } catch (error) {
          throw error;
        }
    };

    const getUsersByGang = async (id) => {
    try {
        const response = await fetchedUsersByGang({
        gangID: id,
        });
        setUsers(response);
    } catch (error) {
        throw error;
    }
    };

    
    function handleChange(e) {
      
      if(e.target.name=="gangID"){
          const select = document.getElementById("gangID");

          const selectedIndex = select.selectedIndex;
          const selectedValue = select.value;
          const selectedText = select.options[selectedIndex].text; 

          let nextGang = { ...questionState.gang, gangID: Number(e.target.value), gangName: selectedText };
          let nextQuestionState = { ...questionState, gang: nextGang, userID: 0 };
          setQuestionState(nextQuestionState);

          getUsersByGang(e.target.value);
      }else{
        setQuestionState({ ...questionState, [e.target.name]: e.target.value });
      }
    }

    const handleQuery = (e) => {
    searchSuggestions();
    
    
    };

    function searchSuggestions() {
      
      getActualSalesOfWeek(questionState).then((res) => {
          let results = res.length;
          setSalesWeekData(res)
      });
    }

    useEffect(() => {
      getGangs();
    }, []);

    useEffect(() => {

      if(questionState.week == "2023-W37"){
          // console.log(questionState);
        const today = new Date();
        const year = today.getFullYear();
        const weekNumber = getWeekNumber(today); // Función para obtener el número de semana
        
        // Formatear la semana como "YYYY-WWW"
        const formattedWeek = `${year}-W${weekNumber.toString().padStart(2, '0')}`;
        setQuestionState({ ...questionState, week: formattedWeek });
      }
    }, [questionState]);

    function getWeekNumber(date) {
      const d = new Date(date);
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
      const week1 = new Date(d.getFullYear(), 0, 4);
      return (
        1 + Math.round(((d - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
      );
    }
    return (
      <>
        <div className="row mb-2">

            <div className="col-lg-4">
              <label className="">RUTA:</label>
            </div>

            <div className="col-lg-8">
                <select
                    className="form-control"
                    id="gangID"
                    name="gangID"
                    value={questionState.gangID}
                    onChange={handleChange}
                >
                  <option value={0}>TODOS</option>
                    {gangs.map((item, index) => {
                      return (
                        <option key={index} value={item.gangID}>{item.gangName}</option>
                      );
                    })}
                </select>
            </div>
            
          </div>

          <div className="row mb-2">

            <div className="col-lg-4">
              <label className="">USUARIO:</label>
            </div>
            
            <div className="col-lg-8">
                <select
                    className="form-control"
                    name="userID"
                    value={questionState.userID}
                    onChange={e=>{setQuestionState({ ...questionState, userID: Number(e.target.value) });}}
                >
                  <option value={0}>Elegir</option>
                    {users.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.first_name} | {item.last_name}
                        </option>
                      );
                    })}
                </select>
            </div>
       
        </div>

        <div className="row mb-2">

          <div className="col-lg-4">
            <label className="">SEMANA:</label>
          </div>

          <div className="col-lg-8">
            <input type="week" name="week" value={questionState.week} id="camp-week" onChange={handleChange} required className="form-control" />
          </div>
       
        </div>

        <div className="row">

          <div className="col-lg-12">
            <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
            <i className="fas fa-search"></i> Buscar
            </button>
          </div>

      </div>
    </>
    )
}