import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { ClientPortfolioFilter } from "../fragments.dispatches/ClientPortfolioFilter";
import { ClientPortfolioList } from "../fragments.dispatches/ClientPortfolioList";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";

const currentDate = new Date();
const initialDate = new Date(currentDate.getFullYear(), 0);
const finalMonth = initialDate.getMonth() + 2;
const finalYear = initialDate.getFullYear() + Math.floor(finalMonth / 12);
const finalDate = new Date(finalYear, finalMonth % 12, initialDate.getDate());

export function ClientPortfolioPage() {
    const [orders, setOrders] = useState([]);
    const [gangs, setGangs] = useState([]);
    const [users, setUsers] = useState([]);
    const [filename, setFilename] = useState("ReporteVentas");
    const [operationStatus, setOperationStatus] = useState([ { id: "02", name: "Comprobantes Generados" }, { id: "01", name: "Cotizaciones" },]);
    const { auth } = useContext(ContextAuth);

    const [questionState, setQuestionState] = useState({
        startDate: getCurrentDate("-"),
        endDate: getCurrentDate("-"),
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
        warehouseID: 0,
        gangID: 0,
        userID: 0,
        operationStatus: "02",
        startMonth: Number(initialDate.getMonth()),
        endMonth: Number(finalDate.getMonth()),
        startYear: Number(initialDate.getFullYear()),
        endYear: Number(finalDate.getFullYear()),
        monthToCompare: 4
      });
      
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Cartera de clientes</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                        <a href="#">Distribuciones</a>
                        </li>
                        <li className="breadcrumb-item active">Listado</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                <div className="card card-default">
                    <div className="card-header"></div>
                    <div className="card-body">
                    <ClientPortfolioFilter setQuestionState={setQuestionState} questionState={questionState} setOrders={setOrders}
                    />
                    <div className="table-responsive">
                        <ClientPortfolioList orders={orders} />
                    </div>
                    
                        
                    </div>
                </div>
                </div>
            </section>

        
        </>
    )
}