import React, { useState, useContext } from "react";
import { sendFilterPurchasesData } from "../../../apis/apis.buys/apisPurchase";
import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function PurchaseFilter({setOrders}) {
    const { auth } = useContext(ContextAuth);
  const sendFilterPurchases = async (data) => {
    try {
      const response = await sendFilterPurchasesData(data);
      //console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  //state to hold responses from input
  const [questionState, setQuestionState] = useState({
    options: [{ radioName: "actions", selected: "" }],
    suggest: "",
    startDate: getCurrentDate("-"),
    endDate: getCurrentDate("-"),
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
  });

  //radio buttons onchange
  const handleChange = (e) => {
    const { options } = questionState;
    const { name, value } = e.target;
    //console.log(name, value);

    const nextState = options.map((opt) => {
      if (opt.radioName !== name) {
        return { ...opt };
      } else {
        return { ...opt, selected: value };
      }
    });

    setQuestionState({ ...questionState, options: nextState });
  };

  function handleChangeSuggest(e) {
    setQuestionState({ ...questionState, suggest: e.target.value });
  }

  function handleChangeDate(e) {
    setQuestionState({ ...questionState, [e.target.name]: e.target.value });
  }

  const handleKeyDownSupplier = async (e) => {
    if (e.keyCode === 13) {
      searchSuggestions();
    }
  };

  const handleQuery = (e) => {
    searchSuggestions();
  };

  function searchSuggestions() {
    sendFilterPurchases(questionState).then((res) => {
      let results = res.length;
      toast.info(
        results > 1
          ? "Se encontraron " + results.toString() + " resultados."
          : "Se encontró " + results.toString() + " resultado(s)."
      );
      setOrders(res);
    });
  }

  return (
    <div className="border border-secondary rounded p-3 mb-3">
      <div className="row">
        <div className="col-auto pt-2 text-cyan">Buscar x</div>
        <div className="col-auto pt-2">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="actions"
              id="action1"
              value="id"
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="action1">
              ID
            </label>
          </div>
          <span className="mr-3">{" / "}</span>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="actions"
              id="action2"
              value="supplierName"
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="action2">
              Proveedor
            </label>
          </div>
          <span className="mr-3">{" / "}</span>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="actions"
              id="action3"
              value="supplierDocumentNumber"
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="action3">
              Ruc
            </label>
          </div>
          
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control"
            name="suggest"
            placeholder="Ingrese Texto (3 caracteres como minimo)"
            onChange={handleChangeSuggest}
            onKeyDown={handleKeyDownSupplier}
            maxLength={50}
            autoComplete="off"
          />
        </div>
        <div className="col-auto pt-2"><span className="">{" y/o "}</span></div>
        <div className="col-auto">
            <input
                type="date"
                name="startDate"
                className="form-control"
                value={questionState.startDate}
                onChange={handleChangeDate}
              />
        </div>
        <div className="col-auto">
            <input
                type="date"
                name="endDate"
                className="form-control"
                value={questionState.endDate}
                onChange={handleChangeDate}
              />
        </div>
        <div className="col-auto">
          <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
          <i className="fas fa-search"></i> Buscar
          </button>
        </div>
      </div>
    </div>
  );
}
