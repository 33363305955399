import React from "react";
import { toast } from "react-toastify";

import {
    sendProductTariffData,
    deleteProductTariffData,
} from "../../../apis/apis.logistics/apisProduct";

export function ProductTariffForm(props) {
    function handleChange(e) {
        props.setItemProductTariff((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    function handleChangeUnit(e) {
        var index = e.nativeEvent.target.selectedIndex;
        props.setItemProductTariff((prevState) => ({
            ...prevState,
            unitID: e.target.value,
            unitName: e.nativeEvent.target[index].text,
        }));
    }

    const existsUnitInProductTariffs = props.products.find((product) => {
        return Number(product.productID) == Number(props.itemProductTariff.productID)
            ? product.productTariffList.find((tariff) => {
                return (
                    Number(tariff.unitID) == Number(props.itemProductTariff.unitID)
                );
            })
            : false;
    });

    const existsProductTariff = props.products.find((product) => {
        return Number(product.productID) == Number(props.itemProductTariff.productID)
            ? product.productTariffList.find((tariff) => {
                return (
                    Number(tariff.unitID) == Number(props.itemProductTariff.unitID) &&
                    Number(tariff.productTariffID) != Number(props.itemProductTariff.productTariffID)
                );
            })
            : false;
    });

    const removeProductTariff = props.products.map((product) => {
        if (product.productID === props.itemProductTariff.productID) {
            var newProductItem = Object.assign({}, product);
            newProductItem.productTariffList = product.productTariffList.filter(
                (tariff) => tariff.productTariffID != props.itemProductTariff.productTariffID
            );
            return newProductItem;
        } else {
            return product;
        }
    });

    function updateProductTariff(data) {

        return props.products.map((product) => {
            if (product.productID === data.productID) {
                var newProductItem = Object.assign({}, product);
                newProductItem.productTariffList = product.productTariffList.map((tariff) => {
                    if (Number(tariff.productTariffID) === Number(data.productTariffID)) {
                        var newTariffItem = Object.assign({}, tariff);
                        newTariffItem.priceSale = data.priceSale;
                        
                        newTariffItem.priceSaleWholesale = data.priceSaleWholesale;
                        newTariffItem.priceSalePrimax = data.priceSalePrimax;
                        newTariffItem.priceSaleSunat = data.priceSaleSunat;

                        newTariffItem.pricePurchaseWholesale = data.pricePurchaseWholesale;
                        newTariffItem.pricePurchasePrimax = data.pricePurchasePrimax;
                        newTariffItem.pricePurchaseSunat = data.pricePurchaseSunat;

                        newTariffItem.pricePurchase = data.pricePurchase;
                        newTariffItem.percentageDiscount = data.percentageDiscount;
                        newTariffItem.quantityMinimum = data.quantityMinimum;
                        newTariffItem.unitID = data.unitID;
                        newTariffItem.unitName = data.unitName;
                        return newTariffItem;
                    } else {
                        return tariff;
                    }
                }
                );
                return newProductItem;
            } else {
                return product;
            }
        });
    }


    function createProductTariff(data) {
        return props.products.map((product) => {
            if (Number(product.productID) === Number(props.itemProductTariff.productID)) {
                return { ...product, productTariffList: [...product.productTariffList, data] };
            } else {
                return product;
            }
        });
    }


    const sendTariff = async (registerData) => {
        try {
            const response = await sendProductTariffData(registerData);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const removeTariff = async (registerData) => {
        try {
            const response = await deleteProductTariffData(registerData);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleClickBtnRemove = async () => {
        if (window.confirm("¿Estás seguro de que deseas anular esta operación?")) {
            await removeTariff(props.itemProductTariff).then(async (r) => {
                if (r.status == 201 && r.success) {
                    await props.setProducts(removeProductTariff);
                    toast.success("presentacion borrada con exito!");
                } else {
                    toast.error("Ocurrio algun error");
                }
                document.getElementById("btn-close-modal-tariff").click();
            });
        }
    };

    const handleClickBtnSave = async () => {
        if (props.itemProductTariff.unitID != "0") {
            if (Number(props.itemProductTariff.priceSale) >= 0) {
                if (Number(props.itemProductTariff.quantityMinimum) > 0) {
                    if (props.itemProductTariff.productTariffID == "0") {
                        if (existsUnitInProductTariffs == undefined) {
                            sendTariff(props.itemProductTariff)
                                .then(async (r) => {
                                    let data = {
                                        productTariffID: r.productTariffID,
                                        unitID: props.itemProductTariff.unitID,
                                        unitName: props.itemProductTariff.unitName,
                                        productID: props.itemProductTariff.productID,
                                        priceSale: Number(props.itemProductTariff.priceSale),
                                        pricePurchase: Number(props.itemProductTariff.pricePurchase),
                                        priceSaleWholesale: Number(props.itemProductTariff.priceSaleWholesale),
                                        priceSalePrimax: Number(props.itemProductTariff.priceSalePrimax),
                                        priceSaleSunat: Number(props.itemProductTariff.priceSaleSunat),
                                        pricePurchaseWholesale: Number(props.itemProductTariff.pricePurchaseWholesale),
                                        pricePurchasePrimax: Number(props.itemProductTariff.pricePurchasePrimax),
                                        pricePurchaseSunat: Number(props.itemProductTariff.pricePurchaseSunat),
                                        percentageDiscount: Number(props.itemProductTariff.percentageDiscount),
                                        quantityMinimum: Number(props.itemProductTariff.quantityMinimum),
                                    };

                                    return data;
                                })
                                .then(async (data) => {
                                    if (data) {
                                        await props.setProducts(createProductTariff(data));
                                        await props.setItemProductTariff(
                                            props.initialProductTariffState
                                        );
                                        toast.success("presentacion registrada con exito!");
                                    } else {
                                        toast.error("Ocurrio algun error");
                                    }
                                });
                        } else {
                            toast.error("ya existe una presentacion con esa unidad");
                        }
                    } else {
                        if (existsProductTariff == undefined) {
                            sendTariff(props.itemProductTariff)
                                .then(async (r) => {
                                    let data = {
                                        productTariffID: r.productTariffID,
                                        unitID: props.itemProductTariff.unitID,
                                        unitName: props.itemProductTariff.unitName,
                                        productID: props.itemProductTariff.productID,
                                        priceSale: Number(props.itemProductTariff.priceSale),
                                        pricePurchase: Number(props.itemProductTariff.pricePurchase),
                                        priceSaleWholesale: Number(props.itemProductTariff.priceSaleWholesale),
                                        priceSalePrimax: Number(props.itemProductTariff.priceSalePrimax),
                                        priceSaleSunat: Number(props.itemProductTariff.priceSaleSunat),
                                        pricePurchaseWholesale: Number(props.itemProductTariff.pricePurchaseWholesale),
                                        pricePurchasePrimax: Number(props.itemProductTariff.pricePurchasePrimax),
                                        pricePurchaseSunat: Number(props.itemProductTariff.pricePurchaseSunat),
                                        percentageDiscount: Number(props.itemProductTariff.percentageDiscount),
                                        quantityMinimum: Number(props.itemProductTariff.quantityMinimum),
                                    };
                                    return data;
                                })
                                .then(async (data) => {
                                    if (data) {
                                        await props.setProducts(updateProductTariff(data));
                                        await props.setItemProductTariff(
                                            props.initialProductTariffState
                                        );
                                        toast.success("presentacion actualizada con exito!");
                                    } else {
                                        toast.error("Ocurrio algun error");
                                    }
                                });
                        } else {
                            toast.error("la presentacion ya existe");
                        }
                    }
                    document.getElementById("btn-close-modal-tariff").click();
                } else {
                    toast.error("Verifique la cantidad minima");
                }
            } else {
                toast.error("Verifique el precio");
            }
        } else {
            toast.error("Verifique la unidad");
        }
    };

    return (
        <div
            className="modal fade"
            id="modal-product-tariff"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title font-weight-light">
                            {props.itemProductTariff.productSaleName}{" "}
                            {props.itemProductTariff.productTariffID == "0"
                                ? <span className="badge badge-light font-weight-light">Nuevo</span>
                                : <span className="badge badge-light font-weight-light">Editar</span>}


                        </h4>

                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group row mb-2">
                                <label className="col text-right font-weight-light">Unidad de venta:</label>
                                <div className="col-6">
                                    <select
                                        className="form-control font-weight-light"
                                        name="unitID"
                                        onChange={handleChangeUnit}
                                        value={props.itemProductTariff.unitID}
                                    >
                                        <option value="0">Elige</option>

                                        {props.units.map((item, index) => {
                                            return (
                                                <option key={index} value={item.unitID}>
                                                    {item.unitName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group row mb-2">
                                <label className="col text-right font-weight-light">Cantidad minima</label>
                                <div className="col-6">
                                    <input
                                        type="number"
                                        className="form-control font-weight-light"
                                        placeholder="Ingrese ..."
                                        name="quantityMinimum"
                                        maxLength={6}
                                        value={props.itemProductTariff.quantityMinimum}
                                        onChange={(e) => {
                                            if (Number(e.target.value) >= 0) {

                                                props.setItemProductTariff((prevState) => ({
                                                    ...prevState,
                                                    quantityMinimum: e.target.value,
                                                }));
                                                if (props.itemProductTariff.unitName !== "UND") {
                                                    props.setItemProductTariff((prevState) => ({
                                                        ...prevState,
                                                        priceSale: Number(Number(props.itemProductTariff.pricePurchase) / Number(e.target.value)).toFixed(6),
                                                    }));
                                                }
                                            }
                                        }}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        

                        <div className="row">
                            <div className="col">
                                

                                
                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">{props.itemProductTariff.unitName == "UND" ? "P. V. (Tiendas)" : props.itemProductTariff.unitName == "CJ" || props.itemProductTariff.unitName == "TIR" ? "Costo x Unidad" : "P. V."}</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="priceSale"
                                            maxLength={6}
                                            readOnly={props.itemProductTariff.unitName !== "UND"}
                                            value={props.itemProductTariff.priceSale}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col">

                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">{props.itemProductTariff.unitName == "UND" ? "P. V. (Publico)" : props.itemProductTariff.unitName == "CJ" || props.itemProductTariff.unitName == "TIR" ? "Costo Caja (Pepsico)" : "Precio Compra"}</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="pricePurchase"
                                            maxLength={6}
                                            value={props.itemProductTariff.pricePurchase}
                                            onChange={(e) => {
                                                if (Number(e.target.value) >= 0) {

                                                    props.setItemProductTariff((prevState) => ({
                                                        ...prevState,
                                                        pricePurchase: e.target.value,
                                                    }));
                                                    if (props.itemProductTariff.unitName !== "UND") {
                                                        props.setItemProductTariff((prevState) => ({
                                                            ...prevState,
                                                            priceSale: Number(Number(e.target.value) / Number(props.itemProductTariff.quantityMinimum)).toFixed(6),
                                                        }));
                                                    }
                                                }
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                        {props.itemProductTariff.unitName == "UND" ?
                        <>
                        <h6 className=" font-weight-light">Otros precios</h6>
                        <hr/>
                        <div className="row">
                            <div className="col">
                                <div className="form-group row mb-2">
                                <label className="col text-right font-weight-light">P. V. Mayorista (CON IGV)</label>
                                <div className="col-6">
                                    <input
                                        type="number"
                                        className="form-control font-weight-light"
                                        placeholder="Ingrese ..."
                                        name="priceSaleWholesale"
                                        maxLength={6}
                                        value={props.itemProductTariff.priceSaleWholesale}
                                        onChange={handleChange}
                                        onFocus={(e) => e.target.select()} 
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="col">

                            <div className="form-group row mb-2">
                                <label className="col text-right font-weight-light">C. Mayorista (SIN IGV)</label>
                                <div className="col-6">
                                    <input
                                        type="number"
                                        className="form-control font-weight-light"
                                        placeholder="Ingrese ..."
                                        name="pricePurchaseWholesale"
                                        maxLength={6}
                                        value={props.itemProductTariff.pricePurchaseWholesale}
                                        onChange={handleChange}
                                        onFocus={(e) => e.target.select()} 
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">P. V. Primax (CON IGV)</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="priceSalePrimax"
                                            maxLength={6}
                                            value={props.itemProductTariff.priceSalePrimax}
                                            onChange={handleChange}
                                            onFocus={(e) => e.target.select()} 
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">C. Primax (SIN IGV)</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="pricePurchasePrimax"
                                            maxLength={6}
                                            value={props.itemProductTariff.pricePurchasePrimax}
                                            onChange={handleChange}
                                            onFocus={(e) => e.target.select()} 
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">P. V. Sunat (CON IGV)</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="priceSaleSunat"
                                            maxLength={6}
                                            value={props.itemProductTariff.priceSaleSunat}
                                            onChange={handleChange}
                                            onFocus={(e) => e.target.select()} 
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">C. Sunat (SIN IGV)</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="pricePurchaseSunat"
                                            maxLength={6}
                                            value={props.itemProductTariff.pricePurchaseSunat}
                                            onChange={handleChange}
                                            onFocus={(e) => e.target.select()} 
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        </>
                        :null}

                        {props.itemProductTariff.unitName == "CJ" || props.itemProductTariff.unitName == "TIR" ?
                        <div className="row">
                            <div className="col"></div>
                            <div className="col">
                                <div className="form-group row mb-2">
                                    <label className="col text-right font-weight-light">% Descuento</label>
                                    <div className="col-6">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="percentageDiscount"
                                            maxLength={6}
                                            value={props.itemProductTariff.percentageDiscount}
                                            onChange={handleChange}
                                            onFocus={(e) => e.target.select()} 
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        :null}

                        

                        
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default font-weight-light"
                            data-dismiss="modal"
                            id="btn-close-modal-tariff"
                        >
                            Cerrar
                        </button>
                        <button
                            type="button"
                            className="btn btn-success font-weight-light"
                            onClick={handleClickBtnSave}
                        >
                            Guardar
                        </button>
                        {props.itemProductTariff.productTariffID == "0" ? (
                            ""
                        ) : (
                            <button
                                type="button"
                                className="btn btn-danger font-weight-light"
                                onClick={handleClickBtnRemove}
                            >
                                Eliminar
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
