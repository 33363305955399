import React, { useState } from "react";
import { sendFilterProductData } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";

export function ProductFilter(props) {

    const filterProducts = async (data) => {
        try {
            const response = await sendFilterProductData(data);
            //console.log(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    //state to hold responses from input
    const [questionState, setQuestionState] = useState({
        options: [{ radioName: "actions", selected: "purchaseName" }],
        suggest: "",
    });

    //radio buttons onchange
    const handleChange = (e) => {
        const { options } = questionState;
        const { name, value } = e.target;
        console.log(name, value);

        const nextState = options.map((opt) => {
            if (opt.radioName !== name) {
                return { ...opt };
            } else {
                return { ...opt, selected: value };
            }
        });

        setQuestionState({ ...questionState, options: nextState });
        //console.log(questionState)
    };

    function handleChangeSuggest(e) {
        setQuestionState({ ...questionState, suggest: e.target.value });
    }

    const handleKeyDownSupplier = async (e) => {
        if (e.keyCode === 13) {
            searchSuggestions();
        }
    };

    const handleQuery = (e) => {
        searchSuggestions();
    }

    function searchSuggestions() {
        filterProducts(questionState).then((res) => {
            let results = res.products.length;
            toast.info(
                results > 1
                    ? "Se encontraron " + results.toString() + " resultados."
                    : "Se encontró " + results.toString() + " resultado(s)."
            );
            props.setProducts(res.products);
        });
    }

    /*useEffect(() => {}, []); // <-- Have to pass in [] here!*/

    return (
        <div className="border bg-light rounded p-3">

            <div className="row">
                <div className="col-auto pt-2 text-cyan">Buscar x</div>
                <div className="col-auto pt-2">
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action1"
                            value="code"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action1">
                            codigo
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action2"
                            value="purchaseName"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action2">
                            nombre de compra
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action3"
                            value="saleName"

                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action3">
                            nombre de venta
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action4"
                            value="sku"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action4">
                            sku
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action5"
                            value="brand"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action5">
                            marca
                        </label>
                    </div>
                </div>
                <div className="col">
                    <input
                        type="text"
                        className="form-control"
                        name="suggest"
                        placeholder="Ingrese Texto (3 caracteres como minimo)"
                        onChange={handleChangeSuggest}
                        onKeyDown={handleKeyDownSupplier}
                        maxLength={50}
                        autoComplete="off"
                    />
                </div>
                <div className="col-auto text-cyan">P. V. (Tienda)</div>
                <div className="col-auto">

                    <select className="form-control">
                        {props.prices.map((price, index)=> <option key={index} value={price}>{price}</option>)}
                    </select>
                </div>
                <div className="col-auto">
                    <button className="btn btn-light border" onClick={handleQuery}>
                        Filtrar
                    </button>
                </div>
            </div>
        </div>
    );
}
