import { BASE_API } from "../../configs/ConfigsConstants";

export async function fetchedWarehouseCategories() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_warehouse_categories/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedProducts() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_products/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedMatrix() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_matrix/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedUnits() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_units/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedBrands() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_product_brands/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedGroupOfProducts() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_group_of_products/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function sendProductData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_product/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendOrderPurchaseData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_order_purchase/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendProductTariffData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_product_tariff/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendNoteCreditOfSupplier(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/save_note_credit_operation_of_supplier/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteProductTariffData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/delete_product_tariff/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deleteWarehouse(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/delete_warehouse/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function sendProductBrandData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_product_brand/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendUnitData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_unit/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function sendWarehouseData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_warehouse/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendFilterProductData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/filter_products_by_action/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function sendOperationData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/register_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedWarehousesBySubsidiary(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_warehouses_by_subsidiary/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedOrderPurchasesBySubsidiary(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_order_purchases_by_subsidiary/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedOrderPurchaseByID(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_order_purchase_by_id/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedInventoryBetweenDates(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_inventory_between_dates/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedWarehousesWithoutProducts(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_warehouses_without_products_by_subsidiary/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedWarehousesAndProducts(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_warehouses_with_products_by_subsidiary/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedProductsWithTariffs() {
  try {
    const url = `${BASE_API}/sales/api/v1/get_products_with_tariffs/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendAnnulmentOperation(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/annular_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendPurchaseOperationData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/generate_purchase_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function savePurchaseOperationData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/save_purchase_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function annularPurchaseOperationData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/annular_order_purchase_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function concludePurchaseOperationData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/conclude_order_purchase_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendFilterOrderPurchasesData(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/filter_order_purchases_by_action/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedGangs() {
  try {

    const url = `${BASE_API}/hrm/api/get_gangs/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    };

    const response = await fetch(url, params);
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function fetchedRoute(data) {
  try {
    const url = `${BASE_API}/dispatches/api/v1/get_route/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function fetchedProductsByWarehouse(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_stock_in_warehouse/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}
