import React from "react";
import { map } from "lodash";
export function FragmentTableSubsidiary(props) {
  const { subsidiarys, updateSubsidiary, onDeleteSubsidiary } = props;
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr className="text-left">
            <th className="text-center" style={{ width: 10 }}>
              Nº
            </th>
            <th className="text-center">Serie</th>
            <th>Nombre sede</th>
            <th className="text-center">Ruc</th>
            <th>Razón Social</th>
            <th className="text-center">Celular</th>
            <th>Dirección</th>
            <th className="text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {map(subsidiarys, (subsidiary, index) => (
            <tr className="text-left" key={index}>
              <td className="align-middle text-center">{subsidiary.id}</td>
              <td className="align-middle text-center">{subsidiary.serial}</td>
              <td className="align-middle">{subsidiary.name}</td>
              <td className="align-middle text-center">{subsidiary.ruc}</td>
              <td className="align-middle">{subsidiary.business}</td>
              <td className="align-middle text-center">{subsidiary.phone}</td>
              <td className="align-middle">{subsidiary.address}</td>
              <Actions
                subsidiary={subsidiary}
                updateSubsidiary={updateSubsidiary}
                onDeleteSubsidiary={onDeleteSubsidiary}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function Actions(props) {
  const { subsidiary, updateSubsidiary, onDeleteSubsidiary } = props;
  return (
    <td className="align-middle text-center">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-widget"
        className="btn btn-outline-warning btn-sm item-edit mr-1"
        onClick={() => updateSubsidiary(subsidiary)}
      >
        <i className="fa fa-pencil-alt fa-1x"></i>
      </button>
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={() => onDeleteSubsidiary(subsidiary)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    </td>
  );
}
