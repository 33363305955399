import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedAllCashesAndBanks } from "../../../apis/apis.accounting/apisCash";
import { sendNewOperationReceive } from "../../../apis/apis.accounting/apisCash";

export function AccountReceiveOperations({
  selectedCashFlowDebtor,
  newOperationPayReceive,
  setNewOperationPayReceive,
  allCashesAndBanks,
  setallCashesAndBanks,
}) {
  /*API DE TODOS LOS BANCOS Y CAJAS*/

  const getAllCashesAndBanks = async () => {
    try {
      const response = await fetchedAllCashesAndBanks();
      setallCashesAndBanks(response);

      setallBanks(response.filter((item) => item.cashType == "B"));
      setallCashes(response.filter((item) => item.cashType == "C"));

      return response;
    } catch (error) {
      throw error;
    }
  };

  /* ***** */

  const [allBanks, setallBanks] = useState([]);
  const [allCashes, setallCashes] = useState([]);
  const { auth } = useContext(ContextAuth);

  const optionsPayReceive = [
    { id: "E", name: "Efectivo" },
    { id: "D", name: "Deposito" },
    { id: "DY", name: "Yape" },
    //{ id: "TBB", name: "Transferencia" },
  ];

  const saveOperation = async (data) => {
    try {
      const response = await sendNewOperationReceive(data);
      return response;
    } catch (error) {
      throw error;
    }
  };
 // console.log(selectedCashFlowDebtor)
  const handleSubmit = (e) => {
    e.preventDefault();

    /*setNewOperationPayReceive({
      ...newOperationPayReceive,
      userID: auth.data.user_id,
      operationID: selectedCashFlowDebtor.operationID
    });*/

    if (newOperationPayReceive.cashTypeTransaction == "E") {
      if (newOperationPayReceive.cashDescription != "") {
        if (newOperationPayReceive.cashId != "0") {
          if (
            newOperationPayReceive.cashTotal <=
            selectedCashFlowDebtor.differenceTotalAndTotalPayHC
          ) {
            if (
              newOperationPayReceive.cashTotal !== "0" &&
              newOperationPayReceive.cashTotal !== ""
            ) {
              let data = {
                CashNewOperationDate:
                  newOperationPayReceive.CashNewOperationDate,
                bankAccountNumber: newOperationPayReceive.bankAccountNumber,
                bankDescription: newOperationPayReceive.bankDescription,
                bankId: newOperationPayReceive.bankId,
                bankName: newOperationPayReceive.bankName,
                cashDescription: newOperationPayReceive.cashDescription,
                cashId: newOperationPayReceive.cashId,
                cashName: newOperationPayReceive.cashName,
                cashTotal: newOperationPayReceive.cashTotal,
                cashTypeTransaction: newOperationPayReceive.cashTypeTransaction,
                userID: auth.data.user_id,
                operationID: selectedCashFlowDebtor.operationID,
                cashClientID: newOperationPayReceive.cashClientID,
              };
              //console.log(data);
              saveOperation(data).then(async (res) => {
                document.getElementById("btn-search").click();
                toast.success("Operación registrada con exito!");
              });
            } else {
              toast.error(
                "El monto a Pagar no puede ser 0, ni tampoco estar vacio"
              );
              return false;
            }
          } else {
            toast.error("El monto a Pagar debe ser menor al de la Deuda");
            return false;
          }
        } else {
          toast.error("Seleccione una Caja de destino");
          return false;
        }
      } else {
        toast.error("La DESCRIPCION no puede estar en blanco");
        return false;
      }
    } else if (newOperationPayReceive.cashTypeTransaction == "D") {
      if (
        newOperationPayReceive.bankDescription != "" &&
        newOperationPayReceive.bankAccountNumber != ""
      ) {
        if (newOperationPayReceive.bankId != "0") {
          if (
            newOperationPayReceive.cashTotal <=
            selectedCashFlowDebtor.differenceTotalAndTotalPayHC
          ) {
            if (
              newOperationPayReceive.cashTotal !== "0" &&
              newOperationPayReceive.cashTotal !== ""
            ) {
              let data = {
                CashNewOperationDate:
                  newOperationPayReceive.CashNewOperationDate,
                bankAccountNumber: newOperationPayReceive.bankAccountNumber,
                bankDescription: newOperationPayReceive.bankDescription,
                bankId: newOperationPayReceive.bankId,
                bankName: newOperationPayReceive.bankName,
                cashDescription: newOperationPayReceive.cashDescription,
                cashId: newOperationPayReceive.cashId,
                cashName: newOperationPayReceive.cashName,
                cashTotal: newOperationPayReceive.cashTotal,
                cashTypeTransaction: newOperationPayReceive.cashTypeTransaction,
                userID: auth.data.user_id,
                operationID: selectedCashFlowDebtor.operationID,
                cashClientID: newOperationPayReceive.cashClientID
              };
              //console.log(data);
              saveOperation(data).then(async (res) => {
                document.getElementById("btn-search").click();
                toast.success("Operación registrada con exito!");
              });
            } else {
              toast.error(
                "El monto a Pagar no puede ser 0, ni tampoco estar vacio"
              );
              return false;
            }
          } else {
            toast.error("El monto a Pagar debe ser menor al de la Deuda");
            return false;
          }
        } else {
          toast.error("Selecciona una entidad bancaria");
          return false;
        }
      } else {
        toast.error(
          "La DESCRIPCION y el CODIGO DE OPERACIPON no puede estar en blanco"
        );
        return false;
      }
    } else if (newOperationPayReceive.cashTypeTransaction == "DY") {

      if (newOperationPayReceive.cashTotal !== "0" && newOperationPayReceive.cashTotal !== "" ) {
        if (newOperationPayReceive.cashTotal <= selectedCashFlowDebtor.differenceTotalAndTotalPayHC) {
          let data = {
            CashNewOperationDate: newOperationPayReceive.CashNewOperationDate,
            bankAccountNumber: newOperationPayReceive.bankAccountNumber,
            bankDescription: newOperationPayReceive.bankDescription,
            bankId: newOperationPayReceive.bankId,
            bankName: newOperationPayReceive.bankName,
            cashDescription: newOperationPayReceive.cashDescription,
            cashId: newOperationPayReceive.cashId,
            cashName: newOperationPayReceive.cashName,
            cashTotal: newOperationPayReceive.cashTotal,
            cashTypeTransaction: newOperationPayReceive.cashTypeTransaction,
            userID: auth.data.user_id,
            operationID: selectedCashFlowDebtor.operationID,
            cashClientID: newOperationPayReceive.cashClientID
          };
          //console.log(data);
          saveOperation(data).then(async (res) => {
            document.getElementById("btn-search").click();
            toast.success("Operación registrada con exito!");
          });
        } else {
          toast.error(
            "El monto a Pagar no puede ser 0, ni tampoco estar vacio"
          );
          return false;
        }
      } else {
        toast.error("El monto a Pagar no puede ser 0, ni tampoco estar vacio");
        return false;
      } 
    } else {
      toast.error("SELECCIONE UN TIPO DE PAGO VALIDO");
      return false;
    }
    document.getElementById("close-modal-account-receive-operations").click();
  };

  //console.log("newOperationPayReceive", newOperationPayReceive);
  //console.log("allBanks", allBanks);
  //console.log("allCashes", allCashes);

  function handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    setNewOperationPayReceive({
      ...newOperationPayReceive,
      [e.target.name]: e.target.value,
    });
  }

  //console.log("selectedCashFlowDebtor", selectedCashFlowDebtor);

  function handleChangeTypePay(e) {
    let value = e.target.value;

    if (value == "E") {
      setNewOperationPayReceive({
        ...newOperationPayReceive,
        cashTypeTransaction: "E",
        bankId: "0",
        bankAccountNumber: "",
        //bankDescription: "",
      });
    } else if (value === "D") {
      setNewOperationPayReceive({
        ...newOperationPayReceive,
        cashTypeTransaction: "D",
        cashId: "0",
        //cashDescription: "",
      });
    } else if (value === 'DY') {
      setNewOperationPayReceive({
        ...newOperationPayReceive,
        cashTypeTransaction: value,
        cashId: "0",
        cashDescription: "",
        bankId: 12,
        bankAccountNumber: "",
        //bankDescription: "PAGO CON YAPE",
      });
    }
  }

  useEffect(() => {
    getAllCashesAndBanks();
  }, []);

  return (
    <div
      className="modal fade"
      id="modal-account-receive-operations"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">CANCELAR LA SIGUIENTE DEUDA :</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pb-0">
            <div className="form-group row">
              <div className="col-sm-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Deuda restante:</label>
                  <input
                    type="text"
                    className="form-control text-center bg-red"
                    readOnly
                    value={
                      "S/ " +
                      Number(
                        selectedCashFlowDebtor.differenceTotalAndTotalPayHC
                      ).toFixed(2)
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Monto a Pagar:</label>
                  <input
                    type="Number"
                    className="form-control text-center bg-light"
                    name="cashTotal"
                    onChange={handleChange}
                    value={newOperationPayReceive.cashTotal}
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Tipo de Pago:</label>
                  <select
                    className="form-control select2 text-uppercase"
                    onChange={handleChangeTypePay}
                    name="cashTypeTransaction"
                    value={newOperationPayReceive.cashTypeTransaction}
                  >
                    <option value="0">Seleccione...</option>
                    {optionsPayReceive.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Fecha de Operacion:
                  </label>
                  <input
                    type="date"
                    className="form-control text-center"
                    value={newOperationPayReceive.CashNewOperationDate}
                    onChange={handleChange}
                    name="CashNewOperationDate"
                  />
                </div>
              </div>
            </div>

            {newOperationPayReceive.cashTypeTransaction === "E" ? (
              <div className="form-group row bg-gray pt-2">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label
                      htmlFor="inputEmail3"
                      className="form-controltext-right"
                    >
                      Caja Destino:
                    </label>
                    <select
                      className="form-control select2 text-uppercase"
                      onChange={handleChange}
                      name="cashId"
                      //id="cash_id"
                      value={newOperationPayReceive.cashId}
                    >
                      <option value="0">Seleccione...</option>
                      {allCashes.map((item, index) => {
                        return (
                          <option key={index} value={item.cashID}>
                            {item.cashName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {/*<div className="col-sm-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Fecha de Caja:</label>
                    <input
                      type="Number"
                      className="form-control text-center"
                      //value={props.bankItem.bankAccountNumber}
                    />
                  </div>
                    </div>*/}
                <div className="col-sm-9">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Descripcion:</label>
                    <input
                      type="text"
                      className="form-control text-left text-uppercase"
                      value={newOperationPayReceive.cashDescription}
                      onChange={handleChange}
                      name="cashDescription"
                    />
                  </div>
                </div>
              </div>
            ) : newOperationPayReceive.cashTypeTransaction === "D" ? (
              <div className="form-group row">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label
                      htmlFor="inputEmail3"
                      className="form-controltext-right"
                    >
                      Depósito a Cuenta:
                    </label>
                    <select
                      className="form-control select2 text-uppercase"
                      onChange={handleChange}
                      name="bankId"
                      id="bank_id"
                      value={newOperationPayReceive.bankId}
                    >
                      <option value="0">Seleccione...</option>
                      {allBanks.map((item, index) => {
                        return (
                          <option key={index} value={item.cashID}>
                            {item.cashName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {/*<div className="col-sm-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Fecha de Deposito:
                    </label>
                    <input
                      type="Number"
                      className="form-control text-center"
                      //value={props.bankItem.bankAccountNumber}
                    />
                  </div>
                </div>*/}
                <div className="col-sm-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Codigo de operación:
                    </label>
                    <input
                      type="text"
                      className="form-control text-left"
                      value={newOperationPayReceive.bankAccountNumber}
                      name="bankAccountNumber"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Descripcion del Depósito:
                    </label>
                    <input
                      type="text"
                      className="form-control text-left"
                      value={newOperationPayReceive.bankDescription}
                      name="bankDescription"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            ) : newOperationPayReceive.cashTypeTransaction === "DY" ? (
              <>
                <div className="form-group row">
                  <div className="col-sm-4">
                    <label htmlFor="exampleInputEmail1">
                      Cuenta de BCP (YAPE) destino:
                    </label>
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="form-control select2 text-uppercase bg-yape"
                      onChange={handleChange}
                      name="bankId"
                      value={newOperationPayReceive.bankId}
                    >
                      <option value="0">Seleccione...</option>
                      {allBanks.map((item, index) => {
                        return (
                          <option key={index} value={item.cashID}>
                            {item.cashName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              null
            )}
          </div>

          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="close-modal-account-receive-operations"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar Operación
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
