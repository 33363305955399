import React, { useState, useEffect, useContext, createContext } from "react";
import { OrderPurchaseList } from "../fragments.buys/OrderPurchaseList";
import { OrderPurchaseFilter } from "../fragments.buys/OrderPurchaseFilter";
import { OrderPurchaseReceived } from "../fragments.buys/OrderPurchaseReceived";
import { getCurrentDate } from "../../../configs/helpers";
import {
  fetchedOrderPurchasesBySubsidiary
} from "../../../apis/apis.logistics/apisProduct";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { toast } from "react-toastify";

export function OrderPurchasePage() {
  const { auth } = useContext(ContextAuth);
  const [orders, setOrders] = useState([]);
  const [detailsFromOrderPurchaseReceived, setDetailsFromOrderPurchaseReceived] = useState([]);

  const getOrderPurchases = async () => {
    try {
      const response = await fetchedOrderPurchasesBySubsidiary({
        subsidiaryID:  auth.data.subsidiary.subsidiary_id,
      });
      setOrders(response);

    } catch (error) {
      throw error;
    }
  };

  /*useEffect(() => {

    if (auth.data.subsidiary.subsidiary_id != undefined) {
        //console.log("auth", auth.data.user_id);
        getOrderPurchases();
    }
    else{
      toast.error("Problemas con la obtencion de la sede")
    }

  }, []);*/

  
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>ORDENES DE COMPRA</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Orden de Compras</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <OrderPurchaseFilter setOrders={setOrders} />
              <OrderPurchaseList orders={orders} setOrders={setOrders} setDetailsFromOrderPurchaseReceived={setDetailsFromOrderPurchaseReceived} />
              <OrderPurchaseReceived detailsFromOrderPurchaseReceived={detailsFromOrderPurchaseReceived} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
