import React, { useEffect, useState } from "react";
import { useHooksSubsidiary } from "../../../hooks";
import {
  FragmentHeaderSubsidiary,
  FragmentTableSubsidiary,
  FragmentAddEditSubsidiary,
} from "../fragments.hrm";
import { WidgetModal } from "../../modules.widgets";
import swal from "sweetalert2";

export function PageSubsidiary() {
  const [tittleModal, setTittleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setSizeModal] = useState(null);

  const { loading, subsidiarys, getSubsidiarys, deleteSubsidiary } =
    useHooksSubsidiary();
  useEffect(() => {
    getSubsidiarys();
  }, [refetch]);
  //const OpenCloseModal = () => {};
  const onRefetch = () => setRefetch((prev) => !prev);

  const addSubsidiary = () => {
    setTittleModal("Registrar Nueva Sucursal");
    setSizeModal("modal-lg");
    setContentModal(
      <FragmentAddEditSubsidiary onRefetch={onRefetch} subsidiary={{}} />
    );
  };

  const updateSubsidiary = (data) => {
    setTittleModal("Actualizar Sucursal");
    setSizeModal("modal-lg");
    setContentModal(
      <FragmentAddEditSubsidiary onRefetch={onRefetch} subsidiary={data} />
    );
  };

  const onDeleteSubsidiary = async (data) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Esta seguro?",
        text: "Si acepta, No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Acepto!",
        cancelButtonText: "No, Cancelo!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteSubsidiary(data.id);
            onRefetch();
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            `La sede ${data.name} ha sido eliminada`,
            "success"
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            `La sede ${data.name} esta a salvo :)`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <FragmentHeaderSubsidiary
            btnTittle="Registrar Sucursal"
            addSubsidiary={addSubsidiary}
          />
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="chart">
                {loading ? (
                  <h6>Cargando...</h6>
                ) : (
                  <FragmentTableSubsidiary
                    subsidiarys={subsidiarys}
                    updateSubsidiary={updateSubsidiary}
                    onDeleteSubsidiary={onDeleteSubsidiary}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
      <WidgetModal
        children={contentModal}
        modaltittle={tittleModal}
        sizemodal={sizeModal}
      />
    </>
  );
}
