import React, { useState, useEffect, useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from "react-toastify";
import { DevolutionForm } from "../fragments.dispatches/DevolutionForm";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedWarehousesBySubsidiary } from "../../../apis/apis.logistics/apisProduct";
import { fetchedStockInTwoWarehouses } from "../../../apis/apis.buys/apisPurchase";
import { sendDevolutionData, fetchedVehiclesBySubsidiary } from "../../../apis/apis.dispatches/apisDispatch";
import { ContextAuth } from "../../../contexts/ContextAuth";


const initialSupplyingState = {
  warehouseID: 0,
  vehicleID: 0,
  vehicleTwoID: 0,
  warehouseName: "",
  vehicleLicensePlate: "",
  vehicleTwoLicensePlate: "",
  readjustmentMode:"E",
  readjustmentMotive:"",
  supplyingDate: getCurrentDate("-"),
  userID: 0,
  details: [],
  comparatives: [],
};

export function DevolutionPage() {
    const [orders, setOrders] = useState([]);
    const [devolutionState, setDevolutionState] = useState(initialSupplyingState);
    const [vehicles, setVehicles] = useState([]);


    const { auth } = useContext(ContextAuth);

    const [warehouses, setWarehouses] = useState([]);
  
    const sendDevolution = async () => {
      try {
        const response = await sendDevolutionData(devolutionState);
        return response;
      } catch (error) {
        throw error;
      }
    };
    const getVehicles = async (id) => {
        try {
          const response = await fetchedVehiclesBySubsidiary({
            subsidiaryID: id,
          });
          setVehicles(response);
        } catch (error) {
          throw error;
        }
      };
    const getWarehouses = async (id) => {
      try {
        const response = await fetchedWarehousesBySubsidiary({
          subsidiaryID: id,
        });
        setWarehouses(response);
      } catch (error) {
        throw error;
      }
    };
    const getComparativeFromProductsByWarehouse = async (outputs, inputs) => {
      try {
        const response = await fetchedStockInTwoWarehouses({
          warehouseID: outputs,
          vehicleID: inputs,
        });
        //console.log(response) ;
        //console.log(Object.keys(response)) ;
        //console.log(Object.values(response)) ;
        // setDevolutionState({...devolutionState, comparatives:response});  
        setDevolutionState({...devolutionState, comparatives:Object.values(response)});  
      } catch (error) {
        throw error;
      }
    };
  
    
    const handleClickBtnSave = async () => {
      if (devolutionState.comparatives.length) {
          if (Number(devolutionState.vehicleID) > 0) {
              //console.log("devolutionState", devolutionState);
              document.getElementById('btn-register-devolution').disabled=true;
              document.getElementById('sync').classList.remove("d-none");

              await sendDevolution().then((r) => {
                  if (r.status == 201) 
                      toast.success("Operacion registrada con exito!");
                  else 
                      toast.error("Ocurrio algun error");
              });
          
              document.getElementById('btn-register-devolution').disabled=false;
              document.getElementById('sync').classList.add("d-none");
              setDevolutionState(initialSupplyingState);
          } else {toast.error("Verifique el vehiculo");} 
      } else {toast.error("Verifique los items a registrar");}
    };
  
    useEffect(() => {
      getWarehouses(auth.data.subsidiary.subsidiary_id);
      getVehicles(auth.data.subsidiary.subsidiary_id);

    }, []);
  
   
    useEffect(() => {
      if(Number(devolutionState.warehouseID) > 0 && Number(devolutionState.vehicleID) > 0)
          getComparativeFromProductsByWarehouse(devolutionState.vehicleID, devolutionState.warehouseID);
    }, [devolutionState.warehouseID, devolutionState.vehicleID]);
  
    const rowBody1 = { background: "#f8f9fa" };
    const rowBody2 = { background: "#ffc10724" };
    const colBody1 = "#3498db";
    const colBody2 = "#ffc107";

    return (
      <>
      <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>DEVOLUCIONES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Distribuciones</a>
                  </li>
                  <li className="breadcrumb-item active">Devolucion</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
  
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body p-2">

                <div className="row">
                    
                    <div className="col-lg-3">
                        <label className="">VEHICULO ORIGEN:</label>
                    </div>
                    <div className="col-lg-3">
                        <select
                            className="form-control form-control-lg"
                            name="vehicleID"
                            value={devolutionState.vehicleID}
                            style={{color: colBody1}}
                            onChange={(e)=> {
                            setDevolutionState({
                                ...devolutionState,
                                vehicleID: e.target.value,
                                vehicleLicensePlate: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
                            });
                            }}
                        >
                        <option value="0">Elegir</option>
                            {vehicles.map((item, index) => {
                                return (
                                  <option key={index} value={item.vehicleID}>{item.vehicleLicensePlate} | {item.gang.gangName}</option>
                                );
                            })}
                        </select>
                    </div>


                    <div className="col-lg-3">
                        <label className="">ALMACEN DESTINO:</label>
                    </div>
                    <div className="col-lg-3">
                        <select
                            className="form-control form-control-lg text-uppercase"
                            name="warehouseID"
                            style={{color: colBody2}}
                            onChange={async (e) => {
                            
                            const found = await warehouses.find(function (
                                warehouse,
                                index
                            ) {
                                if (warehouse.warehouseID == e.target.value)
                                return true;
                            });
                            if (found) {
                                setDevolutionState({
                                ...devolutionState,
                                warehouseID: found.warehouseID,
                                warehouseName: found.warehouseName,
                                userID: auth.data.user_id,
                                });
                            }
                            }}
                            value={devolutionState.warehouseID}
                        >
                            <option value="0">Elegir</option>
                            {warehouses.map((item, index) => {
                            return (
                                <option key={index} value={item.warehouseID}>
                                {item.gang.gangID==0?item.warehouseName:item.warehouseName + " | " + item.gang.gangName} 
                                </option>
                            );
                            })}
                        </select>
                    </div>

                    <div className="col-lg-3">
                        <label className="">FECHA:</label>
                    </div>

                    <div className="col-lg-3">
                        <input
                            type="date"
                            className="form-control form-control-lg"
                            name="supplyingDate"
                            value={devolutionState.supplyingDate}
                            onChange={(e)=> setDevolutionState({
                                ...devolutionState,
                                supplyingDate: e.target.value,
                            })}
                        />
                    </div>

                    <div className="col-lg-3">
                        <button
                            type="button"
                            className="btn btn-danger btn-lg btn-block"
                            id="btn-register-devolution"
                            onClick={handleClickBtnSave}
                            title="Devolver"
                            style={{/*position: "fixed",bottom: "8px",right: "125px", zIndex: 10000, padding: "16px 20px", borderRadius: "50%"*/}}
                        >
                            {/*<i className="fas fa-save fa-3x"></i>*/}
                            Devolver
                        </button>
                    </div>
                    <div className="col-lg-2">
                    <i className="fas fa-sync fa-spin fa-3x d-none" id="sync"></i>
                    </div>

                </div>

                  {devolutionState.comparatives.length?(
                    <div className="table-responsive mt-3">
                        <Table className="table table-bordered mb-0">
                        <Thead>
                            <Tr className="text-center">
                            <Td>COD.</Td>
                            <Td>CLASIF.</Td>
                            <Td>SKU</Td>
                            <Td>PROD.</Td>
                            <Td>PRECIO VENTA</Td>
                            <Td style={{width:'10%', color: colBody1}}>{devolutionState.vehicleLicensePlate.split(' ').pop()}</Td>
                            <Td style={{width:'10%', color: colBody2}}>{devolutionState.warehouseName.split(' ').pop()}</Td>
                            <Td style={{width:'25%'}}>CANTIDAD</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {devolutionState.comparatives.sort((a, b) => a.productCode > b.productCode ? 1 : -1,).map((item, index) => {
                            return (
                                <Tr key={index} value={item.productCode} style={Number(item.quantity)>0?rowBody2:rowBody1}>
                                <Td className="align-middle">{item.productCode}</Td>
                                <Td className="align-middle">{item.productClassificationDisplay}</Td>
                                <Td className="align-middle">{item.productSku}</Td>
                                <Td className="align-middle">{item.productSaleName}</Td>
                                <Td className="align-middle text-right">{item.priceSale}</Td>
                                <Td className="align-middle text-center font-weight-bold" style={{color: colBody1}}>{item.stockA}</Td>
                                <Td className="align-middle text-center font-weight-bold" style={{color: colBody2}}>{item.stockB}</Td>
                                <Td className="text-center">
                                    {item.remainingQuantityInTariffs.map((tariffObj, index) => {
                                        
                                        return (
                                            <div className="input-group mb-1" key={index}>
                                                <div className="input-group-prepend w-50">
                                                <span className={tariffObj.unitName=="CJ"?"input-group-text w-100 bg-danger px-0":"input-group-text w-100 bg-secondary px-0"}>{tariffObj.unitName}</span>
                                                </div>
                                                <input type="number" 
                                                className="form-control text-center" value={tariffObj.portion} onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    
                                                    if(Number(e.target.value)>=0 && Number(e.target.value)<=Number(item.fixedStockA) /  tariffObj.quantityMinimum){
                                                        
                                                        setDevolutionState({...devolutionState, comparatives:devolutionState.comparatives.map((obj) =>{
                                                            if(obj.productID == item.productID){

                                                                obj.remainingQuantityInTariffs = item.remainingQuantityInTariffs.map((tariff)=>{
                                                                    if (Number(tariff.productTariffID) === Number(tariffObj.productTariffID)){
                                                                        
                                                                        item.quantity = item.quantity - Number(tariff.portion) * tariff.quantityMinimum;
                                                                        
                                                                        if(Number(e.target.value)>0)
                                                                            item.quantity = item.quantity +  Number(e.target.value) * tariff.quantityMinimum;
                                                                        
                                                                        tariff.portion = e.target.value;
                                                                        
                                                                    }
                                                                    
                                                                    return tariff
                                                                });
                                                               
                                                                item.stockA = item.fixedStockA - item.quantity;
                                                                item.stockB = item.fixedStockB + item.quantity;
                                                                if(item.stockA<0)document.getElementById('btn-register-devolution').disabled=true;
                                                                else document.getElementById('btn-register-devolution').disabled=false;

                                                            }
                                                            return obj;
                                                        })});

                                                    }
                                                }}
                                                />
                                                {/*<div className="input-group-append w-25"><span className="input-group-text w-100">{tariffObj.stock}</span></div>*/}
                                            </div>
                                            
                                        );
                                    })}

                                </Td>
                                </Tr>
                            );
                            })}
                        </Tbody>
                        </Table>
                    </div>
                  ):""}
              
              </div>
            </div>
          </div>
        </section>
  
      
      </>
    )
}
