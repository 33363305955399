import React from "react";
export function FragmentHeaderUser(props) {
  const { btnTittle, addUser } = props;

  return (
    <>
      <button
        type="button"
        className="btn btn-success btn-sm"
        onClick={addUser}
        data-toggle="modal"
        data-target="#modal-widget"
      >
        <i className="fas fa-database"></i> {btnTittle}
      </button>
      <div
        className="input-group input-group-sm float-sm-right"
        style={{ width: 150 }}
      >
        <input
          type="text"
          name="table_search"
          className="form-control float-right"
          placeholder="Search"
        />
        <div className="input-group-append">
          <button type="submit" className="btn btn-default">
            <i className="fas fa-search" />
          </button>
        </div>
      </div>
    </>
  );
}
