import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { sendPurchaseOperationData } from "../../../apis/apis.logistics/apisProduct";
import { Link, useNavigate } from "react-router-dom";
export function ReceiveOrderPurchaseList({
  order,
  setOrder,
  initialOrderState,
}) {
  const rowHead = { background: "#121212a1" };
  const rowBody = { background: "#3498db9e" };
  const sendPurchase = async () => {
    try {
      const response = await sendPurchaseOperationData(order);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const navigate = useNavigate();
  return (

    <>
      <div className="table-responsive">
        <table className="table table-sm table-bordered text-center">
          <thead>
            <tr className="" >
              <td className="align-middle font-weight-light" style={{width:"10%"}}>#</td>
              <td className="align-middle font-weight-light">SKU</td>
              <td className="align-middle font-weight-light">PRODUCTO</td>
              <td className="align-middle font-weight-light">UNIDAD</td>
              <td className="align-middle">PRECIO</td>
              <td className="align-middle font-weight-light">CANTIDAD ORDENADA</td>
              <td className="align-middle">CANTIDAD RECIBIDA</td>
              <td className="align-middle">CANTIDAD FALTANTE</td>
              <td className="align-middle">CANTIDAD COMPRADA</td>

              <td className="align-middle">SUBTOTAL ORDENADO</td>
              <td className="align-middle">SUBTOTAL RECIBIDO</td>
              <td className="align-middle">SUBTOTAL FALTANTE</td>
              <td className="align-middle">SUBTOTAL COMPRADO</td>
              <td className="align-middle">FEC. VTO</td>
            </tr>
          </thead>
          <tbody>
            {order.operationItems.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="align-middle">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="condition"
                        id={"condition" + item.operationDetailID}
                        className="custom-control-input"
                        checked={
                          item.condition == "02" || item.condition == "03"
                            ? true
                            : false
                        }
                        disabled={item.condition == "03" ? true : false}
                        onChange={(e) => {
                          if (Number(item.quantity) > 0) {
                            setOrder({
                              ...order,
                              operationItems: order.operationItems.map(
                                (obj) => {
                                  if (
                                    obj.operationDetailID ==
                                    item.operationDetailID
                                  ) {
                                    if (obj.condition == "01")
                                      obj.condition = "02";
                                    else {
                                      obj.condition = "01";
                                      obj.quantity = "";
                                    }
                                  }
                                  return obj;
                                }
                              ),
                            });
                          }
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"condition" + item.operationDetailID}
                      >
                        {item.condition == "01"
                          ? ""
                          : item.condition == "02"
                          ? "RECIBIDO"
                          : "GENERADO"}
                      </label>
                    </div>
                  </td>
                  <td className="align-middle">{item.sku}</td>
                  <td className="align-middle text-left">{item.productName}</td>
                  <td className="align-middle">{item.unitName}</td>

                  <td className="align-middle">
                    {Number(item.price)}
                  </td>

                  <td className="align-middle table-info">{item.quantityOrdered}</td>


                  <td className="align-middle table-secondary">
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      onFocus={(e) => e.target.select()}
                      onWheel={e => e.target.blur()}

                      autoComplete="off"
                      readOnly={item.condition == "03" ? true : false}
                      value={item.quantity}
                      onChange={(e) => {
                        if (
                          Number(e.target.value) > (Number(item.quantityOrdered) - Number(item.quantityReceived))
                        ) {
                          return false;
                        } else if (Number(e.target.value) == 0) {
                          setOrder({
                            ...order,
                            operationItems: order.operationItems.map((o) => {
                              if (
                                o.operationDetailID == item.operationDetailID
                              ) {
                                o.quantity = e.target.value;
                                o.condition = "01";
                              }
                              return o;
                            }),
                          });
                        } else {
                          setOrder({
                            ...order,
                            operationItems: order.operationItems.map((o) => {
                              if (
                                o.operationDetailID == item.operationDetailID
                              ) {
                                o.quantity = e.target.value;
                                o.condition = "02";
                              }
                              return o;
                            }),
                          });
                        }
                      }}
                    />
                  </td>

                  <td className="align-middle table-primary">
                    {Number(item.quantityOrdered) - Number(item.quantityReceived) - Number(item.quantity)}
                  </td>

                  <td className="align-middle table-success">
                    {Number(item.quantityReceived)}
                  </td>


                  <td className="align-middle text-right table-info">S/ {(Number(item.amountDiscount)).toFixed(2)}</td>

                  <td className="align-middle text-right table-secondary">S/ {((Number(item.quantity) * Number(item.price)) - (Number(item.quantity) * Number(item.price)* item.percentageDiscount) / 100).toFixed(2)} 
                  </td>
                  <td className="align-middle text-right table-primary">S/ {(((Number(item.quantityOrdered) - Number(item.quantityReceived) - Number(item.quantity)) * Number(item.price)) - ((Number(item.quantityOrdered) - Number(item.quantityReceived) - Number(item.quantity)) * Number(item.price)* item.percentageDiscount) / 100).toFixed(2)} 
                  </td>
                  <td className="align-middle text-right table-success">S/ {((Number(item.quantityReceived) * Number(item.price)) - (Number(item.quantityReceived) * Number(item.price)* item.percentageDiscount) / 100).toFixed(2)} 
                  </td>

                  <td className="align-middle">
                    <input
                        type="date"
                        name="expireDate"
                        className="form-control"
                        value={item.expireDate}
                        onChange={(e) => {
                            setOrder({
                                ...order,
                                operationItems: order.operationItems.map((o) => {
                                    if (o.operationDetailID == item.operationDetailID) {
                                        o.expireDate = e.target.value;
                                    }
                                    return o;
                                }),
                              });
                        }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
          <tr>
            <td className="align-middle text-right" colSpan={9}>TOTAL</td>
            <td className="align-middle text-right">S/ {Number(order.operationItems.reduce((previousValue, currentValue) => previousValue + (Number(currentValue.amountDiscount)), 0)).toFixed(2)}</td>
            <td className="align-middle text-right">S/ {Number(order.operationItems.reduce((previousValue, currentValue) => previousValue + ((Number(currentValue.quantity) * Number(currentValue.price)) - (Number(currentValue.quantity) * Number(currentValue.price)* currentValue.percentageDiscount) / 100), 0)).toFixed(2)}</td>
            <td className="align-middle text-right">S/ {Number(order.operationItems.reduce((previousValue, currentValue) => previousValue + (((Number(currentValue.quantityOrdered) - Number(currentValue.quantityReceived) - Number(currentValue.quantity)) * Number(currentValue.price)) - ((Number(currentValue.quantityOrdered) - Number(currentValue.quantityReceived) - Number(currentValue.quantity)) * Number(currentValue.price)* currentValue.percentageDiscount) / 100), 0)).toFixed(2)}</td>
            <td className="align-middle text-right">S/ {Number(order.operationItems.reduce((previousValue, currentValue) => previousValue + ((Number(currentValue.quantityReceived) * Number(currentValue.price)) - (Number(currentValue.quantityReceived) * Number(currentValue.price)* currentValue.percentageDiscount) / 100), 0)).toFixed(2)}</td>
            <td className="align-middle text-right"></td>
          </tr>
        </tfoot>
        </table>
      </div>

      <Link to='/buys/new_purchase_order' className="btn btn-outline-secondary mb-3" > <i className="fas fa-plus"></i> Nuevo </Link>
      <Link to='/buys/purchase_orders_issued' className="btn btn-outline-secondary mb-3" > <i className="fas fa-list"></i> Orden de Compras </Link>

      <button
        type="button"
        className="btn btn-warning float-right"
        onClick={async (e)=>{

            if (order.operationItems.length) {

                const found = await order.operationItems.find(function (detail, index) {
                    if (detail.condition == "02") return true;
                });

                if(found){
                    if (order.warehouseID != "0") {
                        if (order.operationDocumentNumber != "") {
                          if (order.operationDate != "") {
                              sendPurchase()
                              .then((r) => {
                                if (r.status == 201) {
                                    toast.success("Compra realizada con exito!");
                                    navigate("/buys/purchase_orders_issued");
                                  
                                } else {
                                    toast.error("Ocurrio algun error");
                                }
                              })
                              .then(() => {
                                setOrder(initialOrderState);
                              });
                          } else {
                            toast.error("Verifique la fecha");
                          }
                        } else {
                          toast.error("Verifique el numero de documento");
                        }
                      } else {
                        toast.error("Verifique el almacen");
                      }
                  }else {
                    toast.error("No hay nada por recibir");
                  }
              } else {
                toast.error("Verifique los items a registrar");
            }
        }}
      >
        Generar compra
      </button>
    </>
  );
}
