import React, { useEffect, useContext, useState } from "react";
import { sendNewCash } from "../../../apis/apis.accounting/apisCash";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function CashForm(props) {
  const { setBanks, itemState, setItemState } = useContext(GlobalContext);   
  
  /*const [cashItem, setCashItem] = useState({
    cashID: "0",
    cashName: "",
    cashAmount: "0",
    cashDate: getCurrentDate("-"),
    cashLastDate: "",
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    userID: auth.data.user_id,
  });*/
  
  function handleChange(e) {
    props.setCashItem({ ...props.cashItem, [e.target.name]: e.target.value });
    //console.log("handleChange", cashItem);
  }

  const saveCash = async (data) => {
    try {
      //console.log(data);
      const response = await sendNewCash(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.cashItem.cashName != '' && props.cashItem.cashDate != ''){       
        //console.log("props.cashItem", props.cashItem);
        saveCash(props.cashItem).then(async(res)=>{
            props.cashItem.cashID = res.cashID;           
            setItemState({ ...itemState, cashID: e.target.value });
            setBanks((prevPlanes) => {
               return [...prevPlanes, props.cashItem];
            });                   
        });
    }
    else{
        alert("Complete los campos necesarioss"); 
    }
    document.getElementById("close-modal-cash").click();
    
  };

  useEffect(() => {  
    /*console.log(auth.data);  
    if (auth.data.subsidiary){
       setCashItem({
         ...cashItem,
         subsidiaryID: auth.data.subsidiary.subsidiary_id,
         userID: auth.data.user_id,
       });
    }
    else {
      console.log('else')
    }*/  

  }, [props.setCashItem]);

  return (
    <div className="modal fade" id="modal-cash" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Nueva Caja:</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Nombre de la Caja:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control text-uppercase"
                  id="inputEmail3"
                  value={props.cashItem.cashName}
                  name="cashName"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Fecha de Apertura:
              </label>
              <div className="col-auto">
                <input
                  type="date"
                  name="cashDate"
                  className="form-control"
                  value={props.cashItem.cashDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Monto Inicial:
              </label>
              <div className="col-sm-4">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail3"
                  value={props.cashItem.cashAmount}
                  name="cashAmount"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="close-modal-cash"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Crear Nueva Caja
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
