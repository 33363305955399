import React, { useState, useContext, useEffect } from "react";
import { fetchedDispatchesByWarehouse, fetchedDispatchDetailsByWarehouse, fetchedVehiclesBySubsidiary, fetchedUsersByGang } from "../../../apis/apis.dispatches/apisDispatch";

import { fetchedGangs } from "../../../apis/apis.logistics/apisProduct";

import { toast } from "react-toastify";


export function DistributionFilter({setOrders, gangs, setGangs, users, setUsers, operationStatus, setOperationStatus, setFilename, questionState, setQuestionState, getCurrentDate}) {


    const getGangs = async () => {
      try {
        const response = await fetchedGangs();
        setGangs(response);
        // console.log(response)
      } catch (error) {
        throw error;
      }
    };

    const getUsersByGang = async (id) => {
      try {
        const response = await fetchedUsersByGang({
          gangID: id,
        });
        setUsers(response);
      } catch (error) {
        throw error;
      }
    };

    const sendFilterDispatches = async (data) => {
      try {
        const response = await fetchedDispatchDetailsByWarehouse(data);
        setOrders(response)
        // console.log(response)
        return response;
      } catch (error) {
        throw error;
      }
    };

    function handleChange(e) {
      setQuestionState({ ...questionState, [e.target.name]: e.target.value });
      if(e.target.name=="gangID"){
        const select = document.getElementById("gangID");

        const selectedIndex = select.selectedIndex;
        const selectedValue = select.value;
        const selectedText = select.options[selectedIndex].text;  
        setFilename("ReporteVentas_"+selectedText.replace(/\s/g, '') +"_"+getCurrentDate("-"));
        getUsersByGang(e.target.value);
      }
    }

    const handleQuery = (e) => {
      searchSuggestions();
    };
  
    function searchSuggestions() {
      sendFilterDispatches(questionState).then((res) => {
        let results = res.length;
        toast.info(
          results > 1
            ? "Se encontraron " + results.toString() + " resultados."
            : "Se encontró " + results.toString() + " resultado(s)."
        );
        setOrders(res);
      });
    }

    useEffect(() => {
      getGangs();
    }, []);

  return (
    <>
      <div className="row mb-3">

        <div className="col-1">
          <label className="">RUTA:</label>
        </div>

        <div className="col-2">
            <select
                className="form-control"
                id="gangID"
                name="gangID"
                value={questionState.gangID}
                onChange={handleChange}
            >
              <option value={0}>Elegir</option>
                {gangs.map((item, index) => {
                  return (
                    <option key={index} value={item.gangID}>{item.gangName}</option>
                  );
                })}
            </select>
        </div>

        <div className="col-1">
          <label className="">USUARIO:</label>
        </div>
        
        <div className="col-2">
            <select
                className="form-control"
                name="userID"
                value={questionState.userID}
                onChange={e=>{setQuestionState({ ...questionState, userID: e.target.value });}}
            >
              <option value={0}>Elegir</option>
                {users.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.first_name} | {item.last_name}
                    </option>
                  );
                })}
            </select>
        </div>
        
        <div className="col-auto">
          <label className="">TIPO DOC:</label>
        </div>

        <div className="col-1">
            <select
                className="form-control"
                name="documentType"
                value={questionState.documentType}
                onChange={e=>{setQuestionState({ ...questionState, documentType: e.target.value });}}
            >
              <option value={"02"}>BOLETA</option>
              <option value={"03"}>FACTURA</option>
              <option value={"01"}>TICKET</option>
               
            </select>
        </div>
        
        <div className="col-auto">
          <label className="">EMITIDO:</label>
        </div>

        <div className="col-1">
            <select
                className="form-control"
                name="httpStatusCode"
                value={questionState.httpStatusCode}
                onChange={e=>{setQuestionState({ ...questionState, httpStatusCode: e.target.value });}}
            >
              <option value={200}>SI</option>
              <option value={0}>NO</option>
              <option value={400}>CON ERROR</option>
               
            </select>
        </div>

        <div className="col-auto">
          <label className="">VISUALIZACION:</label>
        </div>
        
        <div className="col-1">
            <select
                className="form-control"
                name="mode"
                value={questionState.mode}
                onChange={e=>{setQuestionState({ ...questionState, mode: e.target.value });}}
            >
              <option value={"01"}>Detallado</option>
              <option value={"02"}>Resumido</option>
               
            </select>
        </div>

      </div>

      <div className="row">
        
        <div className="col-1">
          <label className="">TIPO DE PEDIDO:</label>
        </div>

        <div className="col-2">
            <select
                className="form-control"
                name="operationStatus"
                value={questionState.operationStatus}
                onChange={e=>{setQuestionState({ ...questionState, operationStatus: e.target.value });}}
            >
                {operationStatus.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
        </div>

        <div className="col-1">
          <label className="">FECHAS:</label>
        </div>

        <div className="col-3">
          <div className="row">
            <div className="col-6">
              <input
                type="date"
                className="form-control"
                name="startDate"
                value={questionState.startDate}
                onChange={handleChange}
              />
            </div>


            <div className="col-6">
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={questionState.endDate}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="col-auto">
          <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
          <i className="fas fa-search"></i> Buscar
          </button>
        </div>


      </div>

    </>
  );
}
