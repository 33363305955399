import React, { useState, useEffect, useContext } from "react";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedInventoryBetweenDates } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { fetchedProducts } from "../../../apis/apis.logistics/apisProduct";


const initialFormDataState = {
  warehouseID: "0",
  warehouses: [],
  startDate: getCurrentDate("-"),
  endDate: getCurrentDate("-"),
  productID: "0"
};

export function InventoryForm(props) {

  const {
    setInventories,
    subsidiary,
    warehouses,
    setWarehouses,
  } = useContext(GlobalContext);

  const [formData, setFormData] = useState(initialFormDataState);
  const [products, setProducts] = useState([]);

  const clearFormDataState = () => {
    setFormData({ ...initialFormDataState });
    // setProducts([]);
  };

  useEffect(() => {
    getComparativeFromProductsOfOneWarehouse();
  }, []);

  useEffect(() => {
    if (warehouses.length)
      setFormData((prevState) => ({
        ...prevState,
        warehouses: warehouses, // add new expense to expenses array
        warehouseID: warehouses[0].warehouseID,
      }));
  }, [setWarehouses]);

  const getInventoryBetweenDates = async () => {
    try {
      const response = await fetchedInventoryBetweenDates({
        warehouseID: formData.warehouseID,
        productID: formData.productID,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
      setInventories(response);
      toast.info("Se encontraron " + response.length + " registros.");
      // console.log(response);
    } catch (error) {
      throw error;
    }
  };
  const getComparativeFromProductsOfOneWarehouse = async () => {
    try {
      const response = await fetchedProducts();
      setProducts(response);

    } catch (error) {
      throw error;
    }
  };
  

  const handleChangeFormWarehouse = (e) => {
    setFormData({ ...formData, warehouseID: e.target.value });
    var index = e.nativeEvent.target.selectedIndex;

    props.setSelectedInventory({
      ...props.selectedInventory,
      warehouseName: e.nativeEvent.target[index].text,
    });
    
  };

  const handleChangeFormProduct = (e) => {

    setFormData({ ...formData, productID: e.target.value });
    var index = e.nativeEvent.target.selectedIndex;    
    const product = products.find(product => Number(product.productID) === Number(e.target.value));
    if (product!=null){
      props.setSelectedInventory({
        ...props.selectedInventory,
        // productName: e.nativeEvent.target[index].text,
        productName: product.saleName,
      });
    }
    
  };

  const handleChangeForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleClickBtnSearch = async () => {
    if (
      new Date(formData.startDate) > new Date(formData.endDate) ||
      new Date(formData.endDate) < new Date(formData.startDate)
    ) {
      toast.error("Verificar fechas");
    } else {

      //if (Number(formData.subsidiaryID) <= 0) {toast.error("Verificar sede");} else{}
        if (Number(formData.warehouseID) <= 0){
          toast.error("Verificar almacen");
        } else{
          if (Number(formData.productID) <= 0) {
            toast.error("Verificar producto");
          } else{
            // console.log("formData", formData);
            getInventoryBetweenDates();
            // clearFormDataState();
          }
        }
      
    }

  }
  return (
    <>
      <div className="form-group row">
        <label className="col-3">Sede:</label>
        <div className="col">
          <select className="form-control" name="subsidiaryID">
            <option value={subsidiary.subsidiaryID}>
              {subsidiary.subsidiaryName}
            </option>
          </select>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3">Almacen:</label>
        <div className="col">
          <select
            className="form-control"
            name="warehouseID"
            value={formData.warehouseID}
            onChange={handleChangeFormWarehouse}
          >
            <option value="0">Elegir</option>

            {warehouses.map((item, index) => {
              return (
                <option key={index} value={item.warehouseID}>
                  {item.warehouseName}{item.gang.gangName!==""?" | " +item.gang.gangName:""}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3">Producto:</label>
        <div className="col">
          <select
            className="form-control"
            name="productID"
            value={formData.productID}
            onChange={handleChangeFormProduct}
          >
<option value="0">Elegir</option>
            {products.map((item, index) => {
              return (
                <option key={index} value={item.productID}>
                  {item.code}|{item.sku}|{item.saleName}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      
      <div className="form-group row">
        <label className="col-3">Desde</label>
        <div className="col">
          <input
            type="date"
            className="form-control"
            placeholder="Ingrese ..."
            name="startDate"
            value={formData.startDate}
            onChange={handleChangeForm}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3">Hasta</label>

        <div className="col">
          <input
            type="date"
            className="form-control"
            placeholder="Ingrese ..."
            name="endDate"
            value={formData.endDate}
            onChange={handleChangeForm}
          />
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary font-weight-light btn-block"
        onClick={handleClickBtnSearch}
      >
        Buscar
      </button>
    </>
  );
}
