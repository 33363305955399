import React, { useState, useContext, useEffect } from "react";
import { fetchedPreSalesByAction, fetchedUsersByGang, getActualSoldProductsData } from "../../../apis/apis.dispatches/apisDispatch";

import { fetchedGangs } from "../../../apis/apis.logistics/apisProduct";

import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export  function SoldProductGraphByDayFilter({setSoldProducts}) {

    const { auth } = useContext(ContextAuth);
    const [gangs, setGangs] = useState([]);
    const [users, setUsers] = useState([]);

    const [questionState, setQuestionState] = useState({
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
        warehouseID: 0,
        gang: {gangID: 0, gangName: ""},
        userID: 0,
        operationDate: getCurrentDate("-"),
    });

    const getActualSoldProducts = async (o) => {
        try {
          const response = await getActualSoldProductsData(o);
          
          return response;
        } catch (error) {
          throw error;
        }
      };
      
    const getGangs = async () => {
        try {
          const response = await fetchedGangs();
          setGangs(response);
        } catch (error) {
          throw error;
        }
    };

    const getUsersByGang = async (id) => {
    try {
        const response = await fetchedUsersByGang({
        gangID: id,
        });
        setUsers(response);
    } catch (error) {
        throw error;
    }
    };

    
    function handleChange(e) {
      
      if(e.target.name=="gangID"){
          const select = document.getElementById("gangID");

          const selectedIndex = select.selectedIndex;
          const selectedValue = select.value;
          const selectedText = select.options[selectedIndex].text; 

          let nextGang = { ...questionState.gang, gangID: Number(e.target.value), gangName: selectedText };
          let nextQuestionState = { ...questionState, gang: nextGang, userID: 0 };
          setQuestionState(nextQuestionState);

          getUsersByGang(e.target.value);
      }else{
        setQuestionState({ ...questionState, [e.target.name]: e.target.value });
      }
    }

    const handleQuery = (e) => {
    searchSuggestions();
    
    
    };

    function searchSuggestions() {
      
        getActualSoldProducts(questionState).then((res) => {
          let results = res.length;
          setSoldProducts(res)
      });
    }

    useEffect(() => {
      getGangs();
    }, []);


    return (
        <>
            <div className="row mb-2">

                <div className="col-lg-4">
                <label className="">RUTA:</label>
                </div>

                <div className="col-lg-8">
                    <select
                        className="form-control"
                        id="gangID"
                        name="gangID"
                        value={questionState.gangID}
                        onChange={handleChange}
                    >
                    <option value={0}>TODOS</option>
                        {gangs.map((item, index) => {
                        return (
                            <option key={index} value={item.gangID}>{item.gangName}</option>
                        );
                        })}
                    </select>
                </div>

                </div>

                <div className="row mb-2">

                <div className="col-lg-4">
                <label className="">USUARIO:</label>
                </div>

                <div className="col-lg-8">
                    <select
                        className="form-control"
                        name="userID"
                        value={questionState.userID}
                        onChange={e=>{setQuestionState({ ...questionState, userID: Number(e.target.value) });}}
                    >
                    <option value={0}>Elegir</option>
                        {users.map((item, index) => {
                        return (
                            <option key={index} value={item.id}>
                            {item.first_name} | {item.last_name}
                            </option>
                        );
                        })}
                    </select>
                </div>

                </div>

                <div className="row mb-2">


                <div className="col-lg-4">
                            <label className="">FECHA:</label>
                        </div>



                        <div className="col-lg-8">
                            <input
                            type="date"
                            className="form-control"
                            id="operationDate"
                            name="operationDate"
                            value={questionState.operationDate}
                            onChange={handleChange}
                            />
                        </div>


                </div>

                <div className="row">

                <div className="col-lg-12">
                <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
                <i className="fas fa-search"></i> Buscar
                </button>
                </div>

            </div>
        </>
    )
}
