import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { savePurchaseOperationData } from "../../../apis/apis.logistics/apisProduct";
import { Link, useNavigate } from "react-router-dom";

export function EditOrderPurchaseReceivedList({order, setOrder, initialOrderState,}) {
    const rowHead = { background: "#121212a1" };
    const rowBody = { background: "#3498db9e" };
    const sendPurchase = async () => {
        try {
        const response = await savePurchaseOperationData(order);
        return response;
        } catch (error) {
        throw error;
        }
    };
    const navigate = useNavigate();
    return (

        <>
        <div className="table-responsive">
            <table className="table table-sm table-bordered text-center">
            <thead>
                <tr className="" >
                <td className="align-middle font-weight-light" style={{width:"10%"}}>#</td>
                <td className="align-middle font-weight-light">SKU</td>
                <td className="align-middle font-weight-light">PRODUCTO</td>
                <td className="align-middle font-weight-light">UNIDAD</td>
                <td className="align-middle">PRECIO</td>
                <td className="align-middle font-weight-light">CANTIDAD ORDENADA</td>
                <td className="align-middle">SUBTOTAL ORDENADO</td>
                <td className="align-middle">FEC. VTO</td>
                </tr>
            </thead>
            <tbody>
                {order.operationItems.map((item, index) => {
                return (
                    <tr key={index}>
                    <td className="align-middle">{item.operationDetailID}</td>
                    <td className="align-middle">{item.sku}</td>
                    <td className="align-middle text-left">{item.productName}</td>
                    <td className="align-middle">{item.unitName}</td>

                    <td className="align-middle">{Number(item.price)}</td>
                    <td className="align-middle">{item.quantity}</td>

                    <td className="align-middle text-right">S/ {(Number(item.quantity) * Number(item.price)).toFixed(2)}</td>

                    <td className="align-middle">
                        <input
                            type="date"
                            name="expireDate"
                            className="form-control"
                            value={item.expireDate}
                            onChange={(e) => {
                                setOrder({
                                    ...order,
                                    operationItems: order.operationItems.map((o) => {
                                        if (o.operationDetailID == item.operationDetailID) {
                                            o.expireDate = e.target.value;
                                        }
                                        return o;
                                    }),
                                });
                            }}
                        />
                    </td>
                    </tr>
                );
                })}
            </tbody>
            <tfoot>
            <tr>
                <td className="align-middle text-right" colSpan={6}>TOTAL</td>
                <td className="align-middle text-right">S/ {Number(order.operationItems.reduce((previousValue, currentValue) => previousValue + (Number(currentValue.quantity) * Number(currentValue.price)), 0)).toFixed(2)}</td>
                <td className="align-middle text-right"></td>
            </tr>
            </tfoot>
            </table>
        </div>

        <Link to='/buys/new_purchase_order' className="btn btn-outline-secondary mb-3" > <i className="fas fa-plus"></i> Nuevo </Link>
        <Link to='/buys/purchase_orders_issued' className="btn btn-outline-secondary mb-3" > <i className="fas fa-list"></i> Orden de Compras </Link>

        <button
            type="button"
            className="btn btn-success float-right"
            onClick={async (e)=>{

                if (order.operationItems.length) {


            
                    if (order.operationDate != "") {
                        sendPurchase()
                        .then((r) => {
                            if (r.status == 201) {
                                toast.success("Compra realizada con exito!");
                                navigate("/buys/purchase_orders_issued");
                            
                            } else {
                                toast.error("Ocurrio algun error");
                            }
                        })
                        .then(() => {
                            setOrder(initialOrderState);
                        });
                    } else {
                        toast.error("Verifique la fecha");
                    }

                }
            }}
        >
            Guardar cambios
        </button>
        </>
    );
}
