import React, { useEffect, useContext, useState } from "react";
import { sendNewOperation } from "../../../apis/apis.accounting/apisCash";
import { getCurrentDate } from "../../../configs/helpers";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { toast } from "react-toastify";

export default function CashOperations(props) {
  
  const { operations, setItemState } = useContext(GlobalContext);
  
  const { auth } = useContext(ContextAuth);
  /*const { oldValues, setOldValues } = useState({
    oldTotal: "0"
  })*/
  //console.log('props.cashFlow', props.cashFlow);
  
  const [amountValues, setAmountValues] = useState({
    cashOperationIgv: "0",
    cashOperationSubtotal: "0",
    cashOperationTotal: "0",
  });

  const options = [            
    {id:'F', name: 'Factura'}, 
    {id:'B', name: 'Boleta'}, 
    {id:'T', name: 'Ticket'}, 
    {id:'V', name: 'Vale'}, 
    {id:'O', name: 'Otro'}
  ]

  function handleChangeAmount(e) {

     if (
       props.cashFlow.cashOperationTypeDocument == "F" ||
       props.cashFlow.cashOperationTypeDocument == "B"
     ) {
       let total = Number(e.target.value);
       let base_amount = Number(total / 1.18).toFixed(2);
       let igv_total = Number(total - base_amount).toFixed(2);

       setAmountValues({
         ...amountValues,
         cashOperationIgv: igv_total,
         cashOperationSubtotal: base_amount,
         cashOperationTotal: e.target.value,
       });
     } else {
       setAmountValues({
         ...amountValues,
         cashOperationIgv: "0",
         cashOperationSubtotal: "0",
         cashOperationTotal: e.target.value,
       });
     }    
  }
  
  function handleChange(e) {
    let value = e.target.value;          
    let name = e.target.name;   
    
    
    if (name == "cashOperationTypeDocument") {  
      if (value == "F" || value == "B") {
        if (Number(amountValues.cashOperationTotal) > 0) {
          let total = Number(amountValues.cashOperationTotal);
          let base_amount = Number(total / 1.18).toFixed(2);
          let igv_total = Number(total - base_amount).toFixed(2);

          setAmountValues({
            ...amountValues,
            cashOperationIgv: igv_total,
            cashOperationSubtotal: base_amount,
          });
        }         
      }else{
        setAmountValues({
          ...amountValues,
          cashOperationIgv: "0",
          cashOperationSubtotal: "0",
        });        
      }      
    }     
    //setItemState({ ...itemState, [e.target.name]: e.target.value });   
    props.setCashFlow({
      ...props.cashFlow,
      [e.target.name]: e.target.value,      
    });   
    /*props.setCashItem({
      ...props.cashItem,
      [e.target.name]: e.target.value,
    });*/   
  }
  
  const saveOperation = async (data) => {
    try {
      const response = await sendNewOperation(data);
      return response;
    } catch (error) {
      throw error;
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      props.cashFlow.cashOperationDescription != "" &&
      props.cashFlow.cashOperationTypeDocument != "0" &&
      amountValues.cashOperationTotal != "0"
    ) {
      let data = {
        cashFlowID: props.cashFlow.cashFlowID,
        cashName: props.cashFlow.cashName,
        cashID: props.cashFlow.cashID,
        cashDate: props.cashFlow.cashDate,
        cashOperationDescription: props.cashFlow.cashOperationDescription,
        cashOperationTypeDocument: props.cashFlow.cashOperationTypeDocument,
        cashOperationType: props.cashFlow.cashOperationType,
        cashOperationIgv: amountValues.cashOperationIgv,
        cashOperationSubtotal: amountValues.cashOperationSubtotal,
        cashOperationTotal: amountValues.cashOperationTotal,
        userID: props.cashFlow.userID,
      };

      //console.log("itemState", itemState);
      //console.log("amountValues", amountValues);
      //console.log("data", data);

      if (props.cashFlow.cashFlowID == 0 ) {

        if (props.cashItem.cashDate == props.cashFlow.cashDate) {
          saveOperation(data).then(async (res) => {
            toast.success("LA OPERACIÓN SE REGISTRO CORRECTAMENTE");
            document.getElementById("btn-search").click();
            props.setCashItem({
              ...props.cashItem,
              cashOperationDescription: "",
              cashOperationTypeDocument: "",
            });
            setAmountValues({
              ...amountValues,
              cashOperationSubtotal: "0",
              cashOperationIgv: "0",
              cashOperationTotal: "0",
            });
          });
        } else {
          toast.error(
            "La Fecha de Operacion no puede ser diferente a la fecha de la caja seleccionada"
          );
          return false;
        }
          
      } else {
        saveOperation(data).then(async (res) => {
          document.getElementById("btn-search").click();
          toast.success("Operacion actualizada con exito!");
        });
      }
      
    } else {
      toast.error("Complete los campos necesarioss");
      return false;
    }

    document.getElementById("close-modal-operations").click();
  };

  //console.log('props.cashItem', props.cashItem);
  //console.log('props.cashFlow', props.cashFlow);
  //console.log("props.selectedCashFlow", props.selectedCashFlow);

  useEffect(() => {        
    const cashFlowFound = operations.find(
      (item2) => item2.cashFlowID == props.selectedCashFlow.cashFlowID
    );
    //console.log("cashFlowFound", cashFlowFound);
    if (cashFlowFound) {
       props.setCashFlow({
         cashFlowID: cashFlowFound.cashFlowID,
         cashID: cashFlowFound.cashID,
         cashName: cashFlowFound.cashName,
         cashFlowID: cashFlowFound.cashFlowID,
         cashOperationDescription: cashFlowFound.description,
         cashOperationTypeDocument: cashFlowFound.documentType,
         cashDate: cashFlowFound.transactionDateFormat,
         cashOperationType: cashFlowFound.typeOperation,
         userID: cashFlowFound.userID,
       });
       setAmountValues({
         ...amountValues,
         cashOperationSubtotal: cashFlowFound.subtotal,
         cashOperationIgv: cashFlowFound.igv,
         cashOperationTotal: cashFlowFound.total,
       });
      //props.cashFlow.cashOperationDescription = cashFlowFound.description;
    }else{      
     props.setCashFlow({
       ...props.cashFlow,
       //cashFlowID: "0",
       //cashID: "0",
       //cashName: "",
       cashDate: getCurrentDate("-"),
       cashOperationDescription: "",
       cashOperationTypeDocument: "",
       cashOperationType: "E",
       userID: auth.data.user_id,       
     });
     setAmountValues({
       cashOperationIgv: "0",
       cashOperationSubtotal: "0",
       cashOperationTotal: "0",
     });

    }
  }, [props.selectedCashFlow]);

  /*useEffect(() => {
    console.log(props.cashFlow);
  }, [props.cashFlow]);       */


  return (
    <div
      className="modal fade"
      id="modal-operations"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {props.selectedCashFlow.cashFlowID ? " Editando " : "Creando "}un
              movimiento de Caja:
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Caja seleccionada:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control text-uppercase"
                  id="inputEmail3"
                  //value={props.cashItem.cashName}
                  value={props.cashFlow.cashName}
                  name="cashName"
                  disabled={true}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Tipo de Operación:
              </label>
              <div className="col-sm-8">
                <div className="form-group row pt-2">
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="cashOperationType"
                        id="exampleRadios1"
                        defaultValue="E"
                        onChange={handleChange}
                        checked={
                          props.cashFlow.cashOperationType == "E" ? true : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Entrada
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="cashOperationType"
                        id="exampleRadios2"
                        defaultValue="S"
                        onChange={handleChange}
                        checked={
                          props.cashFlow.cashOperationType == "S" ? true : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Salida
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Descripcion de la Operación:
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control text-uppercase"
                  id="inputEmail3"
                  value={props.cashFlow.cashOperationDescription}
                  name="cashOperationDescription"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Fecha de Operacion:
              </label>
              <div className="col-auto">
                <input
                  type="date"
                  name="cashDate"
                  className="form-control"
                  //value={props.cashItem.cashDate}
                  value={props.cashFlow.cashDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Seleccione el Tipo de Documento:
              </label>
              <div className="col-sm-4">
                <select
                  className="form-control select2 text-uppercase"
                  onChange={handleChange}
                  name="cashOperationTypeDocument"
                  value={props.cashFlow.cashOperationTypeDocument}
                >
                  <option value="0">Seleccione...</option>
                  {options.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Sub total:
              </label>
              <div className="col-sm-2">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail389"
                  value={amountValues.cashOperationSubtotal}
                  name="cashOperationSubtotal"
                  onChange={handleChangeAmount}
                  readOnly
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Igv:
              </label>
              <div className="col-sm-2">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail38"
                  value={amountValues.cashOperationIgv}
                  name="cashOperationIgv"
                  onChange={handleChangeAmount}
                  readOnly
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">
                Total:
              </label>
              <div className="col-sm-2">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail31"
                  value={amountValues.cashOperationTotal}
                  onFocus={(e) => e.target.select()}
                  name="cashOperationTotal"
                  onChange={handleChangeAmount}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="close-modal-operations"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar Operación
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
