import routesHRM from "./routesHrm";
import routesACCOUNTING from "./routesAccounting";
import routesLOGISTICS from "./routesLogistic";
import routesBUYS from "./routesBuys";
import routesDISPATCHES from "./routesDispatches";
//import routesSales from "./routesSales"

import { ModulesError } from "../modules";
import { LayoutsError } from "../layouts";
const routes = [
  ...routesHRM,
  ...routesACCOUNTING,
  ...routesLOGISTICS,
  ...routesBUYS,
  ...routesDISPATCHES,
  {
    path: "*",
    layout: LayoutsError,
    component: ModulesError,
  },
];
export default routes;
