import { CashList } from "../fragments.accounting/CashList";
import { CashSearch } from "../fragments.accounting/CashSearch";
import React, { useEffect, useContext, useState } from "react";
import { fetchedBanks } from "../../../apis/apis.accounting/apisCash";
import { GlobalContext } from "../../../contexts/GlobalContext";
import CashForm from "../fragments.accounting/CashForm";
import CashOperations from "../fragments.accounting/CashOperations";
import { getCurrentDate } from "../../../configs/helpers";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function CashPage() {
  const { setBanks } = useContext(GlobalContext);
  const { auth } = useContext(ContextAuth);
  const [selectedCashFlow, setSelectedCashFlow] = useState({});
 // const [operations, setOperations] = useState([]);

  const [cashItem, setCashItem] = useState({
    cashID: "0",
    cashName: "",
    cashAmount: "0",
    cashDate: getCurrentDate("-"),
    cashLastDate: "",
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    checkCloseCash: "",
    userID: auth.data.user_id,
  });

  const [cashFlow, setCashFlow] = useState({
    cashFlowID: "0",
    cashID: "0",
    cashName: "",
    cashDate: getCurrentDate("-"),
    cashOperationDescription: "",
    cashOperationTypeDocument: "0",
    cashOperationType: "E",
    userID: auth.data.user_id,
  });

  const getBanks = async () => {
    try {
      const response = await fetchedBanks();
      //console.log("fetchedBanks");
      setBanks(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Control de dinero en efectivo</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Finanzas</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <CashSearch
                cashItem={cashItem}
                setCashItem={setCashItem}
                cashFlow={cashFlow}
                setCashFlow={setCashFlow}
                selectedCashFlow={selectedCashFlow}
                setSelectedCashFlow={setSelectedCashFlow}
              />
              <CashList
                cashItem={cashItem}
                setCashItem={setCashItem}
                selectedCashFlow={selectedCashFlow}
                setSelectedCashFlow={setSelectedCashFlow}
              />
              <CashForm cashItem={cashItem} setCashItem={setCashItem} />
              <CashOperations
                cashItem={cashItem}
                setCashItem={setCashItem}
                cashFlow={cashFlow}
                setCashFlow={setCashFlow}
                selectedCashFlow={selectedCashFlow}
                setSelectedCashFlow={setSelectedCashFlow}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
