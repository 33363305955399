import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { MerchandiseFilter } from "../fragments.logistics/MerchandiseFilter";
import { MerchandiseList } from "../fragments.logistics/MerchandiseList";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedVehiclesBySubsidiary } from "../../../apis/apis.dispatches/apisDispatch";


export function MerchandisePage() {
    const [orders, setOrders] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const { auth } = useContext(ContextAuth);

    const [questionState, setQuestionState] = useState({
      otherDate: getCurrentDate("-"),
      subsidiaryID: auth.data.subsidiary.subsidiary_id,
      warehouseID: 0,
      operationStatus: "02",
    });
    

    const getVehicles = async (id) => {
      try {
        const response = await fetchedVehiclesBySubsidiary({
          subsidiaryID: id,
        });
        setVehicles(response);
      } catch (error) {
        throw error;
      }
    };
    
    useEffect(() => {
      if(auth != undefined)
        getVehicles(auth.data.subsidiary.subsidiary_id);
    }, [auth]);

  return (
    <>
    <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Consulta de mercaderia</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Distribuciones</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
            <MerchandiseFilter setOrders={setOrders} vehicles={vehicles} setVehicles={setVehicles} questionState={questionState} setQuestionState={setQuestionState}
            />
            <MerchandiseList orders={orders} questionState={questionState} />
                
            </div>
          </div>
        </div>
      </section>

    
    </>
  )
}
