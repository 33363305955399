import React, { useState, useContext, useEffect } from "react";
import { fetchedSalesAccumulative, fetchedVehiclesBySubsidiary, fetchedUsersByGang } from "../../../apis/apis.dispatches/apisDispatch";

import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function AccumulativeDispatchFilter({setOrders, vehicles, setVehicles, users, setUsers, operationStatus, setOperationStatus}) {
    const { auth } = useContext(ContextAuth);

    const [questionState, setQuestionState] = useState({
      startDate: getCurrentDate("-"),
      subsidiaryID: auth.data.subsidiary.subsidiary_id,
      gangID: 0,
      userID: 0,
      operationStatus: "02",
    });

    const getVehicles = async (id) => {
      try {
        const response = await fetchedVehiclesBySubsidiary({
          subsidiaryID: id,
        });
        setVehicles(response);
      } catch (error) {
        throw error;
      }
    };

    const getUsersByGang = async (id) => {
        try {
          const response = await fetchedUsersByGang({
            gangID: id,
          });
          setUsers(response);
        } catch (error) {
          throw error;
        }
      };

    const sendFilterDispatches = async (data) => {
      try {
        const response = await fetchedSalesAccumulative(data);
        setOrders(response)
        // console.log(response)
        return response;
      } catch (error) {
        throw error;
      }
    };

    function handleChange(e) {
      setQuestionState({ ...questionState, [e.target.name]: e.target.value });
    }

    const handleQuery = (e) => {
        if(questionState.gangID >0){searchSuggestions();}
        else{toast.info("Elija cuadrilla")}
      
    };
  
    function searchSuggestions() {
      sendFilterDispatches(questionState).then((res) => {
        let results = res.length;
        toast.info(
          results > 1
            ? "Se encontraron " + results.toString() + " resultados."
            : "Se encontró " + results.toString() + " resultado(s)."
        );
        setOrders(res);
      });
    }

    useEffect(() => {
      getVehicles(auth.data.subsidiary.subsidiary_id);
    }, []);

  return (
    <>
      <div className="row">

        <div className="col-auto">
          <label className="">RUTA:</label>
        </div>

        <div className="col-auto">
            <select
                className="form-control"
                name="gangID"
                value={questionState.gangID}
                onChange={e=>{setQuestionState({ ...questionState, gangID: e.target.value }); getUsersByGang(e.target.value);}}
            >
              <option value={0}>Elegir</option>
                {vehicles.map((item, index) => {
                  return (
                    <option key={index} value={item.gang.gangID}>
                      {item.vehicleLicensePlate} | {item.gang.gangName}
                    </option>
                  );
                })}
            </select>
        </div>

        <div className="col-auto">
          <label className="">USUARIO:</label>
        </div>
        
        <div className="col-auto">
            <select
                className="form-control"
                name="userID"
                value={questionState.userID}
                onChange={e=>{setQuestionState({ ...questionState, userID: e.target.value });}}
            >
              <option value={0}>Elegir</option>
                {users.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.first_name} | {item.last_name}
                    </option>
                  );
                })}
            </select>
        </div>

        <div className="col-auto">
          <label className="">TIPO DE PEDIDO:</label>
        </div>

        <div className="col-auto">
            <select
                className="form-control"
                name="operationStatus"
                value={questionState.operationStatus}
                onChange={e=>{setQuestionState({ ...questionState, operationStatus: e.target.value });}}
            >
                {operationStatus.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
        </div>

        <div className="col-auto">
          <input
            type="date"
            className="form-control"
            name="startDate"
            value={questionState.startDate}
            onChange={handleChange}
          />
        </div>



        <div className="col-auto">
          <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
          <i className="fas fa-search"></i> Buscar
          </button>
        </div>


      </div>

    </>
  )
}
