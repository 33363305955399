import React, { useEffect, useContext, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  fetchedBrands,
  fetchedUnits,
} from "../../../apis/apis.logistics/apisProduct";
import { ProductBrandList } from "../fragments.logistics/ProductBrandList";
import { ProductBrandForm } from "../fragments.logistics/ProductBrandForm";
import { UnitList } from "../fragments.logistics/UnitList";
import { UnitForm } from "../fragments.logistics/UnitForm";
export function ProductBrandPage() {
    
const { setBrands } = useContext(GlobalContext);
 const [units, setUnits] = useState([]);
 const [selectedUnit, setSelectedUnit] = useState({});

const getBrands = async () => {
  try {
    const response = await fetchedBrands();
    setBrands(response);
    return response;
  } catch (error) {
    throw error;
  }
};

const getUnits = async () => {
  try {
    const response = await fetchedUnits();
    setUnits(response);
    return response;
  } catch (error) {
    throw error;
  }
};

  useEffect(() => {
    getBrands();
    getUnits();
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Modulo Logistico</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Logistica</a>
                </li>
                <li className="breadcrumb-item active">Marcas y Unidades</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <ProductBrandList />
                </div>
                <div className="col-6">
                  <UnitList units={units} setSelectedUnit={setSelectedUnit} />
                </div>
              </div>
              <ProductBrandForm />
              <UnitForm
                units={units}
                selectedUnit={selectedUnit}
                setUnits={setUnits}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
