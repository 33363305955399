import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export function AccumulativeDispatchList({orders}) {
    const [items, setItems] = useState([]);
  return (
    <> 
    <table className="table table-sm table-bordered text-center mt-3" id="table-to-xls">
        <thead>
          <tr className='text-uppercase'>

            <td className="align-middle bg-navy" >
            #
            </td>

            <td className="align-middle bg-navy" >
              Producto
            </td>
           
            <td className="align-middle bg-navy" >
            Stock Vendido
            </td>

            <td className="align-middle bg-navy" >
            Precio
            </td>

            <td className="align-middle bg-navy" >
            Subtotal
            </td>

          </tr>
        </thead>
        <tbody>
          {orders.map((o, index) => {
            return (
              <tr key={index} value={o.productID}>
                <td className="align-middle">{o.productCode}</td>
                <td className="align-middle text-left">{o.productName}</td>
                <td className="align-middle">{o.quantitySold}</td>          
                <td className="align-middle text-right">S/ {Number(o.salePrice).toFixed(2)}</td>          
                <td className="align-middle text-right">S/ {Number(o.subtotal).toFixed(2)}</td>          
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="align-middle text-right" colSpan={4}>TOTAL</td>
            <td className="align-middle text-right">S/ {Number(orders.reduce((previousValue, currentValue) => previousValue + currentValue.subtotal, 0)).toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
      <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-outline-secondary mt-3 ml-1"
                    table="table-to-xls"
                    filename="report_of_sales"
                    sheet="report"
                    buttonText="Descargar como XLS"/>
    </>
  )
}
