import { BASE_API } from "../../configs/ConfigsConstants";

export async function getPpesApi(token) {
  try {
    const url = `${BASE_API}/hrm/api/get_ppes/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function addPpeApi(data, token) {
  try {
    const url = `${BASE_API}/hrm/api/save_ppe/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updatePpeApi(data, token) {
  try {
    const url = `${BASE_API}/hrm/api/updates_ppe/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function deletePpeApi(id, token) {
  try {
    const url = `${BASE_API}/hrm/api/delete_ppe/${id}/`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}
