import React, { useState, useEffect, useContext, createContext } from "react";
import { sendUnitData } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";

const initialState = {
  unitID: 0,
  unitName: "",
  unitDescription: "",
};

export function UnitForm(props) {
  const [item, setItem] = useState(initialState);

  const saveUnit = async (data) => {
    try {
      const response = await sendUnitData(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  function handleChange(e) {
    setItem({ ...item, [e.target.name]: e.target.value });
  }
const updateUnits = props.units.map((unit) => {
  if (unit.unitID === item.unitID) {
    var newItem = Object.assign({}, unit);
    newItem.unitName = item.unitName;
    newItem.unitDescription = item.unitDescription;
    return newItem;
  } else {
    return unit;
  }
});

  const handleSubmit = (e) => {
    e.preventDefault();

    if (item.unitID == 0) {
      saveUnit(item).then(async (res) => {
        item.unitID = res.unitID;
        setItem(item);
        props.setUnits((prevPlanes) => {
          return [...prevPlanes, item];
        });
                    toast.success("Unidad registrada con exito!");

      });
    } else {
      props.setUnits(updateUnits);
      saveUnit(item);
      toast.success("Unidad actualizada con exito!");
    }
    document.getElementById("close-modal-unit").click();

    //navigate("/");
  };

  useEffect(() => {
    const unitFound = props.units.find(
      (item2) => item2.unitID === props.selectedUnit.unitID
    );
    if (unitFound) {
      setItem({
        unitID: unitFound.unitID,
        unitName: unitFound.unitName,
        unitDescription: unitFound.unitDescription,
      });
    } else {
      setItem(initialState);
    }
  }, [props.selectedUnit]);

  return (
    <div className="modal fade" id="modal-unit" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title font-weight-light">
              {item.unitID ? "Editando " : "Creando "}una unidad
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <input type="hidden" value={item.unitID} />

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label className=" font-weight-light">Nombre:</label>
                  <input
                    type="text"
                    className="form-control font-weight-light"
                    placeholder="Ingrese ..."
                    name="unitName"
                    maxLength={5}
                    value={item.unitName}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label className=" font-weight-light">Descripcion:</label>
                  <input
                    type="text"
                    className="form-control font-weight-light"
                    placeholder="Ingrese ..."
                    name="unitDescription"
                    maxLength={100}
                    value={item.unitDescription}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-light border font-weight-light"
              data-dismiss="modal"
              id="close-modal-unit"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-success font-weight-light"
              onClick={handleSubmit}
            >
              {item.unitID ? "Guardar cambios" : "Registrar"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
