import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchedDebtors } from "../../../apis/apis.accounting/apisCash";
import { fetchedAllOperationsAccountReceiveByClient } from "../../../apis/apis.accounting/apisCash";
//import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
import { customStyles } from "../../../configs/Data";

export function AccountReceiveSearch(props) {

 //console.log("props.allDebtors", props.allDebtors);

 const [options, setOptions] = useState([]);

   function handleSelectDateChange(e) {
     props.setDates({
       ...props.dates,
       [e.target.name]: e.target.value,
     });
   }

   const getClientsWithDebt = async () => {
     try {
       const response = await fetchedDebtors();
       //console.log("response", response)

       response.forEach((mobile) => {
         setOptions((prevState) => [
           ...prevState,
           {
             value: mobile["clientID"],
             label: mobile["clientName"],
             clientName: mobile["clientName"],
             cashName: mobile["cashName"],
             cashAmount: mobile["cashAmount"],
             cashStatus: mobile["cashStatus"],
             clientVisitDay: mobile["clientVisitDay"],
             cashID: mobile["cashID"],
           },
         ]); // add new expense to expenses array
       });
     } catch (error) {
       throw error;
     }
   };

   const handleSelectChange = async (selectOption) => {
     
      //console.log(selectOption.value)      
      props.setDebtorItem({
        ...props.debtorItem,
        debtorClientID: selectOption.value,
        debtorCashID: selectOption.cashID,
        debtorCashAmount: selectOption.cashAmount,
        debtorCashName: selectOption.cashName,
        debtorCashStatus: selectOption.cashStatus,
        debtorClientName: selectOption.clientName,
        debtorClientVisitDay: selectOption.clientVisitDay,
      });
      
    
   };
   //console.log(props.debtorItem);

   function searchAccountsReceiveList(params) {
    //console.log(props.debtorItem.debtorClientID);
     if (
       props.debtorItem.debtorClientID !== "0" &&
       props.debtorItem.debtorCashID !== "0" &&
       props.dates.startDate !== "" &&
       props.dates.endDate !== ""
     ) {
       getAllAccountReceiveList({
         clientID: props.debtorItem.debtorClientID,
         cashID: props.debtorItem.debtorCashID,
         startDate: props.dates.startDate,
         endDate: props.dates.endDate,
       });
     } else {
       toast.warn("SELECCIONE UN CLIENTE..");
     }
   }

   const getAllAccountReceiveList = async (data) => {
     try {
       const response = await fetchedAllOperationsAccountReceiveByClient(data);
       //console.log(response);       
       props.setOperationsReceiveClient(response.AccountReceiveOperationsClient);
       //console.log(props.operationsReceiveClient);
       return response;
     } catch (error) {
       throw error;
     }
   };


   useEffect(() => {
     getClientsWithDebt();
   }, []);

   
  return (
    <div>
      <div className="container-fluid pl-0 pr-3">
        <div className="card-header text-left mt-2 mb-1 p-1 ml-2 bg-light">
          <div className="row mr-3 ml-0 mt-0">
            <div className="col-sm-12 p-0">
              <div className="card-body text-center font-weight-bolder">
                <h2>ESTADO DE CUENTA</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body text-center font-weight-bolder pl-2">
        <div className="card bg-light ">
          <div className="card-body">
            <div className="row pb-1">
              <div className="col-auto">
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-auto col-form-label"
                  >
                    Fecha Inicial:
                  </label>
                  <div className="col-auto">
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={props.dates.startDate}
                      onChange={handleSelectDateChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-auto">
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-auto col-form-label"
                  >
                    Fecha Final:
                  </label>
                  <div className="col-auto">
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={props.dates.endDate}
                      onChange={handleSelectDateChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-4">
                <Select
                  options={options}
                  styles={customStyles}
                  onChange={(e) => handleSelectChange(e)}
                />
              </div>

              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={searchAccountsReceiveList}
                  id="btn-search"
                >
                  Buscar <i className="fas fa-search" />
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
