import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export function MerchandiseList({orders, questionState}) {
    const [items, setItems] = useState([]);
  return (
    <> 
    <table className="table table-sm table-bordered table-striped text-center mt-3" id="table-to-xls">
        <thead>
          <tr className='text-uppercase small'>

            <td className="align-middle bg-navy" >#</td>
            <td className="align-middle bg-navy" >Sku</td>
            <td className="align-middle bg-navy" >Producto</td>
            <td className="align-middle bg-navy" >Unidad</td>

            <td className="align-middle bg-navy" >Precio</td>
            <td className="align-middle bg-maroon" >Inicial</td>
            {(questionState.warehouseID==3)?(
              <td className="align-middle bg-yellow" >Valorizado</td>
            ):(
              <>
              <td className="align-middle bg-yellow" >Abastecido</td>
            <td className="align-middle bg-yellow" >Devuelto</td>
            <td className="align-middle bg-yellow" >Transferido</td>
            <td className="align-middle bg-yellow" >Reajuste<br/>Entrada</td>
            <td className="align-middle bg-yellow" >Reajuste<br/>Salida</td>
            <td className="align-middle bg-blue" >Total enviado</td>
            <td className="align-middle bg-orange" >Vendido</td>
            <td className="align-middle bg-orange" >Valorizado<br/>Vendido</td>
            <td className="align-middle bg-green" >Total Stock</td>
            <td className="align-middle bg-green" >Stock<br/>Valorizado</td>
            <td className="align-middle bg-indigo" >Contabilizado</td>
            <td className="align-middle bg-cyan" >Faltante o<br/>sobrante</td>
            <td className="align-middle bg-cyan" >Valorizado<br/>Faltante o<br/>sobrante</td>
              </>
            )}
            

          </tr>
        </thead>
        <tbody>
          {orders.map((o, index) => {
            return (
              <tr key={index} value={o.productID}>
                <td className="align-middle">{o.productCode}</td>
                <td className="align-middle">{o.productSku}</td>
                <td className="align-middle text-left small">{(questionState.warehouseID==3)?(o.productPurchaseName):(o.productSaleName)}</td>
                <td className="align-middle small">{(questionState.warehouseID==3)?(o.unitNamePurchase):(o.unitName)}</td>
                <td className="align-middle text-nowrap">S/ {(questionState.warehouseID==3)?(Number(o.pricePurchase).toFixed(2)):(Number(o.priceSale).toFixed(2))}</td>
              
              
                <td className="align-middle bg-maroon">{(questionState.warehouseID==3)?(o.stockInBox):(o.stockStarted)}</td>
                {(questionState.warehouseID==3)?(
                  <td className="align-middle bg-yellow">S/ {Number(o.valuedResiduaryPurchase).toFixed(2)}</td>
                ):(
                  <>
                    <td className="align-middle bg-yellow">{o.stockSupplied}</td>
                    <td className="align-middle bg-yellow">{o.stockReturned}</td>
                    <td className="align-middle bg-yellow">{o.stockTransferred}</td>
                    <td className="align-middle bg-yellow">{o.stockReadjustedInput}</td>
                    <td className="align-middle bg-yellow">{o.stockReadjustedOutput}</td>
                    <td className="align-middle bg-blue">{o.stockTotalDelivered}</td>             
                    <td className="align-middle bg-orange">{o.stockSold}</td>             
                    <td className="align-middle bg-orange text-right">S/ {Number(o.valuedSold).toFixed(2)}</td>             
                    <td className="align-middle bg-success">{o.stockResiduary}</td>             
                    <td className="align-middle bg-success text-right">S/ {Number(o.valuedResiduary).toFixed(2)}</td>             
                    <td className="align-middle bg-indigo text-right">{o.stockChecked}</td>             
                    <td className="align-middle bg-cyan text-right">{o.stockResiduary - o.stockChecked}</td>             
                    <td className="align-middle bg-cyan text-right">S/  {Number(Number(o.stockResiduary - o.stockChecked) * Number(o.priceSale)).toFixed(2) }</td>      
                  </>
                )}
                       
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="align-middle text-right" colSpan={13}>TOTAL</td>
            <td className="align-middle text-right">S/ {Number(orders.reduce((previousValue, currentValue) => previousValue + currentValue.valuedSold, 0)).toFixed(2)}</td>
            <td className="align-middle text-right"></td>
            <td className="align-middle text-right">S/ {Number(orders.reduce((previousValue, currentValue) => previousValue + currentValue.valuedResiduary, 0)).toFixed(2)}</td>
            <td className="align-middle text-right"></td>
            <td className="align-middle text-right"></td>
            <td className="align-middle text-right">S/ {Number(orders.reduce((previousValue, currentValue) => previousValue + ((currentValue.stockResiduary - currentValue.stockChecked) * Number(currentValue.priceSale)), 0)).toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
      <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-outline-secondary mt-3 ml-1"
                    table="table-to-xls"
                    filename="report_of_sales"
                    sheet="report"
                    buttonText="Descargar como XLS"/>
    </>
  )
}
