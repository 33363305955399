import React, { useState, useEffect } from "react";
import { useHooksPpe } from "../../../hooks";
import swal from "sweetalert2";

const initialState = {
  ppeID: 0,
  ppeName: "",
  ppeDescription: "",
};

export function FragmentAddEditPpe(props) {
  const { onRefetch, ppe } = props;
  const { addPpe, updatePpe } = useHooksPpe();
  const [itemPpe, setItemPpe] = useState(initialState);
  useEffect(() => {
    if (ppe.ppeID === undefined || ppe.ppeID === 0) {
      setItemPpe(initialState);
    } else {
      setItemPpe(ppe);
    }
  }, [ppe]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    const newValues = {
      ...itemPpe,
      [name]: value,
    };
    setItemPpe(newValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.ppeName.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese el nombre del EPP!",
        //footer: e.target.document,
      });
      return false;
    }
    try {
      if (ppe.ppeID === undefined || ppe.ppeID === 0) {
        await addPpe(itemPpe);
        swal.fire({
          position: "center",
          icon: "success",
          title: "EPP registrada correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        await updatePpe(itemPpe);
        swal.fire({
          position: "center",
          icon: "success",
          title: "EPP actualizada correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      document.getElementById("close-modal").click();
      onRefetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        id="form-ppe"
        className="embed-responsive p-1"
        onSubmit={handleSubmit}
        style={{ fontSize: 13 }}
      >
        <div className="card border-primary m-0">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">Nombre EPP</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="ppeName"
                      maxLength={200}
                      value={itemPpe.ppeName}
                      onChange={handleChange}
                      placeholder="Nombre EPP"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Descripcción EPP
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="ppeDescription"
                      maxLength={200}
                      value={itemPpe.ppeDescription}
                      onChange={handleChange}
                      placeholder="Descripción EPP"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 pt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-success w-50"
                    >
                      <i className="fas fa-check-circle" />{" "}
                      {ppe.ppeID ? "Actualizar EPP" : "Registrar EPP"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
