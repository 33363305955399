import { LayoutsHome } from "../layouts";

import { ProductPage } from "../modules/modules.logistics/pages.logistics/ProductPage";
import { ProductBrandPage } from "../modules/modules.logistics/pages.logistics/ProductBrandPage";
import { WarehousePage } from "../modules/modules.logistics/pages.logistics/WarehousePage";
import { InventoryPage } from "../modules/modules.logistics/pages.logistics/InventoryPage";
import { MerchandisePage } from "../modules/modules.logistics/pages.logistics/MerchandisePage";
import { MatrixPage } from "../modules/modules.logistics/pages.logistics/MatrixPage";

const routesLOGISTICS = [
  {
    path: "/logistics/products",
    layout: LayoutsHome,
    component: ProductPage,
  },
  {
    path: "/logistics/product_brands",
    layout: LayoutsHome,
    component: ProductBrandPage,
  },
  {
    path: "/logistics/warehouses",
    layout: LayoutsHome,
    component: WarehousePage,
  },
  {
    path: "/logistics/inventories",
    layout: LayoutsHome,
    component: InventoryPage,
  },
  {
    path: "/logistics/merchandises",
    layout: LayoutsHome,
    component: MerchandisePage,
  },
  {
    path: "/logistics/matrix",
    layout: LayoutsHome,
    component: MatrixPage,
  },
];
export default routesLOGISTICS;
