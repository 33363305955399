import React, { useState, useContext, useEffect } from "react";
import { fetchedPreSalesByAction, fetchedUsersByGang } from "../../../apis/apis.dispatches/apisDispatch";

import { fetchedGangs } from "../../../apis/apis.logistics/apisProduct";

import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function OrderTrayFilter({setOrders, gangs, setGangs, users, setUsers, dailyRouteStatus, setDailyRouteStatus, setFilename}) {
    const { auth } = useContext(ContextAuth);
    const [questionState, setQuestionState] = useState({
        startDate: getCurrentDate("-"),
        endDate: getCurrentDate("-"),
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
        warehouseID: 0,
        gangID: 0,
        userID: 0,
        dailyRouteStatus: "04",
        includeDates: false
    });


    const getGangs = async () => {
        try {
          const response = await fetchedGangs();
          setGangs(response);
          // console.log(response)
        } catch (error) {
          throw error;
        }
    };

    const getUsersByGang = async (id) => {
    try {
        const response = await fetchedUsersByGang({
        gangID: id,
        });
        setUsers(response);
    } catch (error) {
        throw error;
    }
    };

    const sendFilterDispatches = async (data) => {
    try {
        const response = await fetchedPreSalesByAction(data);
        console.log(response) 
        setOrders(response)
        return response;
    } catch (error) {
        throw error;
    }
    };

    function handleChange(e) {
      setQuestionState({ ...questionState, [e.target.name]: e.target.value });
      if(e.target.name=="gangID"){
          const select = document.getElementById("gangID");

          const selectedIndex = select.selectedIndex;
          const selectedValue = select.value;
          const selectedText = select.options[selectedIndex].text;  
          setFilename("ReporteVentas_"+selectedText.replace(/\s/g, '') +"_"+getCurrentDate("-"));
          getUsersByGang(e.target.value);
      }
    }

    const handleQuery = (e) => {
    searchSuggestions();
    
    };

    function searchSuggestions() {
    sendFilterDispatches(questionState).then((res) => {
        let results = res.length;
        toast.info(
        results > 1
            ? "Se encontraron " + results.toString() + " resultados."
            : "Se encontró " + results.toString() + " resultado(s)."
        );
        setOrders(res);
    });
    }

    useEffect(() => {
    getGangs();
    }, []);

    return (
      <>
        <div className="row mb-2">

            <div className="col-lg-3">
              <label className="">RUTA:</label>
            </div>

            <div className="col-lg-3">
                <select
                    className="form-control"
                    id="gangID"
                    name="gangID"
                    value={questionState.gangID}
                    onChange={handleChange}
                >
                  <option value={0}>Elegir</option>
                    {gangs.map((item, index) => {
                      return (
                        <option key={index} value={item.gangID}>{item.gangName} | {item.warehouseName}</option>
                      );
                    })}
                </select>
            </div>

            <div className="col-lg-3">
              <label className="">USUARIO:</label>
            </div>
            
            <div className="col-lg-3">
                <select
                    className="form-control"
                    name="userID"
                    value={questionState.userID}
                    onChange={e=>{setQuestionState({ ...questionState, userID: e.target.value });}}
                >
                  <option value={0}>Todos</option>
                    {users.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.first_name} | {item.last_name}
                        </option>
                      );
                    })}
                </select>
            </div>
       
        </div>
        <div className="row mb-2">
          
        
          <div className="col-lg-3">
            <label className="">ESTADO DE ENTREGA:</label>
          </div>

          <div className="col-lg-3">
              <select
                  className="form-control"
                  name="dailyRouteStatus"
                  value={questionState.dailyRouteStatus}
                  onChange={e=>{setQuestionState({ ...questionState, dailyRouteStatus: e.target.value });}}
              >
                  {dailyRouteStatus.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
          </div>

          
          <div className="col-lg-3">
            <input
              type="date"
              className="form-control"
              id="startDate"
              name="startDate"
              disabled={!questionState.includeDates}
              value={questionState.startDate}
              onChange={handleChange}
            />
          </div>


          <div className="col-lg-3">
            <input
              type="date"
              className="form-control"
              id="endDate"
              name="endDate"
              disabled={!questionState.includeDates}
              value={questionState.endDate}
              onChange={handleChange}
            />
          </div>
       
        </div>
        <div className="row">

          <div className="col-lg-3">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="Y" id="flexCheckDefault" name="includeDates" defaultChecked={questionState.includeDates} onChange={e=>{
                  setQuestionState({ ...questionState, [e.target.name]: !questionState.includeDates });
                  // if(!questionState.includeDates){}
                    document.getElementById("startDate").disabled = !questionState.includeDates;
                    document.getElementById("endDate").disabled = !questionState.includeDates;
                  
                  
                }}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                incluir filtro de fecha
              </label>
            </div>
          </div>

          <div className="col-lg-3">
            <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
            <i className="fas fa-search"></i> Buscar
            </button>
          </div>

      </div>
    </>
    )
}