import React, { useEffect, useContext, useState } from "react";
import { getCurrentDate } from "../../../configs/helpers";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function AccountPayList(props) {

  return (
    <>
      <table className="table table-striped table-sm ">
        <thead>
          <tr>
            <th className="text-center">
              <h4 className="font-weight-bold">
                {" SALDO ACTUAL: "}
                S/ {Number(props.supplierItem.supplierAmount).toFixed(2)}
              </h4>
            </th>
          </tr>
        </thead>
      </table>

      <table className="table table-bordered table-striped table-sm bg-white">
        <thead>
          <tr>
            <th className="text-center">FECHA OPERACIÓN</th>
            <th className="text-center">RESPONSABLE</th>
            {/*<th className="text-center">NRO DOCUMENTO</th>*/}
            <th className="text-center">PROVEEDOR</th>
            <th className="text-center">MOVIMIENTOS</th>
            {/*<th className="text-center">HABER</th>*/}            
            <th className="text-center" style={{ width: "30%" }}>
              DESCRIPCIÓN
            </th>            
            <th className="text-center">ACCIÓN</th>
          </tr>
        </thead>
        <tbody>
          {props.operationsAccountpay.map((item, index) => {
            return (
              <tr key={index} value={item.cashFlowID}>
                <td className="text-uppercase text-center">
                  {item.transactionDate}
                </td>
                <td className="text-uppercase text-center">{item.userName}</td>
                {/*<td className="text-uppercase text-center">
                  {item.operationNumber}
            </td>*/}
                <td className="text-uppercase text-center">
                  {item.operationSupplierName}
                </td>
                <td
                  className={
                    item.typeOperation == "DC"
                      ? "text-right font-weight-bold text-danger"
                      : "text-right font-weight-bold"
                  }
                >
                  {item.typeOperation == "HC"
                    ? "S/ " + item.total.toFixed(2)
                    : "S/ -" + item.total.toFixed(2)}
                </td>

                <td className="text-center">{item.description}</td>
                <td className="text-center">
                  <button
                    className="btn btn-link"
                    data-toggle="modal"
                    data-target="#modal-banks-operations"
                    /*onClick={(e) => {
                      props.setSelectedBankCashFlow(item);
                      //console.log("item", item);
                    }}*/
                  >
                    <i className="text-success fas fa-edit"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
