export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "#f8f9fa" : "#343a40",
    padding: ".25rem .5rem",
    height: "calc(1.8125rem + 2px)",
    lineHeight: "1.5"
  }),
  /*control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },*/
};

export const readjustmentModeOptions = [
  {id:"E", name:"ENTRADA"}, 
  {id:"S", name:"SALIDA"}, 
];

export const classificationOptions = [
  {id:"O", name:"ORO"}, 
  {id:"P", name:"PLATA"}, 
  {id:"B", name:"BRONCE"},
  {id:"PP", name:"PROMOCIONAL"},
];

export const igvOptions = [
  { id: "01", name: "INCLUIDO IGV" },
  { id: "02", name: "MAS IGV" },
  { id: "NA", name: "NO APLICA" },
];

export const personDocumentTypes = [
  { id: "01", name: "DNI" },
  { id: "06", name: "RUC" },
  { id: "NA", name: "NO APLICA" },
];

export const operationActions = [
  { id: "E", name: "ENTRADA" },
  { id: "S", name: "SALIDA" },
  { id: "NA", name: "NO APLICA" },
];

export const operationTypes = [
  { id: "01", name: "Compra" },
  { id: "02", name: "Venta" },
  //{ id: "03", name: "Guia" },
  //{ id: "04", name: "Reparto - Abastecimiento" },
  //{ id: "05", name: "Reparto - Devolucion" },
  //{ id: "06", name: "Logistica - Inventario Inicial" },
  //{ id: "07", name: "Logistica - Cuadre de Inventario" },
  { id: "08", name: "Orden de compra" },
  { id: "NA", name: "NO APLICA" },
];

export const warehouses = [
  { warehouseID: "3", warehouseName: "ALM VENTAS", category: "03" },
  { warehouseID: "2", warehouseName: "ALM MERCADERIA", category: "01" },
];

export const operationDocumentTypes = [
  { id: "01", name: "Ticket" },
  { id: "02", name: "Boleta" },
  { id: "03", name: "Factura" },
  { id: "04", name: "Guia" },
  { id: "NA", name: "NO APLICA" },
];

export const persons = [
  {
    personID: 14,
    personName: "CRISTHIAN IRVING",
    personFirstSurname: "VALENCIA",
    personSecondSurname: "BAZAN",
    fiscalAddress: "",
    documentType: "01",
    documentNumber: "45454545",
    is_client: true,
    is_supplier: false,
  },
  {
    personID: 15,
    personName: "AREQUIPA EXPRESO MARVISUR EIRL",
    personFirstSurname: "",
    personSecondSurname: "",
    fiscalAddress:
      "CAL.GARCI CARBAJAL NRO. 511 URB. IV CENTENARIO AREQUIPA - AREQUIPA - AREQUIPA",
    documentType: "06",
    documentNumber: "20498189637",
    is_client: true,
    is_supplier: false,
  },
  {
    personID: 16,
    personName: "LECHE GLORIA SOCIEDAD ANONIMA – GLORIA S.A.",
    personFirstSurname: "",
    personSecondSurname: "",
    fiscalAddress: "Av. Republica de Panama Nro. 2461",
    documentType: "06",
    documentNumber: "20100190797",
    is_client: false,
    is_supplier: true,
  },
  {
    personID: 17,
    personName: "UNIVERSIDAD TECNOLOGICA DEL PERU S.A.C. O UTP S.A.C.",
    personFirstSurname: "",
    personSecondSurname: "",
    fiscalAddress: "Cal. Natalio Sanchez Nro. 125",
    documentType: "06",
    documentNumber: "20462509236",
    is_client: true,
    is_supplier: false,
  },
  {
    personID: 18,
    personName: "SOCIEDAD MINERA CERRO VERDE S.A.A.",
    personFirstSurname: "",
    personSecondSurname: "",
    fiscalAddress: "Cal. Jacinto Ibañez Nro. 315",
    documentType: "06",
    documentNumber: "20170072465",
    is_client: false,
    is_supplier: true,
  },
  {
    personID: 19,
    personName: "EDWIN",
    personFirstSurname: "ZEBALLOS",
    personSecondSurname: "HUAMAN",
    fiscalAddress: "",
    documentType: "01",
    documentNumber: "45454544",
    is_client: false,
    is_supplier: true,
  },
  {
    personID: 20,
    personName: "RENE AURESTELIA",
    personFirstSurname: "VELIZ",
    personSecondSurname: "REGALADO",
    fiscalAddress: "",
    documentType: "01",
    documentNumber: "45454543",
    is_client: true,
    is_supplier: false,
  },
  {
    personID: 21,
    personName: "RUBI YERISF",
    personFirstSurname: "SAJAMI",
    personSecondSurname: "CEOPA",
    fiscalAddress: "",
    documentType: "01",
    documentNumber: "45454542",
    is_client: true,
    is_supplier: false,
  },
];

export const operations = [
  {
    clientDocumentNumber: "45454545",
    clientDocumentType: "01",
    clientFirstSurname: "VALENCIA",
    clientFiscalAddress: "",
    clientID: "0",
    clientName: "CRISTHIAN IRVING",
    clientSecondSurname: "BAZAN",
    details: [
      {
        grammage: "95",
        price: 1,
        productID: "4",
        productName: "CHIZITOS 150GX48X1",
        productTariffID: 1,
        quantity: "1",
        sku: "KU8000",
        subtotal: "1.00",
        tariffs: [
          {
            productTariffID: 1,
            unitID: "1",
            unitName: "UND",
            price: 1,
            quantityMinimum: 1,
          },
          {
            productTariffID: 2,
            unitID: "3",
            unitName: "DOC",
            price: 10,
            quantityMinimum: 12,
          },
          {
            productTariffID: 3,
            unitID: "4",
            unitName: "TIR",
            price: 9,
            quantityMinimum: 10,
          },
          {
            productTariffID: 4,
            unitID: "2",
            unitName: "CJ",
            price: 60,
            quantityMinimum: 50,
          },
        ],
        unitName: "UND",
      },
      {
        grammage: "90",
        price: 0.8,
        productID: "5",
        productName: "CHOKIS CHISPAS 37GX16X6 PER",
        productTariffID: 5,
        quantity: "1",
        sku: "KU9000",
        subtotal: "0.80",
        tariffs: [
          {
            productTariffID: 5,
            unitID: "1",
            unitName: "UND",
            price: 0.8,
            quantityMinimum: 1,
          },
          {
            productTariffID: 6,
            unitID: "2",
            unitName: "CJ",
            price: 50,
            quantityMinimum: 70,
          },
        ],
        unitName: "UND",
      },
    ],
    isClient: true,
    isSupplier: false,
    operationActionID: "E",
    operationAmountBase: 1.8,
    operationAmountIgv: "0",
    operationAmountTotal: 1.8,
    operationDocumentNumber: "",
    operationDocumentTypeID: "01",
    operationTypeID: "02",
    supplierDocumentNumber: "",
    supplierDocumentType: "NA",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierName: "",
    supplierSecondSurname: "",
    warehouseCategory: "03",
    warehouseID: "1",
    warehouseName: "ALM VENTAS",
  },
];


export const productsWithTariffs = [
  /*{
      productID: "0",
      productName: "Elegir",
      productGrammage: "",
      productSku: "",
      productTariff: []
    },*/
  {
    productID: "4",
    purchaseName: "CHIZITOS 150GX48X1",
    productGrammage: "95",
    productSku: "KU8000",
    productTariff: [
      {
        productTariffID: 1,
        unitID: "1",
        unitName: "UND",
        price: 1.0,
        quantityMinimum: 1,
      },
      {
        productTariffID: 2,
        unitID: "3",
        unitName: "DOC",
        price: 10.0,
        quantityMinimum: 12,
      },
      {
        productTariffID: 3,
        unitID: "4",
        unitName: "TIR",
        price: 9.0,
        quantityMinimum: 10,
      },
      {
        productTariffID: 4,
        unitID: "2",
        unitName: "CJ",
        price: 60.0,
        quantityMinimum: 50,
      },
    ],
  },
  {
    productID: "5",
    purchaseName: "CHOKIS CHISPAS 37GX16X6 PER",
    productGrammage: "90",
    productSku: "KU9000",
    productTariff: [
      {
        productTariffID: 5,
        unitID: "1",
        unitName: "UND",
        price: 0.8,
        quantityMinimum: 1,
      },
      {
        productTariffID: 6,
        unitID: "2",
        unitName: "CJ",
        price: 50.0,
        quantityMinimum: 70,
      },
    ],
  },
];

const subsidiaries = [
  { subsidiaryID: 1, name: "PRINCIPAL" },
  { subsidiaryID: 2, name: "SEDE 2" },
];

const inventories = [
  {
    operationID: 1,
    operationDate: "10/04/2022",
    operationAction: "E",
    operationType: "01",
    quantity: 45,
    priceUnit: 1,
    priceTotal: 1,
    remainingQuantity: 45,
  },
  {
    operationID: 2,
    operationDate: "10/04/2022",
    operationAction: "S",
    operationType: "02",
    quantity: 1,
    priceUnit: 1,
    priceTotal: 1,
    remainingQuantity: 44,
  },
  {
    operationID: 3,
    operationDate: "10/04/2022",
    operationAction: "S",
    operationType: "02",
    quantity: 2,
    priceUnit: 1,
    priceTotal: 1,
    remainingQuantity: 42,
  },
];


const orderList = [
  {
    operationID: 9,
    operationDate: "2022-04-09",
    operationDocumentNumber: "",
    operationCorrelative: 1000,
    operationCode: "08",
    percentageIgv: 18,
    conditionIgv: "02",
    operationNumberItems: 4,
    operationItems: [
      {
        operationID: 9,
        operationDetailID: 9,
        productTariffID: 71,
        productID: 7,
        productName: "CHEETOS HORNEADOS QUESO 74GX32X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 51.2,
        priceSale: 60.42,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 5,
        quantity: 0,
        price: 0,
        amount: 256.0,
        discount: 28.16,
        amountDiscount: 227.84,
        igv: 41.0112,
        amountIgv: 268.8512,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 9,
        operationDetailID: 10,
        productTariffID: 72,
        productID: 40,
        productName: "LOS CUATES RANCHERITOS 63GX9X6 TIR",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 16.8,
        pricePurchase: 38.34,
        priceSale: 45.24,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 5,
        quantity: 0,
        price: 0,
        amount: 191.7,
        discount: 32.2056,
        amountDiscount: 159.4944,
        igv: 28.709,
        amountIgv: 188.2034,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 9,
        operationDetailID: 11,
        productTariffID: 73,
        productID: 7,
        productName: "CHEETOS HORNEADOS QUESO 74GX32X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 40.7,
        priceSale: 48.03,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 10,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 9,
        operationDetailID: 12,
        productTariffID: 74,
        productID: 4,
        productName: "CHEESE TRIS QUESO 39GX96X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 15,
        pricePurchase: 68.16,
        priceSale: 80.43,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 20,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 9,
        operationDetailID: 13,
        productTariffID: 75,
        productID: 32,
        productName: "PIQUEO SNAX 225GX10X1 PA",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 48.5,
        priceSale: 57.23,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 6,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 9,
        operationDetailID: 14,
        productTariffID: 76,
        productID: 14,
        productName: "DORITOS FLAMIN HOT 39GX12X6 TIR",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 16,
        pricePurchase: 66.24,
        priceSale: 78.16,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 5,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
    ],
    operationStatus: "01",
    operationStatusDisplay: "PENDIENTE",
    operationActionID: "NA",
    operationTypeID: "08",
    operationDocumentTypeID: "03",
    operationAmountBase: "",
    operationAmountIgv: "",
    operationAmountTotal: "",
    supplierDocumentType: "06",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierSecondSurname: "",
    supplierDocumentNumber: "20462509236",
    supplierName: "UNIVERSIDAD TECNOLOGICA DEL PERU S.A.C. O UTP S.A.C.",
    warehouseID: 0,
    warehouseName: "",
    warehouseCategory: "",
    userID: 0,
  },
  {
    operationID: 10,
    operationDate: "2022-04-09",
    operationDocumentNumber: "",
    operationCorrelative: 1001,
    operationCode: "08",
    percentageIgv: 18,
    conditionIgv: "02",
    operationNumberItems: 3,
    operationItems: [
      {
        operationID: 10,
        operationDetailID: 15,
        productTariffID: 77,
        productID: 40,
        productName: "LOS CUATES RANCHERITOS 63GX9X6 TIR",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 16.8,
        pricePurchase: 38.34,
        priceSale: 45.24,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 5,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 10,
        operationDetailID: 16,
        productTariffID: 78,
        productID: 7,
        productName: "CHEETOS HORNEADOS QUESO 74GX32X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 40.7,
        priceSale: 48.03,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 10,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 10,
        operationDetailID: 17,
        productTariffID: 79,
        productID: 4,
        productName: "CHEESE TRIS QUESO 39GX96X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 15,
        pricePurchase: 68.16,
        priceSale: 80.43,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 20,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
    ],
    operationStatus: "08",
    operationStatusDisplay: "RECIBIDA",
    operationActionID: "NA",
    operationTypeID: "08",
    operationDocumentTypeID: "03",
    operationAmountBase: "",
    operationAmountIgv: "",
    operationAmountTotal: "",
    supplierDocumentType: "06",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierSecondSurname: "",
    supplierDocumentNumber: "20370816710",
    supplierName: "FARMACEUTICA DEL SUR S.R.L.",
    warehouseID: 0,
    warehouseName: "",
    warehouseCategory: "",
    userID: 0,
  },
  {
    operationID: 11,
    operationDate: "2022-04-09",
    operationDocumentNumber: "",
    operationCorrelative: 1002,
    operationCode: "08",
    percentageIgv: 18,
    conditionIgv: "02",
    operationNumberItems: 2,
    operationItems: [
      {
        operationID: 11,
        operationDetailID: 18,
        productTariffID: 80,
        productID: 7,
        productName: "CHEETOS HORNEADOS QUESO 74GX32X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 40.7,
        priceSale: 48.03,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 10,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 11,
        operationDetailID: 19,
        productTariffID: 81,
        productID: 4,
        productName: "CHEESE TRIS QUESO 39GX96X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 15,
        pricePurchase: 68.16,
        priceSale: 80.43,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 20,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
    ],
    operationStatus: "01",
    operationStatusDisplay: "PENDIENTE",
    operationActionID: "NA",
    operationTypeID: "08",
    operationDocumentTypeID: "03",
    operationAmountBase: "",
    operationAmountIgv: "",
    operationAmountTotal: "",
    supplierDocumentType: "06",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierSecondSurname: "",
    supplierDocumentNumber: "20601763789",
    supplierName: "UNIDAD DE GESTION EDUCATIVA LOCAL  ISLAY",
    warehouseID: 0,
    warehouseName: "",
    warehouseCategory: "",
    userID: 0,
  },
  {
    operationID: 12,
    operationDate: "2022-04-10",
    operationDocumentNumber: "",
    operationCorrelative: 1003,
    operationCode: "08",
    percentageIgv: 18,
    conditionIgv: "02",
    operationNumberItems: 1,
    operationItems: [
      {
        operationID: 12,
        operationDetailID: 20,
        productTariffID: 82,
        productID: 40,
        productName: "LOS CUATES RANCHERITOS 63GX9X6 TIR",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 16.8,
        pricePurchase: 38.34,
        priceSale: 45.24,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 5,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 12,
        operationDetailID: 21,
        productTariffID: 83,
        productID: 7,
        productName: "CHEETOS HORNEADOS QUESO 74GX32X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 40.7,
        priceSale: 48.03,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 10,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 12,
        operationDetailID: 22,
        productTariffID: 84,
        productID: 4,
        productName: "CHEESE TRIS QUESO 39GX96X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 15,
        pricePurchase: 68.16,
        priceSale: 80.43,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 20,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 12,
        operationDetailID: 23,
        productTariffID: 85,
        productID: 32,
        productName: "PIQUEO SNAX 225GX10X1 PA",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 48.5,
        priceSale: 57.23,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 6,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
    ],
    operationStatus: "08",
    operationStatusDisplay: "RECIBIDA",
    operationActionID: "NA",
    operationTypeID: "08",
    operationDocumentTypeID: "03",
    operationAmountBase: "",
    operationAmountIgv: "",
    operationAmountTotal: "",
    supplierDocumentType: "06",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierSecondSurname: "",
    supplierDocumentNumber: "20321775145",
    supplierName: "IGLESIA EVANGELICA LUTERANA - PERU",
    warehouseID: 0,
    warehouseName: "",
    warehouseCategory: "",
    userID: 0,
  },
  {
    operationID: 13,
    operationDate: "2022-04-10",
    operationDocumentNumber: "",
    operationCorrelative: 1004,
    operationCode: "08",
    percentageIgv: 18,
    conditionIgv: "02",
    operationNumberItems: 1,
    operationItems: [
      {
        operationID: 13,
        operationDetailID: 24,
        productTariffID: 86,
        productID: 7,
        productName: "CHEETOS HORNEADOS QUESO 74GX32X1",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 40.7,
        priceSale: 48.03,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 10,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 13,
        operationDetailID: 25,
        productTariffID: 87,
        productID: 32,
        productName: "PIQUEO SNAX 225GX10X1 PA",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 11,
        pricePurchase: 48.5,
        priceSale: 57.23,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 6,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
      {
        operationID: 13,
        operationDetailID: 26,
        productTariffID: 88,
        productID: 14,
        productName: "DORITOS FLAMIN HOT 39GX12X6 TIR",
        productStockMin: 5,
        productStockMax: 1000000,
        productStock: 100,
        percentageDiscount: 16,
        pricePurchase: 66.24,
        priceSale: 78.16,
        unitName: "CJ",
        unitID: 2,
        quantityOrdered: 5,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
      },
    ],
    operationStatus: "03",
    operationStatusDisplay: "ANULADO",
    operationActionID: "NA",
    operationTypeID: "08",
    operationDocumentTypeID: "03",
    operationAmountBase: "",
    operationAmountIgv: "",
    operationAmountTotal: "",
    supplierDocumentType: "06",
    supplierFirstSurname: "",
    supplierFiscalAddress: "",
    supplierID: "0",
    supplierSecondSurname: "",
    supplierDocumentNumber: "20455925020",
    supplierName: "GSB CONSTRUCCIONES Y SERVICIOS AREQUIPA S.A.C.",
    warehouseID: 0,
    warehouseName: "",
    warehouseCategory: "",
    userID: 0,
  },
];
