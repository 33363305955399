import React, { useState, useEffect, useContext, createContext } from "react";
import { SpecialSaleForm } from "../fragments.dispatches/SpecialSaleForm";
import { getCurrentDate } from "../../../configs/helpers";
import { sendFilterSpecialSalesData } from "../../../apis/apis.buys/apisPurchase";
import { toast } from "react-toastify";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const initialRegisterDataState = {
    operationTypeID: "02",
    operationActionID: "S",
    operationDocumentTypeID: "03",
    operationDocumentNumber: "",
    warehouseID: "3",
    gangID: "0",
    warehouseName: "",

    userID: "0",

    operationDate: getCurrentDate("-"),

    clientID: "0",
    clientName: "",
    clientFirstSurname: "",
    clientSecondSurname: "",
    clientDocumentType: "NA",
    clientDocumentNumber: "",
    clientFiscalAddress: "",

    supplierID: "0",
    supplierName: "",
    supplierFirstSurname: "",
    supplierSecondSurname: "",
    supplierDocumentType: "NA",
    supplierDocumentNumber: "",
    supplierFiscalAddress: "",
    details: [],
    operationAmountBase: "0",
    operationAmountIgv: "0",
    operationAmountTotal: "0",
};

export function SpecialSalePage() {
    const [registerData, setRegisterData] = useState(initialRegisterDataState);
    const [questionState, setQuestionState] = useState({
        startDate: getCurrentDate("-"),
        endDate: getCurrentDate("-"),
    });
    const [hostname, setHostname] = useState(null);
    const [filename, setFilename] = useState("ReporteEspecialesVentas");
  useEffect(() => {
    
    if(hostname == null){

      if(window.location.hostname !== "localhost")
        setHostname("https://www.deldiadistribuciones.nom.pe");
      else
        setHostname("http://192.168.1.56:9017");

    }
  }, [hostname]);

    function handleChangeDate(e) {
        setQuestionState({ ...questionState, [e.target.name]: e.target.value });
    }
    const [clients, setClients] = useState([]);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const operationValues = Object.values(orders);
    const clearRegisterDataState = () => {
        setRegisterData({ ...initialRegisterDataState });
        setClients([]);
        // setProducts([]);
    };
    const handleQuery = (e) => {
        searchSuggestions();
    };
    const sendFilterPurchases = async (data) => {
        try {
            const response = await sendFilterSpecialSalesData(data);
            //console.log(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    function searchSuggestions() {
        sendFilterPurchases(questionState).then((res) => {
            let results = res.length;
            toast.info(
                results > 1
                    ? "Se encontraron " + results.toString() + " resultados."
                    : "Se encontró " + results.toString() + " resultado(s)."
            );
            console.log(res)
            setOrders(res);
        });
    }


    const tbodies = operationValues.map((item, i) =>  {
      
        return (
          <tr key={i} className={"bg-"+item.background}>

            <td className="align-middle text-center font-weight-light">{item.operationType=="02"?item.registerDate:""}</td>
            <td className="align-middle text-center font-weight-light">{item.operationType=="02"?item.registerTime:""}</td>
            <td className="align-middle text-center font-weight-light">{item.operationType=="02"?item.userName:""}</td>
            <td className="align-middle text-center font-weight-light">{item.dailyRouteDisplayStatus}</td>
            
            <td className="align-middle text-center font-weight-light">{item.clientObservation}</td>
            <td className="align-middle text-center font-weight-light">{item.clientName}</td>
            <td className="align-middle text-center font-weight-light">{item.clientVisitDayDisplay}</td>
            <td className="align-middle text-center font-weight-light">{item.clientAddress}</td>
            
            <td className="align-middle text-center font-weight-light">{item.receiptSerial}</td>
            <td className="align-middle text-center font-weight-light">{item.receiptNumber}</td>
            <td className="align-middle text-right font-weight-light">S/ {Number(item.total).toFixed(2)}</td>
            <td className="align-middle text-right font-weight-light">
                <div className="d-flex flex-row justify-content-center">


                    
                    {item.dailyRouteStatus=="02"?
                    <>
                                               
                        <a className="btn btn-warning ml-1" href={`${hostname}/dispatches/print_ticket/${item.operationID}/`} target="_blank" title="Descargar PDF" download onClick={e=>{
                            // 1. Find the todo with the provided id
                            const currentTodoIndex = operationValues.findIndex((todo) => todo.operationID === item.operationID);

                            // 2. Mark the todo as complete
                            const updatedTodo = Object.assign({}, operationValues[currentTodoIndex]);
                            updatedTodo.background = "success";

                            // 3. Update the todo list with the updated todo
                            const newTodos = operationValues.slice();
                            newTodos[currentTodoIndex] = updatedTodo;
                            setOrders(newTodos);

                          }}><i className="fas fa-file-pdf"></i></a>

                    </> :""}

                
                </div>
            </td>
          
          </tr>
        );

    });
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>VENTAS ESPECIALES</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Ventas y abastecimiento</a>
                                </li>
                                <li className="breadcrumb-item active">Ventas especiales</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-header">

                            <div className="row d-flex justify-content-end align-items-end">
                                <div className="col-auto">
                                    <input
                                        type="date"
                                        name="startDate"
                                        className="form-control"
                                        value={questionState.startDate}
                                        onChange={handleChangeDate}
                                    />
                                </div>
                                <div className="col-auto">
                                    <input
                                        type="date"
                                        name="endDate"
                                        className="form-control"
                                        value={questionState.endDate}
                                        onChange={handleChangeDate}
                                    />
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
                                        <i className="fas fa-search"></i> Buscar
                                    </button>
                                </div>
                                <div className="col-auto">
                                <button
                                    className="btn btn-dark  font-weight-light"
                                    data-toggle="modal"
                                    data-target="#modal-register-sale-operation"
                                    onClick={() => {
                                        clearRegisterDataState();
                                    }}
                                >
                                    Registrar Venta
                                </button></div>
                            </div>


                        </div>
                        <div className="card-body">
                            
                            
                        <div className="table-responsive">
    <table className="table table-sm table-bordered text-center mt-3" id="table-to-xls">
        <thead>
          
          
          <tr className='text-uppercase'>
           
            <td className="align-middle bg-navy" >
              Fecha
            </td>
            
            <td className="align-middle bg-navy" >
            Hora
            </td>
            <td className="align-middle bg-navy" >
              Usuario
            </td>
            <td className="align-middle bg-navy" >
              Estado
            </td>
            
            
            <td className="align-middle bg-navy" >
              Codigo
            </td>
            
            <td className="align-middle bg-navy" >
              Cliente
            </td>
            
            <td className="align-middle bg-navy" >
              Dia
            </td>
            <td className="align-middle bg-navy" >
              Direccion
            </td>
            
            <td className="align-middle bg-navy" >
              Serie
            </td>
            <td className="align-middle bg-navy" >
              Correlativo
            </td>
            <td className="align-middle bg-navy" style={{width:'8%'}} >
              Total
            </td>
            <td className="align-middle bg-navy" >
              Acciones
            </td>
          </tr>
        </thead>
        <tbody>
          {tbodies}
        </tbody>
        
        <tfoot>
          <tr>
            <td className="text-right font-weight-bold" colSpan={10}>TOTAL</td>
            <td className="text-right font-weight-light">S/ {Number(orders.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0)).toFixed(2)}</td>
            <td className="text-right font-weight-bold"></td>
          </tr>
          
        </tfoot>
      </table>
      </div> 

      <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-outline-secondary mt-3 ml-1"
                    table="table-to-xls"
                    filename={filename}
                    sheet="report"
                    buttonText="Descargar como XLS"/>


                            <SpecialSaleForm registerData={registerData} setRegisterData={setRegisterData} clients={clients} setClients={setClients} 
                            products={products} setProducts={setProducts} clearRegisterDataState={clearRegisterDataState} searchSuggestions={searchSuggestions} />
                            {/* <UnitForm
                        units={units}
                        selectedUnit={selectedUnit}
                        setUnits={setUnits}
                    /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

