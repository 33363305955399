import React, { useState, useContext, useEffect } from "react";
import { fetchedClientPortfolio, fetchedUsersByGang } from "../../../apis/apis.dispatches/apisDispatch";
import { fetchedGangs } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";

const currentYear = new Date().getFullYear();
const years = [currentYear - 1, currentYear, currentYear + 1];
const monthNames = [
  { id: 0, value: "Enero" },
  { id: 1, value: "Febrero" },
  { id: 2, value: "Marzo" },
  { id: 3, value: "Abril" },
  { id: 4, value: "Mayo" },
  { id: 5, value: "Junio" },
  { id: 6, value: "Julio" },
  { id: 7, value: "Agosto" },
  { id: 8, value: "Septiembre" },
  { id: 9, value: "Octubre" },
  { id: 10, value: "Noviembre" },
  { id: 11, value: "Diciembre" },
];

export function ClientPortfolioFilter({ setQuestionState, questionState, setOrders }) {

    const [gangs, setGangs] = useState([]);
    const [users, setUsers] = useState([]);

    function addMonthsToDate(date, monthsToAdd) {
        const newMonth = date.getMonth() + monthsToAdd;
        const newYear = date.getFullYear() + Math.floor(newMonth / 12);
        const newDate = new Date(newYear, newMonth % 12, date.getDate());
        return newDate;
    }

    function handleChange(e) {
        const monthsToAdd = 2; // Cantidad de meses a sumar
        let currentYear, currentMonth;
        if (e.target.name === "startMonth") {
            currentYear = Number(questionState.startYear);
            currentMonth = Number(e.target.value);
            const newDate = addMonthsToDate(
                new Date(currentYear, currentMonth),
                monthsToAdd
            );
            const newYear = newDate.getFullYear();
            const newMonthIndex = newDate.getMonth(); // 0-indexed

            setQuestionState({
                ...questionState,
                [e.target.name]: e.target.value,
                endYear: newYear,
                endMonth: newMonthIndex,
            });
        } else if (e.target.name === "startYear") {
            currentYear = Number(e.target.value);
            currentMonth = Number(questionState.startMonth);
            const newDate = addMonthsToDate(
                new Date(currentYear, currentMonth),
                monthsToAdd
            );
            const newYear = newDate.getFullYear();
            const newMonthIndex = newDate.getMonth(); // 0-indexed

            setQuestionState({
                ...questionState,
                [e.target.name]: e.target.value,
                endYear: newYear,
                endMonth: newMonthIndex,
            });
        } else if(e.target.name === "gangID") {
            getUsersByGang(e.target.value);
            setQuestionState({ ...questionState, [e.target.name]: e.target.value });
        } else {
            setQuestionState({ ...questionState, [e.target.name]: e.target.value });
        }

    // const newMonthName = getMonthName(newMonthIndex);
    // console.log(`Sumando ${monthsToAdd} meses a ${currentMonth}-${currentYear}, obtenemos: ${newMonthName}-${newYear}`);
    }

    const handleQuery = (e) => {
        // if(questionState.userID  === 0){
        //     toast.info("Seleccione vendedor");
        //     return
        // }
        if(questionState.startMonth  === questionState.endMonth && questionState.startYear === questionState.endYear){
            toast.info("Seleccione mes");
            return
        }
        searchSuggestions();
    };

    function searchSuggestions() {
        sendFilterClientPortfolio(questionState).then((res) => {
            let results = res.length;
            toast.info(
            results > 1
                ? "Se encontraron " + results.toString() + " resultados."
                : "Se encontró " + results.toString() + " resultado(s)."
            );
            setOrders(res);
        });
    }

    const getGangs = async () => {
        try {
          const response = await fetchedGangs();
          setGangs(response);
        } catch (error) {
          throw error;
        }
    };

    const getUsersByGang = async (id) => {
        try {
            const response = await fetchedUsersByGang({
            gangID: id,
            });
            setUsers(response);
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        getGangs();
    }, []);

      
    const sendFilterClientPortfolio = async (data) => {
        try {
          const response = await fetchedClientPortfolio(data);
        //   setOrders(response)
          // console.log(response)
          return response;
        } catch (error) {
          throw error;
        }
      };
    return (
        <>
        <div className="row mb-3">
            <div className=" col-auto d-flex align-items-center">
                <label className="m-0">CADA 3 MESES:</label>
            </div>
            <div className=" col-auto">
                <div className="input-group">
                    <div className="input-group-prepend">
                    <select
                        className="form-control"
                        name="startYear"
                        value={questionState.startYear}
                        onChange={handleChange}
                    >
                        {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                        ))}
                    </select>
                    </div>

                    <select
                    className="custom-select"
                    name="startMonth"
                    value={questionState.startMonth}
                    onChange={handleChange}
                    >
                    {monthNames.map((m, i) => (
                        <option key={i} value={m.id}>
                        {m.value}
                        </option>
                    ))}
                    </select>
                </div>
            </div>

            <div className=" col-auto">
                <div className="input-group">
                    <div className="input-group-prepend">
                    <select
                        className="custom-select"
                        name="endYear"
                        value={questionState.endYear}
                        onChange={handleChange}
                        disabled
                    >
                        {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                        ))}
                    </select>
                    </div>
                    <select
                    className="custom-select"
                    name="endMonth"
                    value={questionState.endMonth}
                    onChange={handleChange}
                    disabled
                    >
                    {monthNames.map((m, i) => (
                        <option key={i} value={m.id}>
                        {m.value}
                        </option>
                    ))}
                    </select>
                </div>
            </div>


            <div className="col-auto d-flex align-items-center">
                <label className="m-0">MES A COMPARAR:</label>
            </div>

            <div className="col-1">
                <select
                    className="form-control"
                    id="monthToCompare"
                    name="monthToCompare"
                    value={questionState.monthToCompare}
                    onChange={handleChange}
                >
                    {monthNames.map((m, i) => (
                        <option key={i} value={m.id}>
                        {m.value}
                        </option>
                    ))}
                </select>
            </div>



            <div className="col-auto d-flex align-items-center">
                <label className="m-0">RUTA:</label>
            </div>

            <div className="col-1">
                <select
                    className="form-control"
                    id="gangID"
                    name="gangID"
                    value={questionState.gangID}
                    onChange={handleChange}
                >
                <option value={0}>TODOS</option>
                    {gangs.map((item, index) => {
                    return (
                        <option key={index} value={item.gangID}>{item.gangName}</option>
                    );
                    })}
                </select>
            </div>


            <div className="col-auto d-flex align-items-center">
                <label className="m-0">VENDEDOR:</label>
            </div>

            <div className="col-2">
                <select
                    className="form-control"
                    name="userID"
                    value={questionState.userID}
                    onChange={e=>{setQuestionState({ ...questionState, userID: Number(e.target.value) });}}
                >
                <option value={0}>TODOS</option>
                    {users.map((item, index) => {
                    return (
                        <option key={index} value={item.id}>
                        {item.first_name} | {item.last_name}
                        </option>
                    );
                    })}
                </select>
            </div>

            <div className="col-auto">
                <button className="btn btn-outline-secondary" onClick={handleQuery} id="btn-filter">
                    <i className="fas fa-search"></i> Buscar
                </button>
            </div>
        </div>
        </>
    );
}
