import React, { useState, useEffect, useContext, createContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { sendProductBrandData } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";

const initialState = {
  productBrandID: 0,
  name: ""
};

export function ProductBrandForm() {
  const { itemProductBrand, brands, setItemProductBrand, setBrands } =
    useContext(GlobalContext);

  const [item, setItem] = useState(initialState);

  useEffect(() => {
    const productBrandFound = brands.find(
      (item2) => item2.productBrandID === itemProductBrand.productBrandID
    );
    if (productBrandFound) {
      setItem({
        productBrandID: productBrandFound.productBrandID,
        name: productBrandFound.name,
      });
    } else {
      setItem(initialState);
    }
  }, [itemProductBrand]);

  function handleChange(e) {
    setItem({ ...item, [e.target.name]: e.target.value });
  }

  const saveProductBrand = async (data) => {
    try {
      const response = await sendProductBrandData(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const updateProductBrands = brands.map((brand) => {
    if (brand.productBrandID === item.productBrandID) {
      var newItem = Object.assign({}, brand);
      newItem.name = item.name;
      return newItem;
    } else {
      return brand;
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

      if (item.productBrandID == 0) {
        saveProductBrand(item).then(async (res) => {
          item.productBrandID = res.productBrandID;
          setItem(item);
          setBrands((prevPlanes) => {
            return [...prevPlanes, item];
          });
          toast.success("Marca registrada con exito!");
        });
      } else {
        setBrands(updateProductBrands);
        saveProductBrand(item);
        toast.success("Marca actualizada con exito!");
      }
      document.getElementById("close-modal").click();


    //navigate("/");
  };
  return (
    <div
      className="modal fade"
      id="modal-product-brand"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title  font-weight-light">
              {item.productBrandID ? "Editando " : "Creando "}una marca
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">

            <input type="hidden" value={item.productBrandID || 0} />

            <div className="row">
              <div className="col-sm-12">

                    
                    <div className="form-group">
                      <label className=" font-weight-light">Nombre:</label>
                      <input
                        type="text"
                        className="form-control font-weight-light"
                        placeholder="Ingrese ..."
                        name="name"
                        maxLength={100}
                        value={item.name || ""}
                        onChange={handleChange}
                      />
                    </div>

              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-light border font-weight-light"
              data-dismiss="modal"
              id="close-modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-success font-weight-light"
              onClick={handleSubmit}
            >
              {item.productBrandID ? "Guardar cambios" : "Registrar"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
