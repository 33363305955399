import React, { useState, useEffect, useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from "react-toastify";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedRoute, fetchedVehiclesBySubsidiary, registerClientData, updateClientData } from "../../../apis/apis.dispatches/apisDispatch";
import { fetchedGangs } from "../../../apis/apis.logistics/apisProduct";

import { ContextAuth } from "../../../contexts/ContextAuth";
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initialClientState = {
    personID: 0,
    name: "",
    firstSurname: "",
    secondSurname: "",
    documentType: "",
    documentNumber: "",

    observation: "",
    cellphone: "",
    visitDay: 0,
    customerType: 0,
    showcases: 0,
    physicalDistribution: "01",
    gangID: 0,
    district: "",
    address: "",
    latitude: 0,
    longitude: 0,
    comment: "",
    isEnabled: true
};

export function MapPage() {

    const [vehicles, setVehicles] = useState([]);
    const [gangs, setGangs] = useState([]);
    const { auth } = useContext(ContextAuth);
    const [orders, setOrders] = useState([]);
    const [client, setClient] = useState(initialClientState);

    const [markerType, setMarkerType] = useState(false);

    const defaultProps = {
        center: {
          lat: -16.430613,
          lng: -71.532155
        },
        zoom: 12
      };

      const containerStyle = {
        width: '100%',
        height: '600px'
      };

    const [questionState, setQuestionState] = useState({
        startDate: getCurrentDate("-"),
        subsidiaryID: auth.data.subsidiary.subsidiary_id,
        gangID: 0,
        userID: 0,
        visitDay: 0,
        operationStatus: "02",
        isEnabled: true,
        useDotTypeMarker: true,
      });
  
      const sendFilterDispatches = async (data) => {
        try {
          const response = await fetchedRoute(data);
          setOrders(response)
          // console.log(response)
          return response;
        } catch (error) {
          throw error;
        }
      };
  
      const registerClient = async (data) => {
        try {
          const response = await registerClientData(data);
          // console.log(response)
          return response;
        } catch (error) {
          throw error;
        }
      };
  
      const updateClient = async (data) => {
        try {
          const response = await updateClientData(data);
          // console.log(response)
          return response;
        } catch (error) {
          throw error;
        }
      };

    const getVehicles = async (id) => {
        try {
          const response = await fetchedVehiclesBySubsidiary({
            subsidiaryID: id,
          });
          setVehicles(response);
        } catch (error) {
          throw error;
        }
      };

    const getGangs = async () => {
        try {
          const response = await fetchedGangs();
          setGangs(response);
        } catch (error) {
          throw error;
        }
      };

    useEffect(() => {
      getVehicles(auth.data.subsidiary.subsidiary_id);
      getGangs();
    }, []);
  
   
    useEffect(() => {
      if(Number(questionState.gangID) > 0 && Number(questionState.visitDay) >= 0)
        searchSuggestions();
    }, [questionState.gangID, questionState.visitDay, questionState.isEnabled]);

    function searchSuggestions() {
        sendFilterDispatches(questionState).then((res) => {
          let results = res.length;
          toast.info(
            results > 1
              ? "Se encontraron " + results.toString() + " resultados."
              : "Se encontró " + results.toString() + " resultado(s)."
          );
          setOrders(res);
        });
      }


    const colBody2 = "#fd7e14";

    const days = 
    [
        {id:0, name:"LUNES"}, 
        {id:1, name:"MARTES"},
        {id:2, name:"MIERCOLES"},
        {id:3, name:"JUEVES"},
        {id:4, name:"VIERNES"},
        {id:5, name:"SABADO"},
        {id:6, name:"DOMINGO"},
    ];

    const handleMapDoubleClick = event => {
        const newMarker = {
            personID: 0,
            name: "",
            firstSurname: "",
            secondSurname: "",
            documentType: "",
            documentNumber: "",
        
            observation: "",
            cellphone: "",
            visitDay: 0,
            customerType: 0,
            showcases: 0,
            physicalDistribution: "01",
            gangID: 0,
            district: "",
            address: "",
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng(),
            comment: "",
            isEnabled: true
        };

        document.getElementById("btn-client-modal").click();
        setClient(newMarker);
        setOrders([...orders, newMarker]);
    };

    const [googleMapsApiLoaded, setGoogleMapsApiLoaded] = useState(false);

    const handleLoad = () => {
      setGoogleMapsApiLoaded(true);
    };
  

  return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Ubicacion de clientes</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                        <a href="#">Distribuciones</a>
                        </li>
                        <li className="breadcrumb-item active">Mapa</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-body p-2">                        

                            <div className="row mb-3">
                            
                                <div className="col-auto">
                                    <label className="">CUADRILLA:</label>
                                </div>

                                <div className="col-auto">
                                    <select
                                        className="form-control"
                                        name="gangID"
                                        value={questionState.gangID}
                                        onChange={e=>{setQuestionState({ ...questionState, gangID: e.target.value });}}
                                    >
                                    <option value={0}>Elegir</option>
                                        {vehicles.map((item, index) => {
                                        return (
                                            <option key={index} value={item.gang.gangID}>
                                            {item.vehicleLicensePlate} | {item.gang.gangName}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>


                                <div className="col-auto">
                                    <label className="">DIA:</label>
                                </div>
                                <div className="col-auto">
                                    <select
                                        className="form-control"
                                        name="visitDay"
                                        style={{color: colBody2}}
                                        value={questionState.visitDay}
                                        onChange={(e)=> {setQuestionState({...questionState, visitDay: e.target.value});}}
                                    >
                                        {days.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            );
                                        })}
                                        <option key={6} value={7}>TODOS</option>
                                    </select>
                                </div>

                                <div className="col-auto ">
                                  <div className="custom-control custom-switch">
                                  
                                    <input
                                      type="checkbox"
                                      id="customSwitch2"
                                      className="custom-control-input"
                                      name="isEnabled"
                                      checked={questionState.isEnabled? true : false}
                                      onChange={(e) => {
                                        const { target } = e;
                                        const { name, checked } = target;
                                        const newValues = {
                                          ...questionState,
                                          [name]: checked,
                                        };
                                        setQuestionState(newValues);
                                      }}
                                    />
                                    <label className="custom-control-label " htmlFor="customSwitch2">{questionState.isEnabled? "ACTIVOS" : "DADOS DE BAJA"}</label>
                                  </div>
                                </div>

                                <div className="col-auto ">
                                  <div className="custom-control custom-switch">
                                  
                                    <input
                                      type="checkbox"
                                      id="customSwitch3"
                                      className="custom-control-input"
                                      name="useDotTypeMarker"
                                      checked={questionState.useDotTypeMarker? true : false}
                                      onChange={(e) => {
                                        const { target } = e;
                                        const { name, checked } = target;
                                        const newValues = {
                                          ...questionState,
                                          [name]: checked,
                                        };
                                        setQuestionState(newValues);
                                      }}
                                    />
                                    <label className="custom-control-label " htmlFor="customSwitch3">{questionState.useDotTypeMarker? "MARCADORES TIPO PUNTO" : "MARCADORES TIPO APP"}</label>
                                  </div>
                                </div>

                            </div>




                  {orders.length?(
                    <>

                        <LoadScript googleMapsApiKey="AIzaSyDTNkHY7wSUzvSiNnEk23oym-1dGK6JYN4" onLoad={handleLoad}>
                            <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={defaultProps.center}
                            zoom={defaultProps.zoom}
                            loadingElement={<p>Cargando</p>}
                            onDblClick={event => handleMapDoubleClick(event)}
                            >
                            { /* Child components, such as markers, info windows, etc. <><Marker position={defaultProps.center} /></>*/ }
                            
                            {orders?.map((loc, index) => (
                              //let color = ''; 
                            
                            <Marker 
                                key={index}
                                position={{ lat: loc.latitude, lng: loc.longitude }} 
                                label={questionState.useDotTypeMarker ?{
                                    text:`\0\r\0\r\0\r\0\r\0\r${loc.observation}`, fontSize: "15px", fontWeight: "bold", color:'#343a40'
                                }:{
                                    text:`${loc.observation}`, fontSize: "15px", fontWeight: "normal", color:'#fff'
                                }} 
                                
                                title={loc.observation}
                                icon={googleMapsApiLoaded && questionState.useDotTypeMarker ?{
                                    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                                    fillColor: loc.visitDay==0?'#dc3545':loc.visitDay==1?'#007bff':loc.visitDay==2?'#4cd964':loc.visitDay==3?'#ffc107':loc.visitDay==4?'#6c757d':loc.visitDay==5?'#6610f2':'#343a40',
                                    fillOpacity: 1.0,
                                    style:{position: 'absolute', transform: 'translate(-50%, -100%)' },
                                    // anchor: new window.google.maps.Point(0,0),
                                    labelOrigin:{ x: 10, y: -20},
                                    strokeWeight: 1,
                                    scale: .15,
                                    rotation: 30

                                }: googleMapsApiLoaded && !questionState.useDotTypeMarker?{
                                    url: loc?.routeStatus=="01"?'/markers/pin-blue.png':loc?.routeStatus=="02"?'/markers/pin-green.png':loc?.routeStatus=="03"?'/markers/pin-red.png':loc?.routeStatus=="04"?'/markers/pin-yellow.png':loc?.routeStatus=="05"?'/markers/pin-sky-blue.png':'/markers/pin-green-light.png',
                                    scaledSize: new window.google.maps.Size(45, 45),
                                }:null}
                                
                                onClick={() => {
                                  // console.log({ lat: loc.latitude, lng: loc.longitude });
                                  console.log("onClick");
                                  
                              }}
                                draggable={true}
                                onDragEnd={marker => {

                                    document.getElementById("btn-client-modal").click();
                                    setClient({ ...client, 
                                        personID: loc.personID,
                                        name: loc.name,
                                        firstSurname: loc.firstSurname,
                                        secondSurname: loc.secondSurname,
                                        documentType: loc.documentType,
                                        documentNumber: loc.documentNumber,

                                        observation: loc.observation,
                                        cellphone: loc.cellphone,
                                        visitDay: loc.visitDay,
                                        customerType: loc.customerType,
                                        showcases: loc.showcases,
                                        physicalDistribution: loc.physicalDistribution,

                                        gangID: loc.gangID,
                                        district: loc.district,
                                        address: loc.address,
                                        latitude: marker.latLng.lat(), 
                                        longitude: marker.latLng.lng(), 
                                        comment: loc.comment,
                                        isEnabled: loc.isEnabled
                                    });
                                }}

                            />
                            
                            ))}

                            </GoogleMap>
                        </LoadScript>

                        <div>
                          <span className="badge badge-pill font-weight-light text-white" style={{backgroundColor:'#dc3545'}}>LUNES</span>
                          <span className="badge badge-pill font-weight-light text-white" style={{backgroundColor:'#007bff'}}>MARTES</span>
                          <span className="badge badge-pill font-weight-light text-white" style={{backgroundColor:'#4cd964'}}>MIERCOLES</span>
                          <span className="badge badge-pill font-weight-light text-dark" style={{backgroundColor:'#ffc107'}}>JUEVES</span>
                          <span className="badge badge-pill font-weight-light text-white" style={{backgroundColor:'#6c757d'}}>VIERNES</span>
                          <span className="badge badge-pill font-weight-light text-white" style={{backgroundColor:'#6610f2'}}>SABADO</span>
                          <span className="badge badge-pill font-weight-light text-white" style={{backgroundColor:'#343a40'}}>DOMINGO</span>
                        </div>

                    <div className="table-responsive mt-3" id="all-clients">
                        <table className="table table-sm table-bordered mb-0 small" id="table-to-xls">
                        <thead>
                            <tr className="text-center">
                                <th className="align-middle">COD.</th>
                                <th className="align-middle">CLIENTE</th>
                                <th className="align-middle">DIRECCION</th>
                                <th className="align-middle">DOCUMENTO</th>
                                <th className="align-middle">EXHIBIDOR</th>
                                <th className="align-middle">CELULAR</th>
                                <th className="align-middle">CUADRILLA</th>
                                <th className="align-middle">VISITA</th>
                                <th className="align-middle">TIPO CLIENTE</th>
                                <th className="align-middle">LAT</th>
                                <th className="align-middle">LON</th>
                                <th className="align-middle">routeStatusDisplay</th>
                                <th className="align-middle">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.sort((a, b) => Number(a.observation) > Number(b.observation) ? 1 : -1,).map((item, index) => {
                            return (
                                <tr key={index} value={item.personID}>
                                    <td className="align-middle">{item.observation}</td>
                                    <td className="align-middle">{item.fullName}</td>
                                    <td className="align-middle">{item.address}</td>
                                    <td className="align-middle">{item.documentNumber}</td>
                                    <td className="align-middle">{item.showcasesDisplay}</td>
                                    <td className="align-middle">{item.cellphone}</td>
                                    <td className="align-middle">{item.gangName}</td>
                                    <td className="align-middle">{item.visitDayDisplay}</td>
                                    <td className="align-middle">{item.customerTypeDisplay}</td>
                                    <td className="align-middle">{item.latitude}</td>
                                    <td className="align-middle">{item.longitude}</td>
                                    <td className="align-middle">{item.routeStatusDisplay}</td>
                                    <td className="align-middle text-center"><button type="button" className="btn btn-primary" onClick={e=>{
                                        document.getElementById("btn-client-modal").click();
                                        setClient({ ...client, 
                                            personID: item.personID,
                                            name: item.name,
                                            firstSurname: item.firstSurname,
                                            secondSurname: item.secondSurname,
                                            documentType: item.documentType,
                                            documentNumber: item.documentNumber,
    
                                            observation: item.observation,
                                            cellphone: item.cellphone,
                                            visitDay: item.visitDay,
                                            customerType: item.customerType,
                                            showcases: item.showcases,
                                            physicalDistribution: item.physicalDistribution,
    
                                            gangID: item.gangID,
                                            district: item.district,
                                            address: item.address,
                                            latitude: item.latitude, 
                                            longitude: item.longitude, 
                                            comment: item.comment,
                                            isEnabled: item.isEnabled
                                        });
                                    }}>Editar</button></td>
                                </tr>
                            );
                            })}
                        </tbody>
                        </table>

                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-outline-secondary mt-3 ml-1"
                          table="table-to-xls"
                          filename="report_of_routes"
                          sheet="report"
                          buttonText="Descargar como XLS"/>

                        <button type="button" 
                            className="btn btn-link d-none" 
                            id="btn-client-modal"
                            data-toggle="modal" 
                            data-target="#clientModal">
                        Launch
                        </button>

                    </div>


    <div className="modal fade" id="clientModal" tabIndex="-1" role="dialog" aria-labelledby="clientModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="clientModalLabel">Datos del cliente</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    

                    <div className="row" id="form-client">

                        
                        <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="name" className="font-weight-normal">Nombre o razon social</label>
                            <input type="text" className="form-control" id="name" maxLength={200} 
                            value={client.name || ""} onChange={(e)=>setClient({...client, name: e.target.value})} />
                        </div>
                        </div>

                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="firstSurname" className="font-weight-normal">Apellido paterno</label>
                            <input type="text" className="form-control" id="firstSurname" maxLength={200} 
                            value={client.firstSurname || ""} onChange={(e)=>setClient({...client, firstSurname: e.target.value})} />
                        </div>
                        </div>

                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="secondSurname" className="font-weight-normal">Apellido Materno</label>
                            <input type="text" className="form-control" id="secondSurname" maxLength={200} 
                            value={client.secondSurname || ""} onChange={(e)=>setClient({...client, secondSurname: e.target.value})} />
                        </div>
                        </div>


                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="documentType" className="font-weight-normal">Tip Doc</label>
                            <select className="form-control" id="documentType" value={client.documentType} onChange={e=>{
                                setClient({...client, documentType: e.target.value});
                            }}>
                            <option value="">Elige</option>
                            <option value="01">DNI</option>
                            <option value="06">RUC</option>
                            </select>
                        </div>
                        </div>

                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="documentNumber" className="font-weight-normal">Num Doc</label>
                            <input type="text" className="form-control" id="documentNumber" maxLength={12} 
                            value={client.documentNumber || ""} onChange={(e)=>setClient({...client, documentNumber: e.target.value})} />
                        </div>
                        </div>

                        <div className="col-lg-12">
                            <hr/>
                        </div>

                        <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="observation" className="font-weight-normal">Codigo</label>
                            <input type="text" className="form-control" id="observation" maxLength={20} 
                            value={client.observation} onChange={(e)=>setClient({...client, observation: e.target.value})} />
                        </div>
                        </div>

                        <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="cellphone" className="font-weight-normal">Celular</label>
                            <input type="text" className="form-control" id="cellphone" maxLength={12} 
                            value={client.cellphone} onChange={(e)=>setClient({...client, cellphone: e.target.value})} />
                        </div>
                        </div>


                        <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="customerType" className="font-weight-normal">Tipo de cliente</label>
                            <select className="form-control" id="customerType" value={client.customerType} onChange={e=>{
                                setClient({...client, customerType: e.target.value});
                            }}>
                            <option value={0}>PEPSICO</option>
                            <option value={1}>PROPIO</option>
                            </select>
                        </div>
                        </div>

                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="showcases" className="font-weight-normal">Sis. de exhibixion</label>
                            <select className="form-control" id="showcases" value={client.showcases} onChange={e=>{
                                setClient({...client, showcases: e.target.value});
                            }}>
                            <option value={0}>NO CUENTA</option>
                            <option value={1}>AEREO</option>
                            <option value={2}>2 NIVELES</option>
                            <option value={3}>5 NIVELES</option>
                            <option value={4}>6X8 NIVELES</option>
                            </select>
                        </div>
                        </div>

                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="physicalDistribution" className="font-weight-normal">Tip entrega</label>
                            <select className="form-control" id="physicalDistribution" value={client.physicalDistribution} onChange={e=>{
                                setClient({...client, physicalDistribution: e.target.value});
                            }}>
                                <option value="NA">NO APLICA</option>
                                <option value="01">TIRAS</option>
                                <option value="02">SUELTAS</option>
                            </select>
                        </div>
                        </div>




                        <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="gangID" className="font-weight-normal">Ruta</label>
                            <select className="form-control" 
                            id="gangID"
                            value={client.gangID}
                            onChange={e=>{setClient({ ...client, gangID: e.target.value });}}>
                            <option value={0}>Elegir</option>
                            {gangs.map((item, index) => {
                                return (
                                    <option key={index} value={item.gangID}>
                                    {item.gangName}
                                    </option>
                                );
                            })}
                            </select>
                        </div>
                        </div>


                        <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="visitDay" className="font-weight-normal">Dia de visita</label>
                            <select className="form-control" id="visitDay" value={client.visitDay} onChange={e=>{
                                setClient({...client, visitDay: e.target.value});
                            }}>
                            <option value={0}>LUNES</option>
                            <option value={1}>MARTES</option>
                            <option value={2}>MIERCOLES</option>
                            <option value={3}>JUEVES</option>
                            <option value={4}>VIERNES</option>
                            <option value={5}>SABADO</option>
                            <option value={6}>DOMINGO</option>
                            </select>
                        </div>
                        </div>




                        <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="district" className="font-weight-normal">Distrito</label>
                            <select className="form-control" id="district" value={client.district} onChange={e=>{
                                setClient({...client, district: e.target.value});
                            }}>
                            <option value="040129">JOSE LUIS BUSTAMANTE Y RIVERO</option>
                            <option value="040119">SOCABAYA</option>
                            <option value="040113">SABANDIA</option>
                            <option value="040104">CHARACATO</option>
                            </select>
                        </div>
                        </div>


                        <div className="col-lg-6">
                        <div className="form-group">
                            <label htmlFor="address" className="font-weight-normal">Direccion actual</label>
                            <input type="text" className="form-control" id="address" maxLength={500} 
                            value={client.address} onChange={(e)=>setClient({...client, address: e.target.value})} />
                        </div>
                        </div>



                        <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="latitude" className="font-weight-normal">Latitud</label>
                            <input type="text" className="form-control" id="latitude" readOnly value={client.latitude} />
                        </div>
                        </div>

                        <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="longitude" className="font-weight-normal">Longitud</label>
                            <input type="text" className="form-control" id="longitude" readOnly value={client.longitude} />
                        </div>
                        </div>



                        <div className="col-lg-12">

                        <div className="form-group">
                            <label htmlFor="comment" className="font-weight-normal">Comentarios</label>
                            <textarea className="form-control" id="comment" rows="3" maxLength={500} 
                            value={client.comment || ""} onChange={(e)=>setClient({...client, comment: e.target.value})}></textarea>
                        </div>
                        </div>

                        <div className="col-lg-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="isEnabled" checked={client.isEnabled} onChange={ (e)=>setClient({ ...client, isEnabled: e.target.checked })}/>
                            <label className="form-check-label" htmlFor="isEnabled">
                            Activo
                            </label>
                        </div>
                        </div>


                    </div>



                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" id="btn-close-modal">CERRAR</button>
                    <button type="button" className="btn btn-primary" onClick={(e) => {
                        updateClient(client).then(async(res)=>{
                            setClient(initialClientState);
                            toast.success("Cliente guardado con exito!");
                            document.getElementById("btn-close-modal").click();
                            searchSuggestions();
                        });
                        
                    }}>GUARDAR</button>
                </div>
                </div>
        </div>
    </div>



                    

                    </>
                  ):""}



                    </div>
                </div>
                </div>
            </section>

            
        </>
  )
}
