import React from "react";

export function FragmentFooter() {
  return (
    <footer className="main-footer">
      <strong>
        Copyright © 2022-2025{" "}
        <a href="https://4soluciones.net">4soluciones.net</a>
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.2.0
      </div>
    </footer>
  );
}
