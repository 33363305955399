import React, { useState, useEffect, useContext } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { sendConfirmDispatchData } from "../../../apis/apis.dispatches/apisDispatch";

const initialOptionState = {
    name:"cash", description:"CONTADO", value:0
};

export function ConfirmDispatchForm({dispatchToConfirm, setDispatchToConfirm}) {
    const [method, setMethod] = useState(initialOptionState);
    const [paymentMethodsArray, setPaymentMethodsArray] = useState([]);
    const { auth } = useContext(ContextAuth);
    const navigate = useNavigate();
    const sendDispatch = async () => {
      try {
        const response = await sendConfirmDispatchData(dispatchToConfirm);
        return response;
      } catch (error) {
        throw error;
      }
    };
    useEffect(() => {
        setMethod({ ...method, value:dispatchToConfirm.total });
        
    }, [dispatchToConfirm.total]);

    useEffect(() => {
        if("physicalDistribution" in dispatchToConfirm && dispatchToConfirm["physicalDistribution"] != "")
            sendDispatch().then((r) => {
                if("documentNumber" in r){
                    console.log(r["documentNumber"]);
                    toast.success("Se genero el documento " + r["documentNumber"]);
                    document.getElementById("hidden-cancel").click();
                    document.getElementById("btn-filter").click();
                    setPaymentMethodsArray([]);
                }
            });
    }, [dispatchToConfirm.paymentMethods,]);

    function handleRemoveItem(item) {
        if (window.confirm("¿Esta usted seguro de eliminar el item?")){
            const newPeople = paymentMethodsArray.filter((obj) => obj.name !== item.name);
            setPaymentMethodsArray(newPeople);
        }
        
    }

    const handleConfirmDispatch = async () => {
        if (window.confirm("¿Esta usted seguro de confirmar la preventa?")){
            let subtotal = Number(document.getElementById("dialogSubtotal").value);
            let total = Number(document.getElementById("dialogTotal").value);
            if(subtotal == total){
                let payments = {};
                paymentMethodsArray.map((item, index) => {
                    payments[item.name]=Number(item.value);
                });

                setDispatchToConfirm({...dispatchToConfirm, 
                    paymentMethods: payments, 
                    userID: auth.data.user_id
                });

                /*await sendDispatch()
                .then((r) => {
                  setOrder({
                    ...order,
                    operationID: r.operationID,
                    operationCorrelative: r.operationCorrelative,
                  });
  
                  return r.status;
                })
                .then((status) => {
                  if (status == 201) {
                    toast.success("Operacion registrada con exito!");
                    setOrder(initialOrderState);
                    navigate('/dispatches/order_tray');
                  } else {
                    toast.error("Ocurrio algun error");
                  }
                });*/

            }
            else{
                toast.info("verifique el pago");
            }
        }
        
    }
     
  return (
    <>

        <div
            className="modal fade"
            id="modal-confirm-dispatch"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Confirmar pedido</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="hidden-cancel"
                        >
                        <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                    <div className="d-flex flex-row mb-3">

                        <div className="form-group flex-fill border bg-aqua-light">
                            <label htmlFor="dialogItems" className="w-100 text-center pt-3">ITEMS</label>
                            <input type="number" className="form-control border-0 bg-transparent text-center" id="dialogItems" value={dispatchToConfirm.details!=null?dispatchToConfirm.details.length:0} readOnly={true}></input>
                        </div>

                        <div className="form-group flex-fill border bg-gradient-light">
                            <label htmlFor="dialogSubtotal" className="w-100 text-center pt-3">PAGADO</label>
                            <input type="number" className="form-control border-0 bg-transparent text-center" id="dialogSubtotal" value={paymentMethodsArray.length?Number(paymentMethodsArray.reduce((pv, cv) => pv + Number(cv.value), 0)).toFixed(2):0} readOnly={true}></input>
                        </div>

                        <div className="form-group flex-fill border bg-gradient-light">
                            <label htmlFor="dialogTotal" className="w-100 text-center pt-3">TOTAL</label>
                            <input type="number" className="form-control border-0 bg-transparent text-center" id="dialogTotal" value={dispatchToConfirm.total} readOnly={true}></input>
                        </div>
                    </div>
                        
                            <div className="form-group">
                                <label htmlFor="methodName">Metodos de pago</label>
                                <div className="d-flex flex-row mb-3">
                                    <select className="form-control" name="methodName" id="methodName" value={method.name}
                                        onChange={(e) => { 
                                            var index = e.nativeEvent.target.selectedIndex;
                                            setMethod({ ...method, name: e.target.value, description: e.nativeEvent.target[index].text });
                                        }}
                                    >
                                        <option value={"cash"}>CONTADO</option>
                                        <option value={"yape"}>YAPE</option>
                                        <option value={"bcp"}>BCP - DEPOSITO</option>
                                        <option value={"credit"}>CREDITO</option>
                                    </select>
                                    <input type="number" className="form-control mx-1" name="methodPrice" id="methodPrice" value={method.value} 
                                        onChange={(e) => { setMethod({ ...method, value: e.target.value });}}
                                        onFocus={(e) => e.target.select()}
                                    ></input>

                                    <button type="button" className="btn btn-primary"
                                        onClick={(e)=>{
                                            const found =  paymentMethodsArray.find(function ( detail, index) {
                                                if (detail.name == method.name)
                                                    return true;
                                            });
                                        
                                            if (!found) {
                                                if (Number(method.value) <= 0){
                                                    toast.info("verifique el nuevo costo");
                                                }
                                                else {
                                                    setPaymentMethodsArray([ ...paymentMethodsArray,  method ]);
                                                    setMethod(initialOptionState);
                                                }
                                            }else {
                                                toast.info("Ya existe este item");
                                            }
                                        }}
                                    ><i className="fas fa-plus"></i></button>
                            
                                </div>

                            </div>
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered">
                                    <thead>
                                        <tr className="bg bg-gradient-blue">
                                            <td>METODO</td>
                                            <td>MONTO</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {paymentMethodsArray.map((item, index) => {
                                        return (
                                        <tr key={index}>
                                            <td className="align-middle">{item.description}</td>
                                            <td className="align-middle">{item.value}</td>
                                            <td className="align-middle text-center">
                                                <button type="button" className="btn btn-link p-0"
                                                    onClick={(e) => handleRemoveItem(item)}
                                                ><i className="fas fa-trash"></i></button>
                                            </td>
                                        
                                        </tr>);
                                    })}
                                   
                                    </tbody>
                                </table>
                            </div>
                            

                        <div className="form-group">
                            <label htmlFor="physicalDistribution">Tipo entrega</label>
                            <select className="form-control" id="physicalDistribution" value={dispatchToConfirm.physicalDistribution} onChange={e=>{
                                setDispatchToConfirm({...dispatchToConfirm, 
                                    physicalDistribution: e.target.value
                                });
                            }}>
                            <option value={"01"}>TIRAS</option>
                            <option value={"02"}>SUELTAS</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="documentType">Tipo documento</label>
                            <select className="form-control" id="documentType" value={dispatchToConfirm.documentType} onChange={e=>{
                                setDispatchToConfirm({...dispatchToConfirm, 
                                    documentType: e.target.value
                                });
                            }}>
                            <option value={"01"}>TICKET</option>
                            <option value={"02"}>BOLETA</option>
                            <option value={"03"}>FACTURA</option>
                            </select>
                        </div>

                    </div>

                    <div className="modal-footer justify-content-between">
                        <button
                            type="button"
                            className="btn btn-outline-light"
                            data-dismiss="modal"
                            id="close-modal"
                        >Cerrar
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleConfirmDispatch}>Confirmar</button>
                    </div>
                </div>
            </div>
        </div>

    
    </>
  )
}
