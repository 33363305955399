import React from "react";
import { PageLogin, PageHome } from "../../modules";
import { useHooksAuth } from "../../hooks";
import {
  FragmentFooter,
  FragmentHeader,
  FragmentNavigation,
} from "../../modules/modules.home/fragments.home";

export function LayoutsHome(props) {
  const { children } = props;
  const { auth } = useHooksAuth();
  let names = "",
    email = "";
  if (!auth) {
    return <PageLogin />;
  } else {
    names = auth.data?.first_name || "";
    email = auth.data?.email;
  }
  return (
    <div>
      <FragmentHeader />
      <FragmentNavigation />
      <FragmentFooter />
      <div className="content-wrapper">
        <div className="content-header pb-1 pt-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <p className="m-0">Usuario: {names}</p>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#!">Correo: {email}</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <PageHome>{children}</PageHome>
        </section>
      </div>
      <aside className="control-sidebar control-sidebar-dark"></aside>
    </div>
  );
}
