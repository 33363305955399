import { LayoutsHome } from "../layouts";

import { DistributionPage } from "../modules/modules.dispatches/pages.dispatches/DistributionPage";
import { SupplyingPage } from "../modules/modules.dispatches/pages.dispatches/SupplyingPage";
import { DevolutionPage } from "../modules/modules.dispatches/pages.dispatches/DevolutionPage";
import { ReadjustmentPage } from "../modules/modules.dispatches/pages.dispatches/ReadjustmentPage";
import { AccumulativeDispatchPage } from "../modules/modules.dispatches/pages.dispatches/AccumulativeDispatchPage";
import { MapPage } from "../modules/modules.dispatches/pages.dispatches/MapPage";
import { OrderTrayPage } from "../modules/modules.dispatches/pages.dispatches/OrderTrayPage";
import { SaleStatisticPage } from "../modules/modules.dispatches/pages.dispatches/SaleStatisticPage";
import { DailyRouteGraphByDayPage } from "../modules/modules.dispatches/pages.dispatches/DailyRouteGraphByDayPage";
import { SoldProductGraphByDayPage } from "../modules/modules.dispatches/pages.dispatches/SoldProductGraphByDayPage";
import { ClientPortfolioPage } from "../modules/modules.dispatches/pages.dispatches/ClientPortfolioPage";
import { SpecialSalePage } from "../modules/modules.dispatches/pages.dispatches/SpecialSalePage";

const routesDISPATCHES = [
  {
    path: "/dispatches/distributions",
    layout: LayoutsHome,
    component: DistributionPage,
  },
  {
    path: "/dispatches/supplyings",
    layout: LayoutsHome,
    component: SupplyingPage,
  },
  {
    path: "/dispatches/devolutions",
    layout: LayoutsHome,
    component: DevolutionPage,
  },
  {
    path: "/dispatches/readjustments",
    layout: LayoutsHome,
    component: ReadjustmentPage,
  },
  {
    path: "/dispatches/accumulative_dispatches",
    layout: LayoutsHome,
    component: AccumulativeDispatchPage,
  },
  {
    path: "/dispatches/map_clients",
    layout: LayoutsHome,
    component: MapPage,
  },
  {
    path: "/dispatches/order_tray",
    layout: LayoutsHome,
    component: OrderTrayPage,
  },
  {
    path: "/dispatches/sales_statistics",
    layout: LayoutsHome,
    component: SaleStatisticPage,
  },
  {
    path: "/dispatches/daily_route_graph_by_day",
    layout: LayoutsHome,
    component: DailyRouteGraphByDayPage,
  },
  {
    path: "/dispatches/sold_product_graph_by_day",
    layout: LayoutsHome,
    component: SoldProductGraphByDayPage,
  },
  {
    path: "/dispatches/client_portfolios",
    layout: LayoutsHome,
    component: ClientPortfolioPage,
  },
  {
    path: "/dispatches/special_sales",
    layout: LayoutsHome,
    component: SpecialSalePage,
  },

];
export default routesDISPATCHES;
