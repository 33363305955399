import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { DistributionFilter } from "../fragments.dispatches/DistributionFilter";
import { DistributionList } from "../fragments.dispatches/DistributionList";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";

export  function DistributionPage() {
  const [orders, setOrders] = useState([]);
  const [gangs, setGangs] = useState([]);
  const [users, setUsers] = useState([]);
  const [filename, setFilename] = useState("ReporteVentas");
  const [operationStatus, setOperationStatus] = useState([ { id: "02", name: "Comprobantes Generados" }, { id: "01", name: "Cotizaciones" },]);
  const { auth } = useContext(ContextAuth);


  const [questionState, setQuestionState] = useState({
    startDate: getCurrentDate("-"),
    endDate: getCurrentDate("-"),
    subsidiaryID: auth.data.subsidiary.subsidiary_id,
    warehouseID: 0,
    gangID: 0,
    userID: 0,
    operationStatus: "02",
    documentType: "02",
    mode: "01",
    httpStatusCode: 200
  });
  return (
    <>
    <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ventas de reparto</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Distribuciones</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
            <DistributionFilter setOrders={setOrders} gangs={gangs} setGangs={setGangs}  users={users} setUsers={setUsers}
            operationStatus={operationStatus} setOperationStatus={setOperationStatus} setFilename={setFilename} 
            questionState={questionState} setQuestionState={setQuestionState} getCurrentDate={getCurrentDate}
            />
            <DistributionList orders={orders} filename={filename} questionState={questionState} />
                
            </div>
          </div>
        </div>
      </section>

    
    </>
  )
}
