import React from "react";
import { useState, createContext } from "react";
import { getCurrentDate } from "../configs/helpers";
export const GlobalContext = createContext();
export function GlobalProvider({children}) {
  /* logistics */
  const [itemProductBrand, setItemProductBrand] = useState({});
  const [brands, setBrands] = useState([]);
  const [subsidiary, setSubsidiary] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [itemWarehouse, setItemWarehouse] = useState([]);

  const [productsWithTariffs, setProductsWithTariffs] = useState([]);
  const [inventories, setInventories] = useState([]);

  /* accounting */
  const [selectedBank, setSelectedBank] = useState("0");
  const [banks, setBanks] = useState([]);
  const [operations, setOperations] = useState([]);  
  const [opening, setOpening] = useState(null);
  const [closing, setClosing] = useState(null);

  const [itemState, setItemState] = useState({
    cashName: "",
    cashAmount: "0",
    cashID: "0",
    cashDate: getCurrentDate("-"),
    cashOperationDescription: "",
    cashOperationTypeDocument: "0",
    cashOperationType: "E",
  });

  const [totalsCash, setTotalsCash] = useState({
    total_entries_sum: "0",
    total_outputs_sum: "0",
    balance_day: "0",
  });

  return (
    <GlobalContext.Provider
      value={{
        /* GLOBAL DATA LOGISTICS */
        itemProductBrand: itemProductBrand,
        brands: brands,
        setItemProductBrand: setItemProductBrand,
        setBrands: setBrands,
        warehouses: warehouses,
        setWarehouses: setWarehouses,
        itemWarehouse: itemWarehouse,
        setItemWarehouse: setItemWarehouse,
        productsWithTariffs: productsWithTariffs,
        setProductsWithTariffs: setProductsWithTariffs,
        inventories: inventories,
        setInventories: setInventories,
        subsidiary: subsidiary,
        setSubsidiary: setSubsidiary,

        /* GLOBAL DATA ACCOUNTING */
        selectedBank: selectedBank,
        setSelectedBank: setSelectedBank,
        banks: banks,
        setBanks: setBanks,
        operations: operations,
        setOperations: setOperations,
        itemState: itemState,
        setItemState: setItemState,
        opening: opening,
        setOpening: setOpening,
        closing: closing,
        setClosing: setClosing,
        totalsCash: totalsCash,
        setTotalsCash: setTotalsCash,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

/*
      value={[
        selectedBank,
        setSelectedBank,
        banks,
        setBanks,
        operations,
        setOperations,
        selectedProduct,
        setSelectedProduct,
        products,
        setProducts,
        brands,
        setBrands,
        selectedBrand,
        setSelectedBrand,
      ]}

*/