import React from "react";

import { FragmentLoginForm } from "../fragments.login/FragmentLoginForm";

export function PageLogin() {
  return (
    <div className="hold-transition login-page body-login">
      <div className="login-box">
        <div className="card card-outline card-danger">
          <div className="card-header text-center">
            <a href="../../index2.html" className="h1">
              <b>G</b>uillermo
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Inicia sesión</p>
            <FragmentLoginForm />
            <div className="social-auth-links text-center mt-2 mb-3">
              <a href="#!" className="btn btn-block btn-primary">
                <i className="fab fa-facebook mr-2" /> Iniciar sesión usando
                Facebook
              </a>
              <a href="#!" className="btn btn-block btn-danger">
                <i className="fab fa-google-plus mr-2" />
                Iniciar sesión con Google+
              </a>
            </div>
            <p className="mb-1">
              <a href="forgot-password.html">Olvidé mi contraseña</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
