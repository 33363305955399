import React, { useEffect, useState } from "react";
import { useHooksPpe } from "../../../hooks";
import {
  FragmentHeaderPpe,
  FragmentTablePpe,
  FragmentAddEditPpe,
} from "../fragments.hrm";
import { WidgetModal } from "../../modules.widgets";
import swal from "sweetalert2";

export function PagePpe() {
  const [tittleModal, setTittleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setSizeModal] = useState(null);

  const { loading, ppes, getPpes, deletePpe } = useHooksPpe();

  useEffect(() => {
    getPpes();
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);

  const addPpe = () => {
    setTittleModal("Registrar Nuevo EPP");
    setSizeModal("modal");
    setContentModal(<FragmentAddEditPpe onRefetch={onRefetch} ppe={{}} />);
  };

  const updatePpe = (data) => {
    setTittleModal("Actualizar Grupo");
    setSizeModal("modal");
    setContentModal(<FragmentAddEditPpe onRefetch={onRefetch} ppe={data} />);
  };

  const onDeletePpe = async (data) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Esta seguro?",
        text: "Si acepta, No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Acepto!",
        cancelButtonText: "No, Cancelo!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deletePpe(data.ppeID);
            onRefetch();
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            `${data.ppeName} ha sido eliminada`,
            "success"
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            `${data.ppeName} esta a salvo :)`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <FragmentHeaderPpe btnTittle="Registrar Nuevo EPP" addPpe={addPpe} />
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="chart">
                {loading ? (
                  <h6>Cargando...</h6>
                ) : (
                  <FragmentTablePpe
                    ppes={ppes}
                    updatePpe={updatePpe}
                    onDeletePpe={onDeletePpe}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
      <WidgetModal
        children={contentModal}
        modaltittle={tittleModal}
        sizemodal={sizeModal}
      />
    </>
  );
}
