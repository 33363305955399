import React, { useEffect, useContext, useState } from "react";
import { saveNewAmortize } from "../../../apis/apis.accounting/apisCash";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function AccountPayForm(props) {

  const { auth } = useContext(ContextAuth);  

  const optionsPayType = [
    { id: "E", name: "Efectivo" },
    { id: "D", name: "Depósito" },
  ];

  function handleChange(e) {
    var index = e.nativeEvent.target.selectedIndex;
    var value = e.target.value;

    props.setNewPayAmortize({
      ...props.newPayAmortize,
      [e.target.name]: e.target.value,
    });
  }

  function handleChangeCash(e) {
    const search = props.cashes.filter(function (element) {
      return element.cashID == e.target.value;
    });
    let cashStatusText = "";
    let cash_status = document.getElementById("cashStatus");

    if (search.length) {
      //props.setCashItem(search[0]);
      props.setNewPayAmortize({
        ...props.newPayAmortize,
        cashID: search[0].cashID,
        cashName: search[0].cashName,
        cashStatus: search[0].cashStatus,
        lastDateStatus: search[0].lastDateStatus,
        cashAmount: search[0].cashAmount,
        accountPayCashID: props.supplierItem.supplierCashID,
        userID: auth.data.user_id,
        bankID: "0",
        bankName: "",
      });

      if (search[0].cashStatus == "A") {
        cashStatusText = "APERTURADA EL: ";
      } else if (search[0].cashStatus == "C") {
        cashStatusText = "CERRADA EL: ";
      } else {
        cashStatusText = "ESTADO DE CAJA";
      }

      cash_status.textContent = cashStatusText;
    } else {
      props.setNewPayAmortize({
        ...props.newPayAmortize,
        cashID: "0",
        cashName: "",
        cashStatus: "NA",
        lastDateStatus: "",
      });
      cashStatusText = "ESTADO DE CAJA";
      cash_status.textContent = cashStatusText;
    }
  }

  function handleChangeBank(e) {
    const searchbank = props.banks.filter(function (element) {
      return element.bankID == e.target.value;
    });

    if (searchbank.length) {
      props.setNewPayAmortize({
        ...props.newPayAmortize,
        bankID: searchbank[0].bankID,
        bankName: searchbank[0].bankName,
        cashID: "0",
        cashName: "",
        cashStatus: "NA",
        lastDateStatus: "",
      });
    } else {
      props.setNewPayAmortize({
        ...props.newPayAmortize,
        bankID: "0",
        bankName: "",
      });
    }
  }

  const saveAmortize = async (data) => {
    try {
      const response = await saveNewAmortize(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.newPayAmortize.accountPayTypePay == 'E') {
      if (props.newPayAmortize.cashID > 0){     
        console.log("newPayAmortize", props.newPayAmortize); 
        toast.success("AMORTIZACION REGISTRADA CORRECTAMENTE");  
        saveAmortize(props.newPayAmortize).then(async (res) => {
          console.log("res", res);
          //props.newBank.bankID = res.cashID;
          //props.setBankItem({ ...props.bankItem, bankID: res.cashID });
          //props.setAllBanks((prevPlanes) => {
          //  return [...prevPlanes, props.newBank];
        });        
      }
      else{
        toast.error("SELECCIONE UNA CAJA");
        return false;
      }      
    } 
    else if (props.newPayAmortize.accountPayTypePay == "D") {
      if (props.newPayAmortize.bankID > 0) {        
        console.log("newPayAmortize", props.newPayAmortize);
        toast.success("AMORTIZACION REGISTRADA CORRECTAMENTE");
        /*saveAmortize(props.newBank).then(async (res) => {
          console.log("res", res);
          //props.newBank.bankID = res.cashID;
          //props.setBankItem({ ...props.bankItem, bankID: res.cashID });
          //props.setAllBanks((prevPlanes) => {
            return [...prevPlanes, props.newBank];
          });
        });*/
      } else {
        toast.error("SELECCIONE UNA ENTIDAD BANCARIA");
        return false;
      } 
    } else {
      toast.error("Complete los campos necesarioss");
      return false;
    }
    document.getElementById("close-modal-cash").click();
  };
    
  // console.log("cashes", props.cashes);
  // console.log("banks", props.banks);  
  // console.log("supplierItem", props.supplierItem);  

  useEffect(() => {}, [
    props.newPayAmortize,
    props.cashItem,
    props.bankItem,
    props.supplierItem, 
  ]);

  return (
    <div
      className="modal fade"
      id="modal-amortize"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Amortizar a la Cuenta: <span>&nbsp;&nbsp;</span>{" "}
            </h4>
            <div className="new-line text-center text-success font-weight-bolder">
              <h4 className="modal-title">{props.supplierItem.supplierName}</h4>
            </div>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-left"
              >
                Tipo de Pago:
              </label>

              <div className="col-sm-3">
                <select
                  className="form-control select2 text-uppercase"
                  onChange={handleChange}
                  name="accountPayTypePay"
                  //value={props.cashFlow.cashOperationTypeDocument}
                >
                  <option value="0">Seleccione...</option>
                  {optionsPayType.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-right"
              >
                Descripción:
              </label>
              <div className="col-sm-5">
                <input
                  type="text"
                  name="accountPayDescription"
                  className="form-control"
                  //value={props.cashItem.cashDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-left"
              >
                Monto:
              </label>
              <div className="col-sm-3">
                <input
                  type="Number"
                  className="form-control text-right"
                  id="inputEmail3"
                  name="accountPayAmountPay"
                  //value={props.cashItem.cashAmount}
                  onChange={handleChange}
                />
              </div>

              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-right"
              >
                Fecha:
              </label>
              <div className="col-sm-3">
                <input
                  type="date"
                  name="accountPayDate"
                  className="form-control"
                  value={props.newPayAmortize.accountPayDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            {props.newPayAmortize.accountPayTypePay == "E" ? (
              <div className="form-group row bg-light pt-3 pb-3 pr-1 pl-1 rounded">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-1 col-form-label text-left"
                >
                  Caja:
                </label>

                <div className="col-sm-3">
                  <select
                    className="form-control select2 text-uppercase"
                    onChange={handleChangeCash}
                    //onChange={handleChange}
                    name="cashID"
                    value={props.newPayAmortize.cashID}
                  >
                    <option value="0">Seleccione...</option>
                    {props.cashes.map((item, index) => {
                      return (
                        <option key={index} value={item.cashID}>
                          {item.cashName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <label
                  htmlFor="inputEmail3"
                  className={
                    props.newPayAmortize.cashStatus == "NA"
                      ? "col-auto col-form-label text-uppercase text-left text-primary"
                      : props.newPayAmortize.cashStatus == "A"
                      ? "col-auto col-form-label text-uppercase text-left text-success"
                      : "col-auto col-form-label text-uppercase text-left text-danger"
                  }
                  id="cashStatus"
                  name="cashStatus"
                  value={props.newPayAmortize.cashStatus}
                ></label>

                <div className="col-sm-2">
                  <input
                    type="date"
                    name="cashLastDate"
                    //id="cashLastDate"
                    className="form-control text-center pr-0 pl-2 bg-success"
                    value={props.newPayAmortize.lastDateStatus}
                    readOnly
                    //onChange={handleChangeCash}
                    onChange={handleChange}
                  />
                </div>

                <label
                  htmlFor="inputEmail3"
                  className="col-sm-1 col-form-label text-left text-success"
                >
                  Saldo:
                </label>

                <div className="col-sm-2">
                  <input
                    type="text"
                    name="cashLastBalance"
                    className="form-control pr-2 pl-2 text-right bg-success"
                    value={"S/. " + props.newPayAmortize.cashAmount}
                    readOnly
                  />
                </div>
              </div>
            ) : props.newPayAmortize.accountPayTypePay == "D" ? (
              <div className="form-group row bg-light pt-3 pb-3 pr-1 pl-1 rounded">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label text-left"
                >
                  Banco :
                </label>

                <div className="col-sm-3">
                  <select
                    className="form-control select2 text-uppercase"
                    onChange={handleChangeBank}
                    name="bankID"
                    value={props.newPayAmortize.bankID}
                  >
                    <option value="0">Seleccione...</option>
                    {props.banks.map((item, index) => {
                      return (
                        <option key={index} value={item.bankID}>
                          {item.bankName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label text-right"
                >
                  Cod. Op. :
                </label>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name="bankCodOperation"
                    className="form-control"
                    value={props.newPayAmortize.bankCodOperation}
                    /*onChange={(e) => {
                      props.setNewPayAmortize({
                        ...props.newPayAmortize,
                        bankCodOperation: e.target.value,
                      });
                    }}*/
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="close-modal-cash"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
