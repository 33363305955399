import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { AccumulativeDispatchFilter } from "../fragments.dispatches/AccumulativeDispatchFilter";
import { AccumulativeDispatchList } from "../fragments.dispatches/AccumulativeDispatchList";

import { getCurrentDate } from "../../../configs/helpers";

export function AccumulativeDispatchPage() {
    const [orders, setOrders] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [users, setUsers] = useState([]);
    const [operationStatus, setOperationStatus] = useState([ { id: "02", name: "Comprobantes Generados" }, { id: "01", name: "Cotizaciones" },]);
  return (
    <>
    <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ventas acumuladas por producto</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Distribuciones</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
            <AccumulativeDispatchFilter setOrders={setOrders} vehicles={vehicles} setVehicles={setVehicles} users={users} setUsers={setUsers} operationStatus={operationStatus} setOperationStatus={setOperationStatus}
            />
            <AccumulativeDispatchList orders={orders} />
                
            </div>
          </div>
        </div>
      </section>

    
    </>
  )
}
