import React, { useState, useEffect, useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from "react-toastify";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedWarehousesBySubsidiary } from "../../../apis/apis.logistics/apisProduct";
import { fetchedStockInTwoWarehouses } from "../../../apis/apis.buys/apisPurchase";
import { sendSupplyingData, fetchedVehiclesBySubsidiary } from "../../../apis/apis.dispatches/apisDispatch";
import { ContextAuth } from "../../../contexts/ContextAuth";

const initialSupplyingState = {
    warehouseID: 0,
    vehicleID: 0,
    vehicleTwoID: 0,
    warehouseName: "",
    vehicleLicensePlate: "",
    vehicleTwoLicensePlate: "",
    readjustmentMode:"E",
    readjustmentMotive:"",
    supplyingDate: getCurrentDate("-"),
    userID: 0,
    details: [],
    comparatives: [],
  };


export function SupplyingPage() {

    const [supplyingState, setSupplyingState] = useState(initialSupplyingState);
    const [vehicles, setVehicles] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [searchApiData, setSearchApiData] = useState([]);
    const { auth } = useContext(ContextAuth);

    const [warehouses, setWarehouses] = useState([]);
  
    const sendSupplying = async () => {
      try {
        const response = await sendSupplyingData(supplyingState);
        return response;
      } catch (error) {
        throw error;
      }
    };
    const getVehicles = async (id) => {
        try {
          const response = await fetchedVehiclesBySubsidiary({
            subsidiaryID: id,
          });
          setVehicles(response);
        } catch (error) {
          throw error;
        }
      };
    const getWarehouses = async (id) => {
      try {
        const response = await fetchedWarehousesBySubsidiary({
          subsidiaryID: id,
        });
        setWarehouses(response);
        //console.log(response)
      } catch (error) {
        throw error;
      }
    };
    const getComparativeFromProductsByWarehouse = async (outputs, inputs) => {
      try {
        const response = await fetchedStockInTwoWarehouses({
          warehouseID: outputs,
          vehicleID: inputs,
        });
        //console.log(response) ;
        //console.log(Object.keys(response)) ;
        //console.log(Object.values(response)) ;
        setSupplyingState({...supplyingState, comparatives:Object.values(response)});
        setSearchApiData(Object.values(response));
  
      } catch (error) {
        throw error;
      }
    };
  
    const handleFilter = (e) => {
        if(e.target.value == ""){
            setSupplyingState({...supplyingState, comparatives:searchApiData});
        }else{
          const filteredData = searchApiData.filter(value => {
            return (
              value.productSku.toLowerCase().includes(filterValue.toLowerCase()) ||
              value.productSaleName.toLowerCase().includes(filterValue.toLowerCase()) ||
              value.productPurchaseName.toLowerCase().includes(filterValue.toLowerCase())
                .toString()
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            );
          });
          if(filteredData.length > 0){
            setSupplyingState({...supplyingState, comparatives:filteredData});
          }
          /*else{
            setReadjustmentState([{"name": "No data"}]);
          }*/
          
        }
        setFilterValue(e.target.value);
        
      };

    const handleClickBtnSave = async () => {
        // console.log("supplyingState.comparatives", supplyingState.comparatives)
      if (supplyingState.comparatives.length) {
          if (Number(supplyingState.vehicleID) > 0) {

              // console.log("supplyingState", supplyingState);
              document.getElementById('btn-register-supplying').disabled=true;
              document.getElementById('sync').classList.remove("d-none");
  
              await sendSupplying().then((r) => {
                  if (r.status == 201) 
                      toast.success("Operacion registrada con exito!");
                  else 
                      toast.error("Ocurrio algun error");
              });
          
              document.getElementById('btn-register-supplying').disabled=false;
              document.getElementById('sync').classList.add("d-none");
              setSupplyingState(initialSupplyingState);
          } else {toast.error("Verifique el vehiculo");} 
      } else {toast.error("Verifique los items a registrar");}
    };
  
    useEffect(() => {
      getWarehouses(auth.data.subsidiary.subsidiary_id);
      getVehicles(auth.data.subsidiary.subsidiary_id);
    }, []);
  
   
    useEffect(() => {
      if(Number(supplyingState.warehouseID) > 0 && Number(supplyingState.vehicleID) > 0)
          getComparativeFromProductsByWarehouse(supplyingState.warehouseID, supplyingState.vehicleID);
    }, [supplyingState.warehouseID, supplyingState.vehicleID]);
  
    const rowBody1 = { background: "#f8f9fa" };
    const rowBody2 = { background: "#ffc10724" };
    const colBody1 = "#007bff";
    const colBody2 = "#fd7e14";
    const colBody3 = "#28a745";


  return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>ABASTECIMIENTO</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                        <a href="#">Distribuciones</a>
                        </li>
                        <li className="breadcrumb-item active">Abastecer</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                <div className="card card-default">
                    <div className="card-body p-2">                        

                    <div className="row mb-3">
                    
                    <div className="col-sm-6 col-lg-2">
                        <label className="">ALMACEN ORIGEN:</label>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <select
                            className="form-control form-control-lg text-uppercase"
                            name="warehouseID"
                            style={{color: colBody1}}
                            onChange={async (e) => {
                            
                            const found = await warehouses.find(function (
                                warehouse,
                                index
                            ) {
                                if (warehouse.warehouseID == e.target.value)
                                return true;
                            });
                            if (found) {
                                setSupplyingState({
                                ...supplyingState,
                                warehouseID: found.warehouseID,
                                warehouseName: found.warehouseName,
                                userID: auth.data.user_id,
                                });
                            }
                            }}
                            value={supplyingState.warehouseID}
                        >
                            <option value="0">Elegir</option>
                            {warehouses.map((item, index) => {
                            return (
                                <option key={index} value={item.warehouseID}>
                                {item.gang.gangID==0?item.warehouseName:item.warehouseName + " | " + item.gang.gangName} 
                                </option>
                            );
                            })}
                        </select>
                    </div>

                    <div className="col-sm-6 col-lg-2">
                        <label className="">VEHICULO DESTINO:</label>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <select
                            className="form-control form-control-lg"
                            name="vehicleID"
                            style={{color: colBody2}}
                            value={supplyingState.vehicleID}
                            onChange={(e)=> {
                              setSupplyingState({
                                ...supplyingState,
                                vehicleID: e.target.value,
                                vehicleLicensePlate: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
                              });
                            }}
                        >
                        <option value="0">Elegir</option>
                            {vehicles.map((item, index) => {
                                return (
                                    <option key={index} value={item.vehicleID}>{item.vehicleLicensePlate} | {item.gang.gangName}</option>
                                );
                            })}
                        </select>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-sm-6 col-lg-2">
                        <label className="">FECHA:</label>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                        <input
                            type="date"
                            className="form-control form-control-lg"
                            name="supplyingDate"
                            value={supplyingState.supplyingDate}
                            onChange={(e)=> setSupplyingState({
                                ...supplyingState,
                                supplyingDate: e.target.value,
                            })}
                        />
                    </div>

                    <div className="col-sm-6">
                        <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            id="btn-register-supplying"
                            onClick={handleClickBtnSave}
                            title="Abastecer"
                            style={{/*position: "fixed",bottom: "8px",right: "125px", zIndex: 10000, padding: "16px 20px", borderRadius: "50%"*/}}
                        >
                            {/*<i className="fas fa-save fa-3x"></i>*/}
                            Abastecer
                        </button>
                    </div>
                    <div className="col-sm-6">
                    <i className="fas fa-sync fa-spin fa-3x d-none" id="sync"></i>
                    </div>

                </div>



                  {supplyingState.comparatives.length?(
                    <>
                    <div className="row">
                        <div className="col-lg-12"><input type="search" className="form-control" placeholder="Buscar producto por SKU | PRODUCTO" onInput={(e)=>handleFilter(e)} value={filterValue} /></div>
                    </div>
                    <div className="table-responsive mt-3">
                        <Table className="table table-bordered mb-0">
                        <Thead>
                            <Tr className="text-center">
                            <Th className="align-middle">COD.</Th>
                            <Th className="align-middle">CLASIF.</Th>
                            <Th className="align-middle">SKU</Th>
                            <Th className="align-middle">PROD.</Th>
                            
                            <Th className="align-middle">PRECIO VENTA</Th>
                            <Th className="align-middle font-weight-bold" style={{width:'10%', color: colBody1}}>{supplyingState.warehouseName.split(' ').pop()} S. ACTUAL</Th>
                            <Th className="align-middle font-weight-bold" style={{width:'10%', color: colBody3}}>{supplyingState.vehicleLicensePlate.split(' ').pop()}<br/>S. INICIAL</Th>
                            <Th className="align-middle font-weight-bold" style={{width:'10%', color: colBody2}}>{supplyingState.vehicleLicensePlate.split(' ').pop()}<br/>S. TOTAL</Th>
                            <Th className="align-middle" style={{width:'25%'}}>CANTIDAD</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {supplyingState.comparatives.sort((a, b) => a.productCode > b.productCode ? 1 : -1,).map((item, index) => {
                            return (
                                <Tr key={index} value={item.productTariffID} style={Number(item.quantity)>0?rowBody2:rowBody1}>
                                <Td className="align-middle">{item.productCode}</Td>
                                <Td className="align-middle">{item.productClassificationDisplay}</Td>
                                <Td className="align-middle">{item.productSku}</Td>
                                <Td className="align-middle">{item.productSaleName}</Td>
                                
                                <Td className="align-middle text-right">{item.priceSale}</Td>
                                <Td className="align-middle text-center font-weight-bold" style={{color: colBody1}}>{item.stockA}</Td>
                                <Td className="align-middle text-center font-weight-bold" style={{color: colBody3}}>{item.fixedStockB}</Td>
                                <Td className="align-middle text-center font-weight-bold" style={{color: colBody2}}>{item.stockB}</Td>
                                <Td className="text-center">
                                    {item.remainingQuantityInTariffs.map((tariffObj, index) => {
                                        
                                        return (
                                            <div className="input-group mb-1" key={index}>
                                                <div className="input-group-prepend w-50">
                                                <span className={tariffObj.unitName=="CJ"?"input-group-text w-100 bg-warning px-0 text-center":"input-group-text w-100 bg-secondary px-0 text-center"}>{tariffObj.unitName}</span>
                                                </div>
                                                <input type="number" 
                                                onWheel={(e) => e.target.blur()}
                                                className="form-control text-center px-0" value={tariffObj.portion}
                                                onChange={(e) => {
                                                    
                                                    if(Number(e.target.value)>=0 && Number(e.target.value)<=Number(item.fixedStockA) /  tariffObj.quantityMinimum){
                                                        
                                                        setSupplyingState({...supplyingState, comparatives:supplyingState.comparatives.map((obj) =>{
                                                            if(obj.productID == item.productID){

                                                                obj.remainingQuantityInTariffs = item.remainingQuantityInTariffs.map((tariff)=>{
                                                                    if (Number(tariff.productTariffID) === Number(tariffObj.productTariffID)){
                                                                        
                                                                        item.quantity = item.quantity - Number(tariff.portion) * tariff.quantityMinimum;
                                                                        
                                                                        if(Number(e.target.value)>0)
                                                                            item.quantity = item.quantity +  Number(e.target.value) * tariff.quantityMinimum;
                                                                        
                                                                        tariff.portion = e.target.value;
                                                                        
                                                                    }
                                                                    
                                                                    return tariff
                                                                });
                                                               
                                                                item.stockA = item.fixedStockA - item.quantity;
                                                                item.stockB = item.fixedStockB + item.quantity;
                                                                if(item.stockA<0)document.getElementById('btn-register-supplying').disabled=true;
                                                                else document.getElementById('btn-register-supplying').disabled=false;

                                                            }
                                                            return obj;
                                                        })});

                                                    }
                                                }}
                                                />
                                                { /*<div className="input-group-append w-25"><span className="input-group-text w-100">{tariffObj.stock}</span></div>*/ }
                                            </div>
                                            
                                        );
                                    })}

                                </Td>
                                </Tr>
                            );
                            })}
                        </Tbody>
                        </Table>
                    </div>
                    </>
                  ):""}


                    </div>
                </div>
                </div>
            </section>

            
        </>
  )
}
