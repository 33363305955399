import React from "react";
import { map } from "lodash";
export function FragmentTableGang(props) {
  const { gangs, updateGang, onDeleteGang } = props;
  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr className="text-left">
              <th className="text-center align-middle" rowSpan={2}>Nº</th>
              <th className="text-center align-middle" rowSpan={2}>Nombre Grupo</th>
              <th className="text-center align-middle" rowSpan={2}>Serie</th>
              <th className="text-center align-middle" colSpan={4}>Correlativo</th>
              <th className="text-center align-middle" rowSpan={2}>Almacen<br/>Autoventa</th>
              <th className="text-center align-middle" rowSpan={2}>Almacen<br/>Envios</th>
              <th className="text-center align-middle" rowSpan={2}>Estado</th>
              <th className="text-center align-middle" rowSpan={2}>Integrantes</th>
              <th className="text-center align-middle" rowSpan={2}>Acción</th>
          </tr>
          <tr className="text-left">
            
            <th className="text-center align-middle">Preventa</th>
            <th className="text-center align-middle">Ticket</th>
            <th className="text-center align-middle">Boleta</th>
            <th className="text-center align-middle">Factura</th>
            
          </tr>
        </thead>
        <tbody>
          {map(gangs, (gang, index) => (
            <tr className="text-left" key={index}>
              <td className="align-middle text-center">{gang.gangID}</td>
              <td className="align-middle text-center">{gang.gangName}</td>
              <td className="align-middle text-center">{gang.serial}</td>
              <td className="align-middle text-center">{gang.quotationCorrelative}</td>
              <td className="align-middle text-center">{gang.ticketCorrelative}</td>
              <td className="align-middle text-center">{gang.billCorrelative}</td>
              <td className="align-middle text-center">{gang.invoiceCorrelative}</td>
              <td className="align-middle text-center">{gang.warehouseName}</td>
              <td className="align-middle text-center">{gang.shippingWarehouseName}</td>
              <td className="align-middle text-center"> <input type="checkbox" checked={gang.isEnabled} readOnly={true}/>{gang.isEnabled}</td>
              <td className="align-middle text-center">
              <ul className="list-group">
              {gang.users.map((u, index) => {
                return (
                  <li className="list-group-item" key={index} value={u.userID}>{u.userFullName}</li>
                );
              })}
              </ul>
              </td>
              <Actions
                gang={gang}
                updateGang={updateGang}
                onDeleteGang={onDeleteGang}
              />
            </tr>
          ))}
        </tbody>
      </table>
      
    </>
  );
}


function Actions(props) {
  const { gang, updateGang, onDeleteGang } = props;
  return (
    <td className="align-middle text-center">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-widget"
        className="btn btn-outline-warning btn-sm item-edit mr-1"
        onClick={() => updateGang(gang)}
      >
        <i className="fa fa-pencil-alt fa-1x"></i>
      </button>
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={() => onDeleteGang(gang)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    </td>
  );
}
