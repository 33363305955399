import React, { useEffect, useState } from "react";
import swal from "sweetalert2";
import { useHooksUser } from "../../../hooks";
import {
  FragmentHeaderUser,
  FragmentTableUser,
  FragmentAddEditUser,
  FragmentListPpe,
} from "../fragments.hrm";

import { WidgetModal } from "../../modules.widgets";
export function PageUser() {
  const [tittleModal, setTittleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [sizeModal, setSizeModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { loading, users, getUser, deleteUser } = useHooksUser();
  useEffect(() => {
    getUser();
  }, [refetch]);
  //const OpenCloseModal = () => {};
  const onRefetch = () => setRefetch((prev) => !prev);

  const addUser = () => {
    setTittleModal("Registrar Nuevo Empleado");
    setSizeModal("modal-lg");
    setContentModal(<FragmentAddEditUser onRefetch={onRefetch} user={{}} />);
  };

  const updateUser = (data) => {
    setTittleModal("Actualizar Empleado");
    setSizeModal("modal-lg");
    setContentModal(<FragmentAddEditUser onRefetch={onRefetch} user={data} />);
  };

  const onDeleteUser = async (data) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Esta seguro?",
        text: "Si acepta, No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Acepto!",
        cancelButtonText: "No, Cancelo!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteUser(data.id);
            onRefetch();
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "El empleado ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "El empleado está a salvo :)",
            "error"
          );
        }
      });
  };
  const addPpe = (data) => {
    setTittleModal("EPPS Empleado");
    setSizeModal("modal-lg");
    setContentModal(<FragmentListPpe user={data} />);
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <FragmentHeaderUser
            btnTittle="Registrar empleado"
            addUser={addUser}
          />
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {loading ? (
                  <div id="loader" className="overlay dark p-2">
                    <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                    <div className="text-bold pt-2"> Cargando... </div>
                  </div>
                ) : (
                  <FragmentTableUser
                    users={users}
                    updateUser={updateUser}
                    onDeleteUser={onDeleteUser}
                    addPpe={addPpe}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
      <WidgetModal
        children={contentModal}
        modaltittle={tittleModal}
        sizemodal={sizeModal}
      />
    </>
  );
}
