import { BASE_API } from "../../configs/ConfigsConstants";

export async function fetchedBanks() {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_cash_and_banks/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({cash_id: cashID}),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function fetchedBankOperations(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_operations_by_cash/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify( data ),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log('result', result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendNewCash(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/register_new_cash/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendNewOperation(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/register_new_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function toggleCash(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/toggle_cash/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

//--------------------Bancos----------------------------------------

export async function fetchedAllBanks(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_all_banks/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function fetchedAllOperationsBanksByDate(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_operations_by_banks/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log('result', result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendNewBankOperation(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/register_new_bank_operation/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendNewBank(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/register_new_bank/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


//--------------------Cuentas Por Pagar----------------------------------------


export async function fetchedSupplierbycash() {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_supplier_by_cash/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({cash_id: cashID}),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedAllOperationsAccountPayByCash(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_operations_accounts_pay_by_cash/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log('result', result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function saveNewAmortize(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/register_new_amortize/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedDebtors() {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_debtors/`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },      
    };
    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function fetchedAllOperationsAccountReceiveByClient(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_operations_receive_by_client/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log('result', result);
    return result;
  } catch (error) {
    throw error;
  }
}


export async function fetchedAllCashesAndBanks(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/get_all_cashes_and_banks/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log('result', result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function sendNewOperationReceive(data) {
  try {
    const url = `${BASE_API}/accounting/api/v1/register_new_operation_receive/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}