import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import {
  annularPurchaseOperationData
} from "../../../apis/apis.logistics/apisProduct";

export function OrderPurchaseList({orders, setDetailsFromOrderPurchaseReceived}) {

  const navigate = useNavigate();
  
  const annularPurchaseOperation = async (id) => {
    try {
      const response = await annularPurchaseOperationData({operationID: id});
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>

      

    <table className="table table-sm table-bordered text-center">
        <thead>
          <tr className='text-uppercase'>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              #
            </td>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              COD PEDIDO
            </td>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              Estado
            </td>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              Fecha
            </td>
            <td className="align-middle bg-navy" style={{ width: "20%" }}>
              Factura Asoc.
            </td>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              Ruc
            </td>
            <td className="align-middle bg-navy" style={{ width: "30%" }}>
              Proveedor
            </td>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              Articulos
            </td>
            <td className="align-middle bg-navy" style={{ width: "10%" }}>
              Acciones
            </td>
          </tr>
        </thead>
        <tbody>
          {orders.map((o, index) => {
            return (
              <tr key={index} value={o.operationID}>
                <td className="align-middle">{o.operationID}</td>
                <td className="align-middle">{o.code}</td>
                <td className="align-middle">
                  {o.operationStatus == "01" ? (
                    <span className="badge bg-lime">
                      {o.operationStatusDisplay}
                    </span>
                  ) : o.operationStatus == "08" ? (
                    <span className="badge bg-indigo">
                      {o.operationStatusDisplay}
                    </span>
                  ) : (
                    <span className="badge bg-fuchsia">
                      {o.operationStatusDisplay}
                    </span>
                  )}
                </td>
                <td className="align-middle">{o.operationDate}</td>
                <td className="align-middle">
                    <ul className="list-group">
                    {o.purchaseGenerated.map((p, i) => {
                        return (
                            <li 
                              role="button" 
                              data-toggle="modal" 
                              data-target="#modal-order-purchase-received" 
                              className="list-group-item" 
                              onClick={() => {
                                setDetailsFromOrderPurchaseReceived(p.details);
                              }}
                              key={i} value={p.operationID}
                            >{p.operationDocumentNumber}/{p.details.length}</li>
                        );
                    })}
                    </ul>
                </td>
                <td className="align-middle">{o.supplierDocumentNumber}</td>
                <td className="align-middle">{o.supplierName}</td>
                <td className="align-middle">{o.operationNumberItems}</td>
                <td className="align-middle">

               
                    {o.operationStatus == "01" ?

                      <div className="btn-group">
                          <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Acciones
                          </button>
                          <div className="dropdown-menu">

                            <Link to={"/buys/edit_purchase_order/"+o.operationID} className="dropdown-item" > <i className="fas fa-highlighter"></i> Editar </Link>
                            <Link to={"/buys/receive_purchase_order/"+o.operationID} className="dropdown-item" > <i className="fas fa-archive"></i> Recibir </Link>
                            <Link to={"/buys/conclude_purchase_order/"+o.operationID} className="dropdown-item" > <i className="fas fa-clipboard-check"></i> Concluir </Link>


                            <a className='dropdown-item' href='#' onClick={
                              () => {
                                if (window.confirm("¿Esta usted seguro de anular la orden?")) {
                                  annularPurchaseOperation(o.operationID)
                                  .then((r) => {
                                    if (r.status == 201) {
                                      toast.success("Orden anulada con exito!");
                                    } else {
                                      toast.error("Ocurrio algun error");
                                    }
                                  })
                                  .then(() => {
                                    //getOrderPurchases();
                                    document.getElementById("btn-filter").click();
                                  });
                                }
                            }}><i className="fas fa-ban"></i> Anular</a>

                          
                          
                        </div>
                      </div>
                      
                      :
                      o.operationStatus == "08" ?
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Acciones
                            </button>
                            <div className="dropdown-menu">

                                <Link to={"/buys/review_purchase_order/"+o.operationID} className="dropdown-item" > <i className="fas fa-highlighter"></i> Revisar </Link>


                            </div>
                        </div>
                      :
                      ""
                      }

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Link to='/buys/new_purchase_order' className="btn btn-outline-secondary mt-3" > <i className="fas fa-plus"></i> Nuevo </Link>
    </>
  )
}
