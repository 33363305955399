import React from "react";
import { map } from "lodash";
export function FragmentTablePpe(props) {
  const { ppes, updatePpe, onDeletePpe } = props;
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr className="text-left">
            <th className="text-center">Nº</th>
            <th className="text-center">Nombre EPP</th>
            <th className="text-center">Descripción</th>
            <th className="text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {map(ppes, (ppe, index) => (
            <tr className="text-left" key={index}>
              <td className="align-middle text-center">{ppe.ppeID}</td>
              <td className="align-middle">{ppe.ppeName}</td>
              <td className="align-middle">{ppe.ppeDescription}</td>
              <Actions
                ppe={ppe}
                updatePpe={updatePpe}
                onDeletePpe={onDeletePpe}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function Actions(props) {
  const { ppe, updatePpe, onDeletePpe } = props;
  return (
    <td className="align-middle text-center">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-widget"
        className="btn btn-outline-warning btn-sm item-edit mr-1"
        onClick={() => updatePpe(ppe)}
      >
        <i className="fa fa-pencil-alt fa-1x"></i>
      </button>
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={() => onDeletePpe(ppe)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    </td>
  );
}
