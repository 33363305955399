import React, { useState, useEffect, createContext } from "react";
import { setToken, getToken, removeToken } from "../apis/apis.hrm/apisToken";
import { useHooksUser } from "../hooks";

export const ContextAuth = createContext({
  auth: undefined,
  login: () => null,
  logout: () => null,
});


export function AuthProvider(props) {
  const { children } = props;
  const [auth, setAuth] = useState(undefined);
  const { getLogin } = useHooksUser();

  useEffect(() => {
    (async () => {
      const token = getToken();
      if (token) {
        const data = await getLogin(token);
        setAuth({ token, data });
      } else {
        setAuth(null);
      }
    })();
  }, []);

  const login = async (token) => {
    setToken(token);
    const data = await getLogin(token);
    setAuth({ token, data });
  };

  const logout = () => {
    if (auth) {
      removeToken();
      setAuth(null);
    }
  };

  const state_login = {
    auth,
    login,
    logout,
  };
  if (auth === undefined) return null;
  return (
    <ContextAuth.Provider value={state_login}>{children}</ContextAuth.Provider>
  );
}
