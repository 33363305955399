import { useState } from "react";
import {
  getGansApi,
  addGangApi,
  updateGangApi,
  deleteGangApi,
  getWareHousesApi,
} from "../../apis/apis.hrm/apisGang";
import { useHooksAuth } from ".";

export function useHooksGang() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gangs, setGangs] = useState(null);
  const [warehouses, setWarehouse] = useState(null);
  const { auth } = useHooksAuth();
  const getGangs = async () => {
    try {
      setLoading(true);
      const response = await getGansApi(auth.token);
      setLoading(false);
      setGangs(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const addGang = async (data) => {
    try {
      setLoading(true);
      await addGangApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const updateGang = async (data) => {
    try {
      setLoading(true);
      await updateGangApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const deleteGang = async (id) => {
    try {
      setLoading(true);
      await deleteGangApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const getWareHouses = async () => {
    try {
      setLoading(true);
      const response = await getWareHousesApi(auth.token);
      setLoading(false);
      setWarehouse(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  return {
    loading,
    error,
    gangs,
    warehouses,
    getGangs,
    addGang,
    updateGang,
    deleteGang,
    getWareHouses,
  };
}
