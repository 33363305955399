import React, { useState, useEffect, useContext, createContext } from "react";
import Select from "react-select";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { queryDocumentReniec } from "../../../apis/apis.logistics/apisSunat";
import { sendOperationData } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";

import {
  customStyles,
  personDocumentTypes,
  operationActions,
  operationTypes,
  warehouses,
  operationDocumentTypes,
  persons,
} from "../../../configs/Data";


const initialSelectedProductState = {
  productID: "",
  productName: "",
  grammage: "",
  sku: "",
  unitName: "",
  tariffs: [],
  price: "0",
  quantity: "1",
  subtotal: "0",
  productTariffID: "0",
};

const initialRegisterDataState = {
  operationTypeID: "NA",
  operationActionID: "NA",
  operationDocumentTypeID: "NA",
  operationDocumentNumber: "",
  warehouseID: "0",
  warehouseName: "",
  warehouseCategory: "",

  userID: "0",
  operationDate: getCurrentDate("-"),

  isClient: false,
  isSupplier: false,

  clientID: "0",
  clientName: "",
  clientFirstSurname: "",
  clientSecondSurname: "",
  clientDocumentType: "NA",
  clientDocumentNumber: "",
  clientFiscalAddress: "",

  supplierID: "0",
  supplierName: "",
  supplierFirstSurname: "",
  supplierSecondSurname: "",
  supplierDocumentType: "NA",
  supplierDocumentNumber: "",
  supplierFiscalAddress: "",
  details: [],
  operationAmountBase: "0",
  operationAmountIgv: "0",
  operationAmountTotal: "0",
};

export function InventoryRegisterOperation(props) {

  const { auth } = useContext(ContextAuth);
  const [selectedProduct, setSelectedProduct] = useState(initialSelectedProductState);
  const [registerData, setRegisterData] = useState(initialRegisterDataState);
  
  const { setInventories, subsidiary, warehouses, setWarehouses } =
    useContext(GlobalContext);

  const clearSelectedProductState = () => {
    setSelectedProduct({ ...initialSelectedProductState });
  };
  const clearRegisterDataState = () => {
    setRegisterData({ ...initialRegisterDataState });
  };
  const sendOperation = async () => {
    try {
      const response = await sendOperationData(registerData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getPersonData = async (
    typeDocument,
    numberDocument,
    isClient,
    isSupplier
  ) => {
    /*return persons.find((obj) => {
      return (
        obj.documentType === document && obj.documentNumber === nroDocument
      );
    });*/
    try {
      const response = await queryDocumentReniec({
        typeDocument: typeDocument,
        numberDocument: numberDocument,
        isClient: isClient,
        isSupplier: isSupplier,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
  /*
  const options = [
    { value: "4", label: "CHIZITOS 150GX48X1" },
    { value: "5", label: "CHOKIS CHISPAS 37GX16X6 PER" },
  ];
  */

  const handleChangeProductBox = async (selectOption) => {
    setSelectedProduct({
      ...selectedProduct,
      tariffs: selectOption.tariff,
      productID: selectOption.value,
      productName: selectOption.label,
      grammage: selectOption.grammage,
      sku: selectOption.sku,
      price: selectOption.tariff[0].price,
      subtotal: Number(selectOption.tariff[0].price).toFixed(2),
      productTariffID: selectOption.tariff[0].productTariffID,
      unitName: selectOption.tariff[0].unitName,
    });

    /*let sumValues = await getSumValues();
  console.log('sumValues', sumValues);*/

    //document.getElementById("productTariffID").value = selectOption.tariff[0].productTariffID;
    // console.log("productTariffID", selectOption.tariff[0].productTariffID);

  };

  const handleChangeUnitBox = async (e) => {
    const found = await selectedProduct.tariffs.find(function (post, index) {
      if (post.productTariffID == e.target.value) return true;
    });
    setSelectedProduct({
      ...selectedProduct,
      price: found.price,
      subtotal: (
        Number(found.price) * Number(selectedProduct.quantity)
      ).toFixed(2),
      productTariffID: found.productTariffID,
      unitName: found.unitName,
    });

    /*let sumValues = await getSumValues();
console.log("sumValues", sumValues);*/
  };

  const handleChangePriceAndQuantityBox = (e) => {
    let subtotal = document.getElementById("subtotal");
    if (e.target.name == "price") {
      subtotal = Number(selectedProduct.quantity) * Number(e.target.value);
      setSelectedProduct({
        ...selectedProduct,
        price: e.target.value,
        subtotal: subtotal.toFixed(2),
      });
    } else if (e.target.name == "quantity") {
      subtotal = Number(selectedProduct.price) * Number(e.target.value);
      setSelectedProduct({
        ...selectedProduct,
        quantity: e.target.value,
        subtotal: subtotal.toFixed(2),
      });
    }

    /*setSelectedProduct({
      ...selectedProduct,
      [e.target.name]: e.target.value,
    });*/
  };
  function getSumValues() {
    return registerData.details
      .map((item) => item.subtotal)
      .reduce((prev, curr) => prev + curr, 0);
  }

  const handleChangeBase = async (e) => {
    setRegisterData({
      ...registerData,
      operationAmountIgv: (Number(e.target.value) * 0.18).toFixed(2),
      operationAmountTotal: (
        Number(e.target.value) +
        Number(e.target.value) * 0.18
      ).toFixed(2),
      operationAmountBase: e.target.value,
    });
    document.getElementById("operationAmountIgv").value = (
      Number(e.target.value) * 0.18
    ).toFixed(2);
  };
  const handleChangeTotal = async (e) => {
    setRegisterData({
      ...registerData,
      operationAmountBase: (Number(e.target.value) / 1.18).toFixed(2),
      operationAmountIgv: (
        Number(e.target.value) -
        Number(e.target.value) / 1.18
      ).toFixed(2),
      operationAmountTotal: e.target.value,
    });
    document.getElementById("operationAmountIgv").value = (
      Number(e.target.value) -
      Number(e.target.value) / 1.18
    ).toFixed(2);
  };

  async function handleButtonRemove(item) {
    setRegisterData({
      ...registerData,
      details: registerData.details.filter(function (obj) {
        return (
          obj.productID.toString().concat(obj.productTariffID.toString()) !==
          item.productID.toString().concat(item.productTariffID.toString())
        );
      }),
      operationAmountBase:
        registerData.details.length > 1
          ? Number(
              Number(registerData.operationAmountBase) - item.subtotal
            ).toFixed(2)
          : "0",
      operationAmountTotal:
        registerData.details.length > 1
          ? Number(
              Number(registerData.operationAmountBase) - item.subtotal
            ).toFixed(2)
          : "0",
      operationAmountIgv: "0",
    });
  }

  const handleButtonAdd = async () => {
    const found = await registerData.details.find(function (detail, index) {
      if (
        detail.productID == selectedProduct.productID &&
        detail.productTariffID == selectedProduct.productTariffID
      )
        return true;
    });

    if (!found) {
      if (Number(selectedProduct.productID) > 0) {
        if (
          Number(selectedProduct.price) > 0 &&
          Number(selectedProduct.quantity) > 0
        ) {
          setRegisterData((prevState) => ({
            ...prevState,
            details: [...prevState.details, selectedProduct], // add new expense to expenses array
            operationAmountBase:
              Number(registerData.operationAmountBase) +
              Number(selectedProduct.subtotal), // reset this field
            operationAmountIgv: "0", // reset this field
            operationAmountTotal:
              Number(registerData.operationAmountBase) +
              Number(selectedProduct.subtotal), // reset this field
          }));
          document.getElementById("operationAmountIgv").value = "0";
          /*setSelectedProduct({
            ...selectedProduct,
            quantity: "1",
            price: "0",
            subtotal: "0",
            //productTariffID: found.productTariffID,
            //unitName: found.unitName,
          });*/
          clearSelectedProductState();
          
        } else {
          toast.error("Verifique cantidad y precio");
        }
      } else {
        toast.info("Elija un producto");
      }
    } else {
      toast.info("Ya existe este item");
    }
  };

  const handleChangeOperation = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const handleChangeOperationAction = (e) => {
    setRegisterData({
      ...registerData,
      operationActionID: e.target.value,
      operationTypeID: e.target.value == "S" ? "02" : "01",
      isClient: e.target.value == "S" ? true : false,
      isSupplier: e.target.value == "E" ? true : false,
    });
  };

  const handleChangeOperationType = (e) => {
    setRegisterData({
      ...registerData,
      operationTypeID: e.target.value,
      isClient: e.target.value == "02" ? true : false,
      isSupplier: e.target.value == "01" ? true : false,
      operationActionID: e.target.value == "03" ? "S" : "E",
    });
  };

  const handleChangeOperationDocumentType = (e) => {
    setRegisterData({
      ...registerData,
      operationDocumentTypeID: e.target.value,
      clientDocumentType: e.target.value == "03" ? "06" : "01",
      supplierDocumentType: e.target.value == "03" ? "06" : "01",
    });
  };

  const handleChangeOperationWarehouse = async (e) => {
    const found = await warehouses.find(function (warehouse, index) {
      if (warehouse.warehouseID == e.target.value) return true;
    });
    if (found) {

      setRegisterData({
        ...registerData,
        warehouseID: found.warehouseID,
        warehouseName: found.warehouseName,
        warehouseCategory: found.warehouseCategory,
        userID: auth.data.user_id,
      });

    }
  };

  const handleChangeOperationClientDocumentType = (e) => {
    setRegisterData({
      ...registerData,
      clientDocumentType: e.target.value,
      operationDocumentTypeID: e.target.value == "06" ? "03" : "01",
    });
  };

  const handleChangeOperationSupplierDocumentType = (e) => {
    setRegisterData({
      ...registerData,
      supplierDocumentType: e.target.value,
      operationDocumentTypeID: e.target.value == "06" ? "03" : "01",
    });
  };

  const handleChangeOperationClient = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDownClient = async (e) => {
    if (e.keyCode === 13) {
      
      let res = await getPersonData(
        registerData.clientDocumentType, registerData.clientDocumentNumber, true, false
      );

      if (!res.data.error) {
        setRegisterData({
          ...registerData,
          clientFirstSurname: res.data.firstSurname,
          clientName: res.data.name,
          clientSecondSurname: res.data.secondSurname,
          clientFiscalAddress: res.data.fiscalAddress,
        });
        toast.info(res.data.message);
      } else {
        setRegisterData({
          ...registerData,
          clientFirstSurname: "",
          clientName: "",
          clientSecondSurname: "",
          clientFiscalAddress: "",
        });
        toast.error(res.data.message);
      }
    }
  };

  const handleKeyDownSupplier = async (e) => {
    if (e.keyCode === 13) {

      let res = await getPersonData(
        registerData.supplierDocumentType, registerData.supplierDocumentNumber, false, true
      );

      if (!res.data.error) {
        setRegisterData({
          ...registerData,
          supplierFirstSurname: res.data.firstSurname,
          supplierName: res.data.name,
          supplierSecondSurname: res.data.secondSurname,
          supplierFiscalAddress: res.data.fiscalAddress,
        });
        toast.info(res.data.message);
      } else {
        setRegisterData({
          ...registerData,
          supplierFirstSurname: "",
          supplierName: "",
          supplierSecondSurname: "",
          supplierFiscalAddress: "",
        });
        toast.error(res.data.message);
      }
    }
  };
  const handleChangeOperationSupplier = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickBtnSave = async () => {
    if (registerData.details.length) {
      
      if (registerData.operationActionID != "NA") {
        
        if (registerData.operationTypeID != "NA") {
          
          if (registerData.warehouseID != "0") {
            // console.log("handleClickBtnSave", registerData);

              await sendOperation().then((r) => {
                if (r.status == 201) {
                  toast.success("Operacion registrada con exito!");
                  clearRegisterDataState();
                } else {
                  toast.error("Ocurrio algun error");
                }
              });
            
            
            document.getElementById("btn-close-modal-register").click();
            
          } else {toast.error("Verifique el almacen");}

        } else {toast.error("Verifique el tipo de operacion");}
      } else {toast.error("Verifique la accion");}
    } else {toast.error("Verifique los items a registrar");}
  };

  useEffect(() => {

      
    if (auth.data.user_id != undefined) {
        //console.log("auth", auth.data.user_id);
        setRegisterData({
          ...registerData,
          userID: auth.data.user_id,
        });
    }
    else{
      toast.error("Problemas con la obtencion del usuario")
    }



  }, []);

  /*useEffect(() => {
    console.log("registerData", registerData);
  }, [registerData]);*/

  return (
    <div className="modal fade" id="modal-register-operation">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Registrar operacion</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card bg-light ">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col">
                    <label className="">ACCION:</label>
                  </div>

                  <div className="col">
                    <select
                      className="form-control"
                      name="operationActionID"
                      onChange={handleChangeOperationAction}
                      value={registerData.operationActionID}
                    >
                      {operationActions.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col">
                    <label className="">TIP OPERACION:</label>
                  </div>
                  <div className="col">
                    <select
                      className="form-control"
                      name="operationTypeID"
                      onChange={handleChangeOperationType}
                      value={registerData.operationTypeID}
                    >
                      {operationTypes.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <label className="">TIP DOC OPE:</label>
                  </div>
                  <div className="col">
                    <select
                      className="form-control"
                      name="operationDocumentTypeID"
                      onChange={handleChangeOperationDocumentType}
                      value={registerData.operationDocumentTypeID}
                    >
                      {operationDocumentTypes.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col">
                    <label className="">DOC OPE:</label>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="operationDocumentNumber"
                      placeholder="Ingrese Texto"
                      onChange={handleChangeOperation}
                      value={registerData.operationDocumentNumber}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <label className="">ALMACEN:</label>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control"
                      name="warehouseID"
                      onChange={handleChangeOperationWarehouse}
                      value={registerData.warehouseID}
                    >
                      <option value="0">Elegir</option>
                      {warehouses.map((item, index) => {
                        return (
                          <option key={index} value={item.warehouseID}>
                            {item.warehouseName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-3">
                    <label className="">FECHA:</label>
                  </div>
                  <div className="col-3">
                    <input
                      type="date"
                      name="operationDate"
                      className="form-control"
                      value={registerData.operationDate}
                      onChange={handleChangeOperation}
                    />
                  </div>
                </div>
              </div>
            </div>
            {registerData.isClient ? (
              <div className="card">
                <div className="card-body">
                  <p>DATOS CLIENTE</p>
                  <hr />

                  <div className="row mb-3">
                    <div className="col-3">
                      <label className="">TIP DE DOC:</label>
                    </div>

                    <div className="col-3">
                      <select
                        className="form-control"
                        name="clientDocumentType"
                        value={registerData.clientDocumentType}
                        onChange={handleChangeOperationClientDocumentType}
                      >
                        {personDocumentTypes.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-3">
                      <label className="">DOCUMENTO:</label>
                    </div>
                    <div className="col-3">
                      <input
                        type="text"
                        className="form-control"
                        name="clientDocumentNumber"
                        placeholder="Ingrese Texto"
                        value={registerData.clientDocumentNumber}
                        onChange={handleChangeOperationClient}
                        onKeyDown={handleKeyDownClient}
                        maxLength={11}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-3">
                      <label className="">NOMBRES / RAZ SOCIAL:</label>
                    </div>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        name="clientName"
                        placeholder="Ingrese Texto"
                        value={registerData.clientName}
                        onChange={handleChangeOperationClient}
                        maxLength={200}
                      />
                    </div>
                  </div>
                  {registerData.clientDocumentType != "06" ? (
                    <div className="row mb-3">
                      <div className="col-3">
                        <label className="">APE PATERNO:</label>
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control"
                          name="clientFirstSurname"
                          placeholder="Ingrese Texto"
                          value={registerData.clientFirstSurname}
                          onChange={handleChangeOperationClient}
                          maxLength={200}
                        />
                      </div>

                      <div className="col-3">
                        <label className="">APE MATERNO:</label>
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control"
                          name="clientSecondSurname"
                          placeholder="Ingrese Texto"
                          value={registerData.clientSecondSurname}
                          onChange={handleChangeOperationClient}
                          maxLength={200}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-3">
                        <label className="">DIRECCION:</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control"
                          name="clientFiscalAddress"
                          placeholder="Ingrese Texto"
                          value={registerData.clientFiscalAddress}
                          onChange={handleChangeOperationClient}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : registerData.isSupplier ? (
              <div className="card">
                <div className="card-body">
                  <p>DATOS PROVEEDOR</p>
                  <hr />

                  <div className="row mb-3">
                    <div className="col-3">
                      <label className="">TIP DE DOC:</label>
                    </div>
                    <div className="col-3">
                      <select
                        className="form-control"
                        name="supplierDocumentType"
                        value={registerData.supplierDocumentType}
                        onChange={handleChangeOperationSupplierDocumentType}
                      >
                        {personDocumentTypes.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-3">
                      <label className="">DOCUMENTO:</label>
                    </div>
                    <div className="col-3">
                      <input
                        type="text"
                        className="form-control"
                        name="supplierDocumentNumber"
                        placeholder="Ingrese Texto"
                        value={registerData.supplierDocumentNumber}
                        onChange={handleChangeOperationSupplier}
                        onKeyDown={handleKeyDownSupplier}
                        maxLength={11}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-3">
                      <label className="">NOMBRES / RAZ SOCIAL:</label>
                    </div>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        name="supplierName"
                        placeholder="Ingrese Texto"
                        value={registerData.supplierName}
                        onChange={handleChangeOperationSupplier}
                        maxLength={200}
                      />
                    </div>
                  </div>
                  {registerData.supplierDocumentType != "06" ? (
                    <div className="row mb-3">
                      <div className="col-3">
                        <label className="">APE PATERNO:</label>
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control"
                          name="supplierFirstSurname"
                          placeholder="Ingrese Texto"
                          value={registerData.supplierFirstSurname}
                          onChange={handleChangeOperationSupplier}
                          maxLength={200}
                        />
                      </div>

                      <div className="col-3">
                        <label className="">APE MATERNO:</label>
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          className="form-control"
                          name="supplierSecondSurname"
                          placeholder="Ingrese Texto"
                          value={registerData.supplierSecondSurname}
                          onChange={handleChangeOperationSupplier}
                          maxLength={200}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-3">
                        <label className="">DIRECCION:</label>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control"
                          name="supplierFiscalAddress"
                          placeholder="Ingrese Texto"
                          value={registerData.supplierFiscalAddress}
                          onChange={handleChangeOperationSupplier}
                          maxLength={500}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              "No es cliente ni proveedor"
            )}

            <div className="row">
              <div className="col-auto table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr className="text-center">
                      <td scope="col" style={{ width: "10%" }}>
                        SKU
                      </td>
                      <td scope="col" style={{ width: "15%" }}>
                        PRODUCTO
                      </td>
                      <td scope="col" style={{ width: "15%" }}>
                        GRAMAGE
                      </td>
                      <td scope="col" style={{ width: "10%" }}>
                        PRESENTACION
                      </td>
                      <td scope="col" style={{ width: "15%" }}>
                        PRECIO
                      </td>
                      <td scope="col" style={{ width: "10%" }}>
                        CANTIDAD
                      </td>
                      <td scope="col" style={{ width: "15%" }}>
                        SUBTOTAL
                      </td>
                      <td scope="col" style={{ width: "10%" }}>
                        ACCIONES
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {props.options.length ? (
                          <Select
                            options={props.options}
                            styles={customStyles}
                            onChange={(e) => handleChangeProductBox(e)}
                            //defaultValue={props.options[0]}
                          />
                        ) : (
                          "loading"
                        )}
                      </td>
                      <td>
                        <select
                          className="form-control"
                          name="productTariffID"
                          id="productTariffID"
                          disabled={
                            selectedProduct.tariffs.length ? false : true
                          }
                          value={selectedProduct.productTariffID}
                          onChange={(e) => handleChangeUnitBox(e)}
                        >
                          {selectedProduct.tariffs.map((item, index) => {
                            return (
                              <option key={index} value={item.productTariffID}>
                                {item.unitName}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          placeholder="price"
                          value={selectedProduct.price}
                          onChange={(e) => handleChangePriceAndQuantityBox(e)}
                          maxLength={6}
                          autoComplete="off"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control text-center"
                          name="quantity"
                          placeholder="quantity"
                          value={selectedProduct.quantity}
                          onChange={(e) => handleChangePriceAndQuantityBox(e)}
                          maxLength={6}
                        />
                      </td>
                      <td>
                        <div className="d-flex">
                          <span className="p-2">S/</span>

                          <input
                            type="number"
                            className="form-control text-right"
                            name="subtotal"
                            id="subtotal"
                            placeholder="subtotal"
                            value={selectedProduct.subtotal}
                            onChange={(e) => handleChangePriceAndQuantityBox(e)}
                            readOnly
                            maxLength={6}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-warning btn-block"
                          onClick={handleButtonAdd}
                        >
                          Agregar
                        </button>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {registerData.details.map((item, index) => {
                      return (
                        <tr key={index} value={item.productTariffID}>
                          <td>{item.sku}</td>
                          <td>{item.productName}</td>
                          <td className="text-center">{item.grammage}</td>
                          <td className="text-center">{item.unitName}</td>
                          <td className="text-center">{item.price}</td>
                          <td className="text-center">{item.quantity}</td>
                          <td>
                            <div className="d-flex">
                              <span className="p-2">S/</span>
                              <input
                                type="number"
                                className="form-control text-right"
                                defaultValue={item.subtotal}
                                readOnly
                              />
                            </div>
                          </td>

                          <td>
                            <button
                              className="btn btn-outline-warning btn-block"
                              onClick={() => handleButtonRemove(item)}
                            >
                              Quitar
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={6} className="text-right">
                        MONTO BASE
                      </td>
                      <td>
                        <div className="d-flex">
                          <span className="p-2">S/</span>
                          <input
                            type="number"
                            className="form-control text-right"
                            name="operationAmountBase"
                            id="operationAmountBase"
                            value={registerData.operationAmountBase}
                            onChange={handleChangeBase}
                            maxLength={6}
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={6} className="text-right">
                        IGV(18%){" "}
                      </td>
                      <td>
                        <div className="d-flex">
                          <span className="p-2">S/</span>
                          <input
                            type="number"
                            className="form-control text-right"
                            name="operationAmountIgv"
                            id="operationAmountIgv"
                            defaultValue={registerData.operationAmountIgv}
                            readOnly
                            maxLength={6}
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={6} className="text-right">
                        TOTAL
                      </td>
                      <td>
                        <div className="d-flex">
                          <span className="p-2">S/</span>
                          <input
                            type="number"
                            className="form-control text-right"
                            name="operationAmountTotal"
                            id="operationAmountTotal"
                            value={registerData.operationAmountTotal}
                            onChange={handleChangeTotal}
                            maxLength={6}
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              id="btn-close-modal-register"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClickBtnSave}
            >
              Registrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
