import React, { useState, useEffect } from "react";
import { useHooksGang } from "../../../hooks";
import { map } from "lodash";
import swal from "sweetalert2";

const initialState = {
  gangID: 0,
  gangName: "",
  warehouseID: 0,
  warehouseName: "",
  shippingWarehouseID: 0,
  shippingWarehouseName: "",
  serial: "",
  quotationCorrelative: "0",
  ticketCorrelative: "0",
  billCorrelative: "0",
  invoiceCorrelative: "0",
  isEnabled: false,
};

export function FragmentAddEditGang(props) {
  const { onRefetch, gang } = props;
  const { addGang, updateGang, warehouses, getWareHouses } = useHooksGang();
  const [itemGang, setItemGang] = useState(initialState);
  useEffect(() => {
    getWareHouses();
  }, []);
  useEffect(() => {
    if (gang.gangID === undefined || gang.gangID === 0) {
      setItemGang(initialState);
    } else {
      setItemGang(gang);
    }
  }, [gang]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    const newValues = {
      ...itemGang,
      [name]: value,
    };
    setItemGang(newValues);
  };
  
  const handleChangeCheckbox = (e) => {
    const { target } = e;
    const { name, checked } = target;
    const newValues = {
      ...itemGang,
      [name]: checked,
    };
    setItemGang(newValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.gangName.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese el nombre del grupo!",
      });
      return false;
    }

    if (e.target.warehouseID.value === "" || e.target.warehouseID.value === 0) {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Seleccione el almacen/autoventa",
        //footer: e.target.document,
      });
      return false;
    }

    if (e.target.shippingWarehouseID.value === "" || e.target.shippingWarehouseID.value === 0) {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Seleccione el almacen/envio",
        //footer: e.target.document,
      });
      return false;
    }

    try {
      if (gang.gangID === undefined || gang.gangID === 0) {
        await addGang(itemGang);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Grupo registrado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        await updateGang(itemGang);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Grupo actualizado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      document.getElementById("close-modal").click();
      onRefetch();
    } catch (error) {
      console.log(error);
    }
  };
  

  return (
    <>
    
      <form
        id="form-person"
        className="embed-responsive p-1"
        onSubmit={handleSubmit}
        style={{ fontSize: 13 }}
      >
        <div className="card border-primary m-0">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Seleccione el almacén de autoventa
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="warehouseID"
                      value={itemGang.warehouseID}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value={0}>Seleccione</option>
                      {map(warehouses, (item) => (
                        <option key={item.warehouseID} value={item.warehouseID}>
                          {item.warehouseName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Seleccione el almacén de envio
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="shippingWarehouseID"
                      value={itemGang.shippingWarehouseID}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value={0}>Seleccione</option>
                      {map(warehouses, (item) => (
                        <option key={item.warehouseID} value={item.warehouseID}>
                          {item.warehouseName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Nombre Grupo
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="gangName"
                      maxLength={200}
                      value={itemGang.gangName}
                      onChange={handleChange}
                      placeholder="Nombre grupo"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Serie Grupo
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="serial"
                      maxLength={200}
                      value={itemGang.serial}
                      onChange={handleChange}
                      placeholder="Serie grupo"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                    Correlativo de preventa
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="quotationCorrelative"
                      maxLength={10}
                      value={itemGang.quotationCorrelative}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                    Correlativo de ticket
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="ticketCorrelative"
                      maxLength={10}
                      value={itemGang.ticketCorrelative}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                    Correlativo de boleta
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="billCorrelative"
                      maxLength={10}
                      value={itemGang.billCorrelative}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                    Correlativo de factura
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      name="invoiceCorrelative"
                      maxLength={10}
                      value={itemGang.invoiceCorrelative}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 pt-3">
                  <div className="custom-control custom-switch">
                   
                    <input
                      type="checkbox"
                      id="customSwitch2"
                      className="custom-control-input"
                      name="isEnabled"
                      checked={itemGang.isEnabled? true : false}
                      onChange={(e) => handleChangeCheckbox(e)}
                    />
                     <label className="custom-control-label font-weight-light" htmlFor="customSwitch2">
                    Estado
                    </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 pt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-success w-50"
                    >
                      <i className="fas fa-check-circle" />{" "}
                      {gang.gangID ? "Actualizar Grupo" : "Registrar Grupo"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
