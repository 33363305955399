import { LayoutsHome } from "../layouts";

import { OrderPurchasePage } from "../modules/modules.buys/pages.buys/OrderPurchasePage";
import { NewOrderPurchasePage } from "../modules/modules.buys/pages.buys/NewOrderPurchasePage";
import { EditOrderPurchasePage } from "../modules/modules.buys/pages.buys/EditOrderPurchasePage";
import { ReceiveOrderPurchasePage } from "../modules/modules.buys/pages.buys/ReceiveOrderPurchasePage";
import { EditOrderPurchaseReceivedPage } from "../modules/modules.buys/pages.buys/EditOrderPurchaseReceivedPage";
import { ConcludeOrderPurchasePage } from "../modules/modules.buys/pages.buys/ConcludeOrderPurchasePage";
import { ReviewOrderPurchasePage } from "../modules/modules.buys/pages.buys/ReviewOrderPurchasePage";
import { PurchasePage } from "../modules/modules.buys/pages.buys/PurchasePage";

const routesBUYS = [
  {
    path: "/buys/purchase_orders_issued",
    layout: LayoutsHome,
    component: OrderPurchasePage,
  },
  {
    path: "/buys/new_purchase_order",
    layout: LayoutsHome,
    component: NewOrderPurchasePage,
  },
  {
    path: "/buys/edit_purchase_order/:id",
    layout: LayoutsHome,
    component: EditOrderPurchasePage,
  },
  {
    path: "/buys/receive_purchase_order/:id",
    layout: LayoutsHome,
    component: ReceiveOrderPurchasePage,
  },
  {
    path: "/buys/edit_order_purchase_received/:id",
    layout: LayoutsHome,
    component: EditOrderPurchaseReceivedPage,
  },
  {
    path: "/buys/conclude_purchase_order/:id",
    layout: LayoutsHome,
    component: ConcludeOrderPurchasePage,
  },
  {
    path: "/buys/review_purchase_order/:id",
    layout: LayoutsHome,
    component: ReviewOrderPurchasePage,
  },
  {
    path: "/buys/purchases_issued",
    layout: LayoutsHome,
    component: PurchasePage,
  },
];
export default routesBUYS;
