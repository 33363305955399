import React, { useState, useEffect, useContext, createContext } from "react";
import { PurchaseList } from "../fragments.buys/PurchaseList";
import { PurchaseFilter } from "../fragments.buys/PurchaseFilter";
import { getCurrentDate } from "../../../configs/helpers";
import { OrderPurchaseReceived } from "../fragments.buys/OrderPurchaseReceived";
import { NewNoteCreditForm } from "../fragments.buys/NewNoteCreditForm";
import { ContextAuth } from "../../../contexts/ContextAuth";

const today = new Date().toISOString().split('T')[0];

const initialNoteCreditState = {
  purchaseID: "0",
  totalCost: "0",
  operationDocumentNumber: "",
  operationDate: today,
  motive: "",
};

export function PurchasePage() {
  const { auth } = useContext(ContextAuth);
  const [orders, setOrders] = useState([]);
  const [detailsFromOrderPurchaseReceived, setDetailsFromOrderPurchaseReceived] = useState([]);
  const [noteCredit, setNoteCredit] = useState(initialNoteCreditState);
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>COMPRAS RECIBIDAS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Compras</a>
                </li>
                <li className="breadcrumb-item active">Listado</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
              <PurchaseFilter setOrders={setOrders} />
              <PurchaseList orders={orders} setOrders={setOrders} setDetailsFromOrderPurchaseReceived={setDetailsFromOrderPurchaseReceived} />
              <OrderPurchaseReceived detailsFromOrderPurchaseReceived={detailsFromOrderPurchaseReceived} />
              <NewNoteCreditForm noteCredit={noteCredit} setNoteCredit={setNoteCredit} orders={orders} initialNoteCreditState={initialNoteCreditState} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
