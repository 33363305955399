import React, { useState, useEffect } from "react";
import { map } from "lodash";
import swal from "sweetalert2";
//import { useFormik } from "formik";
//import * as Yup from "yup";
import { useHooksSubsidiary, useHooksUser, useHooksGang } from "../../../hooks";
const initialState = {
  id: 0,
  first_name: "",
  last_name: "",
  document: "",
  phone: "",
  code: "0",
  email: "",
  password: "",
  is_superuser: false,
  is_staff: false,
  is_active: false,
  subsidiary_id: 0,
  subsidiary_name: "",
  birth_date: "",
  relative_phone: "",
  start_date: "",
  end_date: "",
  gang_id: 0,
  gang_name: "",
};

export function FragmentAddEditUser(props) {
  const { onRefetch, user } = props;
  const { addUser, updateUser } = useHooksUser();
  const [itemUser, setItemUser] = useState(initialState);
  // const [isChecked, setIsChecked] = useState(false);
  const { subsidiarys, getSubsidiarys } = useHooksSubsidiary();
  const { gangs, getGangs } = useHooksGang();
  //const [subsidiaryFormat, setSubsidiaryFormat] = useState([]);
  //console.log("users", user);
  useEffect(() => {
    getSubsidiarys();
    getGangs();
  }, []);

  useEffect(() => {
    if (user.id === undefined || user.id === 0) {
      setItemUser(initialState);
    } else {
      setItemUser(user);
    }
  }, [user]);

  const handleChange = (e) => {
    //setItemUser({ ...itemUser, [e.target.name]: e.target.value });
    const { target } = e;
    const { name, value } = target;
    const newValues = {
      ...itemUser,
      [name]: value,
    };
    setItemUser(newValues);
  };

  const handleChangeCheckbox = (e) => {
    const { target } = e;
    const { name, checked } = target;
    const newValues = {
      ...itemUser,
      [name]: checked,
    };
    setItemUser(newValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //const data = new FormData({ [e.target.name]: e.target.value });
    if (e.target.document.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese un numero de documento!",
        //footer: e.target.document,
      });
      return false;
    }
    if (e.target.first_name.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese el nombre del empleado!",
        //footer: e.target.first_name,
      });
      return false;
    }
    if (e.target.last_name.value === "") {
      swal.fire({
        icon: "error",
        title: "Ups...",
        text: "Ingrese los apellidos del empleado!",
        //footer: e.target.last_name,
      });
      return false;
    }
    if (e.target.email.value === "") {
      swal.fire({
        icon: "error",
        title: "Importante...",
        text: "Ingrese el correo electronico del empleado!",
        //footer: e.target.email,
      });
      return false;
    }

    try {
      if (user.id === undefined || user.id === 0) {
        //console.log("Register", User.id);
        if (e.target.password.value === "") {
          swal.fire({
            icon: "error",
            title: "Importante...",
            text: "Ingrese la contraseña de usuario!",
            //footer: e.target.email,
          });
          return false;
        }
        await addUser(itemUser);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Empleado registrado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        
        await updateUser(itemUser);
        swal.fire({
          position: "center",
          icon: "success",
          title: "Empleado actualizado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      document.getElementById("close-modal").click();
      onRefetch();
    } catch (error) {
      console.log("error->", error);
      swal.fire({
        icon: "error",
        title: "Error en la operación",
        text: error,
        footer: '<a href="#!">Verfique la información</a>',
      });
    }
  };

  //{formik.touched.paternal && formik.errors.paternal ? (
  // <div className="error text-warning mt-1">
  //   <strong>Ups! </strong>
  //   {formik.errors.paternal}
  //  </div>
  // ) : null}
  // Funcion que se encarga de cambiar el estado del inputName
  /* const handleInputNames = (e) => {
    setInputNames(e.target.value);
    console.log(inputNames);
    console.log(e.target.value);
  };
  const handleInputPaternal = (e) => {
    setInputPaternal(e.target.value);
  };
  const handleInputMaternal = (e) => {
    setInputMaternal(e.target.value);
  };
  const handleInputAddress = (e) => {
    setInputAddress(e.target.value);
  };
  const handleInputPhone = (e) => {
    setInputPhone(e.target.value);
  };*/

  return (
    <>
      <form
        id="form-person"
        className="embed-responsive p-1"
        onSubmit={handleSubmit}
        style={{ fontSize: 13 }}
      >
        <div className="card border-primary m-0">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row">

                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Seleccione la sucursal
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="subsidiary_id"
                      value={itemUser.subsidiary_id}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value={0}>Seleccione</option>
                      {map(subsidiarys, (item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="text-primary mb-1 mt-2">
                      Seleccione la cuadrilla
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="gang_id"
                      value={itemUser.gang_id}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value={0}>SIN CUADRILLA</option>
                      {map(gangs, (item) => (
                        <option key={item.gangID} value={item.gangID}>
                          {item.gangName}
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2">
                    <label className="text-primary mb-1 mt-2">Codigo</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="code"
                      value={itemUser.code}
                      defaultValue="0"
                      onChange={handleChange}
                      maxLength={10}
                    />
                  </div>

                  <div className="col-sm-5 col-md-5 col-lg-5">
                    <label className="text-primary mb-1 mt-2">Nombres</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="first_name"
                      maxLength={150}
                      value={itemUser.first_name}
                      //onChange={formik.handleChange}
                      onChange={handleChange}
                      // onBlur={formik.handleBlur}
                      placeholder="Nombres"
                      autoComplete="true"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 col-lg-5">
                    <label className="text-primary mb-1 mt-2">Apellidos</label>
                    <input
                      type="text"
                      maxLength={150}
                      className="form-control form-control-sm"
                      name="last_name"
                      value={itemUser.last_name}
                      onChange={handleChange}
                      placeholder="Apellidos"
                      autoComplete="true"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label className="text-primary mb-1 mt-2">
                      Nro documento
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="document"
                      maxLength={15}
                      //value={itemUser.paternal}
                      value={itemUser.document}
                      onChange={handleChange}
                      //onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                      //error={formik.errors}
                      placeholder="Nro documento"
                      autoComplete="true"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label className="text-primary mb-1 mt-2">
                      Telefono/Celular
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="phone"
                      maxLength={12}
                      //value={itemUser.maternal}
                      //onChange={handleChangeSuggest}
                      value={itemUser.phone}
                      onChange={handleChange}
                      //onChange={formik.handleChange}
                      //onBlur={formik.handleBlur}
                      //error={formik.errors}
                      placeholder="Telefono"
                      autoComplete="true"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="row m-3">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch1"
                              name="is_superuser"
                              checked={itemUser.is_superuser ? true : false}
                              value={itemUser.is_superuser ? true : false}
                              onChange={(e) => handleChangeCheckbox(e)}
                              autoComplete="true"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customSwitch1"
                            >
                              Super Usuario
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch2"
                              name="is_staff"
                              checked={itemUser.is_staff ? true : false}
                              value={itemUser.is_staff ? true : false}
                              onChange={(e) => handleChangeCheckbox(e)}
                              //on={itemUser.is_staff}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customSwitch2"
                            >
                              Permitir ingreso fuera de horario
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch3"
                              name="is_active"
                              checked={itemUser.is_active ? true : false}
                              value={itemUser.is_active ? true : false}
                              onChange={(e) => handleChangeCheckbox(e)}
                              //on={itemUser.is_active}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customSwitch3"
                            >
                              Activo
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="text-primary mb-1 mt-2">
                          Correo electronico
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          name="email"
                          maxLength={200}
                          //value={itemUser.address}
                          //onChange={handleChangeSuggest}
                          value={itemUser.email}
                          onChange={handleChange}
                          //onBlur={formik.handleBlur}
                          //error={formik.errors}
                          placeholder="Correo electronico"
                          autoComplete="true"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="text-primary mb-1 mt-2">
                          Contraseña
                        </label>
                        <input
                          type="password"
                          className="form-control form-control-sm"
                          name="password"
                          maxLength={20}
                          //value={itemUser.address}
                          //onChange={handleChangeSuggest}
                          value={itemUser.password}
                          onChange={handleChange}
                          //onBlur={formik.handleBlur}
                          //error={formik.errors}
                          placeholder="Contraseña"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label className="text-primary mb-1 mt-2">
                      Fecha de Nacimiento
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      name="birth_date"
                      maxLength={12}
                      value={itemUser.birth_date}
                      onChange={handleChange}
                      placeholder="Fecha Nacimento"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label className="text-primary mb-1 mt-2">
                      Telefono Pariente
                    </label>
                    <input
                      type="text"
                      maxLength={9}
                      className="form-control form-control-sm"
                      name="relative_phone"
                      value={itemUser.relative_phone}
                      onChange={handleChange}
                      placeholder="Telfono Pariente"
                      autoComplete="false"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label className="text-primary mb-1 mt-2">
                      Fecha inicio
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      name="start_date"
                      maxLength={12}
                      value={itemUser.start_date}
                      onChange={handleChange}
                      placeholder="Fecha Inicio"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label className="text-primary mb-1 mt-2">
                      Fecha finalización
                    </label>
                    <input
                      type="date"
                      maxLength={12}
                      className="form-control form-control-sm"
                      name="end_date"
                      value={itemUser.end_date}
                      onChange={handleChange}
                      placeholder="Fecha Termino"
                      autoComplete="false"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 pt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-sm btn-success w-50"
                    >
                      <i className="fas fa-check-circle" />{" "}
                      {user.id ? "Actualizar Empleado" : "Registrar Empleado"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
/**
const initialValues = () => {
  return {
    names: data?.name || "",
    paternal: data?.paternal || "",
    maternal: data?.maternal || "",
    address: data?.address || "",
    phone: data?.phone || "",
  };
};
function initialValues(data) {
  console.log("initialValues->", data.name);
  //console.log("-->", data.name ? data.name : "VACIO");
  //formik.setValues(data);
  if (data.name == undefined) {
    return {
      name: "",
      paternal: "",
      maternal: "",
      address: "",
      phone: "",
    };
  } else {
    return {
      name: data.name,
      paternal: data.paternal,
      maternal: data.maternal,
      address: data.address,
      phone: data.phone,
      //phone: data?.phone || "",
    };
  }
}
const validationSchema = Yup.object({
  names: Yup.string().required("Requiere Nombres"),
  paternal: Yup.string().required("Requiere Apellido Paterno"),
  maternal: Yup.string().required("Requiere Apellido Materno"),
  address: Yup.string(),
  phone: Yup.string(),
});**/
