import React, { useState, useEffect } from "react";
import { sendProductData } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";
//import firebase from "../../../configs/firebase";
import { storage } from "../../../configs/firebase";
import { v4 as uuid } from "uuid";
import {
    classificationOptions
  } from "../../../configs/Data";

const initialState = {
  productID: 0,
  code: "",
  sku: "",
  purchaseName: "",
  saleName: "",
  stockMin: "0",
  stockMax: "0",
  productBrandID: 0,
  productBrandName: "",
  path: "https://m.media-amazon.com/images/I/81ALZ7HhFDL._AC_SX342_.jpg",
  grammage: "",
  classification: "B",
  available: true,
  isDeleted: false,
  warehouseDict: [],
  productTariffList: [],
  isGroup: false,
  parentProductID: 0,
};

export function ProductForm(props) {

  const [item, setItem] = useState(initialState);
  // const [classifications, setClassifications] = useState([]);
  useEffect(() => {
    const productFound = props.products.find(
      (item2) => item2.productID === props.itemProduct.productID
    );
    if (productFound) {
      setItem({
        productID: productFound.productID,
        code: productFound.code,
        sku: productFound.sku,
        purchaseName: productFound.purchaseName,
        saleName: productFound.saleName,
        stockMin: productFound.stockMin,
        stockMax: productFound.stockMax,
        path: productFound.path,
        grammage: productFound.grammage,
        classification: productFound.classification,
        productBrandID: productFound.productBrandID,
        productBrandName: productFound.productBrandName,
        available: productFound.available,
        isDeleted: productFound.isDeleted,
        warehouseDict: productFound.warehouseDict,
        productTariffList: productFound.productTariffList,
        isGroup: productFound.isGroup,
        parentProductID: productFound.parentProductID,
      });
    } else {
      setItem(initialState);
    }
  }, [props.itemProduct]);

  const readImages = async (e) => {
    const file = e.target.files[0];

    const name = file.name;
    const lastDot = name.lastIndexOf('.');

    // const fileName = name.substring(0, lastDot);
    
    const ext = name.substring(lastDot + 1);
    const fileName = item.saleName.replaceAll(' ', '_').replaceAll('-', '_').toLowerCase() + '.' + ext;

    console.log(fileName)
    console.log(ext)
    const id = uuid();
    // const storageRef = storage.ref("guille").child("images").child(id);
    const storageRef = storage.ref("guille").child("images").child(fileName);
    await storageRef.put(file);
    storageRef.getDownloadURL().then((url)=> {
      setItem({ ...item, path: url });
    });
    //console.log(file);
  }

  function handleChange(e) {
    setItem({ ...item, [e.target.name]: e.target.value });
  }

  function handleChangeBrand(e) {
        var index = e.nativeEvent.target.selectedIndex;

    setItem({
      ...item,
      productBrandID: e.target.value,
      productBrandName: e.nativeEvent.target[index].text,
    });
  }

  function handleChangeParentProduct(e) {
        var index = e.nativeEvent.target.selectedIndex;

    setItem({
      ...item,
      parentProductID: e.target.value
    });
  }

  function handleChangeClasification(e) {
        var index = e.nativeEvent.target.selectedIndex;

    setItem({
      ...item,
      classification: e.target.value,
    });
  }

  function handleToggleClick(e) {
    setItem({ ...item, [e.target.name]: e.target.checked });
  }

  const saveProduct = async (data) => {
    try {
      const response = await sendProductData(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

const updateProducts = props.products.map((product) => {
    if (product.productID === item.productID) {
        var newItem = Object.assign({}, product);
        newItem.code = item.code;
        newItem.sku = item.sku;
        newItem.purchaseName = item.purchaseName;
        newItem.saleName = item.saleName;
        newItem.stockMin = item.stockMin;
        newItem.stockMax = item.stockMax;
        newItem.path = item.path;
        newItem.grammage = item.grammage;
        newItem.classification = item.classification;
        newItem.productBrandID = item.productBrandID;
        newItem.productBrandName = item.productBrandName;
        newItem.available = item.available;
        newItem.isDeleted = item.isDeleted;
      return newItem;
    }else{
        return product;
    }
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    if (item.classification != "0") {
        if (item.productBrandID != "0") {
            if (item.productID == 0) {
                
                saveProduct(item).then(async(res)=>{
                    item.productID = res.productID;
                    setItem(item);
                    props.setProducts((prevPlanes) => {
                    return [...prevPlanes, item];
                    });
                    toast.success("Producto registrado con exito!");

                });
                
                

            } else {
                
                saveProduct(item).then(async(res)=>{
                //var select = document.getElementById("productBrandID");
                //item.productBrandName = select.options[select.selectedIndex].text;
                //console.log("saveProduct productBrandID", item.productBrandID);
                //console.log("saveProduct productBrandName", item.productBrandName);
                //setItem(item);
                toast.success("Producto actualizado con exito!");
                props.setProducts(updateProducts);
                });
                
            }
            document.getElementById("close-modal").click();
            props.getProducts();
            props.getGroupOfProducts();
        } else {
        alert("Elija una marca. ");
        }
    } else {
        alert("Elija una clasificacion.");
    }

    //navigate("/");
  };
  return (
    <div
      className="modal fade"
      id="modal-product"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {item.productID ? "Editando " : "Creando "}un producto
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <input type="hidden" value={item.productID || 0} />

            <div className="row">
              <div className="col-sm-8">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className=" font-weight-light">Nro de orden</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ingrese ..."
                        name="code"
                        maxLength={100}
                        value={item.code || ""}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className=" font-weight-light">Sku</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ingrese ..."
                        name="sku"
                        maxLength={100}
                        value={item.sku || ""}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className=" font-weight-light">Nombre de compra:</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Ingrese ..."
                        name="purchaseName"
                        maxLength={100}
                        value={item.purchaseName || ""}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className=" font-weight-light">Nombre de venta:</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Ingrese ..."
                        name="saleName"
                        maxLength={100}
                        value={item.saleName || ""}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className=" font-weight-light">Grammage:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ingrese ..."
                        name="grammage"
                        maxLength={100}
                        value={item.grammage || ""}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className=" font-weight-light">Stock min almacen:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Ingrese ..."
                        name="stockMin"
                        maxLength={6}
                        value={item.stockMin}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className=" font-weight-light">Stock max almacen:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Ingrese ..."
                        name="stockMax"
                        maxLength={6}
                        value={item.stockMax}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                </div>

                <div className="row">

                  
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className=" font-weight-light">Clasificacion:</label>
                      <select
                        className="form-control"
                        name="classification"
                        id="classification"
                        onChange={handleChangeClasification}
                        value={item.classification}
                      >
                        <option value="0">Elige</option>

                        {classificationOptions.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className=" font-weight-light">Marca:</label>
                      <select
                        className="form-control"
                        name="productBrandID"
                        id="productBrandID"
                        onChange={handleChangeBrand}
                        value={item.productBrandID}
                      >
                        <option value="0">Elige</option>

                        {props.brands.map((item, index) => {
                          return (
                            <option key={index} value={item.productBrandID}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label  className=" font-weight-light" htmlFor="customFile">Path</label>

                      <div className="custom-file">
                        <input
                          type="file"
                          accept="image/*"
                          className="custom-file-input"
                          name="path"
                          onChange={readImages}
                          id="customFile"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Elige una imagen
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch3"
                          name="available"
                          checked={item.available}
                          onChange={handleToggleClick}
                        />
                        <label
                          className="custom-control-label font-weight-light"
                          htmlFor="customSwitch3"
                        >
                          Disponible
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch5"
                          name="isDeleted"
                          checked={item.isDeleted}
                          onChange={handleToggleClick}
                        />
                        <label
                          className="custom-control-label font-weight-light"
                          htmlFor="customSwitch5"
                        >
                          Eliminar
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch4"
                          name="isGroup"
                          checked={item.isGroup}
                          onChange={handleToggleClick}
                        />
                        <label
                          className="custom-control-label font-weight-light"
                          htmlFor="customSwitch4"
                        >
                          {item.isGroup?"Es cabeza de grupo":"No es cabeza de grupo"}
                        </label>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>

              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-12">
                    <img
                      className="img-fluid"
                      src={item.path}
                      alt="Photo"
                      readOnly
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
                    <div className="col-sm-8">
                    
                      <div className="form-group">
                        <label className=" font-weight-light">Grupo al que pertenece:</label>
                        <select
                          className="form-control"
                          name="parentProductID"
                          id="parentProductID"
                          onChange={handleChangeParentProduct}
                          value={item.parentProductID}
                          disabled={item.isGroup}
                        >
                          <option value="0">Elige</option>

                          {props.groupOfProducts.map((p, index) => {
                            return (
                              <option key={index} value={p.productID}>
                                {p.productName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    
                    </div>
                  </div>


          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-light border font-weight-light"
              data-dismiss="modal"
              id="close-modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-success font-weight-light"
              onClick={handleSubmit}
            >
              {item.productID ? "Guardar cambios" : "Registrar"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
