import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
    sendOrderPurchaseData,
  } from "../../../apis/apis.logistics/apisProduct";

export function NewOrderPurchaseOperationDetailList({ order, setOrder, initialOrderState }) {
  const rowHead = { background: "#f8f9fa" };
  const rowBody = { background: "#12121269" };
  const navigate = useNavigate();
  const sendOrderPurchase = async () => {
    try {
      const response = await sendOrderPurchaseData(order);
      return response;
    } catch (error) {
      throw error;
    }
  };
  function handleRemoveItem(item) {
    if (window.confirm("¿Esta usted seguro de eliminar el item?"))
      setOrder({
        ...order,
        operationItems: order.operationItems.filter(
          (obj) => obj.productTariffID !== item.productTariffID
        ),
      });
  }
  function generateTotals() {
    let operationAmountBase = order.operationItems.reduce(
      (acumulador, actual) => acumulador + Number(actual.amountDiscount),
      0
    );
    let operationAmountIgv = order.operationItems.reduce(
      (acumulador, actual) => acumulador + Number(actual.igv),
      0
    );
    let operationAmountTotal = order.operationItems.reduce(
      (acumulador, actual) => acumulador + Number(actual.amountIgv),
      0
    );

    document.getElementById("operationAmountBase").value =
      operationAmountBase.toFixed(2);
    document.getElementById("operationAmountIgv").value =
      operationAmountIgv.toFixed(2);
    document.getElementById("operationAmountTotal").value =
      operationAmountTotal.toFixed(2);

    setOrder({
      ...order,
      operationAmountBase: operationAmountBase.toFixed(2),
      operationAmountIgv: operationAmountIgv.toFixed(2),
      operationAmountTotal: operationAmountTotal.toFixed(2),
      operationNumberItems: order.operationItems.length,
    });
  }

  const handleClickBtnProcess = async () => {
    if (order.operationItems.length) {
        if (order.supplierDocumentNumber != "") {
          if (order.supplierName != "") {
            console.log(order)
            await sendOrderPurchase()
              .then((r) => {
                setOrder({
                  ...order,
                  operationID: r.operationID,
                  operationCorrelative: r.operationCorrelative,
                });

                return r.status;
              })
              .then((status) => {
                if (status == 201) {
                  toast.success("Operacion registrada con exito!");
                  setOrder(initialOrderState);
                  navigate('/buys/purchase_orders_issued');
                } else {
                  toast.error("Ocurrio algun error");
                }
              });

          } else {
            toast.error("Verifique el nombre del proveedor");
          }
        } else {
          toast.error("Verifique el numero de documento del proveedor");
        }
    } else {
      toast.error("Verifique los items a registrar");
    }
  };

  useEffect(() => {
    generateTotals();
  }, [order.operationItems]);

  return (
    <>
      <div className="table-responsive">
        <table className="table table-sm table-bordered text-center">
          <thead>
            <tr className="" style={rowHead}>
              <td className="align-middle font-weight-light">SKU</td>
              <td className="align-middle font-weight-light">PRODUCTO</td>
              <td className="align-middle font-weight-light">UNIDAD</td>
              <td className="align-middle font-weight-light">
                CANTIDAD ORDENADA
              </td>
              <td className="align-middle">NUEVO COSTO</td>
              <td className="align-middle">V VTA BRUTA</td>
              <td className="align-middle">%DSCTO</td>
              <td className="align-middle bg-aqua-marine">VALOR DSCTO</td>
              <td className="align-middle">V VTA NETA</td>
              {/*<td className="align-middle">IGV</td>
              <td className="align-middle">TOTAL A PAGAR</td>*/}
              <td className="align-middle">ELI.</td>
            </tr>
          </thead>
          <tbody>
            {order.operationItems.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="align-middle">{item.sku}</td>
                  <td className="align-middle text-left">{item.productName}<br/><span className="small">FEC. VTO: {item.expireDate}</span></td>
                  <td className="align-middle">{item.unitName}</td>
                  
                  <td className="align-middle">
                    <input
                        type="number"
                        className="form-control text-center"
                        value={item.quantityOrdered}
                        onWheel={e => e.target.blur()}
                        onChange={async (e) => {
                            let amount, discount, amountDiscount, igv, amountIgv, base, total;

                            if(Number(e.target.value)>=0 && Number(e.target.value)<=100000)
                            setOrder({...order, operationItems:order.operationItems.map((obj) =>{
                                if(obj.productTariffID == item.productTariffID){
                                    obj.quantityOrdered=e.target.value;
                                  
                                    amount = Number(item.price) * Number(e.target.value);
                                    discount = (Number(amount) * Number(item.percentageDiscount)) / 100;
                                    amountDiscount = Number(amount) - Number(discount);

                                    if (order.conditionIgv == "02") {
                                      base = amountDiscount;
                                      igv = (base * Number(order.percentageIgv)) / 100;
                                      total = base + Number(igv);
                                    } else if (order.conditionIgv == "01") {
                                      base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
                                      igv = amountDiscount - base;
                                      total = amountDiscount;
                                    } else if (order.conditionIgv == "NA") {
                                      base = amountDiscount;
                                      igv = 0;
                                      total = base + igv;
                                    }

                                    obj.amount = amount.toFixed(2);
                                    obj.discount = discount.toFixed(2);
                                    obj.amountDiscount = base.toFixed(2);
                                    obj.igv = igv.toFixed(2);
                                    obj.amountIgv = total.toFixed(2);


                                }
                                return obj;
                            })});
                        }}
                        maxLength={6}
                        autoComplete="off"
                    />
                  </td>

                  <td className="align-middle text-right">
                    <input
                        type="number"
                        className="form-control text-center"
                        value={item.price}
                        onWheel={e => e.target.blur()}
                        onChange={async (e) => {
                            let amount, discount, amountDiscount, igv, amountIgv, base, total;

                            if(Number(e.target.value)>=0 && Number(e.target.value)<=100000)
                            setOrder({...order, operationItems:order.operationItems.map((obj) =>{
                                if(obj.productTariffID == item.productTariffID){
                                    obj.price=e.target.value;
                                  
                                    amount = Number(item.quantityOrdered) * Number(e.target.value);
                                    discount = (Number(amount) * Number(item.percentageDiscount)) / 100;
                                    amountDiscount = Number(amount) - Number(discount).toFixed(2);

                                    if (order.conditionIgv == "02") {
                                      base = amountDiscount;
                                      igv = (base * Number(order.percentageIgv)) / 100;
                                      total = base + Number(igv);
                                    } else if (order.conditionIgv == "01") {
                                      base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
                                      igv = amountDiscount - base;
                                      total = amountDiscount;
                                    } else if (order.conditionIgv == "NA") {
                                      base = amountDiscount;
                                      igv = 0;
                                      total = base + igv;
                                    }

                                    obj.amount = amount.toFixed(2);
                                    obj.discount = discount.toFixed(2);
                                    obj.amountDiscount = base.toFixed(2);
                                    obj.igv = igv.toFixed(2);
                                    obj.amountIgv = total.toFixed(2);


                                }
                                return obj;
                            })});
                        }}
                        maxLength={6}
                        autoComplete="off"
                    />

                  </td>
                  <td className="align-middle text-right">
                    {"S/ "}
                    {item.amount}
                  </td>
                  <td className="align-middle">

                    <input
                        type="number"
                        className="form-control text-center"
                        value={item.percentageDiscount}
                        onWheel={e => e.target.blur()}
                        onChange={async (e) => {
                            let amount, discount, amountDiscount, igv, amountIgv, base, total;

                            if(Number(e.target.value)>=0 && Number(e.target.value)<=100)
                            setOrder({...order, operationItems:order.operationItems.map((obj) =>{
                                if(obj.productTariffID == item.productTariffID){
                                    obj.percentageDiscount=e.target.value;
                                  
                                    discount = Math.round((Math.round(((Number(item.amount) * Number(e.target.value)) / 100) * 1000) / 1000) * 100) / 100;
                                    amountDiscount = Number(item.amount) - Number(discount);
                                    if (order.conditionIgv == "02") {
                                      base = amountDiscount;
                                      igv = (base * Number(order.percentageIgv)) / 100;
                                      total = base + Number(igv);
                                    } else if (order.conditionIgv == "01") {
                                      base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
                                      
                                      igv = amountDiscount - base;
                                      total = amountDiscount;
                                    } else if (order.conditionIgv == "NA") {
                                      base = amountDiscount;
                                      igv = 0;
                                      total = base + igv;
                                    }

                                    obj.discount = discount.toFixed(2);
                                    obj.amountDiscount = base.toFixed(2);
                                    obj.igv = igv.toFixed(2);
                                    obj.amountIgv = total.toFixed(2);


                                }
                                return obj;
                            })});
                        }}
                        maxLength={6}
                        autoComplete="off"
                    />

                  </td>
                  <td className="align-middle text-right bg-aqua-marine">

                    <input
                        type="number"
                        className="form-control text-center"
                        value={item.discount}
                        onWheel={e => e.target.blur()}
                        onChange={async (e) => {
                            let amount, discount, amountDiscount, igv, amountIgv, base, total;

                            //if(Number(e.target.value)>=0 && Number(e.target.value)<=100)
                              setOrder({...order, operationItems:order.operationItems.map((obj) =>{
                                if(obj.productTariffID == item.productTariffID){
                                    obj.discount=e.target.value;
                                  
                                    amountDiscount = Number(item.amount) - Number(obj.discount);
                                    if (order.conditionIgv == "02") {
                                      base = amountDiscount;
                                      igv = (base * Number(order.percentageIgv)) / 100;
                                      total = base + Number(igv);
                                    } else if (order.conditionIgv == "01") {
                                      base = amountDiscount / (1 + Number(order.percentageIgv) / 100);
                                      
                                      igv = amountDiscount - base;
                                      total = amountDiscount;
                                    } else if (order.conditionIgv == "NA") {
                                      base = amountDiscount;
                                      igv = 0;
                                      total = base + igv;
                                    }

                                    obj.amountDiscount = base.toFixed(2);
                                    obj.igv = igv.toFixed(2);
                                    obj.amountIgv = total.toFixed(2);

                                }
                                return obj;
                            })});
                          //else console.log("negative")
                        }}
                        maxLength={6}
                        autoComplete="off"
                    />








                  </td>
                  <td className="align-middle text-right">
                    {"S/ "}
                    {item.amountDiscount}
                  </td>
                  {/*<td className="align-middle text-right">
                    {"S/ "}
                    {item.igv}
                  </td>
                  <td className="align-middle text-right">
                    {"S/ "}
                    {item.amountIgv}
                  </td>*/}
                  <td className="align-middle">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={(e) => handleRemoveItem(item)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>

            <tr>
              <td colSpan={8} className="text-right">SUBTOTAL</td>
              <td className="text-right" id="operationAmountBase"> {"S/ "}{order.operationAmountBase}</td>
              <td className="text-right"></td>
            </tr>

            <tr>
              <td colSpan={8} className="text-right">I.G.V. 18%</td>
              <td className="text-right" id="operationAmountIgv"> {"S/ "}{order.operationAmountIgv}</td>
              <td className="text-right"></td>
            </tr>

            <tr>
              <td colSpan={8} className="text-right">TOTAL VENTA</td>
              <td className="text-right" id="operationAmountTotal"> {"S/ "}{order.operationAmountTotal}</td>
              <td className="text-right"></td>
            </tr>

          </tfoot>
        </table>
      </div>
      <Link to='/buys/purchase_orders_issued' className="btn btn-primary mb-3" > <i className="fas fa-list"></i> Orden de Compras </Link>
      <button
              type="button"
              className="btn btn-success float-right"
              onClick={handleClickBtnProcess}
            >
              Procesar orden de compra
            </button>
    </>
  );
}
