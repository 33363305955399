import React, { useState } from "react";
import { loginApi } from "../../../apis/apis.hrm/apisUsers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHooksAuth } from "../../../hooks";
export function FragmentLoginForm() {
  const [status, setStatus] = useState(false);
  const { login } = useHooksAuth();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (formValue) => {
      try {
        const response = await loginApi(formValue);
        const { access } = response;
        login(access);
        setStatus(false);
      } catch (error) {
        setStatus(true);
        console.log(error);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      {status ? (
        <div
          className="alert alert-danger alert-dismissible fade w-100 show small"
          role="alert"
        >
          Correo y/o contraseña invalidos!
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">x</span>
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="input-group mb-3">
        <input
          type="email"
          name="email"
          className="form-control login"
          autoComplete="true"
          placeholder="Correo electrónico"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //error={formik.errors.email}
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-envelope" />
          </div>
        </div>
      </div>
      {formik.touched.email && formik.errors.email ? (
        <div className="error alert alert-danger alert-dismissible fade w-100 show small">
          <strong>Ups! </strong>
          {formik.errors.email}
        </div>
      ) : null}
      <div className="input-group mb-3">
        <input
          type="password"
          name="password"
          className="form-control login"
          placeholder="Contraseña"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors}
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-lock" />
          </div>
        </div>
      </div>
      {formik.touched.password && formik.errors.password ? (
        <div className="error alert alert-danger alert-dismissible fade w-100 show small">
          <strong>Ups! </strong>
          {formik.errors.password}
        </div>
      ) : null}
      <div className="row">
        <div className="col-6">
          <div className="icheck-primary">
            <input type="checkbox" id="remember" />
            <label htmlFor="remember">Recordar</label>
          </div>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary btn-block">
            Iniciar sesión
          </button>
        </div>
      </div>
    </form>
  );
}
const initialValues = {
  email: "",
  password: "",
};
/*const onSubmit = async (formvalues) => {
    try {
      const response = await loginApi(formvalues);
      const { access } = response;
      login(access);
      console.log(access);
    } catch (error) {
      console.log(error);
    }
  };
  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Requiere correo electronico";
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Formato de correo electronico invalido";
      }
    }
    if (!values.password) {
      errors.password = "Requiere contraseña";
    }
    return errors;
  };*/
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Formato de correo invalido")
    .required("Requiere correo electronico"),
  password: Yup.string().required("Requiere contraseña"),
});
