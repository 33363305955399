import { useState } from "react";
import {
  getPpeUsersApi,
  addPpeUserApi,
  updatePpeUserApi,
  deletePpeUserApi,
} from "../../apis/apis.hrm/apisPpeUser";
import { useHooksAuth } from ".";

export function useHooksPpeUser() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ppeusers, setPpeUsers] = useState(null);
  const { auth } = useHooksAuth();
  const getPpeUsers = async (id) => {
    try {
      setLoading(true);
      const response = await getPpeUsersApi(id, auth.token);
      setLoading(false);
      setPpeUsers(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const addPpeUser = async (data) => {
    try {
      setLoading(true);
      await addPpeUserApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const updatePpeUser = async (data) => {
    try {
      setLoading(true);
      await updatePpeUserApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const deletePpeUser = async (id) => {
    try {
      setLoading(true);
      await deletePpeUserApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    loading,
    error,
    ppeusers,
    getPpeUsers,
    addPpeUser,
    updatePpeUser,
    deletePpeUser,
  };
}
