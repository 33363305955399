import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { sendPurchaseOperationData } from "../../../apis/apis.logistics/apisProduct";
import { Link, useNavigate } from "react-router-dom";
export function ReviewOrderPurchaseList({
  order,
  setOrder,
  initialOrderState,
}) {
  const rowHead = { background: "#121212a1" };
  const rowBody = { background: "#3498db9e" };
  const sendPurchase = async () => {
    try {
      const response = await sendPurchaseOperationData(order);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const navigate = useNavigate();
  return (

    <>
      <div className="table-responsive">
        <table className="table table-sm table-bordered text-center">
          <thead>
            <tr className="" style={rowHead}>
              <td className="align-middle font-weight-light">SKU</td>
              <td className="align-middle font-weight-light">PRODUCTO</td>
              <td className="align-middle font-weight-light">UNIDAD</td>

              <td className="align-middle font-weight-light">
                CANTIDAD ORDENADA
              </td>

              <td className="align-middle">CANTIDAD FALTANTE</td>
              <td className="align-middle">CANTIDAD COMPRADA</td>
            </tr>
          </thead>
          <tbody>
            {order.operationItems.map((item, index) => {
              return (
                <tr key={index}>
                  
                  <td className="align-middle">{item.sku}</td>
                  <td className="align-middle text-left">{item.productName}</td>
                  <td className="align-middle">{item.unitName}</td>

                  <td className="align-middle">{item.quantityOrdered}</td>
                    

                  <td className="align-middle">
                    {Number(item.quantityOrdered) - Number(item.quantityReceived) - Number(item.quantity)}
                  </td>

                  <td className="align-middle">
                    {Number(item.quantityReceived)}
                  </td>

                 
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Link to='/buys/new_purchase_order' className="btn btn-outline-secondary mb-3" > <i className="fas fa-plus"></i> Nuevo </Link>
      <Link to='/buys/purchase_orders_issued' className="btn btn-outline-secondary mb-3" > <i className="fas fa-list"></i> Orden de Compras </Link>

    </>
  );
}
