import React, { useState, useEffect, useContext, createContext } from "react";
import Select from "react-select";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { queryDocumentReniec } from "../../../apis/apis.logistics/apisSunat";
import { fetchedGangs, fetchedProductsByWarehouse, fetchedRoute, sendOperationData } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";

import {
  customStyles,
  personDocumentTypes,
  operationActions,
  operationTypes,
  warehouses,
  operationDocumentTypes,
  persons,
} from "../../../configs/Data";


const initialSelectedProductState = {
  productID: "",
  productName: "",
  productCode: "",
  grammage: "",
  sku: "",
  unitName: "",
  tariffs: [],
  price: "0",
  quantity: "1",
  subtotal: "0",
  stock: "0",
  productTariffID: "0",
};

const initialRegisterDataState = {
  operationTypeID: "02",
  operationActionID: "E",
  operationDocumentTypeID: "01",
  operationDocumentNumber: "",
  warehouseID: "0",
  gangID: "0",
  warehouseName: "",
  warehouseCategory: "",

  userID: "0",
  operationDate: getCurrentDate("-"),

  isClient: true,
  isSupplier: false,

  clientID: "0",
  clientName: "",
  clientFirstSurname: "",
  clientSecondSurname: "",
  clientDocumentType: "NA",
  clientDocumentNumber: "",
  clientFiscalAddress: "",

  supplierID: "0",
  supplierName: "",
  supplierFirstSurname: "",
  supplierSecondSurname: "",
  supplierDocumentType: "NA",
  supplierDocumentNumber: "",
  supplierFiscalAddress: "",
  details: [],
  operationAmountBase: "0",
  operationAmountIgv: "0",
  operationAmountTotal: "0",
};

export function InventoryRegisterSaleOperation(props) {

  const { auth } = useContext(ContextAuth);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(initialSelectedProductState);
  const [registerData, setRegisterData] = useState(initialRegisterDataState);

  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  
  /*const { setInventories, subsidiary, warehouses, setWarehouses } =
    useContext(GlobalContext);*/

  const clearSelectedProductState = () => {
    setSelectedProduct({ ...initialSelectedProductState });
  };
  const clearRegisterDataState = () => {
    setRegisterData({ ...initialRegisterDataState });
    setClients([]);
    setProducts([]);
  };
  const sendOperation = async () => {
    try {
      const response = await sendOperationData(registerData);
      return response;
    } catch (error) {
      throw error;
    }
  };

    const handleChangeOperationClient = (selectOption) => {
        setRegisterData({
            ...registerData,
            clientID: selectOption.value,
            clientName: selectOption.label,
        });
    };


  const handleChangePriceAndQuantityBox = (e) => {
    let subtotal = document.getElementById("subtotal");
    if (e.target.name == "price") {
      subtotal = Number(selectedProduct.quantity) * Number(e.target.value);
      setSelectedProduct({
        ...selectedProduct,
        price: e.target.value,
        subtotal: subtotal.toFixed(2),
      });
    } else if (e.target.name == "quantity") {
        if(Number(e.target.value)<=selectedProduct.stock){
            subtotal = Number(selectedProduct.price) * Number(e.target.value);
            setSelectedProduct({
                ...selectedProduct,
                quantity: e.target.value,
                subtotal: subtotal.toFixed(2),
            });
        }
      
    }


  };

  function getSumValues() {
    return registerData.details
      .map((item) => item.subtotal)
      .reduce((prev, curr) => prev + curr, 0);
  }

  

  async function handleButtonRemove(item) {
    setRegisterData({
      ...registerData,
      details: registerData.details.filter(function (obj) {
        return (
          obj.productID.toString().concat(obj.productTariffID.toString()) !==
          item.productID.toString().concat(item.productTariffID.toString())
        );
      }),
      operationAmountBase:
        registerData.details.length > 1
          ? Number(
              Number(registerData.operationAmountBase) - item.subtotal
            ).toFixed(2)
          : "0",
      operationAmountTotal:
        registerData.details.length > 1
          ? Number(
              Number(registerData.operationAmountBase) - item.subtotal
            ).toFixed(2)
          : "0",
      operationAmountIgv: "0",
    });
  }

  const handleButtonAdd = () => {
    const found = registerData.details.find(function (detail, index) {
      if (detail.productTariffID == selectedProduct.productTariffID)
        return true;
    });

    if (!found) {
      if (Number(selectedProduct.productID) > 0) {
        if (
          Number(selectedProduct.price) > 0 &&
          Number(selectedProduct.quantity) > 0
        ) {
          setRegisterData((prevState) => ({
            ...prevState,
            details: [...prevState.details, selectedProduct], // add new expense to expenses array
            operationAmountBase:
              Number(registerData.operationAmountBase) +
              Number(selectedProduct.subtotal), // reset this field
            operationAmountIgv: "0", // reset this field
            operationAmountTotal:
            Number(Number(registerData.operationAmountBase) + Number(selectedProduct.subtotal)).toFixed(2), // reset this field
          }));
          document.getElementById("operationAmountIgv").value = "0";

          clearSelectedProductState();
          
        } else {
          toast.error("Verifique cantidad y precio");
        }
      } else {
        toast.info("Elija un producto");
      }
    } else {
      toast.info("Ya existe este item");
    }
  };

  const handleChangeOperation = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };



  const handleClickBtnSave = async () => {
    if (registerData.details.length) {
      

        
        if (registerData.operationTypeID != "NA") {
          
          if (registerData.warehouseID != "0") {
          if (registerData.clientID != "0") {
            console.log("handleClickBtnSave", registerData);

              await sendOperation().then((r) => {
                if (r.status == 201) {
                  toast.success("Operacion registrada con exito!");
                  clearRegisterDataState();
                } else {
                  toast.error("Ocurrio algun error");
                }
              });
            
            
            document.getElementById("btn-close-modal-register").click();
            
          } else {toast.error("Verifique el cliente");}
          } else {toast.error("Verifique el almacen");}

        } else {toast.error("Verifique el tipo de operacion");}

    } else {toast.error("Verifique los items a registrar");}
  };

  const getRoute = async (data) => {
    try {
      const response = await fetchedRoute(data);

      // console.log(response);

        response.forEach((client) => {
            setClients((prevState) => [
                ...prevState,
                {
                value: client["personID"],
                label: client["name"] + " | " + client["routeStatusDisplay"],
                routeDate: client["routeDate"],
                },
            ]); // add new expense to expenses array
        });

      return response;
    } catch (error) {
      throw error;
    }
  };


  const handleChangeProductBox = async (selectOption) => {
    setSelectedProduct({
      ...selectedProduct,
      // tariffs: selectOption.tariff,
      productID: selectOption.productID,
      productTariffID: selectOption.productTariffID,

      productName: selectOption.productSaleName,
      // grammage: selectOption.grammage,
      sku: selectOption.sku,
      stock: selectOption.stock>=0?selectOption.stock:0,
      price: selectOption.price>=0?selectOption.price:0,
      // price: selectOption.tariff[0].price,
      // subtotal: Number(selectOption.tariff[0].price).toFixed(2),
      // productTariffID: selectOption.tariff[0].productTariffID,
      // unitName: selectOption.tariff[0].unitName,
    });

  };

  const getProducts = async (data) => {
    try {
        const response = await fetchedProductsByWarehouse(data);

        // console.log(response);

        response.forEach((product) => {
            setProducts((prevState) => [
                ...prevState,
                {
                    value: product["productID"],
                    label: product["productCode"] + " | " + product["productSku"] + " | " + product["productSaleName"],
                    stock: product["stock"],
                    price: product["priceSale"],
                    productID: product["productID"],
                    productSaleName: product["productSaleName"],
                    productTariffID: product["productTariffID"],
                    sku: product["productSku"],
                },
            ]); // add new expense to expenses array
        });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const getWarehouses = async () => {
    try {
      const response = await fetchedGangs();
      setWarehouses(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleChangeOperationWarehouse = (e) => {

    const found = warehouses.find(function (warehouse, index) {
      if (warehouse.warehouseID == e.target.value) return true;
    });
    if (found) {
        setClients([]);
        setProducts([]);
        let current = new Date();
        let today = current.toLocaleDateString('en-US',{weekday: 'long'});
        let weekday;
        switch (today) {
            case "Monday":
              weekday = 0;
              break;
            case "Tuesday":
              weekday = 1;
              break;
            case "Wednesday":
              weekday = 2;
              break;
            case "Thursday":
              weekday = 3;
              break;
            case "Friday":
              weekday = 4;
              break;
            case "Saturday":
              weekday = 5;
              break;
            case "Sunday":
              weekday = 6;
              break;
            default: 
              console.log("Wrong input!");
              break;
        }
        
        getRoute({gangID: found.gangID, visitDay: weekday});
        getProducts({warehouseID: found.warehouseID});
        

        setRegisterData({
            ...registerData,
            warehouseID: found.warehouseID,
            gangID: found.gangID,
            warehouseName: found.warehouseName,
            // warehouseCategory: found.warehouseCategory,
            userID: auth.data.user_id,
        });

    }
  };

  useEffect(() => {

      
    if (auth.data.user_id != undefined) {
        getWarehouses();
        //console.log("auth", auth.data.user_id);
        setRegisterData({
          ...registerData,
          userID: auth.data.user_id,
        });
    }
    else{
      toast.error("Problemas con la obtencion del usuario")
    }



  }, []);

    return (
        <div className="modal fade" id="modal-register-sale-operation">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Registrar Venta</h4>
                        <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="card bg-light ">
                            <div className="card-body">


                                
                                <div className="row mb-3">
                                <div className="col-3">
                                    <label className="">CUADRILLA:</label>
                                </div>
                                <div className="col-3">
                                    <select
                                    className="form-control"
                                    name="warehouseID"
                                    onChange={handleChangeOperationWarehouse}
                                    value={registerData.warehouseID}
                                    >
                                    <option value="0">Elegir</option>
                                    {warehouses.map((item, index) => {
                                        return (
                                        <option key={index} value={item.warehouseID}>
                                            {item.gangName} | {item.warehouseName}
                                        </option>
                                        );
                                    })}
                                    </select>
                                </div>
                                <div className="col-3">
                                    <label className="">FECHA:</label>
                                </div>
                                <div className="col-3">
                                    <input
                                    type="date"
                                    name="operationDate"
                                    className="form-control"
                                    value={registerData.operationDate}
                                    onChange={handleChangeOperation}
                                    />
                                </div>
                                </div>

                                <div className="row">
                                <div className="col-3">
                                <label className="">CLIENTE:</label>
                                </div>
                                <div className="col-9">
                                {clients.length ? (
                                    <Select
                                        options={clients}
                                        styles={customStyles}
                                        onChange={(e) => handleChangeOperationClient(e)}
                                        //defaultValue={props.options[0]}
                                    />
                                    ) : (
                                    <select className="form-control" disabled></select>
                                    )}
                                </div>
                            </div>
                            </div>

                        </div>
                        
                        <table className="table table-bordered">
                            <thead>
                                <tr className="text-center">
                                <td scope="col" style={{ width: "10%" }}>
                                    SKU
                                </td>
                                <td scope="col" style={{ width: "30%" }}>
                                    PRODUCTO
                                </td>
                                <td scope="col" style={{ width: "10%" }}>
                                    STOCK
                                </td>
                                <td scope="col" style={{ width: "15%" }}>
                                    PRECIO
                                </td>
                                <td scope="col" style={{ width: "10%" }}>
                                    CANTIDAD
                                </td>
                                <td scope="col" style={{ width: "15%" }}>
                                    SUBTOTAL
                                </td>
                                <td scope="col" style={{ width: "10%" }}>
                                    ACCIONES
                                </td>
                                </tr>
                                <tr>
                                <td colSpan={2}>
                                    {products.length ? (
                                    <Select
                                        options={products}
                                        styles={customStyles}
                                        onChange={(e) => handleChangeProductBox(e)}
                                        //defaultValue={props.options[0]}
                                    />
                                    ) : (
                                        <select className="form-control" disabled></select>
                                    )}
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="stock"
                                        id="stock"
                                        value={selectedProduct.stock}
                                        readOnly
                                    />
                                    
                                </td>
                                <td>
                                    <input
                                    type="number"
                                    className="form-control"
                                    name="price"
                                    placeholder="price"
                                    value={selectedProduct.price}
                                    onChange={(e) => handleChangePriceAndQuantityBox(e)}
                                    maxLength={6}
                                    autoComplete="off"
                                    />
                                </td>
                                <td>
                                    <input
                                    type="number"
                                    className="form-control text-center"
                                    name="quantity"
                                    placeholder="quantity"
                                    value={selectedProduct.quantity}
                                    onChange={(e) => handleChangePriceAndQuantityBox(e)}
                                    maxLength={6}
                                    />
                                </td>
                                <td>
                                    <div className="d-flex">
                                    <span className="p-2">S/</span>
            
                                    <input
                                        type="number"
                                        className="form-control text-right"
                                        name="subtotal"
                                        id="subtotal"
                                        placeholder="subtotal"
                                        value={selectedProduct.subtotal}
                                        onChange={(e) => handleChangePriceAndQuantityBox(e)}
                                        readOnly
                                        maxLength={6}
                                    />
                                    </div>
                                </td>
                                <td>
                                    <button
                                    className="btn btn-outline-warning btn-block"
                                    onClick={handleButtonAdd}
                                    >
                                    Agregar
                                    </button>
                                </td>
                                </tr>
                            </thead>
                            <tbody>
                                {registerData.details.map((item, index) => {
                                return (
                                    <tr key={index} value={item.productTariffID}>
                                    <td>{item.sku}</td>
                                    <td>{item.productName}</td>
                                    <td className="text-center">{item.productCode }</td>
                                    <td className="text-center">{item.price}</td>
                                    <td className="text-center">{item.quantity}</td>
                                    <td>
                                        <div className="d-flex">
                                        <span className="p-2">S/</span>
                                        <input
                                            type="number"
                                            className="form-control text-right"
                                            defaultValue={item.subtotal}
                                            readOnly
                                        />
                                        </div>
                                    </td>
            
                                    <td>
                                        <button
                                        className="btn btn-outline-warning btn-block"
                                        onClick={() => handleButtonRemove(item)}
                                        >
                                        Quitar
                                        </button>
                                    </td>
                                    </tr>
                                );
                                })}
                            </tbody>
                            <tfoot>
                                
                                <tr>
                                <td colSpan={5} className="text-right">
                                    TOTAL
                                </td>
                                <td>
                                    <div className="d-flex">
                                    <span className="p-2">S/</span>
                                    <input
                                        type="number"
                                        className="form-control text-right"
                                        name="operationAmountTotal"
                                        id="operationAmountTotal"
                                        value={registerData.operationAmountTotal}
                                        readOnly
                                        maxLength={6}
                                    />
                                    </div>
                                </td>
                                <td></td>
                                </tr>
                            </tfoot>
                        </table>
            



                    </div>
                    <div className="modal-footer justify-content-between">
                        <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                        id="btn-close-modal-register"
                        >
                        Close
                        </button>
                        <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleClickBtnSave}
                        >
                        Registrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
