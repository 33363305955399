import { useState } from "react";
import {
  getSubsidiarysApi,
  addSubsidiaryApi,
  updateSubsidiaryApi,
  deleteSubsidiaryApi,
} from "../../apis/apis.hrm/apisSubsidiary";
import { useHooksAuth } from ".";

export function useHooksSubsidiary() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subsidiarys, setSubsidiarys] = useState(null);
  const { auth } = useHooksAuth();
  const getSubsidiarys = async () => {
    try {
      setLoading(true);
      const response = await getSubsidiarysApi(auth.token);
      setLoading(false);
      setSubsidiarys(response);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const addSubsidiary = async (data) => {
    try {
      setLoading(true);
      await addSubsidiaryApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const updateSubsidiary = async (data) => {
    try {
      setLoading(true);
      await updateSubsidiaryApi(data, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  const deleteSubsidiary = async (id) => {
    try {
      setLoading(true);
      await deleteSubsidiaryApi(id, auth.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  return {
    loading,
    error,
    subsidiarys,
    getSubsidiarys,
    addSubsidiary,
    updateSubsidiary,
    deleteSubsidiary,
  };
}
