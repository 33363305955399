import React, { useState, useEffect, useContext, createContext } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { sendAnnulmentOperation } from "../../../apis/apis.logistics/apisProduct";
import { ContextAuth } from "../../../contexts/ContextAuth";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export function PurchaseList({ orders, setDetailsFromOrderPurchaseReceived }) {
    const { auth } = useContext(ContextAuth);
    const sendAnnulment = async (data) => {
        try {
            const response = await sendAnnulmentOperation(data);
            return response;
        } catch (error) {
            throw error;
        }
    };
    return (
        <>
            <table className="table table-sm table-bordered text-center" id="table-to-xls">
                <thead>
                    <tr className='text-uppercase '>
                        <td className="align-middle">
                            #
                        </td>
                        <td className="align-middle">
                            Estado
                        </td>
                        <td className="align-middle">
                            Tip Doc
                        </td>
                        <td className="align-middle">
                            Documento
                        </td>
                        <td className="align-middle">
                            Fecha
                        </td>
                        <td className="align-middle">
                            Ruc
                        </td>
                        <td className="align-middle">
                            Proveedor
                        </td>
                        <td className="align-middle">
                            Articulos
                        </td>
                        <td className="align-middle">
                            OP. GRAVADAS
                        </td>
                        <td className="align-middle">
                            I.G.V.
                        </td>
                        <td className="align-middle">
                            TOTAL VENTA
                        </td>
                        <td className="align-middle">
                            ACCIONES
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((o, index) => {
                        return (
                            <tr key={index} value={o.operationID} className={o.operationStatus == "03" ? "table-danger" : o.operationStatus == "02" ? "bg-light" : "table-primary"}>
                                <td className="align-middle">{o.operationID}</td>
                                <td className="align-middle">{o.operationStatusDisplay}</td>
                                <td className="align-middle">{o.operationDocumentDisplay}</td>
                                <td className="align-middle">{o.operationDocumentNumber}</td>
                                <td className="align-middle">{o.operationDate}</td>
                                <td className="align-middle">{o.supplierDocumentNumber}</td>
                                <td className="align-middle">{o.supplierName}</td>
                                <td className="align-middle">{o.operationNumberItems}</td>
                                <td className="align-middle text-right text-nowrap">S/ {Number(o.operationAmountBase).toFixed(2)}</td>
                                <td className="align-middle text-right text-nowrap">S/ {Number(o.operationAmountIgv).toFixed(2)}</td>
                                <td className="align-middle text-right text-nowrap">S/ {Number(o.operationAmountTotal).toFixed(2)}</td>
                                <td className="align-middle">
                                    {o.operationDocumentTypeID == "03" ?
                                        <div className="btn-group">

                                            <button type="button" className="btn btn-dark border dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Acciones
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className='dropdown-item' href='#' onClick={async () => {
                                                    if (window.confirm("¿Esta usted seguro de anular la compra?")) {
                                                        await sendAnnulment({
                                                            operationID: o.operationID,
                                                            userID: auth.data.user_id,
                                                        }).then((r) => {
                                                            if (r.status == 201) {
                                                                toast.success("Operacion anulada con exito!");
                                                            } else {
                                                                toast.error("Ocurrio algun error");
                                                            }
                                                        });
                                                    }
                                                }}><i className="fas fa-ban"></i> Anular</a>
                                                <Link to={"/buys/edit_order_purchase_received/" + o.operationID} className="dropdown-item" > <i className="fas fa-archive"></i> Editar vencimiento </Link>

                                                <a className='dropdown-item' href='#' onClick={() => { setDetailsFromOrderPurchaseReceived(o.operationItems); }}
                                                    data-toggle="modal" data-target="#modal-order-purchase-received" ><i className="fas fa-highlighter"></i> Ver Detalles</a>

                                            </div>

                                        </div>

                                        : null}


                                </td>

                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    
                    <tr>
                        <td className="align-middle text-right" colSpan={10}>Total facturas completadas</td>
                        <td className="align-middle text-right text-nowrap">S/ {Number(orders.filter((o => o.operationDocumentTypeID == "03" && o.operationStatus == "02")).reduce((previousValue, currentValue) => previousValue + currentValue.operationAmountTotal, 0)).toFixed(2)}</td>
                        <td colSpan={1}></td>
                    </tr>

                    <tr>
                        <td className="align-middle text-right" colSpan={10}>Total facturas anuladas</td>
                        <td className="align-middle text-right text-nowrap">S/ {Number(orders.filter((o => o.operationDocumentTypeID == "03" && o.operationStatus == "03")).reduce((previousValue, currentValue) => previousValue + currentValue.operationAmountTotal, 0)).toFixed(2)}</td>
                        <td colSpan={1}></td>
                    </tr>
                    <tr>
                        <td className="align-middle text-right" colSpan={10}>Total notas de Credito</td>
                        <td className="align-middle text-right text-nowrap">S/ {Number(orders.filter((o => o.operationDocumentTypeID == "06")).reduce((previousValue, currentValue) => previousValue + currentValue.operationAmountTotal, 0)).toFixed(2)}</td>
                        <td colSpan={1}></td>
                    </tr>
                </tfoot>
            </table>
            <Link to='/buys/new_purchase_order' className="btn btn-outline-secondary mt-3" > <i className="fas fa-plus"></i> Nueva orden de compra </Link>
            <button
                className="btn btn-outline-secondary mt-3 ml-1"
                data-toggle="modal"
                data-target="#modal-note-credit"
            // onClick={() =>
            //   props.setItemProductTariff({
            //     productTariffID: "0",
            //     unitID: "0",
            //     unitName: "",
            //     productSaleName: item.saleName,
            //     productID: item.productID,
            //     priceSale: "",
            //     pricePurchase: "",
            //     percentageDiscount: "",
            //     quantityMinimum: "",

            //   })
            // }
            >
                <i className="fas fa-plus"></i> Nota de credito
            </button>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-outline-secondary mt-3 ml-1"
                table="table-to-xls"
                filename="reporte_compras"
                sheet="report"
                buttonText="Descargar como XLS" />
        </>
    )
}
