import React, { useState, useEffect, useContext } from "react";
import { fetchedWarehousesBySubsidiary } from "../../../apis/apis.logistics/apisProduct";
import { queryDocumentReniec } from "../../../apis/apis.logistics/apisSunat";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

import {
  personDocumentTypes,
  operationDocumentTypes,
} from "../../../configs/Data";

export function ConcludeOrderPurchaseForm({ order, setOrder }) {
    
  const { auth } = useContext(ContextAuth);
  const [warehouses, setWarehouses] = useState([]);
  const getWarehouses = async (id) => {
    try {
      const response = await fetchedWarehousesBySubsidiary({
        subsidiaryID: id,
      });
      setWarehouses(response);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getWarehouses(auth.data.subsidiary.subsidiary_id);
  }, []);
  return (
    <>
      <div className="card bg-light ">
        <div className="card-body" style={{ fontSize: "small" }}>
          <p>DATOS OPERACION</p>
          <hr />

          <div className="row mb-2">
            <div className="col-auto">
              <label className="">TIP/NUM DOC:</label>
            </div>
            <div className="col-auto">
              <select
                className="form-control text-uppercase"
                name="operationDocumentTypeID"
                readOnly
                value={order.operationDocumentTypeID}
              >
                {operationDocumentTypes.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control"
                name="operationDocumentNumber"
                placeholder="Ingrese Texto"
                readOnly
                value={order.operationDocumentNumber}
                autoComplete="off"
                maxLength={15}
              />
            </div>


            <div className="col-auto">
              <label className="">FECHA DE RECEPCION:</label>
            </div>
            <div className="col-auto">
              <input
                type="date"
                name="operationDate"
                className="form-control"
                value={order.operationDate}
                readOnly
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
