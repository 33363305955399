import React, { useEffect, useRef, useContext, useState } from 'react';
import { ContextAuth } from "../../../contexts/ContextAuth";
import Chart from 'chart.js/auto';
import { SoldProductGraphByDayFilter } from "../fragments.dispatches/SoldProductGraphByDayFilter";

export function SoldProductGraphByDayPage() {
    const barChartRef = useRef(null);

  const { auth } = useContext(ContextAuth);
  
    const [soldProducts, setSoldProducts] = useState([]); 

  const [salesWeekData, setSalesWeekData] = useState({
    monday:0,
    tuesday:0,
    wednesday:0,
    thursday:0,
    friday:0,
    saturday:0,
    sunday:0
  });

  useEffect(() => {
    //getSalesForChartPie();
    // console.log(auth.data)


    const barChartData = {
      labels: soldProducts.map(product => product.productName),
      datasets: [{
        label: 'Cantidad Vendida',
        data: soldProducts.map(product => product.quantitySold),
        backgroundColor: '#07bc0c'
      }]
    };

    const barChartOptions = {
        indexAxis: 'y',
        responsive: true,
        // maintainAspectRatio: false,
        elements: {
            bar: {
              borderWidth: 2,
            }
          },
          plugins: {
            legend: {
              position: 'right',
            },
            title: {
              display: true,
              text: 'Productos mas vendidos'
            }
          }
    };

    const barChart = new Chart(barChartRef.current, {
      type: 'bar',
      
      data: barChartData,
      options: barChartOptions
    });

    return () => {
      barChart.destroy();
    };
  }, [soldProducts]);

    return (
        <>
            <section className="content-header">
            <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                <h1>Ventas de productos</h1>
                </div>
                <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                    <a href="#">Distribuciones</a>
                    </li>
                    <li className="breadcrumb-item active">Listado</li>
                </ol>
                </div>
            </div>
            </div>
        </section>

        <section className="content">
            <div className="container-fluid">
            <div className="card card-default">
                <div className="card-header"></div>
                <div className="card-body">

                
                    <div className='row'>
                        
                            
                            <div className='col-4'>
                            <SoldProductGraphByDayFilter setSoldProducts={setSoldProducts} />
                            </div>
                            
                            <div className='col-8'>
                            <canvas ref={barChartRef} />
                            </div>
                        
                        
                    </div>
                </div>
            </div>
            </div>
        </section>
        </>
    )
}
