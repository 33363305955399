import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedAllOperationsAccountPayByCash } from "../../../apis/apis.accounting/apisCash";

export function AccountSearch(props) {

  //console.log(props.supplierItem);
  //console.log("props.suppliersCash", props.suppliersCash);

  function handleSelectChange(e) {
    var index = e.nativeEvent.target.selectedIndex;
    var value = e.target.value;
    //console.log('value', value)

    const search = props.suppliersCash.filter(function (element) {
      return element.supplierID == value;
    });  

    if (search.length) {
      //console.log('search[0]', search[0]);
      props.setSupplierItem({
        ...props.supplierItem,
        supplierID: search[0].supplierID,
        supplierName: search[0].supplierName,
        supplierFiscalAddress: search[0].supplierFiscalAddress,
        supplierDocumentNumber: search[0].supplierDocumentNumber,
        supplierAmount: search[0].supplierAmount,
        supplierCashID: search[0].supplierCashID,
        //bankDate: getCurrentDate("-"),
      });
    } else {
      props.setSupplierItem({
        ...props.supplierItem,
        supplierID: "0",
        supplierName: "",
        supplierFiscalAddress: "",
        supplierDocumentNumber: "",
        supplierAmount: "",
        supplierCashID: ""
        //bankDate: getCurrentDate("-"),
      });
    }
  }

  function searchAccountsPayOperations(params) {
    if (props.supplierItem.supplierID != "0") {        
      getAllAccountPayOperation({
        supplierID: props.supplierItem.supplierID,
        //bankStartDate: props.dates.startDate,
        //bankEndDate: props.dates.endDate,
      });
    } else {
      toast.error("SELECCIONE UNA ENTIDAD BANCARIA");
    }
  }

  const getAllAccountPayOperation = async (data) => {
    try {
      const response = await fetchedAllOperationsAccountPayByCash(data);

      props.setOperationsAccountPay(response.AccountPayOperations);
      props.setSupplierItem({
        ...props.supplierItem,
        supplierAmount: response.balance,
      });
      
      return response;
    } catch (error) {
      throw error;
    }
  };

  function cleanAmortize() {
    props.setNewPayAmortize({
      ...props.newPayAmortize,
      accountPayCashID: "0",
      accountPayTypePay: "0",
      accountPayAmountPay: "",
      accountPayDescription: "",
      accountPayDate: getCurrentDate("-"),
    });    
  }
  

  return (
    <div>
      <div className="row pb-3">
        <div className="col-auto">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-auto col-form-label">
              Seleccione Cliente:
            </label>
            <div className="col-auto">
              <select
                className="form-control select2 text-uppercase"
                onChange={handleSelectChange}
              >
                <option value="0">Seleccione...</option>
                {props.suppliersCash.map((item, index) => {
                  return (
                    <option key={index} value={item.supplierID}>
                      {item.supplierName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-auto">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={searchAccountsPayOperations}
            id="btn-search"
          >
            Buscar <i className="fas fa-search" />
          </button>
        </div>

        <div className="col-auto">
          <button
            className="btn btn-warning text-white"
            data-toggle="modal"
            data-target="#modal-amortize"
            disabled={props.supplierItem.supplierID == "0" ? true : false}
            onClick={(e) => cleanAmortize()}
          >
            Amortizar <i className="far fa-credit-card" />
          </button>
        </div>
      </div>
    </div>
  );
}
