import React from "react";

export function PageHome(props) {
  const { children } = props;
  return (
    <>
      <div className="container-fluid">{children}</div>
    </>
  );
}
