import { ProductList } from "../fragments.logistics/ProductList";
import { ProductFilter } from "../fragments.logistics/ProductFilter";
import { ProductForm } from "../fragments.logistics/ProductForm";
import { ProductTariffForm } from "../fragments.logistics/ProductTariffForm";
import React, { useState, useEffect } from "react";
import { fetchedProducts, fetchedBrands, fetchedUnits, fetchedMatrix } from "../../../apis/apis.logistics/apisProduct";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export function MatrixPage() {
    const [products, setProducts] = useState([]);

    const getMatrix = async () => {
        console.log('getMatrix')
        try {
          const response = await fetchedMatrix();
          setProducts(response);
          return response;
        } catch (error) {
          throw error;
        }
    };

    useEffect(() => {
        getMatrix();
    }, []);

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Modulo Logistico</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                <a href="#">Logistica</a>
                                </li>
                                <li className="breadcrumb-item active">Matriz de productos</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-header">Matriz de productos (Alm. Central)</div>
                        <div className="card-body">


                            <p className="float-right">
                                Total productos: {products.length}
                            </p>
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered" style={{ fontSize: "small" }} id="table-to-xls">
                                    <thead className="bg-light ">

                                        <tr>
                                        <th className="align-middle text-center text-uppercase font-weight-bold" colSpan={10}></th>
                                        <th className="align-middle text-center text-uppercase font-weight-bold" colSpan={3}>Costo Tienda </th>
                                        <th className="align-middle text-center text-uppercase font-weight-bold" colSpan={3}>Costo Mayorista  </th>
                                        <th className="align-middle text-center text-uppercase font-weight-bold" colSpan={3}>Costo para Sunat </th>
                                        </tr>
                                        <tr>

                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-sky-blue-excel">CODIGO</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-sky-blue-excel">CLASIFICACION</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-sky-blue-excel">SKU</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-sky-blue-excel">Descripción del Producto PEPSICO</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-sky-blue-excel">Descripción del Producto LAYS</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-blue-excel">Cantidad</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold">Gramage</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold">Marca</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-blue-excel">Costo Caja Pepsico</th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-blue-excel">Costo Pepsico Unidad</th>
                                            {/* <th className="align-middle text-center text-uppercase font-weight-bold">Disponible</th> */}

                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-aqua-blue">P.V. </th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-green-excel">Costo Docena </th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-yellow-excel">Costo Unit </th>

                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-aqua-blue">P.V. </th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-green-excel">Costo Docena </th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-yellow-excel">Costo Unit </th>

                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-aqua-blue">P.V. </th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-green-excel">Costo Docena </th>
                                            <th className="align-middle text-center text-uppercase font-weight-bold bg-yellow-excel">Costo Unit </th>

                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                        {products.map((item, index) => {
                                            return (
                                                <tr key={index} value={item.productID}>
                                                    <td className="align-middle bg-aqua-blue">{item.productCode}</td>
                                                    <td className="align-middle bg-aqua-blue">{item.productClassificationDisplay}</td>
                                                    <td className="align-middle bg-aqua-blue">{item.productSku}</td>
                                                    <td className="align-middle bg-aqua-blue text-nowrap">{item.productPurchaseName}</td>
                                                    <td className="align-middle bg-aqua-blue text-nowrap">{item.productSaleName}</td>
                                                    <td className="align-middle bg-blue-excel font-weight-bolder text-xl-center">{item.remainingQuantity}</td>
                                                    <td className="align-middle">{item.productGrammage}</td>
                                                    <td className="align-middle text-nowrap">{item.productBrandName}</td>
                                                    <td className="align-middle">{item.maxProductTariffPricePurchase}</td>
                                                    <td className="align-middle">{item.maxProductTariffPriceSale}</td>
                                                    {/* <td className="align-middle">{item.productAvailable?"SI":"NO"}</td> */}


                                                    <td className="align-middle bg-aqua-blue">{item.minProductTariffPriceSaleWholesale}</td>
                                                    <td className="align-middle bg-green-excel">{Number(item.minProductTariffPricePurchaseWholesale*12).toFixed(6)}</td>
                                                    <td className="align-middle bg-yellow-excel">{item.minProductTariffPricePurchaseWholesale}</td>

                                                    <td className="align-middle bg-aqua-blue">{item.minProductTariffPriceSalePrimax}</td>
                                                    <td className="align-middle bg-green-excel">{Number(item.minProductTariffPricePurchasePrimax*12).toFixed(6)}</td>
                                                    <td className="align-middle bg-yellow-excel">{item.minProductTariffPricePurchasePrimax}</td>

                                                    <td className="align-middle bg-aqua-blue">{item.minProductTariffPriceSaleSunat}</td>
                                                    <td className="align-middle bg-green-excel">{Number(item.minProductTariffPricePurchaseSunat*12).toFixed(6)}</td>
                                                    <td className="align-middle bg-yellow-excel">{item.minProductTariffPricePurchaseSunat}</td>
                                                   


                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-success mt-3 ml-1"
                            table="table-to-xls"
                            filename="matriz_de_productos"
                            sheet="report"
                            buttonText="Descargar como XLS"
                            
                            />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
