import { BASE_API } from "../../configs/ConfigsConstants";

export async function sendFilterPurchasesData(data) {
    try {
      const url = `${BASE_API}/sales/api/v1/filter_purchases_by_action/`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
  
      const response = await fetch(url, params);
      const result = await response.json();
      //console.log(result);
      return result;
    } catch (error) {
      throw error;
    }
  }
  

export async function sendFilterSpecialSalesData(data) {
    try {
      const url = `${BASE_API}/sales/api/v1/filter_special_sales_by_action/`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
  
      const response = await fetch(url, params);
      const result = await response.json();
      //console.log(result);
      return result;
    } catch (error) {
      throw error;
    }
  }
  

export async function fetchedProductsByWarehouse(data) {
    try {
      const url = `${BASE_API}/sales/api/v1/get_products_by_warehouse/`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
  
      const response = await fetch(url, params);
      const result = await response.json();
      //console.log(result);
      return result;
    } catch (error) {
      throw error;
    }
  }
  

export async function fetchedStockInOneWarehouse(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_stock_in_one_warehouse/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function fetchedStockInTwoWarehouses(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_stock_in_two_warehouses/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}
  

export async function fetchedStockInThreeWarehouses(data) {
  try {
    const url = `${BASE_API}/sales/api/v1/get_stock_in_three_warehouses/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();
    //console.log(result);
    return result;
  } catch (error) {
    throw error;
  }
}
