import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { fetchedBankOperations } from "../../../apis/apis.accounting/apisCash";
import { toggleCash } from "../../../apis/apis.accounting/apisCash";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";

export function CashSearch(props) {

  const {
    banks,
    setBanks,
    setOperations,
    //itemState,
    //setItemState,
    setOpening,
    setClosing,
    setCheckClosed,
    totalsCash,
    setTotalsCash,
  } = useContext(GlobalContext);

  const { auth } = useContext(ContextAuth);  
  
  const [buttonState, setButtonState] = useState({
    cashName: "ESTADO DE CAJA",
    cashStatus: "NA",
    cashID: "",
    subsidiaryID: "",
    lastDateStatus: "",
  });
  //console.log('buttonState', buttonState);

  function searchCash(value) {
    
   
    const search = banks.filter(function(element) {      
        return element.cashID == value;
    }); 
    //console.log("search[0]", search[0]); 
    
    if (search.length){
      setButtonState(search[0]);
      
      /*props.setCashItem({
        ...props.cashItem,
        cashLastDate: search[0].lastDateStatus,
      });*/       
      
      //console.log('search[0]', search[0]);
      //console.log("banks", banks);
      //let cash_last_date = document.getElementById("cashLastDate");
      //cash_last_date.value = search[0].lastDateStatus;   
      //cash_last_date.textContent = search[0].lastDateStatus; 
      
      /*if (search[0].cashStatus == "A") {
        toast.success(          
            search[0].cashName +
            " ESTA ABIERTA EL: " +
            search[0].lastDateStatus
        );
        //document.getElementById("btn-close-cash").disabled = false;
      }else if (search[0].cashStatus == "C") {
        toast.error(          
            search[0].cashName +
            " ESTA CERRADA EL " +
            search[0].lastDateStatus
        );
        //document.getElementById("btn-close-cash").disabled=true;
      }*/
    }    
    else {
      setButtonState({ ...buttonState, cashStatus: "NA" });
    }
  }

  function handleSelectChange(e) {  
    var index = e.nativeEvent.target.selectedIndex;
    if (Number(e.target.value) > 0)
      searchCash(e.target.value);
    props.setCashItem({
      ...props.cashItem,
      cashID: e.target.value,
      cashName: e.nativeEvent.target[index].text,
      //cashLastDate: buttonState.lastDateStatus,
    });

    props.setCashFlow({
      ...props.cashFlow,
      cashID: e.target.value,
      cashName: e.nativeEvent.target[index].text,      
    });
     
  }  

  function handleSelectDateChange(e) {    
    props.setCashItem({ ...props.cashItem, cashDate: e.target.value });
    
  }

 const getBankOperations = async (data) => {
   try {
     const response = await fetchedBankOperations(data);  
     //console.log("response", response.operations);   

     if (response.operations.length !== 0) {

        setOperations(response.operations);
        setOpening(response.opening);
        setClosing(response.closing);
        props.setCashItem({
          ...props.cashItem,
          checkCloseCash: response.checkClose,
        });
        setTotalsCash({
          ...totalsCash,
          total_entries_sum: response.total_entries_sum,
          total_outputs_sum: response.total_outputs_sum,
          balance_day: response.balance_day,
        });
        toast.success(
          "Operaciones recuperadas correctamente"
        );
     } else {
      toast.warn("La fecha seleccionanda no cuenta con operaciones, Seleccione otra");
     }        
     return response;
   } catch (error) {
     throw error;
   }
 };

  function searchOperations(params) {
    //console.log(itemState)
    if (props.cashItem.cashID != "0") {
      getBankOperations(props.cashItem);
    } else {
      toast.error("Seleccione al menos una caja");
      return false;
    }
  }
  
  function openCash(params) {
    sendData({
      cashID: props.cashItem.cashID,
      cashDate: props.cashItem.cashDate,
      cashStatus: "A",
      userID: auth.data.user_id,
    }).then((r) => {
      console.log(r);
      if (r.data.error) {
        toast.error(r.data.message);
      } else {
        toast.success(r.data.message);
        document.getElementById("btn-search").click();
        document.getElementById("btn-close-cash").disabled = false;
      }
    });  
  }

  const sendData = async (data) => {
    try {
      //console.log(data);
      const response = await toggleCash(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  function clean() {

    props.setSelectedCashFlow({
      //...props.selectedCashFlow,
      //cashFlowID: "0",
      //cashID: "0",
      //cashName: "",
      //cashDate: getCurrentDate("-"),
      cashOperationDescription: "",
      cashOperationTypeDocument: "0",
      cashOperationType: "E",
      userID: auth.data.user_id,
    });   
    
  }
  
  useEffect(() => {
    /*props.setCashItem({
      ...props.cashItem,
      cashLastDate: buttonState.lastDateStatus,
    });*/
    //console.log("Banks", banks);
  }, [buttonState]); 

  return (
    <div>
      <div className="row pb-3">
        <div className="col-auto">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-auto col-form-label">
              Seleccione Caja:
            </label>
            <div className="col-auto">
              <select
                className="form-control select2 text-uppercase"
                onChange={handleSelectChange}
              >
                <option value="0">Seleccione...</option>
                {banks.map((item, index) => {
                  return (
                    <option key={index} value={item.cashID}>
                      {item.cashName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-auto">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-auto col-form-label">
              Fecha:
            </label>
            <div className="col-auto">
              <input
                type="date"
                className="form-control"
                value={props.cashItem.cashDate}
                onChange={handleSelectDateChange}
              />
            </div>
          </div>
        </div>

        <div className="col-auto">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={searchOperations}
            id="btn-search"
          >
            Buscar <i className="fas fa-search" />
          </button>
        </div>

        {/*<div className="col-auto">
          <button
            className={
              buttonState.cashStatus == "NA"
                ? "btn btn-primary"
                : buttonState.cashStatus == "A"
                ? "btn btn-success text-success"
                : "btn btn-danger"
            }
          >
            {buttonState.cashStatus == "NA"
              ? "ESTADO DE CAJA"
              : buttonState.cashStatus == "A"
              ? "ABIERTA"
              : "CERRADA"}
          </button>
          </div>*/}

        {/*<div className="col-sm-1">
          <button type="button" className="btn btn-primary">
            {buttonState.cashStatus == "NA"
              ? "ESTADO DE CAJA"
              : buttonState.cashStatus == "A"
              ? "ABIERTA"
              : "CERRADA"}{" "}
            <span
              name="cashLastDate"
              className="badge badge-light"
              id="cashLastDate"
            ></span>
          </button>

          {/* <input
            type="text"
            name="cashLastDate"
            className="form-control text-center"
            id="cashLastDate"
            disabled
            //value={buttonState.cashLastDate}
            //onChange={handleSelectChange}
          />
        </div>*/}

        {/*<div className="col-auto">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button
                className={
                  buttonState.cashStatus == "NA"
                    ? "btn btn-primary"
                    : buttonState.cashStatus == "A"
                    ? "btn btn-success text-success"
                    : "btn btn-danger"
                }
                //onClick={confirmOpenClose}
                disabled={true}
              >
                {buttonState.cashStatus == "NA"
                  ? "-"
                  : buttonState.cashStatus == "A"
                  ? "ABIERTA EL "
                  : "CERRADA EL"}
              </button>
            </div>
            <input
              type="text"
              className="form-control text-center"
              id="cashLastDate"
              value={buttonState.lastDateStatus}
              name="cashLastDate"
              disabled={true}
            />
          </div>
              </div>*/}

        {/*<div className="col-auto">
          <button
            type="button"
            className="btn btn-success text-success"
            id="btn-open-cash"
            onClick={openCash}
          >
            Aperturar
          </button>
            </div>*/}

        <div className="col-auto"></div>

        <div className="col-auto">
          <button
            className="btn btn-warning text-white"
            data-toggle="modal"
            data-target="#modal-operations"
            //disabled={buttonState.cashStatus == "A" ? false : true}
            onClick={(e) => clean()}
          >
            Operaciones de Caja <i className="fas fa-align-justify" />
          </button>
        </div>

        <div className="col-auto">
          <button
            className="btn btn-info text-white"
            data-toggle="modal"
            data-target="#modal-cash"
          >
            Nueva Caja <i className="fas fa-plus" />
          </button>
        </div>
      </div>
    </div>
  );
}

