import React, { useState, useEffect, useContext } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { getCurrentDate } from "../../../configs/helpers";
import { NewOrderPurchaseOperationForm } from "../fragments.buys/NewOrderPurchaseOperationForm";
import { NewOrderPurchaseOperationDetailForm } from "../fragments.buys/NewOrderPurchaseOperationDetailForm";
import { NewOrderPurchaseOperationDetailList } from "../fragments.buys/NewOrderPurchaseOperationDetailList";
import { fetchedOrderPurchaseByID } from "../../../apis/apis.logistics/apisProduct";
import { ConcludeOrderPurchaseForm } from "../fragments.buys/ConcludeOrderPurchaseForm";
import { ConcludeOrderPurchaseList } from "../fragments.buys/ConcludeOrderPurchaseList";
import {useParams} from "react-router"

    const initialOrderState = {
        operationID: 0,
        operationDate: getCurrentDate("-"),
        operationDocumentNumber: "",
        operationCorrelative: 0,
        operationCode: "",
        code: "",
        percentageIgv: 18,
        conditionIgv: "02",
        operationNumberItems: 0,
        operationItems: [],
        operationStatus: "01",
        operationStatusDisplay: "PENDIENTE",
      
        operationActionID: "NA",
        operationTypeID: "08",
        operationDocumentTypeID: "03",
        operationAmountBase: "",
        operationAmountIgv: "",
        operationAmountTotal: "",
        supplierDocumentType: "06",
        supplierFirstSurname: "",
        supplierFiscalAddress: "",
        supplierID: "0",
        supplierSecondSurname: "",
        supplierDocumentNumber: "20297182456",
        supplierName: "",
        warehouseID: 0,
        warehouseName: "",
        warehouseCategory: "",
        userID: 0,
    };
      
    const initialOptionState = {
        operationID: 0,
        operationDetailID: 0,
        productTariffID: 0,
        productID: 0,
        productName: "",
        productStockMin: 0,
        productStockMax: 0,
        productStock: 0,
        percentageDiscount: 0,
        pricePurchase: 0,
        priceSale: 0,
        unitaryPriceSale: 0,
        unitName: "",
        unitID: 0,
        quantityOrdered: 0,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        amountDiscount: 0,
        igv: 0,
        amountIgv: 0,
        subtotal: 0,
        tariffs: [],
        grammage: "",
        sku: "",
        condition: "01"
    };

export function ConcludeOrderPurchasePage() {
    let {id} = useParams();
    const [order, setOrder] = useState(initialOrderState);
    const [selectedOption, setSelectedOption] = useState(initialOptionState);
    const getOrderPurchase = async () => {
        try {
          const response = await fetchedOrderPurchaseByID({"operationID":id});
          setOrder(response)
          //return response;
        } catch (error) {
          throw error;
        }
      };

      useEffect(() => {
        getOrderPurchase();
      }, []);
      return (
        <>
         <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>ORDENES DE COMPRA: VERIFACION</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Orden de Compras</a>
                    </li>
                    <li className="breadcrumb-item active">{id}</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
    
    
    
          <section className="content">
                <div className="container-fluid">
                <div className="card card-default">
                    <div className="card-header"></div>
                    <div className="card-body">
    
                        <ConcludeOrderPurchaseForm order={order} setOrder={setOrder} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                        <ConcludeOrderPurchaseList order={order} setOrder={setOrder} initialOrderState={initialOrderState} />
    
    
                    </div>
                </div>
                </div>
            </section>
    
    
        </>
      )
}
