import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { getCurrentDate } from "../../../configs/helpers";
import { fetchedAllOperationsBanksByDate } from "../../../apis/apis.accounting/apisCash";

export function BankSearch(props) {

  //console.log(props.bankItem)
  //console.log('props.allBanks', props.allBanks);

  function handleSelectDateChange(e) {
    props.setDates({
      ...props.dates,
      [e.target.name]: e.target.value,
    });
  }

  function handleSelectChange(e) {
    var index = e.nativeEvent.target.selectedIndex;
    var value = e.target.value;
    
  const search = props.allBanks.filter(function (element) {
    return element.bankID == value;
    
  });
    if (search.length){
      //console.log(search[0])
        props.setBankItem({
          ...props.bankItem,
          bankID: search[0].bankID,
          bankName: search[0].bankName,
          bankAmount: search[0].bankAmount,
          bankAccountNumber: search[0].bankAccountNumber,
          bankDate: getCurrentDate("-"),
          bankAccountType: search[0].bankAccountTypeDisplay,
        });
    }
    else{
      props.setBankItem({
        ...props.bankItem,
        bankID: "0",
        bankName: "",
        bankAmount: "",
        bankAccountNumber: "",
        bankAccountType: "",
        bankDate: getCurrentDate("-"),
      });

    } 
  }

  function searchBanksOperations(params) {
    if (props.bankItem.bankID != "0") {
      getAllBankOperations({
        bankID: props.bankItem.bankID,
        bankStartDate: props.dates.startDate,
        bankEndDate: props.dates.endDate,
      });
    } else {
      toast.error("SELECCIONE UNA ENTIDAD BANCARIA")
    }
  }


  const getAllBankOperations = async (data) => {
    try {
      const response = await fetchedAllOperationsBanksByDate(data);
      
      props.setOperationsBanks(response.bankOperations);
      props.setBankItem({
        ...props.bankItem,
        bankAmount: response.balance,
        bankAccountNumber: response.accountNumber,
        bankAccountType: response.accountType,
      });
      //console.log('response', response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  function clean() {
    props.setNewOperation({
      ...props.newOperation,     
      bankFlowID: "0",     
      bankOperationDescription: "",
      bankNewTypeTransaction: "0",
      bankNewOperationDate: getCurrentDate("-"),
      bankOperationTotal: "",
    });
  }
  //console.log(props.newOperation);

  useEffect(() => {
    props.setBankItem({
      ...props.bankItem,      
    });
  }, [props.allBanks]); 

  return (
    <div>
      <div className="row pb-3">
        <div className="col-auto">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-auto col-form-label">
              Seleccione Banco:
            </label>
            <div className="col-auto">
              <select
                className="form-control select2 text-uppercase"
                onChange={handleSelectChange}
              >
                <option value="0">Seleccione...</option>
                {props.allBanks.map((item, index) => {
                  return (
                    <option key={index} value={item.bankID}>
                      {item.bankName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-auto">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-auto col-form-label">
              Fecha Inicial:
            </label>
            <div className="col-auto">
              <input
                type="date"
                className="form-control"
                name="startDate"
                value={props.dates.startDate}
                onChange={handleSelectDateChange}
              />
            </div>
          </div>
        </div>

        <div className="col-auto">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-auto col-form-label">
              Fecha Final:
            </label>
            <div className="col-auto">
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={props.dates.endDate}
                onChange={handleSelectDateChange}
              />
            </div>
          </div>
        </div>

        <div className="col-auto">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={searchBanksOperations}
            id="btn-search"
          >
            Buscar <i className="fas fa-search" />
          </button>
        </div>

        <div className="col-auto">
          <button
            className="btn btn-warning text-white"
            data-toggle="modal"
            data-target="#modal-banks-operations"
            disabled={props.bankItem.bankID == "0" ? true : false}
            onClick={(e) => clean()}
          >
            Movimientos <i className="fas fa-align-justify" />
          </button>
        </div>

        <div className="col-auto">
          <button
            className="btn btn-success text-success"
            data-toggle="modal"
            data-target="#modal-new-bank"
            //disabled={props.bankItem.bankID == "0"? true : false}
            //onClick={(e) => clean()}
          >
            Nueva Entidad Bancaria <i className="fas fa-newspaper" />
          </button>
        </div>
      </div>
    </div>
  );
}
