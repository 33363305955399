import React, { useEffect, useState } from "react";
import { useHooksGang } from "../../../hooks";
import {
  FragmentHeaderGang,
  FragmentTableGang,
  FragmentAddEditGang,
} from "../fragments.hrm";
import { WidgetModal } from "../../modules.widgets";
import swal from "sweetalert2";
export function PageGang() {
  const [tittleModal, setTittleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [sizeModal, setSizeModal] = useState(null);

  const { loading, gangs, getGangs, deleteGang } = useHooksGang();

  useEffect(() => {
    getGangs();
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);

  const addGang = () => {
    setTittleModal("Registrar Nuevo Grupo");
    setSizeModal("modal");
    setContentModal(<FragmentAddEditGang onRefetch={onRefetch} gang={{}} />);
  };

  const updateGang = (data) => {
    setTittleModal("Actualizar Grupo");
    setSizeModal("modal");
    setContentModal(<FragmentAddEditGang onRefetch={onRefetch} gang={data} />);
  };

  const onDeleteGang = async (data) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Esta seguro?",
        text: "Si acepta, No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Acepto!",
        cancelButtonText: "No, Cancelo!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteGang(data.gangID);
            onRefetch();
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            `${data.gangName} ha sido eliminada`,
            "success"
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            `${data.gangName} esta a salvo :)`,
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <FragmentHeaderGang
            btnTittle="Registrar Nueva Ruta"
            addGang={addGang}
          />
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="chart">
                {loading ? (
                  <h6>Cargando...</h6>
                ) : (
                  <FragmentTableGang
                    gangs={gangs}
                    updateGang={updateGang}
                    onDeleteGang={onDeleteGang}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
      <WidgetModal
        children={contentModal}
        modaltittle={tittleModal}
        sizemodal={sizeModal}
      />
    </>
  );
}
