
import React, { useState, useEffect, useContext, useRef  } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from "react-toastify";

import { getCurrentDate } from "../../../configs/helpers";

import { fetchedWarehousesBySubsidiary } from "../../../apis/apis.logistics/apisProduct";
import { fetchedStockInTwoWarehouses } from "../../../apis/apis.buys/apisPurchase";
import { fetchedStockInOneWarehouse } from "../../../apis/apis.buys/apisPurchase";
import { sendReadjustmentData } from "../../../apis/apis.dispatches/apisDispatch";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { readjustmentModeOptions } from "../../../configs/Data";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initialSupplyingState = {
  warehouseID: 0,
  vehicleID: 0,
  vehicleTwoID: 0,
  warehouseName: "",
  vehicleLicensePlate: "",
  vehicleTwoLicensePlate: "",
  readjustmentMode:"E",
  readjustmentMotive:"REAJUSTE DEL SISTEMA",
  supplyingDate: getCurrentDate("-"),
  userID: 0,
  details: [],
  comparatives: [],
};

export function ReadjustmentPage() {
  const [readjustmentState, setReadjustmentState] = useState(initialSupplyingState);

  const { auth } = useContext(ContextAuth);

  const [warehouses, setWarehouses] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [searchApiData, setSearchApiData] = useState([]);

  const sendReadjustment = async () => {
    try {
      const response = await sendReadjustmentData(readjustmentState);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getWarehouses = async (id) => {
    try {
      const response = await fetchedWarehousesBySubsidiary({
        subsidiaryID: id,
      });
      setWarehouses(response);
      //console.log(response)
    } catch (error) {
      throw error;
    }
  };

  const handleFilter = (e) => {
    if(e.target.value == ""){
      setReadjustmentState({...readjustmentState, comparatives:searchApiData});
    }else{
      const filteredData = searchApiData.filter(value => {
        return (
          value.productSku.toLowerCase().includes(filterValue.toLowerCase()) ||
          value.productSaleName.toLowerCase().includes(filterValue.toLowerCase()) ||
          value.productPurchaseName.toLowerCase().includes(filterValue.toLowerCase())
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
      });
      if(filteredData.length > 0){
        setReadjustmentState({...readjustmentState, comparatives:filteredData});
      }
      /*else{
        setReadjustmentState([{"name": "No data"}]);
      }*/
      
    }
    setFilterValue(e.target.value);
    
  };


  const getComparativeFromProductsOfOneWarehouse = async (wa) => {
    try {
      const response = await fetchedStockInOneWarehouse({
        warehouseID: wa,
      });
      //console.log(response) ;
      setReadjustmentState({...readjustmentState, comparatives:Object.values(response)});
      setSearchApiData(Object.values(response));

    } catch (error) {
      throw error;
    }
  };
  
  const handleClickBtnSave = async () => {
      // console.log("readjustmentState", readjustmentState)
    if (readjustmentState.comparatives.length) {
        if (Number(readjustmentState.warehouseID) > 0) {
            // console.log("readjustmentState", readjustmentState);
            document.getElementById('btn-register-readjustment').disabled=true;
            document.getElementById('sync').classList.remove("d-none");

            await sendReadjustment().then((r) => {
                if (r.status == 201) 
                    toast.success("Operacion registrada con exito!");
                else 
                    toast.error("Ocurrio algun error");
            });
        
            document.getElementById('btn-register-readjustment').disabled=false;
              document.getElementById('sync').classList.add("d-none");
              setReadjustmentState(initialSupplyingState);

        } else {toast.error("Verifique el vehiculo");} 
    } else {toast.error("Verifique los items a registrar");}
  };

  useEffect(() => {
    getWarehouses(auth.data.subsidiary.subsidiary_id);
  }, []);

 
  useEffect(() => {
    if(Number(readjustmentState.warehouseID) > 0)
    getComparativeFromProductsOfOneWarehouse(readjustmentState.warehouseID);
  }, [readjustmentState.warehouseID]);

  const rowBody1 = { background: "#f8f9fa" };
  const rowBody2 = { background: "#ffc10724" };
  const rowBody3 = { background: "#007bff00" };
  const colBody1 = "#3498db";
  const colBody2 = "#ffc107";
  
  const tableRef = useRef(null);
  const [hideColum, setHideColum] = useState(false);

  const handleExport = () => {
      setHideColum(!hideColum)
  };

  return (
    <>
    <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>REAJUSTES</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Distribuciones</a>
                </li>
                <li className="breadcrumb-item active">Reajuste</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-header"></div>
            <div className="card-body">
           
                <div className="row mb-3">
                        
                    <div className="col-lg-2">
                        <label className="">ALMACEN ORIGEN:</label>
                    </div>
                    <div className="col-lg-3">
                        <select
                            className="form-control form-control-lg text-uppercase"
                            name="warehouseID"
                            style={{color: colBody1}}
                            onChange={async (e) => {
                            
                            const found = await warehouses.find(function (
                                warehouse,
                                index
                            ) {
                                if (warehouse.warehouseID == e.target.value)
                                return true;
                            });
                            if (found) {
                                setReadjustmentState({
                                ...readjustmentState,
                                warehouseID: found.warehouseID,
                                warehouseName: found.warehouseName,
                                userID: auth.data.user_id,
                                });
                            }
                            }}
                            value={readjustmentState.warehouseID}
                        >
                            <option value="0">Elegir</option>
                            {warehouses.map((item, index) => {
                            return (
                                <option key={index} value={item.warehouseID}>
                                {item.gang.gangID!=0?item.warehouseName+ " | "+item.gang.gangName:item.warehouseName}
                                </option>
                            );
                            })}
                        </select>
                    </div>

                    <div className="col-lg-2">
                        <label className="">MODO REAJUSTE:</label>
                    </div>
                    <div className="col-lg-3">
                        <select
                            className="form-control form-control-lg"
                            name="readjustmentMode"
                            value={readjustmentState.readjustmentMode}
                            onChange={(e)=> {
                                setReadjustmentState({
                                    ...readjustmentState,
                                    readjustmentMode: e.target.value,
                                    comparatives:readjustmentState.comparatives.map((obj) =>{

                                        obj.quantity = 0;

                                        obj.remainingQuantityInTariffs = obj.remainingQuantityInTariffs.map((tariff)=>{
                                            
                                            if(e.target.value == "E") 
                                            obj.quantity = Number(obj.quantity) + Number(tariff.portion) * tariff.quantityMinimum;
                                            else
                                            obj.quantity = Number(obj.quantity) - Number(tariff.portion) * tariff.quantityMinimum;
                                        
                                            return tariff
                                        });
                                    
                                        obj.stockA = obj.fixedStockA + Number(obj.quantity);
                                        if(obj.stockA<0)document.getElementById('btn-register-readjustment').disabled=true;
                                        else document.getElementById('btn-register-readjustment').disabled=false;

                                    return obj;
                                })
                                });
                            }}
                        >
                        <option value="0">Elegir</option>
                            {readjustmentModeOptions.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="col-lg-2">
                        <input
                            type="date"
                            className="form-control form-control-lg"
                            name="supplyingDate"
                            value={readjustmentState.supplyingDate}
                            onChange={(e)=> setReadjustmentState({
                                ...readjustmentState,
                                supplyingDate: e.target.value,
                            })}
                        />
                    </div>

                </div>

                <div className="row mb-3">

                    <div className="col-lg-2">
                        <label className="">MOTIVO DE REAJUSTE:</label>
                    </div>
                    <div className="col-lg-10">
                        <textarea  
                            className="form-control" 
                            rows={2} 
                            value={readjustmentState.readjustmentMotive}
                            onChange={(e)=> {
                                setReadjustmentState({
                                ...readjustmentState,
                                readjustmentMotive: e.target.value,
                            });
                            }}
                        ></textarea>
                        
                    </div>

                </div>

                <div className="row mb-3">

                    <div className="col"></div>
                    
                    <div className="col-lg-1">
                    <i className="fas fa-sync fa-spin fa-3x d-none" id="sync"></i>
                    </div>
                    <div className="col-lg-2">
                        <button
                            type="button"
                            className="btn btn-danger btn-lg btn-block"
                            id="btn-register-readjustment"
                            onClick={handleClickBtnSave}
                            title="Reajustar"
                            style={{/*position: "fixed",bottom: "8px",right: "125px", zIndex: 10000, padding: "16px 20px", borderRadius: "50%"*/}}
                        >
                            {/*<i className="fas fa-save fa-3x"></i>*/}
                            Reajustar
                        </button>
                    </div>

                </div>


                {readjustmentState.comparatives.length?(
                <>
                  <div className="row">
                    <div className="col-lg-12"><input type="search" className="form-control" placeholder="Buscar producto por SKU | PRODUCTO" onInput={(e)=>handleFilter(e)} value={filterValue} /></div>
                  </div>
                  <div className="table-responsive mt-3">
                  
                    <table className="table table-bordered" id="table-to-xls" ref={tableRef}>
                    <thead>
                        <tr>
                        <th className="align-middle" style={{width:'8%'}}>COD.</th>
                        <th className="align-middle" style={{width:'5%'}}>CLASIF.</th>
                        <th className="align-middle">SKU</th>
                        <th className="align-middle">PROD.</th>
                        
                        <th className="align-middle" style={{width:'10%'}}>PRECIO</th>
                        <th className="align-middle" style={{width:'5%', color: colBody1}}>STOCK TOT. UND</th>
                        <th className="align-middle" style={{width:'5%', color: colBody2}}>STOCK DESG. CJ</th>
                        <th className="align-middle" style={{width:'5%', color: colBody2}}>STOCK DESG. UND</th>
                        {hideColum?null:<th className="align-middle" style={{width:'15%'}}>CANT.</th>}
                        <th className="align-middle" style={{width:'10%'}}>VALORIZADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {readjustmentState.comparatives.map((item, index) => {
                        return (
                            <tr key={index} value={item.productTariffID} style={Number(item.quantity)>0?rowBody2:Number(item.quantity)<0?rowBody1:rowBody3}>
                            <td className="align-middle">{item.productCode}</td>
                            <td className="align-middle">{item.productClassificationDisplay}</td>
                            <td className="align-middle h6">{item.productSku}</td>
                            <td className="align-middle h6">{item.productSaleName}</td>
                            <td className="align-middle">{Number(item.priceSale).toFixed(6)}</td>
                            <td className="align-middle text-center h5" style={{color: colBody1}}>{item.stockA}</td>
                            <td className="align-middle text-center h5" style={{color: colBody2}}>{ Math.floor(Number(item.stockA) / Number(item.quantityMaximum)) }</td>
                            <td className="align-middle text-center h5" style={{color: colBody2}}>{ Number(item.stockA) % Number(item.quantityMaximum) }</td>
                            {hideColum?null:
                            <td className="text-center">
                                    {item.remainingQuantityInTariffs.map((tariffObj, index) => {
                                    
                                    return (
                                        <div className="input-group mb-1" key={index}>
                                            <div className="input-group-prepend w-50">
                                            <span className={tariffObj.unitName=="CJ"?"input-group-text w-100 bg-danger px-0":tariffObj.unitName=="UND"?"input-group-text w-100 bg-secondary px-0":"input-group-text w-100 bg-success px-0"}>{tariffObj.unitName}</span>
                                            </div>
                                            <input type="number" 
                                            onWheel={e => e.target.blur()}
                                            className="form-control text-center px-0" value={tariffObj.portion}
                                            onChange={(e) => {
                                                
                                                if(Number(e.target.value)>=0){
                                                    
                                                    setReadjustmentState({...readjustmentState, comparatives:readjustmentState.comparatives.map((obj) =>{
                                                        if(obj.productID == item.productID){

                                                            item.quantity = 0;

                                                            obj.remainingQuantityInTariffs = item.remainingQuantityInTariffs.map((tariff)=>{

                                                                if (Number(tariff.productTariffID) === Number(tariffObj.productTariffID)){

                                                                    tariff.portion = e.target.value;
                                                                    
                                                                }
                                                                
                                                                if(readjustmentState.readjustmentMode == "E") 
                                                                    item.quantity = Number(item.quantity) + Number(tariff.portion) * tariff.quantityMinimum;
                                                                else
                                                                    item.quantity = Number(item.quantity) - Number(tariff.portion) * tariff.quantityMinimum;
                                                                
                                                                return tariff
                                                            });
                                                            
                                                            item.stockA = item.fixedStockA + Number(item.quantity);
                                                            if(item.stockA<0)document.getElementById('btn-register-readjustment').disabled=true;
                                                            else document.getElementById('btn-register-readjustment').disabled=false;
                                                        }
                                                        
                                                        return obj;
                                                    })});

                                                }
                                            }}
                                            />
                                            {/*<div className="input-group-append w-25"><span className="input-group-text w-100  px-0">{tariffObj.stock}</span></div>*/}
                                        </div>
                                        
                                    );
                                    })}
                                

                            </td>
                            }
                              <td className="align-middle">{Number(item.priceSale*item.stockA).toFixed(2)}</td>
                            </tr>
                        );
                        })} 
                    </tbody>
                    </table>
                  </div>

                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-outline-secondary mt-3 ml-1"
                    table="table-to-xls"
                    filename="report_of_readjustments"
                    sheet="report"
                    buttonText="Descargar como XLS"
                    
                    />
                    <button type="button" onClick={handleExport} className="btn btn-outline-secondary mt-3 ml-1"> Ocultar Cantidad</button>

                </>
                ):""}



                
            </div>
          </div>
        </div>
      </section>

    
    </>
  )
}
