import React from "react";
import { map } from "lodash";
export function FragmentTableUser(props) {
  const { users, updateUser, onDeleteUser, addPpe } = props;
  return (
    <>
    
      <table className="table table-striped">
        <thead>
          <tr className="text-left">
            <th className="text-center align-middle" style={{ width: 10 }}>
              Nº
            </th>
            <th className="text-center align-middle">Documento</th>
            <th className="align-middle">Nombres</th>
            <th className="align-middle">Apellidos</th>
            <th className="align-middle">Correo</th>
            <th className="text-center align-middle">Celular</th>
            <th className="text-center align-middle">Ruta</th>
            <th className="text-center align-middle">Almacen</th>
            <th className="text-center align-middle">Super usuario</th>
            <th className="text-center align-middle">Permitir acceso<br/> fuera de horario</th>
            <th className="text-center align-middle">Estado</th>
            <th className="text-center align-middle">Acción</th>
          </tr>
        </thead>
        <tbody>
          {map(users, (user, index) => (
            <tr className="text-left" key={index}>
              <td className="align-middle text-center">{user.code}</td>
              <Epps user={user} addPpe={addPpe} />
              <td className="align-middle">{user.first_name}</td>
              <td className="align-middle">{user.last_name}</td>
              <td className="align-middle">{user.email}</td>
              <td className="align-middle text-center">{user.phone}</td>
              <td className="align-middle text-center">{user.gang_name}</td>
              <td className="align-middle text-center">{user.warehouse_name}</td>
              <td className="align-middle text-center">
                {user.is_superuser ? (
                  <i className="fa fa-check-circle  text-success"></i>
                ) : (
                  <i className="fas fa-times-circle text-danger"></i>
                )}
              </td>
              <td className="align-middle text-center">
                {user.is_staff ? (
                  <i className="fa fa-check-circle  text-success"></i>
                ) : (
                  <i className="fas fa-times-circle text-danger"></i>
                )}
              </td>
              <td className="align-middle text-center">
                {user.is_active ? (
                  <i className="fa fa-check-circle  text-success"></i>
                ) : (
                  <i className="fas fa-times-circle text-danger"></i>
                )}
              </td>
              <Actions
                user={user}
                updateUser={updateUser}
                onDeleteUser={onDeleteUser}
              />
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="text-center">
            <td
              className="font-weight-normal align-middle bg-primary"
              colSpan={11}
            >
              Lista de empleados
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

function Actions(props) {
  const { user, updateUser, onDeleteUser } = props;
  return (
    <td className="align-middle text-center">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-widget"
        className="btn btn-warning btn-sm item-edit m-1"
        onClick={() => updateUser(user)}
      >
        <i className="fa fa-pencil-alt fa-1x"></i>
      </button>
      <button
        type="button"
        className="btn btn-danger btn-sm m-1"
        onClick={() => onDeleteUser(user)}
      >
        <i className="fas fa-trash-alt"></i>
      </button>
    </td>
  );
}
function Epps(props) {
  const { user, addPpe } = props;
  return (
    <td className="align-middle text-center">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-widget"
        className="btn btn-warning btn-sm item-edit m-1"
        onClick={() => addPpe(user)}
      >
        {user.document}
      </button>
    </td>
  );
}
