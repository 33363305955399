import React, { useState, useEffect, useContext } from "react";
import { fetchedWarehousesBySubsidiary } from "../../../apis/apis.logistics/apisProduct";
import { queryDocumentReniec } from "../../../apis/apis.logistics/apisSunat";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";

import {
  personDocumentTypes,
  operationDocumentTypes,
} from "../../../configs/Data";

export function ReceiveOrderPurchaseForm({ order, setOrder }) {
  const { auth } = useContext(ContextAuth);
  const [warehouses, setWarehouses] = useState([]);
  const getWarehouses = async (id) => {
    try {
      const response = await fetchedWarehousesBySubsidiary({
        subsidiaryID: id,
      });
      setWarehouses(response);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getWarehouses(auth.data.subsidiary.subsidiary_id);
  }, []);
  return (
    <>
      <div className="card bg-light ">
        <div className="card-body" style={{ fontSize: "small" }}>
          <p>DATOS OPERACION</p>
          <hr />

          <div className="row mb-2">
            <div className="col-auto">
              <label className="">TIP/NUM DOC:</label>
            </div>
            <div className="col-auto">
              <select
                className="form-control text-uppercase"
                name="operationDocumentTypeID"
                onChange={(e) => {
                  setOrder({
                    ...order,
                    operationDocumentTypeID: e.target.value,
                  });
                }}
                value={order.operationDocumentTypeID}
              >
                {operationDocumentTypes.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control"
                name="operationDocumentNumber"
                placeholder="Ingrese Texto"
                onChange={(e) => {
                  setOrder({
                    ...order,
                    [e.target.name]: e.target.value,
                  });
                }}
                value={order.operationDocumentNumber}
                autoComplete="off"
                maxLength={15}
              />
            </div>


            <div className="col-auto">
              <label className="">ALMACEN:</label>
            </div>
            <div className="col-auto">
              <select
                className="form-control text-uppercase"
                name="warehouseID"
                onChange={async (e) => {
                    const found = await warehouses.find(function (warehouse, index) {
                      if (warehouse.warehouseID == e.target.value) return true;
                    });
                    if (found) {
                
                      setOrder({
                        ...order,
                        warehouseID: found.warehouseID,
                        warehouseName: found.warehouseName,
                        warehouseCategory: found.warehouseCategory,
                        userID: auth.data.user_id,
                      });
                
                    }
                  }}
                value={order.warehouseID}
              >
                <option value="0">Elegir</option>
                {warehouses.map((item, index) => {
                  return (
                    <option key={index} value={item.warehouseID}>
                      {item.warehouseName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-auto">
              <label className="">FECHA DE RECEPCION:</label>
            </div>
            <div className="col-auto">
              <input
                type="date"
                name="operationDate"
                className="form-control"
                value={order.operationDate}
                onChange={(e) => {
                    setOrder({ ...order, [e.target.name]: e.target.value });
                  }}
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
