import React, { useEffect, useRef, useContext, useState } from 'react';
import { ContextAuth } from "../../../contexts/ContextAuth";
import Chart from 'chart.js/auto';
import { DailyRouteGraphByDayFilter } from "../fragments.dispatches/DailyRouteGraphByDayFilter";

export function DailyRouteGraphByDayPage() {
        
    const pieChartRef = useRef(null);

    const { auth } = useContext(ContextAuth);
    
    const [dailyRouteData, setDailyRouteData] = useState({
        allThumbtacks:0,
        grayThumbtacks:0,
        blueThumbtacks:0,
        greenThumbtacks:0,
        redThumbtacks:0,
        yellowThumbtacks:0,
        skyBlueThumbtacks:0,
        darkGreenThumbtacks:0,
        fuchsiaThumbtacks:0,
        all_thumbtacks:1,
    });


    useEffect(() => {
    //getSalesForChartPie();
    console.log(auth.data)
    // dailyRouteData.all_thumbtacks = dailyRouteData.redThumbtacks + dailyRouteData.blueThumbtacks + dailyRouteData.yellowThumbtacks + dailyRouteData.greenThumbtacks + dailyRouteData.grayThumbtacks;

    const pieChartData = {
      /*labels: [
        'SIN COMPRA', 'PENDIENTES', 
        'PREVENTAS - PENDIENTE', 
        'PREVENTAS - CONFIRMADOS', 
        'PREVENTAS - ENTREGADO CONFORME', 
        'PREVENTAS - ENVIO FALLIDO', 
        'VENDIDOS', 'DADOS DE BAJA'],*/
      datasets: [{
        data: [
          dailyRouteData.redThumbtacks, dailyRouteData.blueThumbtacks, 
          dailyRouteData.yellowThumbtacks, 
          dailyRouteData.skyBlueThumbtacks, 
          dailyRouteData.darkGreenThumbtacks, 
          dailyRouteData.fuchsiaThumbtacks, 
          dailyRouteData.greenThumbtacks, dailyRouteData.grayThumbtacks],
        backgroundColor: [
          '#D32F2F', '#303F9F', 
          '#FBCB0A', 
          '#9AD0EC', 
          '#125B50', 
          '#f012be', 
          '#07bc0c', '#D3D3D3']
      }]
    };

    const pieChartOptions = {
      responsive: true,
      //maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: '',
          font: {
            size: 16
          }
        }
      }
    };

    const pieChart = new Chart(pieChartRef.current, {
      type: 'pie',
      data: pieChartData,
      options: pieChartOptions
    });

    return () => {
        pieChart.destroy();
        };
    }, [dailyRouteData]);

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                    <h1>Ventas por ruta </h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                        <a href="#">Distribuciones</a>
                        </li>
                        <li className="breadcrumb-item active">Listado</li>
                    </ol>
                    </div>
                </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-header"></div>
                        <div className="card-body">

                            <DailyRouteGraphByDayFilter setDailyRouteData={setDailyRouteData} />
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-4'>
                                        <table className='table table-sm mt-3'>
                                        <tbody>
                                        <tr>
                                        <td className='text-center'></td>
                                        <td className='text-center align-middle'>DESCRIPCION</td>
                                        <td className='text-center'>CANT.</td>
                                        <td className='text-center'>%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#D3D3D3'}}><i className="fas fa-chart-area fa-lg" style={{color: '#D3D3D3'}}></i></span></td>
                                        <td className='text-left align-middle'>DADOS DE BAJA</td>
                                        <td className='text-center'>{dailyRouteData.grayThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.grayThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#3F51B5', color: '#FFF'}}><i className="fas fa-chart-area fa-lg" style={{color: '#3F51B5'}}></i></span></td>
                                        <td className='text-left align-middle'>PENDIENTES</td>
                                        <td className='text-center'>{dailyRouteData.blueThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.blueThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#D32F2F', color: '#FFF'}}><i className="far fa-chart-bar fa-lg" style={{color: '#D32F2F'}}></i></span></td>
                                        <td className='text-left align-middle'>SIN COMPRA</td>
                                        <td className='text-center'>{dailyRouteData.redThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.redThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#00a000'}}><i className="fas fa-chart-pie fa-lg" style={{color: '#00a000'}}></i></span></td>
                                        <td className='text-left align-middle'>AUTOVENTA - VENDIDOS</td>
                                        <td className='text-center'>{dailyRouteData.greenThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.greenThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#FBCB0A'}}><i className="fas fa-chart-area fa-lg" style={{color: '#FBCB0A'}}></i></span></td>
                                        <td className='text-left align-middle'>PREVENTAS - PENDIENTES</td>
                                        <td className='text-center'>{dailyRouteData.yellowThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.yellowThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#9AD0EC'}}><i className="fas fa-chart-area fa-lg" style={{color: '#9AD0EC'}}></i></span></td>
                                        <td className='text-left align-middle'>PREVENTAS - ENVIADO</td>
                                        <td className='text-center'>{dailyRouteData.skyBlueThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.skyBlueThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#125B50', color: '#FFF'}}><i className="fas fa-chart-line fa-lg" style={{color: '#125B50'}}></i></span></td>
                                        <td className='text-left align-middle'>PREVENTAS - ENTREGADO CONFORME</td>
                                        <td className='text-center'>{dailyRouteData.darkGreenThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.darkGreenThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        <tr>
                                        <td className='text-center'><span className="badge" style={{backgroundColor: '#f012be'}}><i className="fas fa-chart-area fa-lg" style={{color: '#f012be'}}></i></span></td>
                                        <td className='text-left align-middle'>PREVENTAS - ENVIO FALLIDO</td>
                                        <td className='text-center'>{dailyRouteData.fuchsiaThumbtacks}</td>
                                        <td className='text-center'>{Number(dailyRouteData.fuchsiaThumbtacks*100/dailyRouteData.all_thumbtacks).toFixed(2)}%</td>
                                        </tr>

                                        
                                        <tr>
                                        <td className='text-center'></td>
                                        <td className='text-center align-middle'>TOTAL</td>
                                        <td className='text-center'>{dailyRouteData.all_thumbtacks}</td>
                                        <td className='text-center'>{100}%</td>
                                        </tr>

                                        
                                        <tr>
                                        <td className='text-center'></td>
                                        <td className='text-center align-middle'>TOTAL CLIENTES</td>
                                        <td className='text-center'>{dailyRouteData.allThumbtacks}</td>
                                        <td className='text-center'>{100}%</td>
                                        </tr>
                                        </tbody>
                                        

                                        </table>
                                        </div>
                                        <div className='col-3'>
                                        <canvas ref={pieChartRef} style={{ height: '400px' }} />

                                        </div>

                                        
                                        <div className='col-5'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}