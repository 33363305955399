import React, { useState, useEffect, useContext, createContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

export function ProductBrandList() {

  const { brands, itemProductBrand, setItemProductBrand, setBrands } =
    useContext(GlobalContext);

  useEffect(() => {}, [setBrands]); // <-- Have to pass in [] here!

  return (
    <>
      <button
        className="btn btn-light border my-3"
        data-toggle="modal"
        data-target="#modal-product-brand"
        onClick={() => setItemProductBrand({})}
      >
        Agregar Nueva Marca
      </button>
      <p className="float-right">Total brands list: {brands.length}</p>
      <table className="table table-sm table-bordered font-weight-light">
        <thead>
          <tr>
            <th className="align-middle text-center text-uppercase">codigo</th>
            <th className="align-middle text-center text-uppercase">nombre</th>
            <th className="align-middle text-center text-uppercase">
              acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {brands.map((item, index) => {
            return (
              <tr key={index} value={item.productBrandID}>
                <td>{item.productBrandID}</td>
                <td>{item.name}</td>
                <td>
                  <button
                    className="btn btn-light border"
                    data-toggle="modal"
                    data-target="#modal-product-brand"
                    onClick={() => setItemProductBrand(item)}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
